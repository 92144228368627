<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 1048px;"
      >
        <div class="modal-header">
          <div class="dialog-header-title">
            <header class="dialog-title">
              {{ editMode ? 'Edit' : 'Create' }} Campaign
            </header>
          </div>
          <button
            class="red-close-button"
            @click="closeModal"
          >
            <i class="pi pi-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="d-flex flex-column flex-item">
            <div class="campaign-edit-dialog">
              <CampaignEditForm
                v-if="modalMounted"
                :campaign="campaign"
                :campaign-info="campaign_info"
                :company-pharmacies="companyPharmacies"
                :edit-mode="editMode"
                @campaign-updated="handleCampaignUpdated"
                @close-modal="closeModal"
              />
              <ProgressSpinner
                v-else
                style="width: 100%; margin: 0 auto; margin-top: 50px;"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div
            v-if="errorMessage !==''"
            class="error-text"
          >
            <span
              class="red-error-icon"
            >!</span>
            <span class="error-text-span">{{ errorMessage }}</span>
          </div>
      
          <div
            style="margin-left: auto;"
          >
            <Button
              label="Cancel"
              severity="secondary"
              class="button-light-grey mr-4"
              @click="closeModal()"
            />
            <Button
              :label="editMode ? 'Save' : 'Activate'"
              :loading="loadingSaveCampaign"
              class="button-black"
              @click="openUserAuthenticationModal"
            />
          </div>
        </div>
      </div>
      <UserAuthenticationModal 
        v-if="displayUserAuthenticationModal"
        :name="campaign_data.name"
        :payload-type="'campaign'"
        :updates="templatesToBeUpdated"
        :request-type="editMode ? 'Save' : 'Activate'"
        @login-success="saveCampaign"
        @close-modal="displayUserAuthenticationModal = false"
      />
    </div>
  </div>
</template>

<script>
import CampaignEditForm from '@/components/campaign/form/campaign-edit-form.vue';
import UserAuthenticationModal from '@/components/modals/campaign-template-authentication-modal.vue';
import axios from 'axios';

export default {
  components: {
    CampaignEditForm,
    UserAuthenticationModal,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    companyPharmacies: {
      type: Array,
      default: () => []
    }
  },
  emits: [
    'closeModal',
    'campaignUpdated',
    'campaignCreated'
  ],
  data() {
    return {
      campaign_info: {},
      modalMounted: false,
      editMode: false,
      errorMessage: '',
      loadingSaveCampaign: false,
      campaign_data: {},
      templatesToBeUpdated: [],
      displayUserAuthenticationModal: false,
    }
  },
  computed: {
    /**
     * Create campaign data
     * @returns {Object}
     */
    createCampaignData() {
      let startDate = new Date();
      startDate.setHours(0, 0, 0, 0);

      let endDate = this.campaign_data.end_date ? new Date(this.campaign_data.end_date) : null;
      if (endDate) {
        endDate.setHours(23, 59, 59, 999);
      }

      let patientFilters = {};
      if (this.campaign_data.patient_cohort !== null && this.campaign_data.patient_cohort !== undefined) {
        patientFilters = this.campaign_data.patient_cohort;
        let molecules = []
        if (this.campaign_data.patient_cohort.molecules !== null && this.campaign_data.patient_cohort.molecules !== undefined) {
          this.campaign_data.patient_cohort.molecules.forEach((molecule) => {
            molecules = molecules.concat(molecule.atc_code);
          });
        }
        patientFilters.molecules = molecules;
      }

      return {
        name: this.campaign_data.name,
        filters: patientFilters,
        note: this.campaign_data.description,
        start_date: startDate,
        end_date: endDate,
        goal_type: this.campaign_data.goal_type,
        measure_by: this.campaign_data.measure_by,
        goal: this.campaign_data.goal,
        active: true,
        templates: this.campaign_data.templates,
        locations: this.campaign_data.pharmacies,
      }
    },
    /**
     * Edit campaign data
     * @returns {Object}
     */
    editCampaignData() {
      let startDate = this.campaign_info.start_date ? new Date(this.campaign_info.start_date) : new Date();
      startDate.setHours(0, 0, 0, 0);
     
      let endDate = this.campaign_data.end_date ? new Date(this.campaign_data.end_date) : null;
      if (endDate) {
        endDate.setHours(23, 59, 59, 999);
      }
      

      let patientFilters = {};
      if (this.campaign_data.patient_cohort !== null && this.campaign_data.patient_cohort !== undefined) {
        patientFilters = this.campaign_data.patient_cohort;
        let molecules = []
        if (this.campaign_data.patient_cohort.molecules !== null && this.campaign_data.patient_cohort.molecules !== undefined) {
          this.campaign_data.patient_cohort.molecules.forEach((molecule) => {
            molecules = molecules.concat(molecule.atc_code);
          });
        }
        patientFilters.molecules = molecules;
      }

      return {
        name: this.campaign_data.name,
        filters: patientFilters,
        note: this.campaign_data.description,
        start_date: startDate,
        end_date: endDate,
        goal_type: this.campaign_data.goal_type,
        measure_by: this.campaign_data.measure_by,
        goal: this.campaign_data.goal,
        active: this.campaign_data.active,
        templates: this.campaign_data.templates,
        locations: this.campaign_data.pharmacies,
      }
    },
  },
  async mounted() {
    if (this.campaign.campaign_id) {
      this.editMode=true;
      await this.getCampaignInfo();
    }
    this.modalMounted = true;
  },
  methods: {
     /**
     * Get the campaign info
     */
     async getCampaignInfo() {
      this.$store.dispatch('getRefreshToken');
      await axios.post('/api/campaign/' + this.campaign.campaign_id + '/info/enterprise', {}, this.$store.state.header)
      .then(res => {
        this.handleGetCampaignInfoSuccess(res);
      })
      .catch(err => {
        this.handleGetCampaignInfoError(err);
      });
    },
    /**
     * Handle the get campaign info success case
     * @param {Object} res
     */
    handleGetCampaignInfoSuccess(res) {
      console.log('The get campaign info response is', res.data);
      this.campaign_info = res.data;
      if (this.campaign_info.end_date) {
        this.campaign_info.end_date = new Date(this.campaign_info.end_date);
      }
      this.campaign_data.patient_cohort = this.campaign_info.filters;
    },
    /**
     * Handle the get campaign info error case
     * @param {Object} err
     */
    handleGetCampaignInfoError(err) {
      console.log('The get campaign info error is', err.response.data);
    },
    /**
     * Validate the campaign data
     */
     validateCampaignData() {
      this.errorMessage = '';
      
      let errors = [];

      if (!this.campaign_data.name) {
        errors.push('enter a campaign name');
      }
      if (!this.campaign_data.pharmacies.length) {
        errors.push('select at least one pharmacy');
      }
      if (!this.campaign_data.goal_type) {
        errors.push('select a goal type');
      }
      if (!this.campaign_data.measure_by) {
        errors.push('select a measure by');
      }
      if (!this.campaign_data.goal) {
        errors.push('enter a goal');
      }
      if (!this.campaign_data.templates.length) {
        errors.push('select at least one template');
      }
      // If the campaign patient cohort is empty, then show an error
      if (this.campaign_data.patient_cohort === null || this.campaign_data.patient_cohort === undefined || Object.keys(this.campaign_data.patient_cohort).length === 0) {
        errors.push('select at least one patient filter');
      }

      if (errors.length) {
        this.errorMessage = `Please ${errors.join(', ')}.`;
        return false;
      }
      return true;
    },
    /**
     * Save campaign data
     * @returns {Promise<void>}
     */
    async saveCampaign() {
      console.log(this.campaign_data)

      if (!this.validateCampaignData()) {
        return;
      }

      if (!this.editMode) {
        await this.createCampaign();
      }
      else {
        await this.updateCampaign();
      }
    },
    /**
     * Create a new campaign
     * @returns {Promise<void>}
     */
    async createCampaign() {
      console.log('The create campaign data is', this.createCampaignData);
      this.loadingSaveCampaign = true;

      await axios.post('/api/campaign/create/enterprise', this.createCampaignData, this.$store.state.header)
      .then(res => {
        this.handleCampaignCreateSuccess(res);
      }).catch(err => {
        this.handleCampaignCreateError(err);
      });      
    },
    /**
     * Handle the campaign create success case
     * @param {Object} res
     */
    handleCampaignCreateSuccess(res) {
      console.log('The campaign create response is', res.data);
      this.loadingSaveCampaign = false;
      this.$emit('campaignCreated', res.data);
      this.$emit('closeModal');
    },
    /**
     * Handle the campaign create error case
     * @param {Object} err
     */
    handleCampaignCreateError(err) {
      console.log('The campaign create error is', err.response.data);
      this.loadingSaveCampaign = false;
    },
    /**
     * Update an existing campaign
     * @returns {Promise<void>}
     */
    async updateCampaign() {
      this.loadingSaveCampaign = true;

      await axios.post(`/api/campaign/${this.campaign_data.campaign_id}/edit/enterprise`, this.editCampaignData, this.$store.state.header)
      .then(res => {
        this.handleCampaignUpdateSuccess(res);
      }).catch(err => {
        this.handleCampaignUpdateError(err);
      });
    },
    /**
     * Handle the campaign update success case
     * @param {Object} res
     */
    handleCampaignUpdateSuccess(res) {
      console.log('The campaign update response is', res.data);
      this.loadingSaveCampaign = false;
      this.$emit('campaignUpdated', res.data);
      this.$emit('closeModal');
    },
    /**
     * Handle the campaign update error case
     * @param {Object} err
     */
    handleCampaignUpdateError(err) {
      console.log('The campaign update error is', err.response.data);
      this.loadingSaveCampaign = false;
    },
    /**
     * Handle the campaign updated event
     * @param {Object} campaign
     */
    handleCampaignUpdated(campaign) {
      console.log('The campaign data is', campaign);
      this.campaign_data = campaign;
    },
    /**
     * Open the user authentication modal
     */
    openUserAuthenticationModal() {
      if (!this.validateCampaignData()) {
        return;
      }
      this.displayUserAuthenticationModal = true;
    },
    /**
     * Close the modal
     */
    closeModal() {
      this.$emit('closeModal');
    },
  }
}
</script>

<style lang="scss" scoped>
.modal-container {
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  height: 90vh;
  min-width: 80%;
}

.p-button {
  font-size: 14px;
  border-radius: 6px;
  border: none;
  min-width: 100px;
  font-weight: 700;
}

.modal-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 20px 20px 5px 20px;
}

.button-black {
  background: #101820;
  color: #FFFFFF;
}

.button-black:hover {
  background: #000000 !important;
}

.button-light-grey {
  background: #D8DCDC;
  color: #7B7E7E;
}

.button-light-grey:hover {
  background: #b5bbbb !important;
  color: #7B7E7E !important;
}

.error-text {
  max-width: 70%;
}

.campaign-edit-dialog {
  display: flex;
  flex-direction: column;
  text-align: left;
  flex: 1;
}
.modal-footer {
  padding-bottom: 20px;
}
</style>