<template>
  <div class="background">
    <!-- LOCATION DETAILS -->
    <div class="location-details">
      <div class="detail-item">
        <div class="item-data">
          <span class="header">Details</span>
        </div>
        <!-- EDIT LOCATION -->
        <Button
          icon="pi pi-pencil"
          outlined
          rounded
          severity="info"
          @click="openEditPharmacyModal"
        />
      </div>
      <!-- ADDRESS -->
      <div class="detail-item">
        <span class="detail-item-header">Address</span>
        <span class="detail-item-value">{{ selectedLocationLocal.street_address }}, {{ selectedLocationLocal.town }}{{ countryCode == 'AU' ? ' ' + selectedLocationLocal.region : '' }} {{ selectedLocationLocal.postcode }}, {{ selectedLocationLocal.country_name }}</span>
      </div>
      <!-- LOCATION KEY -->
      <div class="detail-item">
        <div class="item-data">
          <span class="detail-item-header">Location Key</span>
          <span class="detail-item-value">{{ selectedLocationLocal.key }}</span>
        </div>
        <Button
          icon="pi pi-copy"
          outlined
          rounded
          severity="info"
          @click="copyToClipBoard(selectedLocationLocal.key)"
        />
      </div>
      <!-- LOCATION UUID -->
      <div class="detail-item">
        <div class="item-data">
          <span class="detail-item-header">Location UUID</span>
          <span class="detail-item-value">{{ selectedLocationLocal.uuid }}</span>
        </div>
        <Button
          icon="pi pi-copy"
          outlined
          rounded
          severity="info"
          @click="copyToClipBoard(selectedLocationLocal.uuid)"
        />
      </div>
    </div>
    <!-- MODULES -->
    <div class="location-details">
      <div class="detail-item">
        <div class="item-data">
          <span class="header">Modules</span>
        </div>
        <!-- ADD MODULES -->
        <Button
          icon="pi pi-plus"
          outlined
          rounded
          severity="info"
          @click="loadModules"
        />
      </div>
      <div
        v-if="displayAddModuleDropdown"
        class="flex-row"
      >
        <Dropdown
          v-model="selectedModule"
          class="flex-item"
          :loading="loadingModules"
          :options="availableModules"
          option-label="name"
        />
        <InputText
          v-if="selectedModule && selectedModule.name == 'pharmaprograms'"
          v-model="selectedModuleExternalId"
          placeholder="External ID"
        />
        <Button
          type="button"
          label="Add"
          :disabled="!selectedModule || addingModule"
          @click="addModule"
        />
      </div>
      <div class="modules-list">
        <Tag
          v-for="(item, index) in selectedLocationLocal.modules"
          :key="index"
          :style="tagStyle(item)"
        >
          <div class="tag">
            <span>{{ item.display_name }}</span>
            <i
              class="pi pi-times close-icon"
              @click="removeModule(item)"
            ></i>
          </div>
        </Tag>
      </div>
    </div>
    <div class="location-details">
      <!-- MENU ACTIONS -->
      <Button
        type="button"
        severity="info"
        label="Actions"
        icon="pi pi-angle-down"
        aria-haspopup="true"
        aria-controls="action_overlay_menu"
        icon-pos="right"
        style="width: 250px;"
        @click="toggleActionsMenu"
      />
      <Menu
        id="action_overlay_menu"
        ref="actions_menu"
        :model="actionsMenuItems"
        :popup="true"
      />
    </div>
  </div>
  <EditPharmacyModal
    v-if="displayEditPharmacyModal"
    :pharmacy="selectedLocationLocal"
    @close-modal="closeModal"
  />
  <AdminMessageHistoryModal
    v-if="displayMessageHistoryReportModal"
    :pharmacy="location"
    @close-modal="closeModal"
  />
  <AdminDoubleDispensingReportModal
    v-if="displayDoubleDispensingReportModal"
    :pharmacy="location"
    @close-modal="closeModal"
  />
  <FakePatientsModal
    v-if="displayFakePatientsModal"
    :pharmacy="location"
    @close-modal="closeModal"
  />
</template>

<script>
import EditPharmacyModal from '@/components/modals/edit-pharmacy-modal.vue'
import AdminMessageHistoryModal from '@/components/modals/admin-message-history-modal.vue';
import AdminDoubleDispensingReportModal from '@/components/modals/admin-double-dispensing-report-modal.vue';
import FakePatientsModal from '@/components/modals/fake-patients-modal';
import axios from 'axios';

export default {
  components: {
    EditPharmacyModal,
    AdminMessageHistoryModal,
    AdminDoubleDispensingReportModal,
    FakePatientsModal,
  },
  props: {
    /**
     * The location data
     * @type {Objecy}
     */
    location: {
      type: Object,
      default() {
        return {
        }
      }
    }
  },
  data() {
    return {
      displayEditPharmacyModal: false,
      removingModule: false,
      removeModuleError: '',
      removeModuleSuccess: '',
      displayAddModuleDropdown: false,
      availableModules: [],
      loadingModules: false,
      loadModulesError: '',
      loadModulesSuccess: '',
      selectedModule: null,
      addingModule: false,
      addModuleError: '',
      addModuleSuccess: '',
      selectedModuleExternalId: '',
      selectedLocationLocal: {},
      displayMessageHistoryReportModal: false,
      displayDoubleDispensingReportModal: false, // show 60 day dispensing report modal
      displayFakePatientsModal: false,
      actionsMenuItems: [
        {
          label: 'Message History Report',
          icon: 'pi pi-envelope',
          command: () => {
            this.openMessageHistoryReportModal();
          }
        },
        {
          label: '60 Day Dispensing Report',
          icon: 'pi pi-envelope',
          command: () => {
            this.openDoubleDispensingReportModal();
          }
        },
        {
          label: 'Fake Patients',
          icon: 'pi pi-users',
          command: () => {
            this.openFakePatientsModal();
          }
        }
      ]
    }
  },
  computed: {
  },
  mounted() {
    /**
     * Set the local variables to the props
     * This is required to ensure that the local variables can be updated prior to emitting changes
     */
    this.selectedLocationLocal = this.location;
  },
  methods: {
    /**
     * Remove the module from the location
     * @param {Object} module - The module to remove
     */
    async removeModule(module) {
      this.$store.dispatch('getRefreshToken');
      this.removeModuleError = '';
      this.removeModuleSuccess = '';
      this.removingModule = true;

      await axios.delete(`/api/key/${this.selectedLocationLocal.key}/module/${module.uuid}`, this.$store.state.header)
      .then(res => {
        this.handleRemoveModuleSuccess(res);
      }).catch(err => {
        this.handleRemoveModuleError(this.handleAxiosError(err));
      })
    },
    /**
     * Handle the success response for removing the module
     * @param {Object} res
     */
    handleRemoveModuleSuccess(res) {
      console.log('The remove module response is: ', res);
      this.removingModule = false;
      this.removeModuleSuccess = 'Module Removed Successfully.';
      this.selectedLocationLocal.modules = res.data.data;
    },
    /**
     * Handle the error response for removing the module
     * @param {Object} err
     */
    handleRemoveModuleError(err) {
      console.log('The remove module error is: ', err);
      this.removingModule = false;
      this.removeModuleError = err;
    },
    /**
     * Load the available modules and populate into the dropdown
     */
    async loadModules() {
      this.$store.dispatch('getRefreshToken');
      this.displayAddModuleDropdown = true;
      this.availableModules = [];
      this.loadingModules = true;
      this.loadModulesError = '';
      this.loadModulesSuccess = '';

      await axios.post(`/api/key/${this.selectedLocationLocal.key}/available-modules`, {}, this.$store.state.header)
      .then(res => {
        this.handleLoadModulesSuccess(res);
      }).catch(err => {
        this.handleLoadModulesError(this.handleAxiosError(err));
      })
    },
    /**
     * Handle the success response for loading the modules
     * @param {Object} res
     */
    handleLoadModulesSuccess(res) {
      console.log('The load modules response is: ', res);
      this.loadingModules = false;
      this.loadModulesSuccess = 'Modules Loaded Successfully.';
      this.availableModules = res.data.data;
    },
    /**
     * Handle the error response for loading the modules
     * @param {Object} err
     */
    handleLoadModulesError(err) {
      console.log('The load modules error is: ', err);
      this.loadingModules = false;
      this.loadModulesError = err;
    },
    /**
     * Add the module to the location
     */
    async addModule() {
      this.$store.dispatch('getRefreshToken');
      this.addModuleError = '';
      this.addModuleSuccess = '';
      this.addingModule = true;

      await axios.post(`/api/key/${this.selectedLocationLocal.key}/module/${this.selectedModule.uuid}`, { external_id: this.selectedModuleExternalId }, this.$store.state.header)
      .then(res => {
        this.handleAddModuleSuccess(res);
      }).catch(err => {
        this.handleAddModuleError(this.handleAxiosError(err));
      })
    },
    /**
     * Handle the success response for adding the module
     * @param {Object} res
     */
    handleAddModuleSuccess(res) {
      console.log('The add module response is: ', res);
      this.addingModule = false;
      this.addModuleSuccess = 'Module Added Successfully.';
      this.selectedModule = null;
      this.selectedLocationLocal.modules = res.data.data;
      // Reload the available modules
      this.loadModules();
    },
    /**
     * Handle the error response for adding the module
     * @param {Object} err
     */
    handleAddModuleError(err) {
      console.log('The add module error is: ', err);
      this.addingModule = false;
      this.addModuleError = err;
    },
    /**
     * Toggle the actions menu visibility
     * @param {Object} event - The event object
     */
    toggleActionsMenu(event) {
      this.$store.dispatch('getRefreshToken');
      this.$refs.actions_menu.toggle(event);
    },
    /**
     * Copy text to clipboard
     * @param string text - The text to copy
     */
    copyToClipBoard(text) {
      navigator.clipboard.writeText(text);      
    },
    /**
     * Open the edit pharmacy modal
     */
    openEditPharmacyModal() {
      this.$store.dispatch('getRefreshToken');
      this.displayEditPharmacyModal = true;
    },
    /**
     * Open the message history report modal
     */
    openMessageHistoryReportModal() {
      this.$store.dispatch('getRefreshToken');
      this.displayMessageHistoryReportModal = true;
    },
    /**
     * Open the 60 day dispensing report modal
     */
     openDoubleDispensingReportModal() {
      this.$store.dispatch('getRefreshToken');
      this.displayDoubleDispensingReportModal = true;
    },
    /**
     * Open the fake patients modal
     */
    openFakePatientsModal() {
      this.$store.dispatch('getRefreshToken');
      this.displayFakePatientsModal = true;
    },
    /**
     * Close the open modals
     */
    closeModal() {
      this.displayEditPharmacyModal = false;
      this.displayMessageHistoryReportModal = false;
      this.displayDoubleDispensingReportModal = false;
      this.displayFakePatientsModal = false;
    },
    tagStyle(item) {
      return {
        background: item.display_colour
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.tag {
  display: flex;
  align-items: center;
}

.background {
  display: flex;
  flex-direction: row;
}

.location-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  gap: 20px;
  max-width: 500px;
}

.detail-item {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

.item-data {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.item-button {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
}

.detail-item-header {
  font-weight: 600;
  font-size: 14px;
}

.detail-item-value {
  font-size: 14px;
  font-weight: 500;
  color: $deepgrey;
}

.header {
  color: $deepgrey;
  font-weight: 600;
  font-size: 16px;
}

.modules-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.flex-item {
  flex: 1;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

</style>