<template>
  <div
    class="view"
    style="height: fit-content; background-position: top center; background-repeat: no-repeat; background-size: cover; background-image: linear-gradient(to bottom, #FEFFFF 0%, 32.6605498790741%, #B3CEE2 65.3210997581482%, 82.6605498790741%, #8DE3DA 100%);"
  >
    <div
      class="form-row"
    >
      <h3>{{ message }}</h3>
    </div>
  </div>
  <footer>
    <div class="footer-content grey-section">
      <div class="footer-logos">
        <div class="footer-logo">
          <img
            :src="strongProLogoSrc"
            alt="StrongRoom AI Logo"
          >
          <div class="footer-tnc">
            <p>
              <a
                :href="privacyPolicyUrl"
                target="_blank"
                style="color: black; text-decoration: none;"
              >Privacy Policy</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios';

export default {
  name: 'DownloadFilePage',
  data() {
    return {
      exportingReferralsCSV: false,
      strongProLogoSrc: require('@/assets/logos/StrongPro_logo.svg'),
      message: 'Validating your request...',
    }
  },
  computed: {
    privacyPolicyUrl() {
      switch (this.countryCode) {
        case 'GB':
          return 'https://strongroom.ai/en-gb/privacy';
        case 'US':
          return 'https://strongroom.ai/en-us/privacy';
        default:
          return 'https://strongroom.ai/privacy';
      }
    },
  },
  async mounted() {
    await this.downloadFile();
  },
  methods: {
    
    async downloadFile() {
      let payload = {
        token: this.$route.params.id
      }
      this.exportingReferralsCSV = true;
      await axios.post('/api/download-file', payload)
      .then((res) => {
        // Get the CSV data and download it
        let csv = res.data;
        const anchor = document.createElement('a');
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
        anchor.target = '_blank';
        anchor.download = `strong_pro_patient_list.csv`;
        anchor.click();
        this.message = 'Downloading file. Please do not refresh';
        this.exportingReferralsCSV = false;
      })
      .catch((error) => {
        console.log('The download file error is: ', error);
        if (error.response && error.response.data.error === 'Invalid token or token has expired') {
          this.message = 'This link has expired. Return to StrongPro to create a new link.';
        } else {
          this.message = 'There was an error processing your request. Return to StrongPro to try again.';
        }
        this.exportingReferralsCSV = false;
      });
    },
  }
}
</script>
<style>
.footer-content {
  max-width: 1150px;
  margin: 0 auto;
  padding: 51px 0;

  p {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: left;
  }
}

.footer-logos {
  display: flex;
  gap: 50px;
  max-width: 800px;
}

.footer-logo {
  flex: 1;

  img {
    display: flex;
    max-width: 300px;
    height: 80px;
  }
}
</style>