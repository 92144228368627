<template>
  <div class="icon-row">
    <img
      v-if="params.toLowerCase() === 'correct'"
      v-tooltip.top="'Correct Balance'"
      :src="checkIcon"
      class="medium-icon"
      alt="Correct Balance"
    >
    <img
      v-else-if="params.toLowerCase() === 'skipped'"
      v-tooltip.top="'Balance Check Skipped'"
      :src="partialIcon"
      class="medium-icon"
      alt="Balance Skipped"
    >
    <img
      v-else-if="params.toLowerCase() === 'incorrect'"
      v-tooltip.top="'Incorrect Balance'"
      :src="crossIcon"
      class="medium-icon"
      alt="Incorrect Balance"
    >
  </div>
</template>

<script>
export default {
  name: "DrugRegisterEntryTags",
  props: {
    params: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      checkIcon: require('@/assets/icons/check_icon.svg'),
      partialIcon: require('@/assets/icons/partial_icon.svg'),
      crossIcon: require('@/assets/icons/cross_icon.svg')
    }
  }
}
</script>

<style lang="scss">
.icon-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 100%;
}

.tag {
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  color: white;
  font-weight: 600;
}
</style>
