<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Create Pharmacy
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <div class="form-row">
                <InputText
                  v-model="addPharmacyForm.name"
                  placeholder="Enter a Pharmacy Name"
                  class="grey-input flex-item"
                />
                <Dropdown
                  v-model="addPharmacyForm.owner"
                  placeholder="Select a Pharmacy Owner"
                  :options="company.logins"
                  class="flex-item"
                  option-value="uuid"
                  option-label="name"
                />
              </div>
              <div class="form-row">
                <InputText
                  v-model="addPharmacyForm.phone_number"
                  placeholder="Pharmacy Phone"
                  class="grey-input flex-item"
                />
                <vue-google-autocomplete
                  v-if="$store.getters.getMapsApiLoaded == true"
                  id="map"
                  classname="p-inputtext p-component grey-input flex-item"
                  placeholder="Pharmacy Address"
                  :country="countryCode.toLowerCase()"
                  :enable-geolocation="true"
                  @placechanged="getAddressData"
                />
                <InputText
                  v-else
                  v-model="addPharmacyForm.street_address"
                  placeholder="Enter a Street Address"
                  class="grey-input flex-item"
                />
              </div>
              <div class="form-row">
                <InputText
                  v-model="addPharmacyForm.town"
                  :placeholder="townPlaceholder"
                  class="grey-input flex-item"
                />
                <InputText
                  v-model="addPharmacyForm.postcode"
                  placeholder="Enter a Postcode"
                  class="grey-input flex-item"
                  :type="countryCode == 'AU' ? 'number' : 'text'"
                />
              </div>
              <div class="form-row">
                <Dropdown
                  v-if="countryCode == 'AU'"
                  v-model="addPharmacyForm.region"
                  placeholder="Select a State"
                  :options="states"
                  class="flex-item"
                />
                <InputText
                  v-model="addPharmacyForm.country_name"
                  placeholder="Enter a Country"
                  class="grey-input flex-item"
                />
              </div>
              <Dropdown
                v-model="addPharmacyForm.data_source"
                :options="dispensingSources"
                option-label="name"
                option-value="key"
                placeholder="Select a Data Source"
                class="grey-input"
              />
              <div class="form-row">
                <MultiSelect
                  v-model="selectedModules"
                  :options="pharmacyModules"
                  option-label="display_name"
                  placeholder="Select Modules"
                  class="grey-input flex-item"
                />
                <InputText
                  v-if="pharmaProgramsSelectedModule"
                  v-model="pharmaProgramsSelectedModule.external_id"
                  placeholder="PharmaPrograms ID"
                  class="grey-input flex-item"
                />
              </div>
              <!-- 
                The no_guild_consent value is a boolean value
                The checkbox is checked if the value is true
              -->
              <div class="flex-input flex-item">
                <label for="no_guild_consent">No Guild Consent</label>
                <Checkbox
                  v-model="addPharmacyForm.no_guild_consent"
                  input-id="no_guild_consent"
                  :binary="true"
                />
              </div>
            </div>
            <p
              v-if="createPharmacyError"
              class="error-message"
            >
              {{ createPharmacyError }}
            </p>
            <p
              v-if="createPharmacySuccess"
              class="success-message"
            >
              {{ createPharmacySuccess }}
            </p>
          </slot>
        </div>
  
        <!-- FOOTER -->
        <div class="modal-footer">
          <slot name="footer">
            <!-- 
                The updateFilters method is called when the confirm button is clicked
                The method emits an event to the parent component
               -->
            <div
              class="black-button"
              @click="addPharmacy"
            >
              Confirm
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  props: {
    /**
     * The selected company
     * @type {Object}
     */
    company: {
      type: Object,
      default() {
        return {
        }
      }
    },
    /**
     * The list of modules
     * @type {Array}
     */
    modules: {
      type: Array,
      default() {
        return []
      }
    }
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'closeModal'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
      loading: false,
      createPharmacyError: '',
      createPharmacySuccess: '',
      addPharmacyForm: {
        company_id: this.company.uuid,
        name: '',
        phone_number: '',
        street_address: '',
        region: '',
        postcode: '',
        country_name: '',
        town: '',
        data_source: '',
        no_guild_consent: false,
        owner: ''
      },
      selectedModules: [],
      modulesLocal: []
    }
  },
  computed: {
    /**
     * The Add Pharmacy Payload
     */
    addPharmacyPayload() {
      return {
        ...this.addPharmacyForm,
        module_ids: this.selectedModules.map((module) => ({
          module_id: module.uuid,
          external_id: module.external_id ? module.external_id : null
        }))
      }
    },
    /**
    * Get the country name
    * @returns {String}
    */
    getCountryName() {
       if (this.countryCode === 'GB') {
         return 'United Kingdom';
       }
       else {
         return 'Australia';
       }
    },
    /**
     * The town placeholder
     * town for GB and suburb for AU
     * @returns {String}
     */
     townPlaceholder() {
      if (this.countryCode === 'GB') {
        return 'Enter a Town';
      }
      else {
        return 'Enter a Suburb';
      }
    },
    /**
     * The modules which are for pharmacy
     * @return {Array}
     */
    pharmacyModules() {
      return this.modulesLocal.filter(module => module.location_type === 'pharmacy');
    },
    pharmaProgramsSelectedModule() {
      return this.selectedModules.find(module => module.name == 'pharmaprograms');
    },
  },
  mounted() {
    /**
     * Set the local variables to the prop
     */
     this.modulesLocal = this.modules.map(x => ({...x}));
     this.addPharmacyForm.country_name = this.getCountryName;
  },
  methods: {
    /**
     * Emit the close modal event
     */
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
     getAddressData(addressData, placeResultData) {
      // Calculate the address from subpremise, street_number, and route
      let street_address = '';
      if (addressData.subpremise) {
        street_address += addressData.subpremise + '/';
      }
      if (addressData.street_number) {
        street_address += addressData.street_number + ' ';
      }
      if (addressData.route) {
        street_address += addressData.route;
      }
      this.addPharmacyForm.street_address = street_address;
      
      // Postal town as town for GB and locality as town for AU
      if (this.countryCode === 'GB') {
        this.addPharmacyForm.town = placeResultData.address_components.find(x => x.types.includes('postal_town')).long_name;
      }
      else if (this.countryCode === 'AU') {
        this.addPharmacyForm.town = addressData.locality;
        this.addPharmacyForm.region = addressData.administrative_area_level_1;
      }
      
      this.addPharmacyForm.postcode = addressData.postal_code;
      this.addPharmacyForm.country_name = addressData.country;
      console.log('this is the address now', addressData, placeResultData, this.addPharmacyForm);
    },
    /**
     * Create a pharmacy
     * @returns {Promise<void>}
     */
    async addPharmacy() {
      this.$store.dispatch('getRefreshToken');
      this.createPharmacyError = '';
      this.createPharmacySuccess = '';
      this.loading = true;

      await axios.post('/api/create-company-pharmacy', this.addPharmacyPayload, this.$store.state.header)
      .then(res => {
        this.handleCreatePharmacySuccess(res);
      }).catch(err => {
        this.handleCreatePharmacyError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the success response for the pharmacy
     * @param {Object} res 
     */
    handleCreatePharmacySuccess(res) {
      console.log('The create pharmacy response is: ', res);
      this.loading = false;
      this.clear();
      this.createPharmacySuccess = 'Pharmacy Created Successfully.'
    },
    /**
     * Handle the error response for the pharmacy
     * @param {Object} err 
     */
    handleCreatePharmacyError(err) {
      console.log('The create pharmacy error is: ', err);
      this.loading = false;
      this.createPharmacyError = err;
    },
    clear() {
      this.addPharmacyForm.name = '';
      this.addPharmacyForm.street_address = '';
      this.addPharmacyForm.region = '';
      this.addPharmacyForm.postcode = '';
      this.addPharmacyForm.town = '';
      this.addPharmacyForm.data_source = '';
      this.addPharmacyForm.no_guild_consent = false;
      this.addPharmacyForm.owner = '';
      this.selectedModules = [];
    }
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.pharmacies-container {
  display: flex;
  border-radius: 5px;
  background-color: $grey;
  overflow-y: auto;
  flex: 1 1 150px;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 10px 0 10px;
}

.pharmacy-bubble {
  display: flex;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  background-color: $bluegreen;
  color: white;
  font-weight: 500;
  font-size: 11pt;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

</style>
