<template>
  <div class="view">
    <div
      class="panel flex-expand flex-column"
    >
      <!-- REFERRALS FILTERS -->
      <div class="filters-row">
        <!-- SEARCH BOX -->
        <span
          class="p-input-icon-right"
          style="flex: 0 1 350px; min-width: 200px;"
        >
          <i
            class="pi pi-search button"
            @click="getReferralsList"
          ></i>
          <InputText
            v-model="referralsSearchTerm"
            placeholder="Search"
            class="grey-input"
            @keyup.enter="getReferralsList"
          />
        </span>
        <Calendar
          v-model="dateRange"
          selection-mode="range"
          :date-format="$t('dateFormat')"
          class="grey-input"
          style="flex: 0 1 300px; min-width: 200px;"
          show-button-bar
          @date-select="getReferralsList"
        />
        <!-- REFRESH BUTTON -->
        <Button
          icon="pi pi-refresh"
          aria-label="Refresh"
          class="medium-icon p-button-raised p-button-rounded no-shrink"
          :loading="loadingReferrals"
          @click="getReferralsList"
        />
        <!-- EXPORT BUTTON -->
        <img
          v-if="!exportingReferralsCSV"
          :src="exportIconSrc"
          class="medium-icon button"
          alt="Export to CSV"
          @click="exportReferralsCSV"
        >
        <ProgressSpinner
          v-else
          class="medium-icon"
          stroke-width="8"
          animation-duration=".75"
          fill="var(--surface-ground)"
          aria-label="Loading"
        />
      </div>
      <!-- EXPORT ROW -->
      <div class="export">
        <span class="key-container">
          <span class="key-heading"> {{ $store.state.recruiter.recruiter_provider_name }} - Clinical Trials Referrals</span>
        </span>
        <!-- <img
          v-if="!exportingCSV"
          :src="exportIconSrc"
          class="small-icon button flex-right"
          alt="Export Button"
          @click="exportReferralsCSV()"
        >
        <ProgressSpinner
          v-else
          class="small-icon flex-right"
          stroke-width="8"
          animation-duration=".75"
          fill="var(--surface-ground)"
          aria-label="Loading"
        /> -->
      </div>
      <!-- REFERRALS CONTENT -->
      <div
        class="flex-column flex-item"
        style="min-height: 0;"
      >
        <DataTable
          ref="referralsTable"
          table-class="revenue-table"
          striped-rows
          :value="referralsRowData.referrals"
          :loading="loadingReferrals"
          scrollable
          scroll-direction="both"
          lazy
          paginator
          scroll-height="flex"
          style="min-height: 0; font-size: 14px;"
          class="flex-item"
          size="small"
          :sort-field="referralsSortField"
          :sort-order="referralsSortOrder"
          :rows="referralsRowData.per_page"
          :total-records="referralsRowData.per_page*referralsRowData.page_count"
          @sort="onReferralsSort($event)"
          @page="onReferralsPage($event)"
        >
          <template #empty>
            No records found
          </template>
          <template #loading>
            Loading records, please wait...
          </template>
          <Column
            field="referral_date"
            header="Referral Date"
            :sortable="true"
            style="min-width: 80px"
            exportable
          >
            <template #body="slotProps">
              <span v-if="slotProps.data.referral_date">
                {{ $d(new Date(slotProps.data.referral_date), 'short') }}
              </span>
            </template>
          </Column>
          <Column
            field="first_name"
            header="Patient First Name"
            :sortable="true"
            style="min-width: 80px"
            exportable
          />
          <Column
            field="last_name"
            header="Patient Last Name"
            :sortable="true"
            style="min-width: 80px"
            exportable
          />
          <Column
            field="phone_number"
            header="Phone"
            :sortable="true"
            style="min-width: 80px"
            exportable
          />
          <Column
            field="email"
            header="Email"
            :sortable="true"
            style="min-width: 80px"
            exportable
          />
          <Column
            field="postcode"
            header="Post Code"
            :sortable="true"
            style="min-width: 80px"
            exportable
          />
          <Column
            field="trial_name"
            header="Trial"
            :sortable="true"
            style="min-width: 80px"
            exportable
          />
          <Column
            field="location_name"
            header="Pharmacy"
            :sortable="true"
            style="min-width: 80px"
            exportable
          />
          <Column
            field="communication_type"
            header="SRAI Communication"
            :sortable="true"
            style="min-width: 80px"
          />
          <Column
            field="enrolled"
            header="Enrolled"
            :sortable="true"
            style="min-width: 80px"
          >
            <template #body="slotProps">
              <InputSwitch
                v-model="slotProps.data.enrolled"
                @change="onInputSwitchChange(slotProps.data)"
              />
            </template>
          </Column>
          <Column
            field="recruiter_contacted"
            header="Contacted"
            :sortable="true"
            style="min-width: 80px"
          >
            <template #body="slotProps">
              <InputSwitch
                v-model="slotProps.data.recruiter_contacted"
                @change="onInputSwitchChange(slotProps.data)"
              />
            </template>
          </Column>
        </DataTable>
        <!-- PAGINATION LINKS -->
        <!-- <div
          class="pagination-links"
          style="align-self: center;"
        >
          <p 
            v-show="referralsRowData.current_page > 1" 
            class="pagination-arrow"
            @click="displayReferralsPage( referralsRowData.current_page - 1 )"
          > 
            &larr; 
          </p>
          <p> 
            Page {{ referralsRowData.current_page }} of {{ referralsRowData.page_count }} 
          </p>
          <p 
            v-show="referralsRowData.current_page < referralsRowData.page_count"
            class="pagination-arrow"  
            @click="displayReferralsPage( referralsRowData.current_page + 1 )"
          > 
            &rarr; 
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ReferralsTable",
  components: {
  },
  props: {
  },
  emits: [
  ],
  data() {
    return {
      exportIconSrc: require('@/assets/icons/export_icon.svg'),
      exportingReferralsCSV: false,
      // Referrals Filters
      referralsSearchTerm: null,
      dateRange: null,
      loadingReferrals: false,
      referralsError: '',
      referralsSortField: 'referral_date',
      referralsSortOrder: -1,
      // Referrals Table Properties
      referralsRowData: {},
      selectedPatient: {},
      referralsPage: 1,
    };
  },
  computed: {
    referralsListPayload() {
      return {
        // first_name: this.$store.state.referralsFirstName || '',
        // last_name: this.$store.state.referralsLastName || '',
        // email: this.$store.state.referralsEmail || '',
        // phone_number: this.$store.state.referralsPhoneNumber || '',
        // postcode: this.$store.state.referralsPostcode || '',
        // trial_name: this.$store.state.referralsTrialName || '',
        // location_name: this.$store.state.referralsLocationName || '',
        // communication_type: this.$store.state.referralsCommunicationType || '',
        start_date: this.dateRange && this.dateRange.length > 0 ? this.dateRange[0].toISOString() : null,
        end_date: this.dateRange && this.dateRange.length > 1 ? this.dateRange[1].toISOString() : null,
        sort_field: this.referralsSortField,
        sort_order: this.referralsSortOrder === 1 ? 'asc' : 'desc',
        search_term: this.referralsSearchTerm,
        page: this.referralsPage
      }
    },
    exportReferralsPayload() {
      return {
        // first_name: this.$store.state.referralsFirstName || '',
        // last_name: this.$store.state.referralsLastName || '',
        // email: this.$store.state.referralsEmail || '',
        // phone_number: this.$store.state.referralsPhoneNumber || '',
        // postcode: this.$store.state.referralsPostcode || '',
        // trial_name: this.$store.state.referralsTrialName || '',
        // location_name: this.$store.state.referralsLocationName || '',
        // communication_type: this.$store.state.referralsCommunicationType || '',
        start_date: this.dateRange && this.dateRange.length > 0 ? this.dateRange[0].toISOString() : null,
        end_date: this.dateRange && this.dateRange.length > 1 ? this.dateRange[1].toISOString() : null,
        sort_field: this.referralsSortField,
        sort_order: this.referralsSortOrder === 1 ? 'asc' : 'desc',
        search_term: this.referralsSearchTerm,
      }
    },
    editReferralPatientPayload() {
      return {
        enrolled: this.selectedPatient.enrolled,
        recruiter_contacted: this.selectedPatient.recruiter_contacted,
      }
    },
  },
  async mounted() {
    // Set the date range - 1 year ago till now
    const currentDate = new Date();
    let to = new Date();
    let from = currentDate;
    from.setFullYear(currentDate.getFullYear() - 1);
    this.dateRange = [from, to];

    await Promise.all([
      this.getReferralsList(),
    ]);
  },
  methods: {
    /**
     * Get the Referrals List
     * @returns {Promise} - The promise for the referrals list
     */
    async getReferralsList() {
      this.$store.dispatch('getRefreshToken');
      this.referralsRowData = {};
      this.loadingReferrals = true;
      this.referralsError = '';

      try {
        const res = await axios.post(`/api/clinical-trial-pharmacy-referral-list`, this.referralsListPayload, this.$store.state.header);
        this.handleReferralsListSuccess(res);
      } catch (err) {
        this.handleReferralsListError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the referrals list
     * @param {Object} res - The response object
     */
     handleReferralsListSuccess(res) {
      console.log('The referrals list response is: ', res.data);
      this.referralsRowData = {
        ...res.data,
        referrals: res.data.referrals.map(referral => {
          const d = referral.referral_date ? referral.referral_date.split("/") : [];
          referral.referral_date = d.length === 3 ? new Date(d[2], d[1] - 1, d[0]) : null;
          return referral;
        })
      };
      this.loadingReferrals = false;
    },
    /**
     * Handle the error response for the referrals list
     * @param {Object} err - The error object
     */
    handleReferralsListError(err) {
      console.error('The referrals list error is: ', err);
      this.loadingReferrals = false;
      this.referralsError = err;
    },
    /**
     * Export the referrals list
     * @returns {Promise} - The promise for the refferals export
     */
     async exportReferralsCSV() {
      this.exportingReferralsCSV = true;
      this.$store.dispatch('getRefreshToken');
      console.log('Exporting referrals');

      try {
        const res = await axios.post(`/api/clinical-trial-pharmacy-referral-list/export`, this.exportReferralsPayload, this.$store.state.header);
        this.handleExportReferralsCSVSuccess(res);
      } catch (err) {
        this.handleExportReferralsCSVError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the referrals export
     * @param {Object} res - The response object
     */
    handleExportReferralsCSVSuccess(res) {
      console.log('The referrals list export response is: ', res.data);
      // Get the CSV data and download it
      let csv = res.data;
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = `srai_clinical_trials_referrals.csv`;
      anchor.click();
      this.exportingReferralsCSV = false;
    },
    /**
     * Handle the error response for the referrals export
     * @param {Object} err - The error object
     */
    handleExportReferralsCSVError(err) {
      console.error('The referrals list export error is: ', err);
      this.exportingReferralsCSV = false;
    },
    /**
     * Handle the page refresh
     * @param integer page - The page number
     */
    displayReferralsPage(page) {
      this.$store.state.referralsPage = page;
      console.log('Page: ', page);
      this.getReferralsList();
    },
    /**
     * When the sort/order column for the referrals list is changed
     * @param {Object} event
     */
    onReferralsSort(event) {
      this.referralsSortField = event.sortField;
      this.referralsSortOrder = event.sortOrder;
      console.log('Sort field: ', this.referralsSortField);
      console.log('Sort order: ', this.referralsSortOrder);
      this.getReferralsList();
    },
    /**
     * When the page for the referrals list is changed
     */
    onReferralsPage(event) {
      this.referralsPage = event.page + 1;
      this.getReferralsList();
    },
    /**
     * Update the patient's status
     * @param {Object} patient - The patient object
     */
    async editReferralPatient() {
      console.log('Updating patient status');
      this.$store.dispatch('getRefreshToken');

      try {
        console.log('Updating patient status with consent_id: ', this.selectedPatient);
        console.log('The payload is: ', this.editReferralPatientPayload);
        const res = await axios.post(`/api/clinical-trial-pharmacy-referral-list/${this.selectedPatient.consent_id}/edit`, this.editReferralPatientPayload, this.$store.state.header);
        this.handleEditReferralPatientSuccess(res);
      } catch (err) {
        this.handleEditReferralPatientError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the patient status update
     * @param {Object} res - The response object
     */
    handleEditReferralPatientSuccess(res) {
      console.log('The patient status update response is: ', res.data);
      // this.getReferralsList();
    },
    /**
     * Handle the error response for the patient status update
     * @param {Object} err - The error object
     */
    handleEditReferralPatientError(err) {
      console.error('The patient status update error is: ', err);
    },
    /**
     * When the enrolled toggle switch is changed
     */
    onInputSwitchChange(data) {
      // Call the /clinical-trial-pharmacy-referral-list/:uuid/edit endpoint
      // with the updated enrolled value

      console.log('Toggle switch changed');
      console.log('Patient data: ', data);
      if (data.enrolled) {
        console.log('Toggle switch is on');
      } else {
        console.log('Toggle switch is off');
      }

      this.selectedPatient = data;

      this.editReferralPatient();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.full-container {
  display: grid;
  column-gap: 40px;
  height: 100%;
}

.grid-container {
  display: grid;
  column-gap: 40px;
  height: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  row-gap: 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.left-col {
  grid-column-start: 1;
  grid-column-end: 2;
}

.right-col {
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filters-row {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  height: 45px;
  margin-bottom: 1em;
  gap: 20px;
}

.flex-item {
  flex: 1;
}

.flex-expand {
  flex: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-right {
  margin-left: auto;
}

.p-selectbutton {
  border-radius: 12px;
  border: 1px solid $bordergrey;
  overflow: hidden;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

:deep(.p-selectbutton) {
  display: flex;
}

:deep(.p-selectbutton) .p-button {
  box-shadow: none;
  white-space: nowrap;
  min-width: max-content;
}

:deep(.p-selectbutton) div {
  flex: 1;
  font-weight: 600;
  background: $grey;
  color: $deepgrey;
  border: none;
}

:deep(.p-selectbutton) div:not(:last-child) {
  border-right: 1px solid $bordergrey;
}

:deep(.p-selectbutton) .p-highlight {
  background: white;
  color: black;
  border: none;
}

.p-togglebutton {
  border-radius: 5px;
  border: 1px solid $strongblue;
  background: white;
  color: $strongblue;
  overflow: hidden;
}

.p-togglebutton :deep(.p-button-icon) {
  padding: 0;
}

.p-togglebutton.p-button.p-highlight {
  background-color: $strongblue !important;
  color: white !important;
}

:deep(.p-togglebutton.p-focus) {
  box-shadow: none;
}

.p-progress-spinner {
  margin: 0;
}

.export {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  gap: 25px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: $grey;
  border: 1px solid $bordergrey;
  border-bottom: none;
}

.key-container {
  display: flex;
  gap: 1.5em;
}

.key-icon {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: inline-block;
}

.key-heading {
  font-size: 11pt;
  font-weight: 600;
  color: $deepgrey;
}

.key-text {
  font-size: 11pt;
  font-weight: 500;
  color: $deepgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.p-datatable th[class*="align-"] .p-column-header-content {
  display: inline-flex ;
}

.p-datatable :deep(.p-column-title) {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: visible;
  text-overflow: ellipsis;
}

:deep(.p-datatable-wrapper) {
  border: 1px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid $bordergrey;
}

:deep(.p-datatable-table) {
  table-layout: auto;
}
</style>