<template>
  <div class="msg-container">
    <div class="warning-icon">
      <img
        :src="warningIconSrc"
        alt="Warning Icon"
      >
    </div>
    <div class="warning-text">
      <slot>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WarningMessage',
  data() {
    return {
      warningIconSrc: require('@/assets/icons/warning_icon.svg'),
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.msg-container {
  border: 1px solid $warningborder;
  border-radius: 2px;
  background-color: $grey;
  color: $warningborder;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 15px;
}

.warning-icon {
  max-width: 20px;
  max-height: 20px;
  width: auto;
  height: auto;

  img {
    max-width: 100%;
  }
}

.warning-text > a {
  text-decoration: none;
}
</style>