<template>
  <div class="progress-container">
    <CampaignStatGroup
      :meter-values="meter_values"
      :total-values="total_values"
    >
      <template #check>
        <div class="check-icon d-flex">
          <img
            :src="checkEnabledIconSrc"
            class="small-icon"
            alt="Green check icon"
          >
        </div>
      </template>
      <template #profile>
        <div class="check-icon">
          <img
            :src="greyProfileIcon"
            class="small-icon"
            alt="Grey profile icon"
          >
        </div>
      </template>
    </CampaignStatGroup>
    <MeterGroup
      :meter-values="meter_values"
      :height="{ value: 0.625, unit: 'rem' }"
    />
  </div>
</template>

<script>
import CampaignStatGroup from "@/components/campaign/campaign-stat-group.vue";
import MeterGroup from "@/components/progress/meter-group.vue";

export default {
  components: {
    CampaignStatGroup,
    MeterGroup
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      checkEnabledIconSrc: require('@/assets/icons/check_enabled_icon.svg'),
      greyProfileIcon: require('@/assets/icons/grey_profile_icon.svg'),
    };
  },
  computed: {
    numCampaignLocations() {
      return this.campaign.locations.length;
    },
    total_values() {
      return {
          label: 'Total',
          value: this.numCampaignLocations,
          icon: 'profile',
      };
    },
    meter_values() {
      return [
        {
          label: 'Pharmacies',
          value: this.campaign.num_made_goal,
          percentage: this.campaign.num_made_goal
            / this.numCampaignLocations
            * 100,
          icon: 'check',
          color: '#00AD50'
        }
      ]
    }
  },
}
</script>

<style style lang="scss" scoped>
@import "../../assets/css/main.scss";
.progress-container {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: center;
  justify-content: center;
}
</style>