<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- SIDEBAR -->
        <div class="sidebar">
          <div class="header-box">
            <div class="header-title flex-item">
              <h3>
                Campaigns
              </h3>
              <Button
                icon="pi pi-plus"
                class="header-button"
                label="New"
                aria-label="Add Campaign"
                @click="openCreateCampaignModal"
              />
            </div>
            <!-- SEARCH BOX -->
            <span class="p-input-icon-right flex-item">
              <i class="pi pi-search"></i>
              <InputText
                v-model="searchCampaign"
                placeholder="Search"
                class="grey-input"
                @input="searchCampaigns"
                @keydown.enter="searchCampaigns"
              />
            </span>
            <!-- CAMPAIGN FILTER -->
            <MultiSelect
              v-model="campaignTypeSelection"
              :options="campaignTypeOptions"
              class="grey-input flex-item"
              @change="changeCampaignTypeSelection"
            />
          </div>
          <!-- CAMPAIGNS LIST -->
          <div 
            v-if="!loadingCampaignList && campaigns.length > 0"
            class="campaigns-list"
          >
            <VirtualScroller 
              v-model:items="campaigns"
              :item-size="64"
              class="flex-item"
              :loading="loadingCampaignList"
              lazy
              @lazy-load="lazyLoadCampaigns"
            >
              <template #item="{ item }">
                <div
                  class="campaign-item"
                  :class="item.campaign_id === selectedCampaignLocal.campaign_id ? 'selected-campaign' : ''"
                  @click="changeSelectedCampaign(item)"
                >
                  <div
                    class="status-icon"
                    :class="item.active === true ? 'green-bg' : 'dark-grey-bg'"
                  ></div>
                  <h4
                    class="flex-item"
                    style="text-align: left;"
                  >
                    {{ item.name }}
                  </h4>
                  <i
                    class="pi pi-chevron-right"
                  ></i>
                </div>
              </template>
            </VirtualScroller>
          </div>
          <div
            v-else-if="!loadingCampaignList && campaigns.length === 0"
          >
            No campaigns found
          </div>
          <ProgressSpinner
            v-else
            style="min-height: 200px; margin-left:auto !important; margin-right:auto !important;"
          />
        </div>
        <!-- MAIN CONTENT -->
        <div class="form-container">
          <!-- HEADER ROW -->
          <div class="flex-row">
            <div
              class="flex-item"
              style="text-align: left;"
            >
              <h3 v-if="selectedCampaignLocalSet">
                {{ selectedCampaignLocal.name }}
              </h3>
            </div>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </div>
          <!-- CAMPAIGN -->
          <div
            v-if="selectedCampaignLocalSet"
            class="campaign-content"
          >
            <!-- EDIT ROW -->
            <div class="edit-row">
              <!-- CAMPAIGN STATUS -->
              <div
                class="status-symbol"
                :class="selectedCampaignLocal.active === true ? ['light-green-bg', 'green-color'] : 'light-grey-bg'"
              >
                <div
                  class="status-icon"
                  :class="selectedCampaignLocal.active === true ? 'green-bg' : 'dark-grey-bg'"
                ></div>
                {{ selectedCampaignLocal.active? "Active": "Inactive" }}
              </div>
              <!-- RECOMMENDATION ICON -->
              <div
                v-if="selectedCampaignLocal.campaign_recommendation_id"
                v-tooltip.top="'Recommended Campaign'"
                class="recommendation-icon strong-blue-bg"
              >
                <img
                  :src="recommendationIconSrc"
                  class="extrasmall-icon"
                  alt="Recommendation Icon"
                >
              </div>
              <!-- GOAL TEXT -->
              <div
                v-if="selectedCampaignLocal.goal_type && selectedCampaignLocal.measure_by && selectedCampaignLocal.goal"
                class="goal-text"
              >
                GOAL: + 
                <span style="text-transform:capitalize">{{ selectedCampaignLocal.goal_type + ' ' }}</span> 
                <span>{{ selectedCampaignLocal.measure_by === "target" ? "to" + ' ' : "by" + ' ' }}</span> 
                <span v-if="selectedCampaignLocal.measure_by !== 'percentage' && selectedCampaignLocal.goal_type === 'revenue'">
                  {{ $n(selectedCampaignLocal.goal, 'currency') }}
                </span>
                <span v-else-if="selectedCampaignLocal.measure_by === 'percentage' || ['adherence', 'loyalty'].includes(selectedCampaignLocal.goal_type)">
                  {{ $n(selectedCampaignLocal.goal, 'integer') }}%
                </span>
                <span v-else>
                  {{ $n(selectedCampaignLocal.goal, 'integer') }}
                </span>
                <span v-if="selectedCampaignLocal.goal_type === 'scripts' | selectedCampaignLocal.goal_type === 'visits'">
                  {{ " " + selectedCampaignLocal.goal_type }}
                </span>
                <!-- {{ selectedCampaignLocal.goal_type.charAt(0).toUpperCase() + selectedCampaignLocal.goal_type.slice(1) }} {{ selectedCampaignLocal.measure_by === "target"? "to": "by" }} {{ selectedCampaignLocal.measure_by !== "percentage" && selectedCampaignLocal.goal_type === "revenue" ? $t('currencySymbol') : "" }}{{ $n(selectedCampaignLocal.goal, 'decimal') }}{{ selectedCampaignLocal.measure_by === "percentage" || (['adherence', 'loyalty'].includes(selectedCampaignLocal.goal_type)) ? "%": "" }} -->
              </div>
              <div
                v-if="!selectedCampaignLocal.active || selectedCampaignLocal.days_left"
                class="time-remaining-text"
                :class="selectedCampaignLocal.days_left <= 7 ? `red-color` : `grey-color`"
              >
                <i
                  class="pi pi-calendar nopad"
                  style="margin-right: 0.5em;"
                ></i>{{ !selectedCampaignLocal.active ? $d(new Date(selectedCampaignLocal.start_date), 'short') + " to " + $d(new Date(selectedCampaignLocal.end_date), 'short') : ( selectedCampaignLocal.days_left ? selectedCampaignLocal.days_left + " Days Left" : "" ) }}
              </div>
              <!-- EDIT RECOMMENDATION -->
              <div
                v-if="!demoCampaignIds.includes(selectedCampaignLocal.campaign_id)"
                class="edit-icon"
                :style="selectedCampaignLocal.active && !selectedCampaignLocal.days_left ? 'margin-left: auto;': ''"
                @click="openEditCampaignModal"
              >
                <img
                  :src="editIconSrc"
                  class="tiny-icon"
                  alt="Edit Recommendation"
                >
              </div>
            </div>
            <!-- CAMPAIGN ATTACHMENTS -->
            <div class="attachments">
              <span>
                <p>Campaign Attachments: <span
                  class="attachments-text"
                  @click="openCampaignAttachmentsModal"
                >{{ (selectedCampaignLocal.templates ? selectedCampaignLocal.templates.length + " Templates" : "") + (selectedCampaignLocal.note ? ", Description": "") }}</span></p>
              </span>
            </div>
            <!-- FILTERS ROW -->
            <div class="filters-row">
              <!-- SEARCH BOX -->
              <span class="p-input-icon-right flex-item">
                <i class="pi pi-search"></i>
                <InputText
                  v-model="searchPharmacy"
                  placeholder="Pharmacy"
                  class="grey-input"
                  @input="searchPharmacies"
                />

              </span>
              <!-- EXPORT ICON -->
              <!-- <img
                :src="exportIconSrc"
                class="medium-icon button"
                alt="Export Icon"
                @click="exportCSV"
              > -->
            </div>
            <!-- TABLE -->
            <DataTable
              ref="pharmacyCampaignStatsTable"
              v-model:expanded-rows="expandedCampaignsRows"
              :row-class="rowClass"
              :value="pharmacyCampaignStats"
              :loading="loadingCampaignStats"
              :virtual-scroller-options="{ lazy: true, onLazyLoad: lazyLoadCampaignPharmacyStats, itemSize: 20, numToleratedItems: 6 }"
            >
              <template #empty>
                No records found
              </template>
              <template #loading>
                Loading records, please wait...
              </template>
              <Column
                field="rank"
                header="Rank"
              />
              <Column
                field="pharmacy_name"
                header="Pharmacy"
              />
              <Column
                field="messages"
                header="Messages"
              />
              <Column
                field="reach"
                header="Reach"
              />
              <Column
                header="Cost"
              >
                <template #body="campaignSlotProps">
                  {{ $n(campaignSlotProps.data.cost, 'currency') }}
                </template>
              </Column>
              <Column
                header="Revenue"
              >
                <template #body="campaignSlotProps">
                  <span>{{ $n(campaignSlotProps.data.revenue, 'currency') + ' ' }}</span>
                  <span
                    :class="campaignSlotProps.data.revenue_change > 0 ? 'green-color' : 'red-color'"
                  >
                    <span v-if="campaignSlotProps.data.rank!=='TOTAL'">{{ campaignSlotProps.data.revenue_change > 0 ? '+' : '' }}{{ campaignSlotProps.data.revenue_change }}%</span>
                  </span>
                </template>
              </Column>
              <Column
                header="Made Goal"
              >
                <template #body="campaignSlotProps">
                  <div
                    v-if="campaignSlotProps.data.rank === 'TOTAL' && !campaignSlotProps.data.made_goal"
                  >
                    {{ campaignSlotProps.data.made_goal_text }}
                  </div>
                  <div
                    v-else-if="campaignSlotProps.data.made_goal"
                    :class="campaignSlotProps.data.made_goal ? `green-color` : `grey-color`"
                  >
                    <img
                      :src="checkEnabledIconSrc"
                      class="small-icon"
                      alt="Check Enabled Icon"
                    >
                  </div>
                  <div
                    v-else-if="!campaignSlotProps.data.made_goal"
                    :class="campaignSlotProps.data.made_goal ? `green-color` : `grey-color`"
                  >
                    <img
                      :src="checkDisabledIconSrc"
                      class="small-icon"
                      alt="Check Disabled Icon"
                    >
                  </div>
                </template>
              </Column>
              <Column
                expander
                style="width: 5rem"
              />
              <!-- EXPANDABLE SECTION -->
              <template
                #expansion="campaignSlotProps"
              >
                <CampaignStats
                  v-if="!loadingCampaignStats"
                  :pharmacy="campaignSlotProps.data"
                  :campaign="selectedCampaignLocal"
                />
                <Skeleton
                  v-else
                  style="min-height: 100px;"
                />
              </template>
            </DataTable>
          </div>

          <!-- FOOTER -->
          <div
            v-if="selectedCampaignLocalSet"
            class="modal-footer"
            style="padding-left: 0;"
          >
            <button
              v-if="!demoCampaignIds.includes(selectedCampaignLocal.campaign_id)"
              class="red-close-button"
              style="margin-right: auto;"
              @click="deleteCampaign"
            >
              <img
                :src="trashIconSrc"
                class="small-icon button"
                alt="Delete Icon"
              >
            </button>
            <div
              class="red-color"
              style="margin-left: auto; margin-right: auto;"
            >
              {{ activateDeactivateError || deleteError }}
            </div>
            <div
              class="black-button"
              @click="getCampaignAffectedTemplates"
            >
              {{ activateOrDeactivateButtonText }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmDialog
      group="settings_confirmation"
      :pt="{message: { style: 'white-space: pre-line !important;' }}"
    >
      <template #message="slotProps">
        <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
          <i
            :class="slotProps.message.icon"
          ></i>
          Are you sure you want to {{ activateOrDeactivateButtonText }} the campaign? The following templates will be {{ activateOrDeactivateButtonText === 'Activate' ? 'activated' : 'deactivated' }}:
          <ul>
            <li 
              v-for="item in templatesToBeUpdated" 
              :key="item"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </template>
    </ConfirmDialog>
    <CampaignEditModal
      v-if="displayCreateCampaignModal || displayEditCampaignModal"  
      :campaign="displayCreateCampaignModal ? {} : selectedCampaignLocal"
      :company-pharmacies="companyPharmaciesLocal"
      @campaign-created="campaignCreated"
      @campaign-updated="campaignUpdated"
      @close-modal="closeOpenModals"
    />
    <CampaignAttachmentsModal
      v-if="displayCampaignAttachmentsModal"
      :templates="selectedCampaignLocal.templates"
      :note="selectedCampaignLocal.note"
      @close-modal="closeOpenModals"
    />
    <UserAuthenticationModal 
      v-if="displayUserAuthenticationModal"
      :name="selectedCampaignLocal.name"
      :payload-type="'campaign'"
      :updates="templatesToBeUpdated"
      :request-type="selectedRequestType"
      @login-success="modifyCampaign"
      @close-modal="closeOpenModals"
    />
  </div>
</template>
  
<script>
import axios from 'axios';
import CampaignEditModal from '@/components/campaign/campaign-edit-modal.vue';
import CampaignAttachmentsModal from '@/components/modals/campaign-attachments-modal.vue';
import UserAuthenticationModal from '@/components/modals/campaign-template-authentication-modal.vue';
import CampaignStats from '@/components/campaign/campaign-stats.vue';
import VirtualScroller from 'primevue/virtualscroller';
import { useConfirm } from 'primevue/useconfirm';

export default {
  components: {
    CampaignEditModal,
    CampaignStats,
    CampaignAttachmentsModal,
    UserAuthenticationModal,
    VirtualScroller
  },
  props: {
    /**
     * The preselected campaign
     * @type {Object}
     */
    selectedCampaign: {
      type: Object,
      default() {
        return {}
      }
    },
    /**
     * The company pharmacies data
     * @type {Object}
     */
    companyPharmacies: {
      type: Array,
      default() {
        return []
      }
    }
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'closeModal',
    'campaignsModified',
    'campaignRemoved'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
      demoCampaignIds: ["66e35efd-5b1e-4a78-9f1b-8ef86e3f911d","b8765694-195b-4034-89b9-f64dabfb5604","43665130-220d-4e5f-ba6d-fdde9731fd81"],
      recommendationIconSrc: require('@/assets/icons/recommendation_white_icon.svg'),
      editIconSrc: require('@/assets/icons/edit_icon.svg'),
      exportIconSrc: require('@/assets/icons/export_icon.svg'),
      trashIconSrc: require('@/assets/icons/trash_icon.svg'),
      checkEnabledIconSrc: require('@/assets/icons/check_enabled_icon.svg'),
      checkDisabledIconSrc: require('@/assets/icons/check_disabled_icon.svg'),
      campaigns: [],
      loadingCampaignList: false,
      selectedCampaignLocal: {},
      campaignTypeSelection: [
        'Active',
        'Inactive'
      ],
      campaignTypeOptions: [
        'Active',
        'Inactive'
      ],
      companyPharmaciesLocal: [],
      activateDeactivateLoading: false,
      activateDeactivateError: '',
      deleteLoading: false,
      deleteError: '',
      campaignListCurrentPage: 1,
      campaignListPageCount: 1,
      campaignListPerPage: 1,
      searchCampaign: '',
      searchPharmacy:'',
      filteredPharmacies: [],
      displayEditCampaignModal: false,
      displayCreateCampaignModal: false,
      displayCampaignAttachmentsModal: false,
      displayUserAuthenticationModal: false,
      expandedCampaignsRows: [],
      pharmacyCampaignStats: [],
      loadingCampaignStats: true,
      selectedRequestType: '',
      pharmacyCampaignStatsCurrentPage: 1,
      pharmacyCampaignStatsPerPage: 1,
      pharmacyCampaignStatsPageCount: 1,
      campaignPharmacies: [],
      delayTimer: null,
      loadingActivateOrDeactivate: false,
      activateOrDeactivateButtonText: 'Activate',
      campaignsModified: false, //to emit back for active campaign list refresh in the parent page
      currentDate : new Date(),
      confirm: useConfirm(),
      templatesToBeUpdated: [],
    }
  },
  computed: {
    /**
    * Returns the payload for the campign list request
    * @returns {Object} campaignListPayLoad
    */
    campaignListPayLoad() {
      let active = this.campaignTypeSelection.includes("Active");
      let inactive = this.campaignTypeSelection.includes("Inactive");
      let payLoadObject = {
        "page": this.campaignListCurrentPage,
      }
      if (active && !inactive) {
        payLoadObject.active = true;
      } else if (inactive && !active) {
        payLoadObject.active = false;
      } 

      if (this.searchCampaign !== '') {
        payLoadObject.search_term = this.searchCampaign;
      }

      return payLoadObject
    },
    /**
     * Returns the payload for the campaign stats request
     * @returns {Object} campaignStatsPayLoad
     */
    campaignStatsPayLoad() {
      return {
        "campaign_id": this.selectedCampaignLocal.campaign_id,
        "page": this.pharmacyCampaignStatsCurrentPage,
        "pharmacy_ids": this.filteredPharmacies.map(x => x.location_id)
      }
    },
    /**
     * Returns the payload for the campaign edit request
     * @returns {Object} campaignEditPayload
     */
    campaignActivateDeactivatePayload() {
      // Set the current time and date
      let currentDate = this.currentDate;

      let payload = {
        "active": !this.selectedCampaignLocal.active,
        "templates": this.selectedCampaignLocal.templates ? this.selectedCampaignLocal.templates.map(x => x.template_id) : []
      }

      if (this.selectedCampaignLocal.active) {
        // If the campaign is active, set the end date to the current date
        currentDate.setHours(23, 59, 59);
        payload.end_date = new Date(currentDate.getTime());
      } 
      else {
        // If the campaign is inactive, set the start date to the current date
        currentDate.setHours(0, 0, 0, 0);
        payload.start_date = new Date(currentDate.getTime());
      }

      return payload
    },
    /**
     * Return true if the selectedCampaignLocal is set
     */
    selectedCampaignLocalSet() {
      return Object.keys(this.selectedCampaignLocal).length > 0;
    }
  },
  async mounted() {
    /**
     * Set the local variables to the prop
     */
    this.companyPharmaciesLocal = this.companyPharmacies;
    if (Object.keys(this.selectedCampaign).length > 0) {
      this.selectedCampaignLocal = this.selectedCampaign;
      this.filteredPharmacies = this.selectedCampaignLocal.locations;
      this.updateActivateDeactivateButton(false);
      console.log('The selected campaign is', this.selectedCampaignLocal);
    }

    await this.getCampaignList();
  },
  methods: {
    /**
     * Open Confirmation Message
     */
    async openConfirmActivateDeactivateCampaignModal() {
      this.$store.dispatch('getRefreshToken');
        
      this.confirmationDetails = await this.getCampaignStatusConfirmationMessage();
      console.log(this.confirmationDetails);

      this.confirm.require({
        header: 'Confirm Campaign Status',
        message: this.confirmationDetails.message,
        icon: 'pi pi-info-circle',
        group: 'settings_confirmation',
        acceptLabel: 'Yes',
        rejectLabel: 'No',
        acceptClass: 'black-button',
        rejectClass: 'red-reject-button',
        /**
         * If the user confirms the change, activate/deactivate the templates
         */
        accept: () => {
          this.activateOrDeactivate();
        },
        reject: () => {
          console.log('The campaign update was cancelled');
        }
      }) 
    },
    /**
     * Get the confirmation message for the campaign status change
     */
    getCampaignStatusConfirmationMessage() {
      let message = '';
      
      if (this.selectedCampaignLocal.active) {
        message = '<p>Are you sure you want to deactivate the campaign?</p>';
        message += '<p>The following templates will be deactivated:</p>';
        message += '<ul>'
        this.templatesToBeUpdated.forEach(template => {
          message += '<li>' + template + '</li>';
        });
      } else {
        message = 'Are you sure you want to activate the campaign?>';
        message += '<p>The following templates will be activate:</p>';
        message += '<ul>'
        this.templatesToBeUpdated.forEach(template => {
          message += '<li>' + template + '</li>';
        });
      }
      return {
        message: message
      }
    },
    /**
     * Get the list of templates affected by the campaign status change
     * @returns {Promise} - The promise for the campaign/affected-templates/enterprise request
     */
    async getCampaignAffectedTemplates() {
      let payload = {
        active: !this.selectedCampaignLocal.active,
      }
      try {
        const res = await axios.post(`/api/campaign/${this.selectedCampaignLocal.campaign_id}/affected-templates/enterprise`, payload, this.$store.state.header);
        this.handleGetCampaignAffectedTemplatesSuccess(res);
      } catch (err) {
        this.handleGetCampaignAffectedTemplatesError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the get campaign affected templates success case
     */
     handleGetCampaignAffectedTemplatesSuccess(res) {
      console.log('The affected templates response is', res.data);
      this.templatesToBeUpdated = res.data.template_names;
      //this.openConfirmActivateDeactivateCampaignModal();
      this.activateOrDeactivate();
      
    },
    /**
     * Handle the get campaign affected templates error case
     */
     handleGetCampaignAffectedTemplatesError(err) {
      console.log('The affected templates error is', err);
    },
    /**
     * Activate or deactivate a campaign
     */
    activateOrDeactivate() {
      this.$store.dispatch('getRefreshToken');
      this.updateActivateDeactivateButton(true);
      this.displayUserAuthenticationModal = true;
      this.selectedRequestType = this.selectedCampaignLocal.active ? 'Deactivate' : 'Activate';
      console.log('activate event');
    },
    /**
     * Emit the Delete event
     */
    deleteCampaign() {
      this.$store.dispatch('getRefreshToken');
      this.displayUserAuthenticationModal = true;
      this.selectedRequestType = 'Delete';
    },
    /**
     * Open the edit campaign modal
     */
    openEditCampaignModal() {
      this.$store.dispatch('getRefreshToken');
      this.displayEditCampaignModal = true;
    },
    /**
     * Open the create campaign modal
     */
    openCreateCampaignModal() {
      this.$store.dispatch('getRefreshToken');
      this.displayCreateCampaignModal = true;
    },
    /**
     * Open the campaign attachments modal
     */
    openCampaignAttachmentsModal() {
      this.$store.dispatch('getRefreshToken');
      this.displayCampaignAttachmentsModal = true;
    },
    /**
     * Update the selected campaign
     * @param {Object} campaign
     */
    campaignUpdated(campaign) {
      console.log('assigning campaign', campaign, this.selectedCampaignLocal)
      // // Update the selected campaign
      // Object.assign(this.selectedCampaignLocal, campaign);
      // // Find and update the campaign in the campaigns list from the campaign_id
      // let index = this.campaigns.findIndex(x => x.campaign_id === campaign.campaign_id);
      // if (index !== -1) {
      //   this.campaigns[index] = campaign;
      // }
      // else {
      //   this.campaigns.unshift(campaign);
      // }
      this.getCampaignList();
    },
    /**
     * Add a new campaign to campaigns list
     * @param {Object} campaign
     */
    campaignCreated(campaign) {
      console.log('adding campaign', campaign);
      this.getCampaignList();
    },
    /**
     * Close the opened modals
     */
    closeOpenModals() {
      this.displayEditCampaignModal = false;
      this.displayCreateCampaignModal = false;
      this.displayCampaignAttachmentsModal = false;
      this.displayUserAuthenticationModal = false;
      // Change the loading activate/deactivate to false
      this.loadingActivateOrDeactivate = false;
      this.updateActivateDeactivateButton(false);
    },
    /**
     * Update the activate/deactivate button text
     */
    updateActivateDeactivateButton(status=false) {
      this.loadingActivateOrDeactivate = status;
      if (this.loadingActivateOrDeactivate) {
        this.activateOrDeactivateButtonText = this.selectedCampaignLocal.active ? 'Inactivating...' : 'Activating...';
      } 
      else if (!this.loadingActivateOrDeactivate) {
        this.activateOrDeactivateButtonText = this.selectedCampaignLocal.active ? 'Deactivate' : 'Activate';
      }
    },
    /**
     * Emit the close modal event
     */
    closeModal() {
      if (this.campaignsModified) {
        this.$emit('campaignsModified');
      }
      this.$emit('closeModal');
    },
    /**
     * Row class function for the data table
     */
    rowClass(data) {
      // Hide the expander for the total row
      return data.rank === "TOTAL" ? "no-expander pharmacies-totals-row" : "pharmacies-row";
    },
    /**
     * Get the list of campaigns for the current logged in enterprise user
     * @returns {Promise} - The promise for the campaign/enterprise/list request
     */
    async getCampaignList() {
      this.loadingCampaignList = true;
      this.$store.dispatch('getRefreshToken');

      if (this.campaignListCurrentPage === 1) {
        this.campaigns = [];
        this.selectedCampaignLocal = {};
      }

      if (!this.campaignTypeSelection.includes("Active") && !this.campaignTypeSelection.includes("Inactive")) {
        this.loadingCampaignList = false;
        return;
      }

      try {
        const res = await axios.post('/api/campaign/enterprise/list', this.campaignListPayLoad, this.$store.state.header);
        this.handleGetCampaignListSuccess(res);
      } catch (err) {
        this.handleGetCampaignListError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the get campaign list success case
     * @param {Object} res
     */
     async handleGetCampaignListSuccess(res) {
      console.log('The get campaign list response is', res.data);

      // Assign campaings list as data
      this.campaigns = [...this.campaigns, ...res.data.campaigns];
      this.campaignListCurrentPage = res.data.current_page;  
      this.campaignListPageCount = res.data.page_count;
      this.campaignListPerPage = res.data.per_page;

      // If there is no selected campaign, and there are available campaigns, select the first one
      if (Object.keys(this.selectedCampaignLocal).length === 0 && this.campaigns.length > 0) {
        Object.assign(this.selectedCampaignLocal, this.campaigns[0]); 
        this.filteredPharmacies = this.selectedCampaignLocal.locations;
        this.updateActivateDeactivateButton(false);
      }
      // Fetch the campaign stats for selected campaign
      await this.getSelectedCampaignStats();

      this.loadingCampaignList = false;
    },
    /**
     * Handle the get campaign list error case
     * @param {Object} err
     */
    handleGetCampaignListError(err) {
      console.log('The get campaign list error is', err);
      this.campaignListError = err;
      this.loadingCampaignList = false;
    },
    /**
     * Change the selected campaign
     * @param {Object} campaign - The campaign to display - if null do not preselect
     */
    changeSelectedCampaign(campaign = {}) {
      this.$store.dispatch('getRefreshToken');
      if (this.selectedCampaignLocal.campaign_id === campaign.campaign_id) {
        return;
      }
      this.selectedCampaignLocal = campaign;
      this.filteredPharmacies = campaign.locations;
      this.expandedCampaignsRows = [];
      this.updateActivateDeactivateButton(false);
      this.getSelectedCampaignStats();
    },
    /**
    * Lazy load the campaigns
    * @param {Object} event - The event object
    */
    async lazyLoadCampaigns(event) {
      if (event.last >= this.campaigns.length && this.campaignListCurrentPage < this.campaignListPageCount) {
        this.campaignListCurrentPage += 1;
        this.getCampaignList();
      }
    },
    /**
     * Get the stats for selected campaign
     * @returns {Promise} - The promise for the campaign/enterprise/stats
     */
     async getSelectedCampaignStats(event = {}) {
      this.loadingCampaignStats = true;
      this.$store.dispatch('getRefreshToken');
      this.pharmacyCampaignStats = [];

      // If event is not empty, then it is a page change event
      event.page !== undefined ? this.pharmacyCampaignStatsCurrentPage = event.page + 1 : this.pharmacyCampaignStatsCurrentPage = 1
      
      try {
        const res = await axios.post('/api/campaign/enterprise/stats', this.campaignStatsPayLoad, this.$store.state.header);
        this.handleGetCampaignStatsSuccess(res);
      } catch (err) {
        this.handleGetCampaignStatsError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the get campaign stats success case
     * @param {Object} res
     */
    handleGetCampaignStatsSuccess(res) {
      console.log('The get campaign stats response is', res.data);
      var totalCampaignStats = {};
      this.pharmacyCampaignStats = res.data.pharmacies.map(x => ({...x}));
      // Get pharmacy name from list of locations based on id
      this.pharmacyCampaignStats.forEach(pharmacy => {
        pharmacy.pharmacy_name = this.companyPharmaciesLocal.find(x => x.pharmacy_id === pharmacy.pharmacy_id).pharmacy_name;
      });
      this.pharmacyCampaignStatsCurrentPage = res.data.current_page;
      this.pharmacyCampaignStatsPerPage = res.data.per_page;
      this.pharmacyCampaignStatsPageCount = res.data.page_count;
      this.campaignPharmacies = res.data.pharmacies.map(x => x.pharmacy_id);

      // Format the totals data
      totalCampaignStats.rank = "TOTAL";
      if (res.data.total_pharmacies === 1) {
        totalCampaignStats.pharmacy_name = res.data.total_pharmacies +" Pharmacy";
      } else {
        totalCampaignStats.pharmacy_name = res.data.total_pharmacies +" Pharmacies";
      }
      totalCampaignStats.messages = res.data.total_messages;
      totalCampaignStats.reach = res.data.total_reach;
      totalCampaignStats.cost = res.data.total_cost;
      totalCampaignStats.revenue = res.data.total_revenue;
      totalCampaignStats.revenue_change = res.data.total_revenue_change;
      if (res.data.total_made_goal ===  res.data.total_pharmacies) {
        totalCampaignStats.made_goal = true;
        totalCampaignStats.made_goal_text = res.data.total_made_goal + "/" + res.data.total_pharmacies;
      } else {
        totalCampaignStats.made_goal = false;
        totalCampaignStats.made_goal_text = res.data.total_made_goal + "/" + res.data.total_pharmacies;
      }
      
      this.pharmacyCampaignStats.unshift(totalCampaignStats);

      this.loadingCampaignStats = false;
    },
    /**
     * Handle the get campaign stats error case
     * @param {Object} err
     */
    handleGetCampaignStatsError(err) {
      console.log('The get campaign stats error is', err);
      this.campaignListError = err;
      this.loadingCampaignStats = false;
    },
    /**
     * Modify the campaign based on the selected request type
     */
    async modifyCampaign() {
      this.$store.dispatch('getRefreshToken');
      if (this.selectedRequestType == 'Activate' || this.selectedRequestType == 'Deactivate') {
        // Call the Edit Campaign Endpoint
        console.log('The activate/deactivate campaign payload is', this.campaignActivateDeactivatePayload);
        this.activateDeactivateLoading = true;
        this.activateDeactivateError = '';

        try {
          const res = await axios.post(`/api/campaign/${this.selectedCampaignLocal.campaign_id}/activate/enterprise`, this.campaignActivateDeactivatePayload, this.$store.state.header);
          this.handleCampaignActivateDeactivateSuccess(res);
        } catch (err) {
          this.handleCampaignActivateDeactivateError(this.handleAxiosError(err));
        }
      } 
      else if (this.selectedRequestType == 'Delete') {
        this.deleteLoading = true;
        this.deleteError = '';

        // Call the Delete Campaign Endpoint
        try {
          const res = await axios.post(`/api/campaign/${this.selectedCampaignLocal.campaign_id}/delete/enterprise`, null, this.$store.state.header);
          this.handleCampaignDeleteSuccess(res);
        } catch (err) {
          this.handleCampaignDeleteError(this.handleAxiosError(err));
        }
      } else {
        console.log("No matching request type!");
      }
    },
    /**
     * Handle the campaign edit success case
     * @param {Object} res
     */
     handleCampaignActivateDeactivateSuccess(res) {
      console.log('The activate/deactivate campaign response is', res);
      // Update the active status of selected campaign
      this.selectedCampaignLocal.active = !this.selectedCampaignLocal.active;
      // If the campaign is inactive, remove from the parent campaigns list
      if (!this.selectedCampaignLocal.active) {
        this.$emit('campaignRemoved', this.selectedCampaignLocal.campaign_id);
      }

      // If the campaign is activate/deactivated, set days left to 0
      this.selectedCampaignLocal.days_left = 0;
      // If the campaign is active, set campaignsModified to true
      if (this.selectedCampaignLocal.active) {
        // Set campaigns modified to true
        this.campaignsModified = true;
        // Set the start date to the current date and end date to null
        this.selectedCampaignLocal.start_date = this.currentDate;
        this.selectedCampaignLocal.end_date = null;
      }
      else {
        // Set the end date to the current date
        this.selectedCampaignLocal.end_date = this.currentDate;
      }

      this.campaigns = this.campaigns.map(x => {
        if (x.campaign_id === this.selectedCampaignLocal.campaign_id) {
          x.active = this.selectedCampaignLocal.active;
          x.start_date = this.selectedCampaignLocal.start_date;
          x.end_date = this.selectedCampaignLocal.end_date;
          x.days_left = this.selectedCampaignLocal.days_left;
        }
        return x;
      });
      this.updateActivateDeactivateButton(false);
      this.activateDeactivateLoading = false;
    },
    /**
     * Handle the campaign edit error case
     * @param {Object} err
     */
     handleCampaignActivateDeactivateError(err) {
      console.log('The activate/deactivate campaign error is', err);
      this.updateActivateDeactivateButton(false);
      this.activateDeactivateError = err;
      this.activateDeactivateLoading = false;
      this.activateDeactivateError = err;
    },
    /**
     * Handle the campaign delete success case
     * @param {Object} res
     */
    handleCampaignDeleteSuccess(res) {
      console.log('The delete campaign response is', res);
      // Remove the campaign from campaigns list
      this.campaigns = this.campaigns.filter(x => x.campaign_id !== this.selectedCampaignLocal.campaign_id);
      // Emit the campaignRemoved event
      this.$emit('campaignRemoved', this.selectedCampaignLocal.campaign_id);
      // select the first campaign
      if (this.campaigns.length > 0) {
        this.selectedCampaignLocal = this.campaigns[0];
      } else {
        this.selectedCampaignLocal = {};
      }
      this.deleteLoading = false;
    },
    /**
     * Handle the campaign delete error case
     * @param {Object} err
     */
    handleCampaignDeleteError(err) {
      console.log('The delete campaign error is', err);
      this.deleteLoading = false;
      this.deleteError = err;
    },
    /**
     * Display the page
     * @param {Number} page - The page number to display
     */
    // displayPage(page) {
    //   this.campaignListCurrentPage = page;
    //   this.getCampaignList();
    /**
     * Search for campaigns based on the search string
     */
    searchCampaigns(event) {
      this.campaignListCurrentPage = 1; // reset current page number to 1

      // If the enter key is pressed, then search for campaigns without delay
      if (event.type==='keydown' && event.key === 'Enter') {
        clearTimeout(this.delayTimer); // clear the delay timer when the serach is triggered by enter key
        this.$store.dispatch('getRefreshToken');
        this.getCampaignList();
      }
      else {
        // If the input change event is triggered, then search for campaigns with delay
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(() => {
          this.$store.dispatch('getRefreshToken');
          this.getCampaignList();
        }, 400);
      }
    },
    /**
     * Change filters for the campaign list
     */
    changeCampaignTypeSelection() {
      this.campaignListCurrentPage = 1; // reset current page number to 1
      this.getCampaignList();
    },
    /**
     * Search for pharmacies in the campaign stats table
     * returns {Promise} - The promise for the pharmacy/enterprise/search request
     */
    searchPharmacies() {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(() => {
        this.filteredPharmacies = this.selectedCampaignLocal.locations.filter(x => x.location_name.toLowerCase().includes(this.searchPharmacy.toLowerCase()));
        this.getSelectedCampaignStats();
      }, 400);
    },
    /**
     * Refresh the campaign list
     */
    refreshCampaignList() {
      this.campaignsModified = true;
      this.getCampaignList();
    },
    /*
     * Lazy load the campaign pharmacy list
     * @param {Object} event - The event object
     */
    async lazyLoadCampaignPharmacyStats(event) {
        // Get the past position of the scroll
        console.log("The event is: ", event);
        
        let scrollerRef = this.$refs.pharmacyCampaignStatsTable;
        let lastPos = scrollerRef.lastScrollPos;
        if (event.last >= this.campaignPharmacies.length && this.pharmacyCampaignStatsCurrentPage < this.pharmacyCampaignStatsPageCount && !this.loadingCampaignStats) {
          this.campaignListCurrentPage += 1;
          await this.getSelectedCampaignStats();
          // Scroll to the last position
          // Scroll to the last position
          scrollerRef.scrollTo({
            left: 0,
            top: lastPos
          });
        }
      },
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: row;
  height: 78vh;
  min-width: 1300px;
}

.sidebar {
  width: 300px;
  background-color: $grey;
  display: flex;
  flex-direction: column;
}

.header-box {
  display: flex;
  flex-direction: column;
  padding: 2em;
  gap: 1em;
}

.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:deep(.p-inputtext) {
  width: 100%;
}

.p-inputtext,
.p-selectbutton,
.p-password-input {
  border-radius: 5px;
  border: 1px solid $deepgrey;
  overflow: hidden;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

.p-selectbutton {
  display: flex;
}

.p-selectbutton :deep(div) {
  flex: 1;
}

:deep(.p-button:focus) {
  box-shadow: none;
}

:deep(.p-button) .p-button-icon {
  padding: 0;
}

.campaigns-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  border-top: 1px solid $deepgrey;
}

.campaign-item {
  height: 4em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $deepgrey;
  padding: 0 1em 0 2em;
  cursor: pointer;
  gap: 10px;
}

.campaign-item:first {
  border-top: 1px solid $deepgrey;
}

.campaign-item:hover {
  background-color: $babyblue;
}

.campaign-item h4 {
  font-weight: 700;
  font-size: 11pt;
  color: $deepgrey;
}

.status-symbol {
  display: flex;
  border-radius: 5px;
  font-size: 10pt;
  font-weight: 600;
  gap: 5px;
  align-items: center;
  padding: 5px 10px 5px 10px;
  width: fit-content;
}

.status-icon {
  width: 1em;
  height: 1em;
  border-radius: 50%;
}

.time-remaining-text {
  font-size: 12pt;
  font-weight: 600;
  align-self: center;
  margin-left: auto;
}

.edit-icon {
  cursor: pointer;
  border-radius: 50%;
  background-color: $strongblue;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.recommendation-icon {
  display: flex;
  width: 2em;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

.selected-campaign {
  background-color: white;
}

.goal-text {
  font-weight: 700;
  color: $strongblue;
  align-self: center;
}

.flex-row {
  display: flex;
}

.campaign-content {
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 1;
  text-align: left;
  overflow-y: auto;
}

.campaign-content h4 {
  margin: 0;
}

.b-bottom {
  padding-bottom: 1em;
  border-bottom: 1px solid $bordergrey;
}

.radio-group {
  gap: 0.5em;
}

.radio-item {
  font-size: 11pt;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.form-container {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: 20px;
  align-items: stretch;
  padding: 30px 30px 10px 30px;
}

.form-container h3 {
  margin: 0;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.edit-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: stretch;
  border-bottom: 1px solid $bordergrey;
  padding-bottom: 10px;
  height: 2.5em;
}

.filters-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.flex-item {
  flex: 1;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.grey-input {
  border: 1px solid #ced4da !important;
  border-radius: 3px;
}

.pharmacies-container {
  display: flex;
  border-radius: 5px;
  background-color: $grey;
  overflow-y: auto;
  flex: 1 1 150px;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 10px 0 10px;
}

.pharmacy-bubble {
  display: flex;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  background-color: $bluegreen;
  color: white;
  font-weight: 500;
  font-size: 11pt;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

.attachments-text {
  cursor: pointer;
  gap: 20px;
  text-decoration: underline;
  align-items: center;
}

.goal-column {
  width:120px; 
  display: flex; 
  flex-direction: row; 
  justify-content: space-between; 
  align-items: center;
}
.goal-column-value {
  width: 80px;
}
.goal-column-change {
  text-align: right;
}
:deep(.pharmacies-row) {
  background-color: $grey !important;
  font-size: 1em;
  font-weight: 500;
  border: 1px solid $midgrey !important;
  border-radius: 6px !important;
  height: 3.5em;
}

:deep(.pharmacies-totals-row) {
  background-color: $darkgrey;
  margin:1em;
}

:deep(.p-datatable-row-expansion td) {
  padding: 0;
}

</style>
