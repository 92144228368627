<template>
  <Card class="campaign-card">
    <template #content>
      <div class="px-4 py-3">
        <div class="d-flex justify-space-between align-center mb-3">
          <div>
            <div 
              style="text-align: left;"
              class="mb-1"
            >
              <span v-if="author"><strong>{{ author }}</strong></span>
            </div>
            <div class="campaign-card-title">
              {{ campaign.name }}
            </div>
          </div>
        </div>
        <CampaignProgress :campaign="campaign" />
      </div>
      <Divider class="ma-0" />
      <div class="px-4 py-2">
        <div class="d-flex justify-space-between align-center">
          <div>
            <Tag
              :class="`campaign-goal-type-tag ${campaign.goal_type}`"
              :value="campaign.goal_type"
            />
          </div>
          <div class="text-right">
            <i class="pi pi-calendar mr-2"></i>
            <span v-if="campaign.start_date"><b>{{ formatDate(campaign.start_date) }} - {{ formatDate(campaign.end_date) }}</b></span>
            <span v-else>From <b>{{ formatDate(campaign.start_date) }}</b></span>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import CampaignProgress from '@/components/campaign/campaign-progress.vue';

export default {
  components: {
    CampaignProgress
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
    }
  },
  computed: {
    author() {
      return this.campaign.is_enterprise ? 'Enterprise User' : 'Pharmacy User'
    }
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) {
        return '';
      }

      const date = new Date(dateString);
      const day = date.getDate();
      const month = date.toLocaleString('default', { month: 'short' });
      return `${day} ${month}`;
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";
:deep(.pi) {
  padding: 0;
}

.campaign-card .campaign-card-title {
  font-size: 1.25em;
  font-weight: 700;
  text-align: left;
  color: #000000;
}

.campaign-card :deep(.p-card-body) {
  padding: 0;
}

.campaign-card :deep(.p-card-content) {
  padding: 0;
}

.campaign-card {
  cursor: pointer;
}

.btn-action {
  width: 30px !important;
  min-width: 30px !important;
  height: 30px !important;
  padding: 0;
  border-color: #0870DC;
  color: #0870DC;
}

.btn-action :deep(.pi) {
  font-size: 14px !important;
  font-weight: bold;
}
</style>