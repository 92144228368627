<template>
  <div class="d-flex justify-space-between align-center mb-4">
    <div>Use filters to refine your patient cohort for your campaign</div>
  </div>
  <div class="row">
    <div class="col col-4">
      <div class="row">
        <div class="col col-6">
          <div class="d-flex flex-col ga-2">
            <label class="fs-14 font-weight-700">Adherence</label>
            <MultiSelect
              v-model="patientFilters.adherence"
              :options="adherenceOptions"
              option-label="label"
              option-value="value"
              placeholder="Select"
              :disabled="disabledAdherence"
              :max-selected-labels="1"
              :selected-items-label="'{0} selected'"
            />
          </div>
        </div>
        <div class="col col-6">
          <div class="d-flex flex-col ga-2">
            <label class="fs-14 font-weight-700">Loyalty</label>
            <MultiSelect
              v-model="patientFilters.loyalty"
              :options="loyaltyOptions"
              option-label="label"
              option-value="value"
              placeholder="Select"
              :disabled="disabledLoyalty"
              :max-selected-labels="1"
              :selected-items-label="'{0} selected'"
            />
          </div>
        </div>
        <div class="col col-12">
          <div class="d-flex flex-col ga-2">
            <label class="fs-14 font-weight-700">Patient Type</label>
            <MultiSelect
              v-model="patientFilters.patient_type"
              :options="patientTypeOptions"
              option-label="name"
              option-value="name"
              placeholder="Select"
              :loading="loadingGroupList"
              :disabled="disabledPatientType"
              :filter="true"
              :max-selected-labels="1"
              :selected-items-label="'{0} types selected'"
            />
          </div>
        </div>
        <div class="col col-12">
          <div class="d-flex flex-col ga-2">
            <label class="fs-14 font-weight-700">Age</label>
            <InputText
              v-model="patientFilters.age"
              placeholder="e.g. 10-15, 65+"
              :disabled="disabledPatientAge"
            />
          </div>
        </div>
        <div class="col col-12">
          <div class="d-flex flex-col ga-2">
            <label class="fs-14 font-weight-700">Patient Sex</label>
            <MultiSelect
              v-model="patientFilters.patient_sex"
              :options="patientSexOptions"
              option-label="name"
              option-value="name"
              placeholder="Select"
              :loading="loadingGroupList"
              :disabled="disabledPatientSex"
            />
          </div>
        </div>
        <div class="col col-12">
          <div class="d-flex flex-col ga-2">
            <label class="fs-14 font-weight-700">Polypharmacy</label>
            <InputText
              v-model="patientFilters.polypharmacy"
              placeholder="e.g. 1-5, 7+"
              :disabled="disabledPolypharmacy"
            />
          </div>
        </div>
        <div class="col col-12">
          <div class="d-flex flex-col ga-2">
            <label class="fs-14 font-weight-700">Drug Type</label>
            <MultiSelect
              v-model="patientFilters.drug_type"
              :filter="true"
              :options="drugTypeOptions"
              option-label="name"
              option-value="name"
              placeholder="Select"
              :loading="loadingGroupList"
              :disabled="disabledDrugType"
              :max-selected-labels="1"
              :selected-items-label="'{0} selected'"
            />
          </div>
        </div>
        <div class="col col-12">
          <div class="d-flex flex-col ga-2">
            <label class="fs-14 font-weight-700">Distance From Store</label>
            <InputText
              v-model="patientFilters.distance_from_store"
              placeholder="e.g. 0-30"
              :disabled="disabledDistanceFromStore"
            />
          </div>
        </div>
        <div 
          v-if="countryCode==='AU'"
          class="col col-12"
        >
          <div class="d-flex flex-col ga-2">
            <label class="fs-14 font-weight-700">60 Day Dispensing</label>
            <MultiSelect
              v-model="patientFilters.double_dispensing"
              :options="sixtyDDOptions"
              option-label="name"
              option-value="name"
              placeholder="Select"
              :loading="loadingGroupList"
              :disabled="disabledSixtyDD"
              :max-selected-labels="1"
              :selected-items-label="'{0} selected'"     
            />
          </div>
        </div>
      </div>
    </div>
    <div class="col col-8">
      <CampaignRefineCohortMolecule
        v-model="patientFilters.molecules"
        :disabled="disabledMolecules"
        @full-molecule-list="setFullMoleculeList"
      />
      <div class="d-flex justify-space-between">
        <div style="width: 50%;">
          <Button
            label="Reset"
            class="button-blue w-100"
            @click="resetFilters"
          />
        </div>
        <div>
          <Button
            label="Back"
            class="button-light-grey mr-4"
            @click="closeModal"
          />
          <Button
            label="Save"
            class="button-black"
            @click="saveCampaignRefineCohort"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import CampaignRefineCohortMolecule from '@/components/campaign/form/campaign-refine-cohort-molecule.vue';

export default {
  components: {
    CampaignRefineCohortMolecule,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    }
  },
  emits: [
    'closeModal',
    'filters'  
  ],
  data() {
    return {
      patientFilters: {
        adherence: [],
        loyalty: [],
        patient_type: [],
        age: '',
        patient_sex: [],
        polypharmacy: '',
        drug_type: [],
        distance_from_store: '',
        double_dispensing: [],
        molecules: []
      },
      groupList: [],
      fullMoleculeList: [],
      dropDownCategoryOptions: [],
      adherenceOptions: [
        { label: 'Very Poor', value: 1 },
        { label: 'Poor', value: 2 },
        { label: 'Average', value: 3 },
        { label: 'Good', value: 4 },
        { label: 'Strong', value: 5 }
      ],
      loyaltyOptions: [
        { label: 'Very Poor', value: 1 },
        { label: 'Poor', value: 2 },
        { label: 'Average', value: 3 },
        { label: 'Good', value: 4 },
        { label: 'Strong', value: 5 }
      ],
      patientTypeOptions : [],
      patientSexOptions: [],
      drugTypeOptions: [],
      sixtyDDOptions: [],
      loadingGroupList: false,
      disabledAdherence: false,
      disabledLoyalty: false,
      disabledPatientType: false,
      disabledPatientAge: false,
      disabledPatientSex: false,
      disabledPolypharmacy: false,
      disabledDrugType: false,
      disabledDistanceFromStore: false,
      disabledSixtyDD: false,
      disabledMolecules: false,
      delayTimer: null,
    }
  },
  computed: {
  },
  async mounted() {
    
    await this.getGroupList();
    if (this.filtersProp) {
      this.patientFilters = this.filtersProp;
      if (this.patientFilters.molecules.length === 0) {
        this.patientFilters.molecules = this.fullMoleculeList;
      }
    }
    else if (this.campaign.patient_cohort) {
      this.patientFilters = structuredClone(this.campaign.patient_cohort);
    }
  },
  methods: {
    async getGroupList() {
      this.$store.dispatch('getRefreshToken');

      this.loadingGroupList = true;

      this.dropDownCategoryOptions = ["All Categories"];

    
      await axios.post(`/api/enterprise-group-list-names`, {}, this.$store.state.header)
      .then((res) => {
        this.handleGroupListSuccess(res);
      })
      .catch((err) => {
        this.handleGroupListError(err);
      })
    },
    /**
     * Handle the success case for getting the group list
     * @param {Object} res
     */
    handleGroupListSuccess(res) {
      this.groupList = res.data.groups

        this.groupList.forEach(group => {
        let catNames = group['categories'].map(category => category['name']);
        this.dropDownCategoryOptions = [...this.dropDownCategoryOptions, ...catNames];
      });

        // Group list types
        let demographicGroupList = this.groupList[0];
        let medicalGroupList = this.groupList[2];
        let socioEconomicGroupList = this.groupList[1];

        this.patientSexOptions = demographicGroupList['categories'][0]['subcategories'];
        this.ageOptions = demographicGroupList['categories'][1]['subcategories'];
        this.patientTypeOptions = demographicGroupList['categories'][2]['subcategories'];
        // sort the patient type options alphabetically
        this.patientTypeOptions.sort((a, b) => a.name.localeCompare(b.name));
        
        this.annualIncomeOptions = socioEconomicGroupList['categories'][1]['subcategories'];

        this.drugTypeOptions = medicalGroupList['categories'][0]['subcategories'];
        // sort the drug type options alphabetically
        this.drugTypeOptions.sort((a, b) => a.name.localeCompare(b.name));

        this.polypharmacyOptions = medicalGroupList['categories'][1]['subcategories'];
        this.sixtyDDOptions = medicalGroupList['categories'][2]['subcategories'];

        this.loadingGroupList = false;
    },
    /**
     * Handle the error case for getting the group list
     * @param {Object} err
     */
    handleGroupListError(err) {
      console.log('The get group list error is', err);
      this.loadingGroupList = false;
    },
    /**
     * Set the full molecule list
     * @param {Array} moleculeList
     */
    setFullMoleculeList(moleculeList) {
      this.fullMoleculeList = moleculeList
    },
    /**
     * Reset the patient filters
     */
    async resetFilters() {
      this.patientFilters = {
        adherence: [],
        loyalty: [],
        patient_type: [],
        age: '',
        patient_sex: [],
        polypharmacy: '',
        drug_type: [],
        distance_from_store: '',
        double_dispensing: [],
        molecules: this.fullMoleculeList,
      }
    },
    disabledPatientList(disabled) {
      this.disabledAdherence = disabled
      this.disabledLoyalty = disabled
      this.disabledPatientType = disabled
      this.disabledPatientAge = disabled
      this.disabledPatientSex = disabled
      this.disabledPolypharmacy = disabled
      this.disabledDrugType = disabled
      this.disabledDistanceFromStore = disabled
      this.disabledSixtyDD = disabled
      this.disabledMolecules = disabled
    },
    closeModal() {
      this.$emit('closeModal')
    },
    saveCampaignRefineCohort() {
      console.log('The patient filters are', this.patientFilters);
      if (this.patientFilters.molecules.length === this.fullMoleculeList.length) {
        this.patientFilters.molecules = [];
      }
      this.$emit('filters', this.patientFilters);
      this.$emit('closeModal');
    }
  },
}
</script>

<style lang="scss" scoped>
.p-button {
  font-size: 14px;
  border-radius: 6px;
  border: none;
  min-width: 100px;
  font-weight: 700;
}

.button-black {
  background: #101820;
  color: #FFFFFF;
}

.button-black:hover {
  background: #000000 !important;
}

.button-light-grey {
  background: #D8DCDC;
  color: #7B7E7E;
}

.button-light-grey:hover {
  background: #b6b9b9 !important;
  color: #7B7E7E;
}

.button-blue {
  background: $babyblue;
  color: $strongblue;
  border: solid 1px $strongblue
}

.button-blue:hover {
  background: $babyblue;
  color: $strongblue;
  border: solid 1px $strongblue;
}
</style>