<template>
  <div class="nav-menu-dropdown">
    <transition>
      <div
        class="profile-menu"
        :class="expanded ? `expanded-menu` : ``"
      >
        <div
          class="profile-heading"
          @click="toggleMenu"
        >
          <img
            :src="profileIconSrc"
            class="small-icon"
            alt="Profile Icon"
          >
          <div>
            <p class="profile-name">
              {{ $store.state.user.first_name }} {{ $store.state.user.last_name }}
            </p>
          </div>
          <transition>
            <i
              class="chevron pi-chevron-right pi"
              :class="expanded ? `rotate`: ``"
            ></i>
          </transition>
        </div>
        <div
          v-show="expanded"
          class="menu-items"
        >
          <div
            class="menu-item"
            @click="eraseLoginDetails"
          >
            <img
              :src="signoutIconSrc"
              class="small-icon"
              alt="Profile Icon"
            >
            <p>
              Signout
            </p>
          </div>
          <div
            v-if="dataAnalytics"
            class="menu-item"
            @click="openMessageHistoryReport"
          >
            <img
              :src="emailIconSrc"
              class="small-icon"
              alt="Report Icon"
            >
            <p>Message History Report</p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'NavMenuDropdown',
  /**
   * Emit events to parent component
   */
  emits: [
    'openMessageHistoryReport'
  ],
  data() {
    return {
      expanded: false,
      displayLocations: false,
      profileIconSrc: require('@/assets/icons/profile_icon.svg'),
      signoutIconSrc: require('@/assets/icons/signout_icon.svg'),
      emailIconSrc: require('@/assets/icons/email_icon.svg'),
      items: [
        {
          label: `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`,
          items: [
            {
              label: 'Log Out',
              icon: 'pi pi-fw pi-power-off',
              command: () => {
                this.eraseLoginDetails()
              }
            },
            {
              label: 'Message History Report',
              icon: 'pi pi-fw pi-envelope',
              command: () => {
                this.openMessageHistoryReport()
              }
            }
          ]
        }
      ]
    }
  },
  computed: {
    dataAnalytics() {
      return JSON.parse(localStorage.getItem('data_analytics') ?? false);
    }
  },
  methods: {
    toggleMenu() {
      this.expanded = !this.expanded;
    },
    eraseLoginDetails() {
      axios.post('/api/logout', {}, this.$store.state.header)
      .then(res => {
        console.log('this is the logout res', res.data);
      }).catch(err => {
        console.log('error is', err.response.data);
      })

      // Remove the username and company if rememberUsername is not set
      if (!localStorage.getItem('rememberUsername') || localStorage.getItem('rememberUsername') !== 'true') {
        localStorage.removeItem('username');
        localStorage.removeItem('company');
      }

      localStorage.removeItem('currentLocation');
      localStorage.removeItem('user');
      localStorage.removeItem('session_id');
      localStorage.removeItem('lastRefresh');
      localStorage.removeItem('savedform');
      localStorage.removeItem('sr_login');
      localStorage.removeItem('guild_login');
      localStorage.removeItem('clinical_trials_login');
      localStorage.removeItem('recruiter');
      localStorage.removeItem('sixty_day_signup');
      localStorage.removeItem('data_analytics');
      localStorage.removeItem('supplier_analytics');
      localStorage.removeItem('ad_analytics');
      localStorage.removeItem('ecdr');
      localStorage.removeItem('portal_eula');
      document.cookie = "auth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      this.$store.commit('setAuthorization');

      // Remove the user in Splunk for RUM
      this.setSplunkUserSession(undefined);

      this.$router.push('/login');
    },
    openMessageHistoryReport() {
      this.$emit('openMessageHistoryReport');
    }
  }
}
</script>

<style land="scss" scoped>
  .nav-functions {
    display: flex;
    align-items: flex-start;
    z-index: 3;
  }
  .p-menu {
    top: 85px !important;
  }
  .profile-menu {
    display: flex;
    flex-direction: column;
    width: 260px;
    border-radius: 10px;
  }
  .profile-name {
    font-weight: 600;
    opacity: 0.85;
    font-size: 14pt;
  }
  .expanded-menu {
    background-color: white;
    box-shadow: 0px 5px 5px -2px rgba(0, 0, 0, 0.4);
  }
  .profile-heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
    width: 100%;
    cursor: pointer;
  }
  .menu-items {
    display: flex;
    flex-direction: column;
    padding: 10px 25px 25px 25px;
    gap: 10px;
  }
  .menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 25px;
    cursor: pointer;
  }
  .chevron {
    transition: all .3s ease-in-out;
    padding: 0px;
  }
  .rotate {
    transform: rotate(90deg);
  }
</style>
