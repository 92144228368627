<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              {{ editMode ? 'Edit' : 'Create' }} Global Template
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <InputText
                v-model="templateForm.name"
                placeholder="Template Name"
                class="grey-input"
              />
              <div class="form-row flex-end">
                <!-- SUBJECT LINE -->
                <InputText
                  v-if="templateForm.email_template"
                  v-model="templateForm.subject"
                  placeholder="Subject Line"
                  class="grey-input flex-item"
                />
                <ToggleButton
                  v-model="templateForm.email_template"
                  on-label="Email"
                  off-label="Email"
                  on-icon="pi pi-check"
                  off-icon="pi pi-times"
                />
                <ToggleButton
                  v-model="templateForm.sms_template"
                  on-label="SMS"
                  off-label="SMS"
                  on-icon="pi pi-check"
                  off-icon="pi pi-times"
                />
              </div>
              <TemplateEditor
                v-if="templateMounted"
                class="flex-item"
                :content="templateForm.body"
                :communication="communicationType"
                @content-updated="setContent"
              />
            </div>
          </slot>
        </div>

        <!-- FOOTER -->
        <div
          class="modal-footer"
          style="padding-left: 0;"
        >
          <div
            v-if="templateCreateEditError && templateCreateEditError !==''"
            class="error-text"
          >
            <span
              class="red-error-icon"
            >!</span>
            <span class="error-text-span">{{ templateCreateEditError }}</span>
          </div>
          <div
            v-else-if="templateCreateEditSuccess && templateCreateEditSuccess !==''"
            class="success-message" 
          >
            <span class="error-text-span">{{ templateCreateEditSuccess }}</span>
          </div>
          <div
            class="black-button"
            style="margin:auto 0;"
            @click="save"
          >
            {{ actionButtonText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TemplateEditor from '@/components/templates/template-editor.vue';
import axios from 'axios';

export default {
  name: 'AdminCreateEditGlobalTemplateModal',
  components: {
    TemplateEditor,
  },
  props: {
    /**
     * The selected template
     * @type {Object}
     */
    templates: {
      type: Array,
      default() {
        return []
      }
    },
     /**
     * The confirmation details
     * @type {Object}
     */
     confirmationDetails: {
      type: Object,
      default() {
        return {}
      }
    },
    /**
     * The flag to create from campaign
     * @type {Object}
     */
    createFromClinicalTrial: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'closeModal',
    'globalTemplateEdited',
    'globalTemplateCreated'
  ],
  data() {
    return {
      currentTemplates: [],
      editMode: false,
      createFromClinicalTrialLocal: false,
      templateMounted: false,
      loadingTemplateCreateEdit: true,
      templateCreateEditError: '',
      templateCreateEditSuccess: '',
      actionButtonText: 'Create',
      templateForm: {
        name: '',
        body: '',
        subject: '',
        sms_template: false,
        email_template: false,
      }
    }
  },
  computed: {
    /**
     * Generate payload to edit the template
     * @returns {Object}
     */
    createEditTemplatePayload() {
      let payload = {
        name: this.templateForm.name,
        body: this.templateForm.body,
        subject: this.templateForm.subject,
        communication_type: this.communicationType,
        active: this.createFromClinicalTrialLocal ? false : true,
      }
      if (this.editMode) {
        payload.template_ids = this.templateForm.template_ids;
      }
      return payload;
    },
    communicationType() {
      if (this.templateForm.sms_template && this.templateForm.email_template) {
        return 'both';
      } else if (this.templateForm.sms_template) {
        return 'sms';
      } else if (this.templateForm.email_template) {
        return 'email';
      } else {
        return 'both';
      }
    }
  },
  mounted() {
    this.currentTemplates = this.templates;
    if (this.currentTemplates.length === 0) {
      this.editMode = false;
      this.actionButtonText = 'Create';
      this.createFromClinicalTrialLocal = this.createFromClinicalTrial;
    }
    else {
      this.editMode = true;
      this.actionButtonText = 'Update';

      if (this.currentTemplates.length === 1) {
        this.templateForm.name = this.currentTemplates[0].name;
        this.templateForm.body = this.currentTemplates[0].body;
        this.templateForm.subject = this.currentTemplates[0].subject;
        if (this.currentTemplates[0].type === 'both') {
          this.templateForm.sms_template = true;
          this.templateForm.email_template = true;
        } else if (this.currentTemplates[0].type === 'sms') {
          this.templateForm.sms_template = true;
          this.templateForm.email_template = false;
        } else {
          this.templateForm.sms_template = false;
          this.templateForm.email_template = true;
        }
        this.templateForm.template_ids = this.currentTemplates.map(template => template.uuid);
      }
      else {
        this.templateForm.name = this.confirmationDetails && this.confirmationDetails.nameSame ? this.currentTemplates[0].name : '';
        this.templateForm.body = this.confirmationDetails && this.confirmationDetails.bodySame ? this.currentTemplates[0].body : '';
        this.templateForm.sms_template = this.confirmationDetails && this.confirmationDetails.typeSame ? this.currentTemplates[0].sms_template : false;
        this.templateForm.email_template = this.confirmationDetails && this.confirmationDetails.typeSame ? this.currentTemplates[0].email_template : false;
        this.templateForm.subject = this.confirmationDetails && this.confirmationDetails.subjectSame ? this.currentTemplates[0].subject : '';
        this.templateForm.template_ids = this.currentTemplates.map(template => template.template_recommendation_id);
      }
    }

    this.templateMounted = true;
  },
  methods: {
    /**
     * Set the current content
     */
    setContent(content) {
      this.templateForm.body = content;
    },
    /**
     * Save the template
     */
    save() {
      this.$store.dispatch('getRefreshToken');

      let errors = [];
      this.templateCreateEditError = ''; 
      
      if (this.templateForm.name === '') {
        errors.push('enter a template name');
      }
      if (!this.templateForm.email_template && !this.templateForm.sms_template) {
        errors.push('select at least one template type');
      }
      if (this.templateForm.body === '') {
        errors.push('enter a message body');
      }
      if (!this.templateForm.body.includes("{Pharmacy Name}")) {
        errors.push('include {Pharmacy Name} in the message body');
      }

      if (errors.length > 0) {
        if (errors.length > 1) {
          errors[errors.length - 1] = 'and ' + errors[errors.length - 1];
        }
        this.templateCreateEditError = `Please ${errors.join(', ')}.`
        return;
      }

      this.loadingTemplateCreateEdit = true;
      
      this.createEditTemplate();
    },
    /**
     * Edit a template
     */
    async createEditTemplate() {
      this.$store.dispatch('getRefreshToken');
      
      console.log('Edit template: ' + this.editMode);
      console.log('The template form is', this.templateForm);

      this.loadingTemplateCreateEdit = true;
      this.actionButtonText = this.editMode ? 'Updating...' : 'Creating...';
      let apiEndpoint = this.editMode ? `/api/template/admin/edit` : '/api/template/admin/create/global';

      await axios.post(apiEndpoint, this.createEditTemplatePayload,  this.$store.state.header)
        .then((response) => {
          this.handleCreateEditTemplateSuccess(response)
        })
        .catch((error) => {
          this.handleCreateEditTemplateError(error);
        });
    },
    /**
     * Handle the successful edit case
     */
     handleCreateEditTemplateSuccess(res) {
      console.log('The create/edit template response is', res.data);
      if (this.editMode) {
        this.templateCreateEditSuccess = 'Template Updated Successfully.';
        this.actionButtonText = 'Update';
        this.$emit('globalTemplateEdited');
      }
      else {
        this.templateCreateEditSuccess = 'Template Created Successfully.';
        this.actionButtonText = 'Create';
        if (this.createFromClinicalTrialLocal) {
          this.templateCreated(res.data.id);
        }
        else {
          this.$emit('globalTemplateCreated');
        }
        this.clear();
      }
    },
    /**
     * Handle the failed edit case
     * @param {Object} err
     */
     handleCreateEditTemplateError(err) {
      console.log('The edit template error is', err);
      this.loadingTemplateCreateEdit = false;
      this.actionButtonText = this.editMode ? 'Update' : 'Create';
      this.templateCreateEditError = err;
    },
    /**
     * Clear the form
     */
    clear() {
      this.templateForm = {
        name: '',
        body: '',
        subject: '',
        email_template: false,
        sms_template: false,
      }
    },
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * Emit the template updated event to the parent component
     */
    templateEdited() {
        this.templateForm.template_id = this.template.template_id;
        this.templateForm.template_recommendation_id = this.template.template_recommendation_id;
        this.templateForm.campaigns = this.template.campaigns;
        this.$emit('globalTemplateEdited', this.templateForm);
        this.$emit('closeModal');
    },
    templateCreated(id) {
      this.templateForm.template_id = id;
      this.$emit('globalTemplateCreated', this.templateForm);
      this.$emit('closeModal');
    },
    /**
     * Close Open Modals
     */
    closeOpenModals() {
      this.loadingTemplateCreateEdit = false;
    }      
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  height: 78vh;
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-end {
  justify-content: flex-end;
}

.flex-item {
  flex: 1;
}

.radio-group {
  gap: 0.5em;
}

.modal-body {
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 2.5em;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.modal-header {
  padding: 40px 40px 0 40px !important;
}

.form-header {
  color: black;
}

.p-togglebutton {
  border-radius: 5px;
  border: 1px solid $deepgrey;
  background: white;
  color: $deepgrey;
  overflow: hidden;
}

.p-togglebutton :deep(.p-button-icon) {
  padding: 0;
}

.p-togglebutton.p-button.p-highlight {
  background-color: $deepgrey !important;
  color: white !important;
}

:deep(.p-togglebutton.p-focus) {
  box-shadow: none;
}

:deep(.p-button) .p-button-icon {
  padding: 0;
}

.error-text {
  color: $error;
  margin: 0 auto 0 0;
  padding-left: 40px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
  text-align: left;
}

.red-error-icon {
  min-width: 20px;
  background-color: $error;
  border-color: $error;
  color: white;
  border-radius: 50% !important;
  height: 20px;
  width: 20px;
  margin: auto;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.error-text-span {
  margin: auto;
  padding-left: 10px;
}

.success-message {
  color: $complete;
  margin: 0 auto 0 0;
  padding-left: 40px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
  text-align: left;
}

</style>