<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Filter Category
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <div class="form-row">
                <!-- CATEGORY SELECTION -->
                <Dropdown
                  v-model="selectedCategoryLocal"
                  class="flex-item"
                  :options="categories"
                  placeholder="Select a Category"
                />
                <!-- NUMBER OF PHARMACIES -->
                <div
                  class="data-box flex-item"
                  :class="selectedPharmaciesLocal.length ? `selected-box` : `unselected-box`"
                >
                  <p>
                    <strong>{{ selectedPharmaciesLocal.length ? selectedPharmaciesLocal.length : 0 }} Pharmacies</strong>
                  </p>
                </div>
              </div>
              <!-- TIME PERIOD DROPDOWN -->
              <div class="form-row">
                <Dropdown
                  v-model="selectedPeriodTypeLocal"
                  :options="periods"
                  placeholder="Select a Period"
                  class="flex-item"
                />
                <Dropdown
                  v-if="selectedPeriodTypeLocal === 'Month'"
                  v-model="selectedPeriodLocal.month"
                  :options="months"
                  option-label="name"
                  option-value="value"
                  placeholder="Select a Month"
                  class="flex-item"
                />
                <Dropdown
                  v-if="selectedPeriodTypeLocal === 'Quarter'"
                  v-model="selectedPeriodLocal.quarter"
                  :options="quarters"
                  option-label="name"
                  option-value="value"
                  placeholder="Select a Quarter"
                  class="flex-item"
                />
                <Dropdown
                  v-model="selectedPeriodLocal.year"
                  :options="years"
                  option-label="name"
                  option-value="value"
                  placeholder="Select a Year"
                  class="flex-item"
                />
              </div>
              
              <div class="form-row">
                <!-- STATE DROPDOWN -->
                <Dropdown
                  id="state"
                  v-model="selectedStateLocal"
                  placeholder="Select a State"
                  class="flex-item"
                  type="text"
                  :options="stateOptions"
                />
                <!-- POSTCODE ENTRY -->
                <InputText
                  v-model="selectedPostcodeLocal"
                  class="grey-input flex-item"
                  type="number"
                  placeholder="Enter a Postcode"
                />
              </div>
              <!-- PHARMACY MULTISELECT DROPDOWN -->
              <MultiSelect
                v-model="selectedPharmaciesLocal"
                :options="filteredPharmacies"
                option-label="pharmacy_name"
                placeholder="Search Pharmacies"
                :max-selected-labels="maxSelectedLabels"
                selected-items-label="Search Pharmacies"
                :filter="true"
              />
              <!-- SELECTED PHARMACIES CONTAINER -->
              <div class="pharmacies-container">
                <!-- 
                  Pharmacy bubbles are displayed here
                  The pharmacy name is displayed in the bubble
                  The close icon removes the pharmacy from the selectedPharmaciesLocal array
                 -->
                <div
                  v-for="(item, index) in selectedPharmaciesLocal"
                  :key="item.pharmacy_id"
                  class="pharmacy-bubble"
                >
                  <div class="pharmacy-name">
                    {{ item.pharmacy_name }}
                  </div>
                  <i
                    class="pi pi-times close-icon"
                    @click="removePharmacy(index)"
                  ></i>
                </div>
              </div>
            </div>
          </slot>
        </div>

        <!-- FOOTER -->
        <div class="modal-footer">
          <slot name="footer">
            <div
              class="grey-button"
              @click="reset"
            >
              Reset
            </div>
            <!-- 
              The updateFilters method is called when the confirm button is clicked
              The method emits an event to the parent component
             -->
            <div
              class="black-button"
              @click="updateFilters"
            >
              Confirm
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    /**
     * The categories to be displayed in the categories dropdown
     * @type {Array}
     */
    categories: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * The selected category
     * @type {String}
     */
    selectedCategory: {
      type: String,
      default: ''
    },
    /**
     * The pharmacies to be displayed in the pharmacies dropdown
     * @type {Array}
     */
    pharmacies: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * The selected pharmacies
     * @type {Array}
     */
    selectedPharmacies: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * The selected period
     * @type {Object}
     */
    selectedPeriod: {
      type: Object,
      default() {
        return {
          year: new Date().getFullYear(),
          month: null,
          quarter: null
        }
      }
    },
    /**
     * The months which can be selected from
     */
    months: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * The quarters which can be selected from
     */
    quarters: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * The years which can be selected from
     */
    years: {
      type: Array,
      default() {
        return []
      }
    },
    selectedPeriodType: {
      type: String,
      default: 'Financial Year'
    },
    /**
     * The states to be displayed in the states dropdown
     * @type {Array}
     */
    stateOptions: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * The selected state
     * @type {String}
     */
    selectedState: {
      type: String,
      default: ''
    },
    /**
     * The selected postcode
     * @type {String}
     */
    selectedPostcode: {
      type: String,
      default: ''
    }
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'updateFilters',
    'closeModal'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
      selectedCategoryLocal: '',
      selectedPeriodLocal: {},
      selectedStateLocal: '',
      selectedPostcodeLocal: '',
      selectedPharmaciesLocal: [],
      periods: [
        'Financial Year',
        'Quarter',
        'Month'
      ],
      selectedPeriodTypeLocal: ''
    }
  },
  computed: {
    /**
     * Filter the pharmacies based on the selected state and postcode
     * @returns {Array} - filtered pharmacies
     */
    filteredPharmacies: {
      get() {
        if (this.pharmacies) {
          return this.pharmacies.filter(pharmacy => {
            if (this.selectedStateLocal && this.selectedPostcodeLocal) {
              return pharmacy.state === this.selectedStateLocal && pharmacy.postcode === this.selectedPostcodeLocal;
            } else if (this.selectedStateLocal) {
              return pharmacy.state === this.selectedStateLocal;
            } else if (this.selectedPostcodeLocal) {
              return pharmacy.postcode === this.selectedPostcodeLocal;
            } else {
              return true;
            }
          })
        } else {
          return false;
        }
      }
    },
    /**
     * Return 0 for max selected labels - required to pass number as prop
     */
    maxSelectedLabels() {
      return 0;
    }
  },
  watch: {
    /**
     * Watch for changes to the selectedPeriodTypeLocal
     * If month is selected, set the quarter to null
     * If quarter is selected, set the month to null
     */
     selectedPeriodTypeLocal: {
      handler() {
        if (this.selectedPeriodTypeLocal === 'Month') {
          this.selectedPeriodLocal.quarter = null;
        } else if (this.selectedPeriodTypeLocal === 'Quarter') {
          this.selectedPeriodLocal.month = null;
        }
      },
      immediate: true
    }
  },
  mounted() {
    /**
     * Set the local variables to the props
     * This is required to ensure that the local variables can be updated prior to emitting changes
     */
    this.selectedCategoryLocal = this.selectedCategory;
    this.selectedPeriodLocal = this.selectedPeriod;
    this.selectedStateLocal = this.selectedState;
    this.selectedPostcodeLocal = this.selectedPostcode;
    // Map required to avoice each pharmacy being passed by reference
    this.selectedPharmaciesLocal = this.selectedPharmacies.map(x => ({...x}));
    this.selectedPeriodTypeLocal = this.selectedPeriodType;
  },
  methods: {
    /**
     * Remove a pharmacy from the selected pharmacies array
     * @param {int} index 
     */
    removePharmacy(index) {
      if (index > -1) {
        this.selectedPharmaciesLocal.splice(index, 1);
      }
    },
    /**
     * Reset the local variables
     */
    reset() {
      this.selectedCategoryLocal = '',
      this.selectedPeriodLocal = {},
      this.selectedStateLocal = '',
      this.selectedPostcodeLocal = '',
      this.selectedPharmaciesLocal = [],
      this.selectedPeriodTypeLocal = 'Financial Year'
    },
    /**
     * Emit the update filters event to the parent component
     * The event is emitted with the local variables
     */
    updateFilters() {
      this.closeModal();
      this.$emit('updateFilters', {
        selectedCategory: this.selectedCategoryLocal,
        selectedPeriod: this.selectedPeriodLocal,
        selectedState: this.selectedStateLocal,
        selectedPostcode: this.selectedPostcodeLocal,
        selectedPharmacies: this.selectedPharmaciesLocal,
        selectedPeriodType: this.selectedPeriodTypeLocal
      });
    },
    /**
     * Emit the close modal event
     */
    closeModal() {
      this.$emit('closeModal');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.pharmacies-container {
  display: flex;
  border-radius: 5px;
  background-color: $grey;
  overflow-y: auto;
  flex: 1 1 150px;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 10px 0 10px;
}

.pharmacy-bubble {
  display: flex;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  background-color: $bluegreen;
  color: white;
  font-weight: 500;
  font-size: 11pt;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

</style>
