<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- SIDEBAR -->
        <div class="sidebar">
          <div class="header-box">
            <div class="header-title flex-item">
              <h3>
                Templates
              </h3>
              <Button
                icon="pi pi-plus"
                label="New"
                aria-label="Add Template"
                class="header-button"
                @click="openCreateTemplateModal"
              />
            </div>
            <!-- SEARCH BOX -->
            <span class="p-input-icon-right flex-item">
              <i class="pi pi-search"></i>
              <InputText
                v-model="searchString"
                placeholder="Search"
                class="grey-input"
                @input="searchTemplates"
                @keydown.enter="searchTemplates"
              />
            </span>
            <!-- TEMPLATE FILTER -->
            <div class="form-row">
              <MultiSelect
                v-model="messageTypeSelection"
                :options="messageTypeOptions"
                class="grey-input flex-item"
                @change="changeTemplateTypeSelection"
              />
              <MultiSelect
                v-model="messageStatusSelection"
                :options="messageStatusOptions"
                :max-selected-labels="2"
                class="grey-input flex-item"
                @change="changeTemplateTypeSelection"
              />
            </div>
          </div>
          <!-- TEMPLATES LIST -->
          <div
            v-if="!loadingTemplateList && templates.length > 0"
            class="templates-list"
          >
            <VirtualScroller
              :items="templates"
              lazy
              :item-size="64"
              class="flex-item"
              :loading="lazyLoadingTemplateList"
              @lazy-load="lazyLoadTemplates"
            >
              <template #item="{item}">
                <div
                  class="template-item"
                  :class="item.template_id === selectedTemplate.template_id ? 'selected-template' : ''"
                  @click="selectedTemplate = item"
                >
                  <div
                    class="status-icon"
                    :class="item.active === true ? 'green-bg' : 'dark-grey-bg'"
                  ></div>
                  <h4
                    class="flex-item"
                    style="text-align: left;"
                  >
                    {{ item.name }}
                  </h4>
                  <i
                    class="pi pi-chevron-right"
                  ></i>
                </div>
              </template>
            </VirtualScroller>
          </div>
          <!-- NO TEMPLATES MESSAGE -->
          <div
            v-else-if="!loadingTemplateList && templates.length === 0"
          >
            No Templates Found
          </div>
          <!-- TEMPLATES LOADING SPINNER -->
          <ProgressSpinner
            v-else
            class="flex-section"
            style="min-height: 50px; min-width: 100%"
          />
        </div>
        <!-- MAIN CONTENT -->
        <div class="form-container">
          <!-- HEADER ROW -->
          <div class="flex-row">
            <div
              class="flex-item"
              style="text-align: left;"
            >
              <h3 v-if="selectedTemplate">
                {{ selectedTemplate.name }}
              </h3>
            </div>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </div>
          <!-- TEMPLATE -->
          <div
            v-if="selectedTemplate"
            class="template-content"
          >
            <!-- EDIT ROW -->
            <div class="edit-row">
              <!-- TEMPLATE STATUS -->
              <div
                class="status-symbol"
                :class="selectedTemplate.active === true ? ['light-green-bg', 'green-color'] : 'light-grey-bg'"
              >
                <div
                  class="status-icon"
                  :class="selectedTemplate.active === true ? 'green-bg' : 'dark-grey-bg'"
                ></div>
                {{ selectedTemplate.active === true ? 'Active' : 'Inactive' }}
              </div>
              <!-- RECOMMENDATION ICON -->
              <div
                v-if="selectedTemplate.template_recommendation_id"
                v-tooltip.top="'Recommended Template'"
                class="recommendation-icon strong-blue-bg"
              >
                <img
                  :src="recommendationIconSrc"
                  class="extrasmall-icon"
                  alt="Recommendation Icon"
                >
              </div>
              <div
                v-if="selectedTemplate.campaigns.length > 0"
                class="attached-campaigns"
              >
                <p>
                  <span class="attached-campaigns-header">Campaigns: </span> 
                  <span
                    v-for="(campaign, index) in selectedTemplate.campaigns"
                    :key="index"
                  >
                    {{ campaign.campaign_name + (index < selectedTemplate.campaigns.length - 1 ? ", ": "") }}
                  </span>
                </p>
              </div>
              <!-- EDIT TEMPLATE -->
              <div 
                v-if="!demoTemplateIds.includes(selectedTemplate.template_id)"
                class="edit-icon"
              >
                <img
                  :src="editIconSrc"
                  class="tiny-icon"
                  alt="Edit Template"
                  @click="openEditTemplateModal"
                >
              </div>
            </div>
            <!-- FILTERS ROW -->
            <div class="filters-row">
              <!-- SUBJECT LINE -->
              <div
                v-if="selectedTemplate.email_template"
                class="flex-item"
                style="font-size:10pt;"
              >
                <p>
                  Subject:
                  <span>{{ selectedTemplate.subject }}</span>
                </p>
              </div>
              <ToggleButton
                v-model="selectedTemplate.email_template"
                on-label="Email"
                off-label="Email"
                on-icon="pi pi-check"
                off-icon="pi pi-times"
                :disabled="true"
              />
              <ToggleButton
                v-model="selectedTemplate.sms_template"
                on-label="SMS"
                off-label="SMS"
                on-icon="pi pi-check"
                off-icon="pi pi-times"
                :disabled="true"
              />
            </div>
            <div class="flex-item grey-border rounded-5">
              <TextEditor
                ref="textEditor"
                v-model="selectedTemplate.body"
                class="flex-item"
                :editable="false"
              />
            </div>
          </div>

          <!-- FOOTER -->
          <div
            v-if="selectedTemplate"
            class="modal-footer"
            style="padding-left: 0;"
          >
            <button
              v-if="!demoTemplateIds.includes(selectedTemplate.template_id)"
              class="red-close-button"
              style="margin-right: auto;"
              @click="deleteTemplate"
            >
              <img
                :src="trashIconSrc"
                class="small-icon button"
                alt="Delete Icon"
              >
            </button>
            <div
              class="black-button"
              @click="getTemplateAffectedCampaigns"
            >
              {{ selectedTemplate.active === true ? 'Deactivate' : 'Activate' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <CreateTemplateModal
      v-if="displayCreateTemplateModal"
      @template-created="getTemplateList"
      @close-modal="closeOpenModals"
    />
    <EditTemplateModal
      v-if="displayEditTemplateModal"
      :template="selectedTemplate"
      @template-updated="templateUpdated"
      @template-created="getTemplateList"
      @close-modal="closeOpenModals"
    />
    <UserAuthenticationModal
      v-if="displayUserAuthenticationModal"
      :name="selectedTemplate.name"
      :payload-type="'template'"
      :updates="campaignsToBeUpdated"
      :request-type="selectedRequestType"
      @login-success="loginSuccess"
      @close-modal="closeOpenModals"
    />
  </div>
</template>
  
<script>
import axios from 'axios';
import TextEditor from '@/components/templates/text-editor.vue';
import CreateTemplateModal from '@/components/modals/create-template-modal.vue';
import EditTemplateModal from '@/components/modals/edit-template-modal.vue';
import UserAuthenticationModal from '@/components/modals/campaign-template-authentication-modal.vue';
import VirtualScroller from 'primevue/virtualscroller';

export default {
  name: 'TemplatesModal',
  components: {
    TextEditor,
    CreateTemplateModal,
    EditTemplateModal,
    UserAuthenticationModal,
    VirtualScroller
  },
  props: {
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'closeModal'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
      demoTemplateIds: ["b346ccad-5caa-47be-98d1-a57e67c1db71","a503cdf8-b492-4e30-b42f-cfefb67eb999","565c87d1-80dd-48fd-ba98-8c6b866e51dd"],
      recommendationIconSrc: require('@/assets/icons/recommendation_white_icon.svg'),
      editIconSrc: require('@/assets/icons/edit_icon.svg'),
      trashIconSrc: require('@/assets/icons/trash_icon.svg'),
      templates: [],
      loadingTemplateList: true,
      lazyLoadingTemplateList: false,
      templateListError: '',
      templateListCurrentPage: 1,
      templateListPerPage: 1,
      templateListPageCount: 1,
      selectedTemplate: null,
      messageStatusSelection: [
        'Active',
        'Inactive'
      ],
      messageStatusOptions: [
        'Active',
        'Inactive'
      ],
      messageTypeSelection: [
        'SMS',
        'Email'
      ],
      messageTypeOptions: [
        'SMS',
        'Email'
      ],
      searchString: '',
      displayEditTemplateModal: false,
      displayCreateTemplateModal: false,
      displayUserAuthenticationModal: false,
      selectedRequestType: '',
      delayTimer: null,
      campaignsToBeUpdated: [],
    }
  },
  computed: {
     /**
     * Returns the payload for the template list request
     * @returns {Object} templateListPayLoad
     */
    templateListPayLoad() {
      let active = this.messageStatusSelection.includes("Active");
      let inactive = this.messageStatusSelection.includes("Inactive");
      let payLoadObject = {
        "page": this.templateListCurrentPage,
        "email_template": this.messageTypeSelection.includes("Email"),
        "sms_template": this.messageTypeSelection.includes("SMS")
      }
      if (active && !inactive) {
        payLoadObject.active = true;
      } else if (inactive && !active) {
        payLoadObject.active = false;
      } 
      if (this.searchString !== '') {
        payLoadObject.search_term = this.searchString;
      }

      return payLoadObject
    },
    /**
     * Returns the payload for the template edit request
     * @returns {Object} templateEditPayLoad
     */
    activateOrDeactivatePayLoad() {
      return {
        "active": !this.selectedTemplate.active
      }
    }
  },
  async mounted() {
    await this.getTemplateList();
  },
  methods: {
    /**
     * Emit the activateOrDeactivate event
     */
    activateOrDeactivate() {
      this.$store.dispatch('getRefreshToken');
      console.log('activate event');
      this.selectedRequestType = this.selectedTemplate.active === true ? 'Deactivate' : 'Activate';
      this.displayUserAuthenticationModal = true;
    },
    /**
     * Get the affected campaigns for the selected template to be activated or deactivated
     * @returns {Promise} - The promise for the template/affected-campaigns/enterprise request
     */
    async getTemplateAffectedCampaigns() {
      this.$store.dispatch('getRefreshToken');
      this.campaignsToBeUpdated = [];
      let payload = {
        "active": !this.selectedTemplate.active
      }
      await axios.post(`/api/template/${this.selectedTemplate.template_id}/affected-campaigns/enterprise`, payload, this.$store.state.header)
      .then(res => {
        this.handleGetTemplateAffectedCampaignsSuccess(res);
      }).catch(err => {
        this.handleGetTemplateAffectedCampaignsError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the success case for the get affected campaigns request
     */
     handleGetTemplateAffectedCampaignsSuccess(res) {
      console.log('The affected campaigns status response is', res.data);
      this.campaignsToBeUpdated = res.data.campaign_names;
      this.activateOrDeactivate();
    },
    /**
     * Handle the error case for the get affected campaigns request
     * @param {Object} err
     */
     handleGetTemplateAffectedCampaignsError(err) {
      console.log('The affected campaigns error is', err);
    },
    /**
     * On loginSuccess, call the appropriate function
     */
    loginSuccess() {
      // If activating or inactivating a template
      if (this.selectedRequestType === 'Activate' || this.selectedRequestType === 'Deactivate') {
        axios.post(`/api/template/${this.selectedTemplate.template_id}/activate/enterprise`, this.activateOrDeactivatePayLoad, this.$store.state.header)
        .then(res => {
          this.handleActivateOrDeactivateSuccess(res);
        }).catch(err => {
          this.handleActivateOrDeactivateError(this.handleAxiosError(err));
        });
      }
      // If deleting a template
      else if (this.selectedRequestType === 'Delete') {
        axios.post(`/api/template/${this.selectedTemplate.template_id}/delete/enterprise`, null, this.$store.state.header)
        .then(res => {
          this.handleDeleteSuccess(res);
        }).catch(err => {
          this.handleDeleteError(this.handleAxiosError(err));
        });
      }
    },
    /**
     * Handle the Activate / Deactivate success case
     * @param {Object} res
     */
    handleActivateOrDeactivateSuccess(res) {
      console.log('The activate / deactivate template response is', res.data);
      this.selectedTemplate.active = !this.selectedTemplate.active;
    },
    /**
     * Handle the get template list error case
     * @param {Object} err
     */
    handleActivateOrDeactivateError(err) {
      console.log('The activate / deactivate template error is', err);
      this.loadingTemplateList = false;
      this.templateListError = err;
    },
    /**
     * Handle the delete success case
     * @param {Object} res
     */
    handleDeleteSuccess(res) {
      console.log('The delete template response is', res.data);
      this.templates = this.templates.filter(x => x.template_id !== this.selectedTemplate.template_id);
      this.selectedTemplate = this.templates.length > 0 ? this.templates[0] : null;
    },
    /**
     * Handle the delete error case
     * @param {Object} err
     */
    handleDeleteError(err) {
      console.log('The delete template error is', err);
      this.loadingTemplateList = false;
      this.templateListError = err;
    },
    /**
     * Open the edit template modal
     */
    openEditTemplateModal() {
      this.$store.dispatch('getRefreshToken');
      this.displayEditTemplateModal = true;
    },
    /**
     * Open the create template modal
     */
    openCreateTemplateModal() {
      this.$store.dispatch('getRefreshToken');
      this.displayCreateTemplateModal = true;
    },
     /**
     * Update the selected template
     * @param {Object} campaign
     */
     templateUpdated(template) {
      console.log('assigning template', template, this.selectedTemplate)
      // Update the selected template
      Object.assign(this.selectedTemplate, template);
      // Find and update the template in the templates list from the template_id
      let index = this.templates.findIndex(x => x.template_id === template.template_id);
      if (index !== -1) {
        this.templates[index] = template;
      }
    },
    /*
    * Add the created template to the templates list
    */
    templateCreated(template) {
      this.templates.unshift(template);
      Object.assign(this.selectedTemplate, this.templates[0]);
    },
    /**
     * Close the opened modals
     */
    closeOpenModals() {
      this.displayEditTemplateModal = false;
      this.displayCreateTemplateModal = false;
      this.displayUserAuthenticationModal = false;
    },
    /**
     * Emit the close modal event
     */
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * Get the list of templates for the current logged in enterprise user
     * @returns {Promise} - The promise for the template/list/enteprise request
     */
    async getTemplateList() {
      this.$store.dispatch('getRefreshToken');

      if (this.templateListCurrentPage === 1) {
        this.templates = [];
        this.loadingTemplateList = true;
      }
      else {
        this.lazyLoadingTemplateList = true;
      }

      if (!this.messageStatusSelection.includes("Active") && !this.messageStatusSelection.includes("Inactive")) {
        this.loadingTemplateList = false;
        return;
      }
      
      try {
        const res = await axios.post('/api/template/list/enterprise', this.templateListPayLoad, this.$store.state.header);
        this.handleGetTemplateListSuccess(res);
      } catch (err) {
        this.handleGetTemplateListError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the get template list success case
     * @param {Object} res
     */
     handleGetTemplateListSuccess(res) {
      console.log('The get template list response is', res.data);
      this.templates = [...this.templates, ...res.data.templates];
      this.lazyLoadingTemplateList = false;
      this.templateListCurrentPage = res.data.current_page;
      this.templateListPerPage = res.data.per_page;
      this.templateListPageCount = res.data.page_count;

      if (this.templates.length > 0) {
        this.selectedTemplate = this.templates[0];
      }

      this.loadingTemplateList = false;
    },
    /**
     * Handle the get template list error case
     * @param {Object} err
     */
    handleGetTemplateListError(err) {
      console.log('The get template list error is', err);
      this.templateListError = err;
      this.loadingTemplateList = false;
      this.lazyLoadingTemplateList = false;
    },
    /**
     * Search for templates based on the search string
     * returns {Promise} - The promise for the template/enterprise/search request
     */
    searchTemplates(event) {
      this.$store.dispatch('getRefreshToken');
      
      this.templateListCurrentPage = 1;
      if (event.type === 'input' && event.key === 'Enter') {
        this.$store.dispatch('getRefreshToken');
        this.getTemplateList();
      }
      else {
        // Debounce the search
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(() => {
          this.$store.dispatch('getRefreshToken');
          this.getTemplateList();
        }, 400);
      }
    },
    /**
     * Change filters for the template list
     */
     changeTemplateTypeSelection() {
      this.$store.dispatch('getRefreshToken');
      this.templateListCurrentPage = 1; // reset current page number to 1
      this.getTemplateList();
    },
    /**
     * Lazy load the templates list
     * @param {Object} event - The event object from the lazy load event
     */
    lazyLoadTemplates(event) {
      if (event.last >= this.templates.length && this.templateListCurrentPage < this.templateListPageCount) {
        this.templateListCurrentPage += 1;
        this.getTemplateList();
      }
    },
    /**
     * Delete the selected template
     */
    deleteTemplate() {
      this.$store.dispatch('getRefreshToken');
      this.selectedRequestType = 'Delete';
      this.displayUserAuthenticationModal = true;
    }
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: row;
  height: 78vh;
  min-width: 1300px;
}

.sidebar {
  width: 350px;
  background-color: $grey;
  display: flex;
  flex-direction: column;
}

.header-box {
  display: flex;
  flex-direction: column;
  padding: 2em;
  gap: 1em;
}

.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:deep(.p-inputtext) {
  width: 100%;
}

.p-inputtext,
.p-selectbutton,
.p-password-input {
  border-radius: 5px;
  border: 1px solid $deepgrey;
  overflow: hidden;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

.p-selectbutton {
  display: flex;
}

.p-selectbutton :deep(div) {
  flex: 1;
}

:deep(.p-button:focus) {
  box-shadow: none;
}

.p-togglebutton {
  border-radius: 5px;
  border: 1px solid $deepgrey;
  background: white;
  color: $deepgrey;
  overflow: hidden;
}

.p-togglebutton :deep(.p-button-icon) {
  padding: 0;
}

.p-togglebutton.p-button.p-highlight {
  background-color: $deepgrey !important;
  color: white !important;
}

:deep(.p-togglebutton.p-focus) {
  box-shadow: none;
}

:deep(.p-button) .p-button-icon {
  padding: 0;
}

.templates-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  border-top: 1px solid $deepgrey;
}

.template-item {
  height: 4em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $deepgrey;
  padding: 0 1em 0 2em;
  cursor: pointer;
  gap: 10px;
}

.template-item:first {
  border-top: 1px solid $deepgrey;
}

.template-item:hover {
  background-color: $babyblue;
}

.template-item h4 {
  font-weight: 700;
  font-size: 11pt;
  color: $deepgrey;
}

.status-symbol {
  display: flex;
  border-radius: 5px;
  font-size: 10pt;
  font-weight: 600;
  gap: 5px;
  align-items: center;
  padding: 5px 10px 5px 10px;
  width: fit-content;
}

.status-icon {
  width: 1em;
  height: 1em;
  border-radius: 50%;
}

.edit-icon {
  cursor: pointer;
  border-radius: 50%;
  background-color: $strongblue;
  width: 1.5em;
  height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-left: auto;
}

.recommendation-icon {
  display: flex;
  width: 2em;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

.attached-campaigns {
  display: flex;
  gap: 20px;
  align-self: center;
}

.attached-campaigns-header {
  font-weight: 600; 
  color: $deepgrey;
}

.selected-template {
  background-color: white;
}

.flex-row {
  display: flex;
}

.template-content {
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 1;
  text-align: left;
}

.template-content h4 {
  margin: 0;
}

.b-bottom {
  padding-bottom: 1em;
  border-bottom: 1px solid $bordergrey;
}

.radio-group {
  gap: 0.5em;
}

.radio-item {
  font-size: 11pt;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.form-container {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: 20px;
  align-items: stretch;
  padding: 35px;
}

.form-container h3 {
  margin: 0;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.edit-row {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: stretch;
  border-bottom: 1px solid $bordergrey;
  padding-bottom: 10px;
  height: 2.5em;
}

.filters-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
}

.flex-item {
  flex: 1;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.grey-input {
  border: 1px solid #ced4da !important;
  border-radius: 3px;
}

.pharmacies-container {
  display: flex;
  border-radius: 5px;
  background-color: $grey;
  overflow-y: auto;
  flex: 1 1 150px;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 10px 0 10px;
}

.pharmacy-bubble {
  display: flex;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  background-color: $bluegreen;
  color: white;
  font-weight: 500;
  font-size: 11pt;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

</style>
