<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              {{ editMode ? "Edit": "Create" }} Campaign
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <div class="flex-input">
                <label for="campaign_name">Campaign Name</label>
                <InputText
                  id="campaign_name"
                  v-model="createEditCampaignForm.name"
                  placeholder="Campaign Name"
                  class="grey-input"
                />
              </div>
              <div class="form-row">
                <!-- GOAL TYPE -->
                <div class="flex-input flex-item">
                  <label for="goal_type">Goal Type</label>
                  <Dropdown
                    id="goal_type"
                    v-model="createEditCampaignForm.goal_type"
                    :options="goalOptions"
                    :option-label="goal => goal.charAt(0).toUpperCase() + goal.slice(1)"
                    placeholder="Select Goal Type"
                    class="grey-input"
                    @change="selectGoalType(createEditCampaignForm.goal_type)"
                  />
                </div>
                <!-- MEASUREMENT TYPE RADIO BUTTONS -->
                <div class="flex-input flex-item">
                  <label for="measurement_type">Measure By</label>
                  <div
                    id="measurement_type"
                    class="radio-group flex-item"
                  >
                    <div
                      :class="[createEditCampaignForm.measure_by == 'target' ? `selected-radio-strong-blue` : `unselected-radio-strong-blue`, `radio-item`]"
                      @click="selectMeasurementType('target')"
                    >
                      <div class="radio-circle"></div>
                      <span class="radio-label">Target</span>
                    </div>
                    <div
                      :class="[createEditCampaignForm.measure_by == 'percentage' ? `selected-radio-strong-blue` : `unselected-radio-strong-blue`, `radio-item`]"
                      @click="selectMeasurementType('percentage')"
                    >
                      <div class="radio-circle"></div>
                      <span class="radio-label">Percentage</span>
                    </div>
                    <div
                      v-if="createEditCampaignForm.goal_type !== 'adherence' && createEditCampaignForm.goal_type !== 'loyalty'"
                      :class="[createEditCampaignForm.measure_by == 'value' ? `selected-radio-strong-blue` : `unselected-radio-strong-blue`, `radio-item`]"
                      @click="selectMeasurementType('value')"
                    >
                      <div class="radio-circle"></div>
                      <span class="radio-label">Value</span>
                    </div>
                    <div
                      v-else
                      :class="[`disabled-radio`, `radio-item`]"
                    >
                      <div class="radio-circle"></div>
                      <span class="radio-label">Value</span>
                    </div>
                  </div>
                </div>
                <!-- GOAL AMOUNT -->
                <div class="flex-input flex-item">
                  <label for="goal_amount">Set Goal</label>
                  <InputNumber
                    id="goal_amount"
                    v-model="createEditCampaignForm.goal"
                    :placeholder="`Enter Goal Amount`"
                    class="grey-input"
                  />
                </div>
              </div>
              <div
                v-if="editMode===false"
                class="form-row"
              >
                <!-- COMPANIES SELECT -->
                <div class="flex-input flex-item">
                  <label for="companies">Company</label>
                  <Dropdown
                    v-model="selectedCompany"
                    :options="companiesData"
                    option-label="name"
                    input-id="uuid"
                    data-key="uuid"
                    :placeholder="companiesPlaceholder"
                    :loading="loadingCompaniesData"
                    :filter="true"
                    :disabled="editMode"
                    @change="getCompanyData"
                  />
                </div>
              </div>
              <div 
                v-if="currentCampaigns && currentCampaigns.length < 2 || selectedCompany!==null"
                class="form-row"
              >
                <div class="flex-input flex-item">
                  <!-- PHARMACIES MULTISELECT -->
                  <div class="flex-input flex-item">
                    <label for="pharmacies">Pharmacies</label>
                    <MultiSelect
                      v-model="selectedPharmacies"
                      :options="companyPharmacies"
                      option-label="pharmacy_name"
                      input-id="pharmacy_id"
                      data-key="pharmacy_id"
                      :placeholder="pharmaciesPlaceholder"
                      :max-selected-labels="maxSelectedPharmacyLabels"
                      :loading="loadingCompanyPharmacies"
                      :selected-items-label="selectedPharmacyItemsLabel"
                      :filter="true"
                    />
                  </div>
                </div>
                <div class="flex-input flex-item">
                  <!-- TEMPLATES MULTISELECT -->
                  <label for="templates">Template(s)</label>
                  <div
                    id="templates"
                    class="form-row flex-item"
                  >
                    <MultiSelect
                      v-model="selectedTemplates"
                      class="flex-item"
                      :options="filteredTemplates"
                      option-label="template_name"
                      input-id="template_id"
                      data-key="template_id"
                      :placeholder="templatesPlaceholder"
                      :max-selected-labels="maxSelectedTemplateLabels"
                      :loading="loadingTemplates"
                      :virtual-scroller-options="{ lazy:true, onLazyLoad: updateTemplateList, itemSize: 40, loading: lazyLoadingTemplates, showLoader: true, numToleratedItems: 3}"
                      :selected-items-label="selectedTemplatesItemsLabel"
                      :filter="true"
                      @filter="searchTemplateList"
                    />
                    <Button
                      icon="pi pi-plus"
                      aria-label="Add Template"
                      class="p-button-raised p-button-rounded strong-blue-bg"
                      @click="addTemplate"
                    />
                  </div>
                </div>
              </div>
              <div class="form-row">
                <!-- NOTE -->
                <div class="flex-input flex-item">
                  <label for="note">Note (optional)</label>
                  <InputText
                    id="note"
                    v-model="createEditCampaignForm.note"
                    placeholder="Enter a Note"
                    class="grey-input"
                  />
                </div>
              </div>
              <div class="form-row">
                <!-- START DATE -->
                <div class="flex-input flex-item">
                  <label for="start_date">Start Date</label>
                  <Calendar
                    id="start_date"
                    v-model="createEditCampaignForm.start_date"
                    show-icon
                    icon-display="input"
                    date-format="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                    :min-date="currentDate"
                    class="grey-input"
                  />
                </div>
                <!-- END DATE -->
                <div class="flex-input flex-item">
                  <label for="end_date">End Date</label>
                  <Calendar
                    id="end_date"
                    v-model="createEditCampaignForm.end_date"
                    show-icon
                    icon-display="input"
                    date-format="dd/mm/yy"
                    placeholder="dd/mm/yyyy"
                    :min-date="currentDate"
                    class="grey-input"
                  />
                </div>
              </div>
            </div>
          </slot>
        </div>
        <!-- FOOTER -->
        <div
          class="modal-footer"
          style="padding-left: 0;"
        >
          <div
            v-if="createEditCampaignError && createEditCampaignError !==''"
            class="error-text"
          >
            <span
              class="red-error-icon"
            >!</span>
            <span class="error-text-span">{{ createEditCampaignError }}</span>
          </div>
          <div
            v-else-if="companiesDataError && companiesDataError !==''"
            class="error-text" 
          >
            <span
              class="red-error-icon"
            >!</span>
            <span class="error-text-span">{{ companiesDataError }}</span>
          </div>
          <div
            v-else-if="campaignCreateEditSuccess && campaignCreateEditSuccess !==''"
            class="success-message" 
          >
            <span class="error-text-span">{{ campaignCreateEditSuccess }}</span>
          </div>
          <div
            class="black-button"
            style="margin:auto 0;"
            @click="activate"
          >
            {{ actionButtonText }}
          </div>
        </div>
      </div>
      <AdminCreateEditTemplateModal
        v-if="displayAddTemplatesModal"
        :selected-companies="[selectedCompany.uuid]"
        :create-from-campaign="true"
        @close-modal="closeOpenModals"
        @template-created="onNewTemplateCreated"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AdminCreateEditTemplateModal from './admin-create-edit-template-modal.vue';

export default {
  name: 'AdminCreateEditCampaignModal',
  components: {
    AdminCreateEditTemplateModal
  },
  props: {
    /**
     * The selected campaigns
     * @type {Object}
     */
    campaigns: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * The confirmation details
     * @type {Object}
     */
     confirmationDetails: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  emits: [
    'closeModal',
    'campaignCreated',
    'campaignEdited'
  ],
  data() {
    return {
      goalOptions:  [
        'revenue',
        'adherence',
        'loyalty',
        'scripts',
      ],
      currentCampaigns: [],
      createEditCampaignForm: {
        name: '',
        goal_type: '',
        measure_by: '',
        goal: null,
        locations: [],
        templates: [],
        note: '',
        start_date: null,
        end_date: null,
        active: true,
      },
      loadingCreateCampaign: false,
      createEditCampaignError: '',
      campaignCreateEditSuccess: '',
      selectedTemplates: [],
      templates: [],
      loadingTemplates: false,
      loadingTemplatesError: '',
      lazyLoadingTemplates: false, 
      currentTemplatesPage: 1,
      totalTemplatePages: 1,
      templateListPerPage: 1,
      searchTemplates: '',
      currentDate: new Date(),
      editMode: false,
      actionButtonText: 'Create',
      companyPharmacies: [],
      selectedPharmacies: [],
      loadingCompanyPharmacies: false,
      loadingCompanyPharmaciesError: '',
      loadingCompaniesData: false,
      companiesData: [],
      companiesDataError: '',
      selectedCompany: null,
      displayAddTemplatesModal: false,
    }
  },
  computed: {
    /**
     * Create Campaign Payload
     */
    createCampaignPayload() {
      let endDate = this.createEditCampaignForm.end_date;
      let startDate = this.createEditCampaignForm.start_date;

      if (startDate) {
        startDate.setHours(0, 0, 0);
        startDate = new Date(startDate.getTime());
      }

      if (endDate) {
        endDate.setHours(23, 59, 59);
        endDate = new Date(endDate.getTime());
      }

      let payload = {
        name: this.createEditCampaignForm.name,
        goal_type: this.createEditCampaignForm.goal_type,
        measure_by: this.createEditCampaignForm.measure_by,
        goal: this.createEditCampaignForm.goal,
        note: this.createEditCampaignForm.note,
        company_id: this.selectedCompany.uuid,
        locations: this.selectedPharmacies.map(pharmacy => pharmacy.pharmacy_id),
        templates:[],
        start_date: startDate,
        end_date: endDate,
        active: true,
      };

      this.selectedTemplates.forEach(template => {
        payload.templates.push({
            template_id: template.template_id,
            template_recommendation_id: null
          });
      });
      
      return payload;
    },
    /**
     * Edit Campaign Payload
     */
    editCampaignPayload() {
      let endDate = this.createEditCampaignForm.end_date;
      let startDate = this.createEditCampaignForm.start_date;

      if (startDate) {
        startDate.setHours(0, 0, 0);
        startDate = new Date(startDate.getTime());
      }

      if (endDate) {
        endDate.setHours(23, 59, 59);
        endDate = new Date(endDate.getTime());
      }

      let payload = {
        name: this.createEditCampaignForm.name,
        goal_type: this.createEditCampaignForm.goal_type,
        measure_by: this.createEditCampaignForm.measure_by,
        goal: this.createEditCampaignForm.goal,
        note: this.createEditCampaignForm.note,
        start_date: startDate,
        end_date: endDate,
        campaign_ids: this.createEditCampaignForm.campaign_ids,
      };

      if (this.selectedCompany !== null) {
        payload.templates = this.selectedTemplates.map(template => template.template_id);
        payload.locations = this.selectedPharmacies.map(pharmacy => pharmacy.pharmacy_id);
      }
      return payload;
    },

    /**
     * Filter the Templates based on search term
     * @returns {Array} - filtered templates
     */
    filteredTemplates() {
      return this.templates.map(template => {
        return {
          template_name: template.template_name,
          template_id: template.template_id
        }
      });
    },
    /**
     * Return the pharmacy placeholder text
     */
     companiesPlaceholder() {
      return this.loadingCompaniesData ? 'Loading Companies' : 'Select Companies';
    },
    /**
     * Return the pharmacy placeholder text
     */
     pharmaciesPlaceholder() {
      return this.loadingCompanyPharmacies ? 'Loading Pharmacies' : 'Select Pharmacies';
    },
    /**
     * Pharmacy selected items label text
     */
    selectedPharmacyItemsLabel() {
      if (this.loadingCompanyPharmacies) return 'Loading Pharmacies';
      return this.selectedPharmacies && this.selectedPharmacies.length ? `${this.selectedPharmacies.length} Pharmacies Selected` : 'Select Pharmacies';
    },
    /**
     * Return the max number of selected template labels
     */
     maxSelectedPharmacyLabels() {
      if (this.selectedPharmacies.length > 0) {
        // If selected templates are not in the list of templates, return 0
        if (this.selectedPharmacies.every(selectedPharmacy => this.companyPharmacies.some(pharmacy => pharmacy.pharmacy_id === selectedPharmacy.pharmacy_id))) {
          return 2;
        }
        else return 0;
      }
      else return 0;
    },
    /**
     * Return the Templates placeholder text
     */
    templatesPlaceholder() {
      return this.loadingTemplates ? 'Loading Templates' : 'Select Templates';
    },
    /**
     * Pharmacy selected items label text
     */
    selectedTemplatesItemsLabel() {
      if (this.loadingTemplates) return 'Loading Templates';
      return this.selectedTemplates && this.selectedTemplates.length ? `${this.selectedTemplates.length} Templates Selected` : 'Select Templates';
    },
    /**
     * Return the max number of selected template labels
     */
    maxSelectedTemplateLabels() {
      if (this.selectedTemplates.length > 0) {
        // If selected templates are not in the list of templates, return 0
        if (this.selectedTemplates.every(selectedTemplate => this.templates.some(template => template.template_id === selectedTemplate.template_id))) {
          return 2;
        }
        else return 0;
      }
      else return 0;
    },
    /**
     * The get templates payload
     * @returns {Object}
     */
    getTemplatesPayload() {
      let payload = {
        page: this.currentTemplatesPage,
        paginate:true,
      }
      if (this.selectedCompany) {
        payload.company = this.selectedCompany.name;
      }
      if (this.searchTemplates !== '') {
        payload.search_term = this.searchTemplates;
      }
      return payload;
    }
  },
  async mounted() {
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    this.createEditCampaignForm.start_date = new Date(startDate.getTime());

    this.currentCampaigns = this.campaigns;

    if (this.currentCampaigns.length === 0) {
      this.editMode = false;
      this.actionButtonText = 'Create';
      this.getCompanies();
    }
    else {
      this.editMode = true;
      this.actionButtonText = 'Update';

      if (this.currentCampaigns.length === 1) {
        this.createEditCampaignForm.name = this.currentCampaigns[0].name;
        this.createEditCampaignForm.goal_type = this.currentCampaigns[0].goal_type;
        this.createEditCampaignForm.measure_by = this.currentCampaigns[0].measure_by;
        this.createEditCampaignForm.goal = this.currentCampaigns[0].goal;
        this.createEditCampaignForm.note = this.currentCampaigns[0].note;
        this.createEditCampaignForm.start_date = new Date(this.currentCampaigns[0].start_date);
        this.createEditCampaignForm.end_date = this.currentCampaigns[0].end_date ? new Date(this.currentCampaigns[0].end_date) : null;
        this.createEditCampaignForm.active = this.currentCampaigns[0].active;
        
        // Set the selected company and get the company data
        this.companiesData = [this.currentCampaigns[0].company];
        this.selectedCompany = this.companiesData[0];
        await this.getCompanyData();

        // Add the campaign's current templates to the selectedTemplates list
        this.currentCampaigns[0].templates.forEach(template => {
          this.selectedTemplates.push({
            template_name: template.template_name,
            template_id: template.template_id
          });
        });
        // Add the campaign's current pharmacies to the selectedPharmacies list
        this.selectedPharmacies = this.currentCampaigns[0].locations.map(location => ({
          pharmacy_name: location.location_name,
          pharmacy_id: location.location_id
        }));
      }
      else {
        this.createEditCampaignForm.name = this.confirmationDetails && this.confirmationDetails.nameSame ? this.currentCampaigns[0].name : '';
        this.createEditCampaignForm.goal_type = this.confirmationDetails && this.confirmationDetails.goalSame ? this.currentCampaigns[0].goal_type : null;
        this.createEditCampaignForm.measure_by = this.confirmationDetails && this.confirmationDetails.goalSame ? this.currentCampaigns[0].measure_by : '';
        this.createEditCampaignForm.goal = this.confirmationDetails && this.confirmationDetails.goalSame ? this.currentCampaigns[0].goal : null;
        this.createEditCampaignForm.note = this.confirmationDetails && this.confirmationDetails.noteSame ? this.currentCampaigns[0].note : '';
        this.selectedCompany = this.confirmationDetails && this.confirmationDetails.companySame ? this.currentCampaigns[0].company : null;

        this.createEditCampaignForm.start_date = new Date(startDate.getTime());
        this.createEditCampaignForm.end_date = null;

        if (this.selectedCompany !== null) {
          await this.getCompanyData();
        }
        console.log(this.selectedCompany);
      }

      this.createEditCampaignForm.campaign_ids = this.currentCampaigns.map(campaign => campaign.campaign_id);
      console.log(this.createEditCampaignForm);
    }
  },
  methods: {
    /**
     * Select the measurement type
     * @param {String} type
     */
    selectMeasurementType(type) {
      this.$store.dispatch('getRefreshToken');
      type = type.toLowerCase();
      this.createEditCampaignForm.measure_by = type;
    },
    /**
     * Select the goal type
     * @param {String} type
     */
    selectGoalType(type) {
      this.$store.dispatch('getRefreshToken');
      if ((type === 'adherence' || type === 'loyalty') && this.createEditCampaignForm.measure_by === 'value') {
        this.createEditCampaignForm.measure_by = '';
      } 
    },
    /**
     * Get the companies
     * @returns {Promise} - The promise for the companies
     */
     async getCompanies() {
      this.$store.dispatch('getRefreshToken');
      this.companiesData = [];
      this.companiesDataError = '';
      this.loadingCompaniesData = true;

      try {
        const res = await axios.post('/api/company/list', null, this.$store.state.header);
        this.handleCompaniesSuccess(res);
      } catch (err) {
        this.handleCompaniesError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the companies
     * @param {Object} res - The response object
     */
     handleCompaniesSuccess(res) {
      console.log('The companies response is: ', res.data);
      this.companiesData = res.data.data;
      this.loadingCompaniesData = false;
    },
    /**
     * Handle the error response for the companies
     * @param {Object} err - The error object
     */
    handleCompaniesError(err) {
      console.log('The companies error is: ', err);
      this.loadingCompaniesData = false;
      this.companiesDataError = err;
    },
    /**
     * Get the list of pharmacies and templates for a campaign
     */
    async getCompanyData() {
      this.$store.dispatch('getRefreshToken');
      this.getCompanyPharmacies();
      this.getTemplates();
    },
    /**
     * Get the list of pharmacies for the campaign
     */
     async getCompanyPharmacies() {
      this.loadingCompanyPharmacies = true;
      this.$store.dispatch('getRefreshToken');
      this.loadingCompanyPharmaciesError = '';
      this.companyPharmacies = [];
      
      try {
        const res = await axios.post(`/api/company/${this.selectedCompany.uuid}/locations`, {}, this.$store.state.header);
        this.handleGetCompanyPharmaciesSuccess(res);
      } catch (err) {
        this.handleGetCompanyPharmaciesError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response from the get pharmacies endpoint
     * @param {Object} res
     */
    handleGetCompanyPharmaciesSuccess(res) {
      console.log('The get pharmacies response is: ', res);
      let pharmacies = res.data.data;

      pharmacies.forEach(pharmacy => {
        this.companyPharmacies.push({
          pharmacy_name: pharmacy.name,
          pharmacy_id: pharmacy.uuid,
        });
      });

      this.loadingCompanyPharmacies = false;
    },
    /**
     * Handle the error response from the get pharmacies endpoint
     * @param {Object} err
     */
    handleGetCompanyPharmaciesError(err) {
      console.log('The get pharmacies error is: ', err);
      this.loadingCompanyPharmacies = false;
      this.loadingCompanyPharmaciesError = err;
    },
    /**
     * Get the list of templates for the campaign
     */
    async getTemplates() {
      this.loadingTemplates = true;
      this.$store.dispatch('getRefreshToken');
      this.loadingTemplatesError = '';

      if (this.currentTemplatesPage === 1) {
        this.templates = [];
      }

      try {
        const res = await axios.post('/api/template/admin/search', this.getTemplatesPayload, this.$store.state.header);
        this.handleGetTemplatesSuccess(res);
      } catch (err) {
        this.handleGetTemplatesError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response from the get templates endpoint
     * @param {Object} res
     */
    handleGetTemplatesSuccess(res) {
      console.log('The get templates response is: ', res);
      let templates = res.data.results;
      this.currentTemplatesPage = res.data.current_page;
      this.totalTemplatePages = res.data.page_count;
      this.templateListPerPage = res.data.per_page;

      // Loop through each of the returned templates and add to the templates list
      templates.forEach(template => {
        this.templates.push({
          template_name: template.name,
          template_id: template.uuid,
        });
      });  
      
      this.loadingTemplates = false;
    },
    /**
     * Handle the error response from the get templates endpoint
     * @param {Object} err
     */
    handleGetTemplatesError(err) {
      console.log('The get templates error is: ', err);
      this.loadingTemplates = false;
      this.loadingTemplatesError = err;
    },
     /**
     * When a new template is created
     * @param {Object} template
     */
     onNewTemplateCreated(template) {
      this.$store.dispatch('getRefreshToken');
      // Add the newly created template to the list of templates
      this.templates.push({
        template_name: template.name,
        template_id: template.template_id,
      });
      // Add the newly created template to the selected templates
      this.selectedTemplates.push({
        template_name: template.name,
        template_id: template.template_id,
      });
    },
    /**
     * Activate the campaign
    */
    activate() {
      this.$store.dispatch('getRefreshToken');
      this.createEditCampaignError = '';
      let errors = [];
      if (this.createEditCampaignForm.name === '') {
        errors.push('enter a campaign name');
      }
      if (this.createEditCampaignForm.goal_type === '') {
        errors.push('select a goal type');
      }
      if (this.createEditCampaignForm.measure_by === '') {
        errors.push('select a measurement type');
      }
      if (this.createEditCampaignForm.goal === null) {
        errors.push('enter a goal amount');
      }
      if (this.createEditCampaignForm.goal <= 0) {
        errors.push('enter a goal amount greater than 0');
      }
      if (this.selectedCompany!== null && this.selectedTemplates && this.selectedTemplates.length === 0) {
        errors.push('select atleast one template');
      }
      if (this.selectedCompany!== null && this.selectedPharmacies && this.selectedPharmacies.length === 0) {
        errors.push('select atleast one template');
      }
      if (errors.length > 0) {
        // If there are multiple errors, add 'and' before the last error
        if (errors.length > 1) errors[errors.length - 1] = `and ${errors[errors.length - 1]}`;
        // Join the errors till the second last with a comma and the last with an 'and'
        this.createEditCampaignError = `Please ${errors.join(', ')}.`;
        return;
      }
      this.loadingCreateCampaign = true;
      this.editMode ? this.updateCampaign() : this.createCampaign();
    },
    /**
     * Update the template list
     */
    async updateTemplateList(event) {
      !this.lazyLoadingTemplates && (this.lazyLoadingTemplates = true);

      //call paginated endpoint
      if (event.last >= (this.templates.length - 3) && this.currentTemplatesPage < this.totalTemplatePages) {
        this.currentTemplatesPage++;
        await this.getTemplates();
      }
      this.lazyLoadingTemplates = false;
    },
    /**
     * Search the template list
     */
    async searchTemplateList(event) {
      !this.lazyLoadingTemplates && (this.lazyLoadingTemplates = true);
      this.searchTemplates = event.value;
      this.currentTemplatesPage = 1;
      this.totalTemplatePages = 1;
      this.templateListPerPage = 1;
      await this.getTemplates();
      this.lazyLoadingTemplates = false;
    },
    /**
     * Create the campaign
     */
    async createCampaign() {
      this.$store.dispatch('getRefreshToken');
      this.loadingCreateCampaign = true;
      this.actionButtonText = 'Creating...';

      this.createEditCampaignForm.templates = this.selectedTemplates.map(template => ({
        template_id: template.template_id,
        template_recommendation_id: null
      }));

      // Call the Create Campaign Endpoint

      try {
        const res = await axios.post('/api/campaign/admin/create', this.createCampaignPayload, this.$store.state.header);
        this.handleCampaignCreateSuccess(res);
      } catch (err) {
        this.handleCampaignCreateError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle Campaign Creation Success
     * @param {Object} res
     */
    handleCampaignCreateSuccess(res) {
      console.log('The campaign creation response is: ', res);
      this.loadingCreateCampaign = false;
      this.actionButtonText = 'Create';
      this.clear();
      this.campaignCreateEditSuccess = 'Campaign created successfully';
      this.$emit('campaignCreated', res); // Emit the created campaign when successful
      // this.$emit('closeModal');
    },
    /**
     * Handle Campaign Creation Error
     * @param {Object} err
     */
    handleCampaignCreateError(err) {
      console.log('The campaign creation error is: ', err);
      this.loadingCreateCampaign = false;
      this.actionButtonText = 'Create';
      this.createEditCampaignError  = err;
    },
    /**
     * Update the campaign
     */
    async updateCampaign() {
      this.$store.dispatch('getRefreshToken');
      this.loadingEditCampaign = true;
      this.actionButtonText = 'Updating...';

      this.createEditCampaignForm.templates = this.selectedTemplates.map(template => ({
        template_id: template.template_id,
        template_recommendation_id: null
      }));

      // Call the Update Campaign Endpoint

      try {
        //const res = await axios.post(`/api/campaign-recommendation/admin/${this.campaign.campaign_id}/edit`, this.editCampaignPayload, this.$store.state.header);
        const res = await axios.post(`/api/campaign/admin/edit`, this.editCampaignPayload, this.$store.state.header);
        this.handleCampaignUpdateSuccess(res);
      } catch (err) {
        this.handleCampaignUpdateError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle Campaign Update Success
     * @param {Object} res
     */
    handleCampaignUpdateSuccess(res) {
      console.log('The campaign update response is: ', res);
      this.loadingCreateCampaign = false;
      this.actionButtonText = 'Update';
      this.campaignCreateEditSuccess = 'Campaign updated successfully';
      this.$emit('campaignEdited', res); // Emit the updated campaign when successful
      // this.$emit('closeModal');
    },
    /**
     * Handle Campaign Update Error
     * @param {Object} err
     */
    handleCampaignUpdateError(err) {
      console.log('The campaign update error is: ', err);
      this.loadingCreateCampaign = false;
      this.createEditCampaignError  = err;
    },
    /**
     * Clear the form
     */
    clear() {
      this.createEditCampaignForm = {
        name: '',
        goal_type: '',
        measure_by: '',
        goal: null,
        locations: [],
        templates: [],
        note: '',
        start_date: null,
        end_date: null,
        active: true,
      };
      this.selectedCompany = null;
      this.selectedPharmacies = [];
      this.companyPharmacies = [];
      this.selectedTemplates = [];
      this.templates = [];
      this.loadingTemplates = false;
      this.loadingTemplatesError = '';
      this.lazyLoadingTemplates = false;
      this.currentTemplatesPage = 1;
      this.totalTemplatePages = 1;
      this.templateListPerPage = 1;
      this.searchTemplates = '';
      this.loadingCreateCampaign = false;
    },
    /**
     * Add a template
     */
     addTemplate() {
      this.$store.dispatch('getRefreshToken');
      if (this.selectedCompany && this.selectedCompany.uuid) {
        this.displayAddTemplatesModal = true;
        this.companiesDataError = '';
      }
      else {
        this.companiesDataError = 'Please select a company first';
      }
      
    },
    /**
     * Close the modal
     */
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * Close any open modals
     */
    closeOpenModals() {
      this.loadingCreateCampaign = false;
      this.displayAddTemplatesModal = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  height: 78vh;
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.radio-group {
  gap: 0.5em;
}

.modal-body {
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 2.5em;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.modal-header {
  padding: 40px 40px 0 40px !important;
}

.form-header {
  color: black;
}

:deep(.p-accordion-header-link) {
  height: 4em;
  background: white;
  border: none;
  border-top: 1px solid $bordergrey;
}

:deep(.p-accordion-content) {
  border: none;
}

:deep(.p-accordion-content) p {
  margin: 0;
  font-size: 11pt;
  font-weight: 500;
  color: $deepgrey;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

:deep(.p-calendar-w-btn .p-datepicker-trigger) {
  background: $strongblue;
}

.error-text {
  color: $error;
  margin: 0 auto 0 0;
  padding-left: 40px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
  text-align: left;
}

.red-error-icon {
  min-width: 20px;
  background-color: $error;
  border-color: $error;
  color: white;
  border-radius: 50% !important;
  height: 20px;
  width: 20px;
  margin: auto;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.error-text-span {
  margin: auto;
  padding-left: 10px;
}

.success-message {
  color: $complete;
  margin: 0 auto 0 0;
  padding-left: 40px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
  text-align: left;
}

</style>  