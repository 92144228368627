<template>
  <div>
    <div class="header">
      <div class="container flex-row">
        <div>
          <a href="https://strongroom.ai/">
            <img
              class="header-logo"
              :src="lockupLogoSrc"
              alt="StrongRoom AI Logo"
            >
          </a>
        </div>
        <div class="black-button">
          <router-link
            to="/login"
            target="_blank"
          >
            Login
          </router-link>
        </div>
      </div>
    </div>
    <div class="page-container">
      <div class="page-content">
        <div class="page-title">
          <h1>404</h1>
        </div>
        <div class="page-body">
          <div class="page-body-content">
            <p>Page not found</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  data() {
    return {
      lockupLogoSrc: require('@/assets/logos/Lockup_Colour_Black.svg'),
    };
  },
};
</script>

<!-- CSS for 404 Not Found page -->
<style lang="scss" scoped>
@import "../assets/css/main.scss";

.view {
  justify-content: center;
}

.header {
  border-bottom: 1px solid grey;
  position: relative;
  width: 100%;
}

.container {
  width: 100%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}

.header > .container {
  padding-bottom: 1.25rem;
  padding-top: 1.25rem;
  height: 5rem;
}

.flex-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.header-logo {
  width: 12rem;
  height: 100%;
}

.black-button a {
  text-decoration: none;
  color: inherit;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}
.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.page-title {
  font-size: 50px;
  font-weight: 600;
}

.page-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.page-body-content {
  font-size: 20px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (min-width: 1320px) {
  .container {
    width: 1240px;
  }
}
</style>