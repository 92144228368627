<template>
  <div class="view">
    <div
      class="panel full-height flex-column"
    >
      <div class="page-header">
        <div class="flex-item flex-row">
          <h1>
            Referrals Dashboard
          </h1>
        </div>
        <NavFunctions class="nav-functions" />
      </div>
      <KeepAlive exclude="ClinicalTrialsReferralTable">
        <component
          :is="currentComponent"
        />
      </KeepAlive>
    </div>
  </div>
</template>

<script>
import NavFunctions from '@/components/navigation/nav-menu-dropdown.vue';
import ClinicalTrialsReferralTable from '@/components/referraldashboard/clinical-trials-referral-table.vue';
import { ref } from 'vue';

export default {
  name: 'ClinicalTrialsReferralDashboard',
  components: {
    NavFunctions,
    ClinicalTrialsReferralTable
  },
  setup() {
    return {
    }
  },
  data() {
    return {
      currentComponent: ref('ClinicalTrialsReferralTable'),
    }
  },
  computed: {
  },
  async mounted() {
  },
  methods: {
    /**
     * Load the ClinicalTrialsReferralTable
     */
    loadReferralTable() {
      this.currentComponent = ref('ClinicalTrialsReferralTable');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/main.scss";

.full-height {
  height: 100%;
}

.header-hover {
  cursor: pointer;
  border-radius: 5px;
}

.header-hover:hover {
  color: $strongblue;
  background-color: $babyblue;
  border: 1px solid $strongblue;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 60px;
  height: 60px;
  margin: 10px 0 10px 0;

  h1 {
    margin: 0;
    padding: 10px;
    border: 1px solid transparent;
  }
}

.nav-functions {
  align-self: flex-start;
  z-index: 3;
}

:deep(.p-inputtext) {
  width: 100%;
}

.p-inputtext,
.p-selectbutton,
.p-password-input {
  border-radius: 5px;
  border: 1px solid $deepgrey;
  overflow: hidden;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

.flex-item {
  flex: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  gap: 1em;
}
</style>