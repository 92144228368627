<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- SIDEBAR -->
        <div class="sidebar">
          <div class="header-box">
            <div class="header-title flex-item">
              <img
                :src="recommendationIconSrc"
                class="medium-icon"
                alt="Recommendation Icon"
              >
              <h3>
                Recommendations
              </h3>
            </div>
            <!-- SEARCH BOX -->
            <span class="p-input-icon-right flex-item">
              <i class="pi pi-search"></i>
              <InputText
                v-model="searchString"
                placeholder="Search"
                class="grey-input"
                @input="searchRecommendations"
                @keydown="searchRecommendations"
              />
            </span>
            <!-- CAMPAIGN/TEMPLATE SELECT BUTTON -->
            <SelectButton
              v-model="typeSelection"
              :options="typeOptions"
              option-label="key"
              option-value="value"
              @change="changeRecommendationTypeSelection"
            />
          </div>
          <!-- RECOMMENDATION LIST -->
          <div
            v-if="!loadingRecommendedList && recommendations.length > 0" 
            class="recommendations-list"
          >
            <!-- RECOMMENDATIONS LIST LAZY LOADING -->
            <VirtualScroller
              v-model:items="recommendations"
              :item-size="50"
              style="flex:1;"
              :loading="loadingRecommendedList"
              lazy
              @lazy-load="lazyLoadRecommendations"
            >
              <template #item="{ item }">
                <div
                  class="recommendation-item"
                  :class="currentRecommendation(item) ? 'selected-recommendation' : ''"
                  @click="changeSelectedRecommendation(item)"
                >
                  <h4
                    class="flex-item"
                    style="text-align: left;"
                  >
                    {{ item.name }}
                  </h4>
                  <Tag
                    v-if="item.new"
                    severity="success"
                    class="tag-success"
                    :value="'NEW'"
                  />
                  <i
                    class="pi pi-chevron-right"
                  ></i>
                </div>
              </template>
            </VirtualScroller>
          </div>
          <!-- NO RECOMMENDATIONS MESSAGE -->
          <div
            v-else-if="!loadingRecommendedList && recommendations.length === 0"
            style="text-align: center; padding: 10px;"
          >
            No Recommended {{ typeSelection === 'campaign'? 'Campaigns': 'Templates' }} Found
          </div>
          <!-- RECOMMENDATIONS LOADING SPINNER -->
          <ProgressSpinner
            v-else
            style="min-height: 200px; margin-left:auto !important; margin-right:auto !important;"
          />
        </div>
        <!-- MAIN CONTENT -->
        <div class="form-container">
          <!-- HEADER ROW -->
          <div class="flex-row">
            <div
              class="flex-item"
              style="text-align: left;"
            >
              <h3 v-if="selectedRecommendationLocal">
                {{ selectedRecommendationLocal.name }}
              </h3>
            </div>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </div>
          <!-- CONSIDER UPDATING THIS CONTENT TO BE A COMPONENT -->
          <!-- TEMPLATE -->
          <div
            v-if="selectedRecommendationLocal && selectedRecommendationLocal.type == 'template'"
            class="recommendation-content"
          >
            <p>
              {{ selectedRecommendationLocal.description }}
            </p>
            <InputText
              v-model="selectedRecommendationLocal.name"
              placeholder="Template Title"
              class="grey-input"
            />
            <div class="form-row">
              <InputText
                v-if="selectedRecommendationLocal.email_template"
                v-model="selectedRecommendationLocal.subject"
                placeholder="Subject Line"
                class="grey-input flex-item"
              />
              <ToggleButton
                v-model="selectedRecommendationLocal.email_template"
                style="margin-left: auto;"
                on-label="Email"
                off-label="Email"
                on-icon="pi pi-check"
                off-icon="pi pi-times"
              />
              <ToggleButton
                v-model="selectedRecommendationLocal.sms_template"
                on-label="SMS"
                off-label="SMS"
                on-icon="pi pi-check"
                off-icon="pi pi-times"
              />
            </div>
            <TemplateEditor 
              v-if="recommendationBody"
              :content="selectedRecommendationLocal.body"
              :communication="communicationType"
              :source="'recommended'"
              class="flex-item" 
              @content-updated="setContent"
            />
          </div>

          <!-- CAMPAIGN -->
          <div
            v-else-if="selectedRecommendationLocal && selectedRecommendationLocal.type == 'campaign'"
            class="recommendation-content"
          >
            <CampaignEditForm
              v-if="selectedRecommendationLocal"
              :campaign="selectedRecommendationLocal"
              :campaign-info="selectedRecommendationLocal"
              :company-pharmacies="companyPharmaciesLocal"
              :modal-source="'recommended'"
              @campaign-updated="setRecommendationLocal"
            />
          </div>
          <!-- FOOTER -->
          <div 
            class="modal-footer"
            style="padding-left: 5px"
          >
            <!-- ERROR MESSAGE -->
            <div
              v-if="createCampaignTemplateError && createCampaignTemplateError !==''"
              class="error-text"
            >
              <span
                class="red-error-icon"
              >!</span>
              <span class="error-text-span">{{ createCampaignTemplateError }}</span>
            </div>
            <!-- ACTIVATE/DEACTIVATE BUTTON -->
            <div
              v-if="selectedRecommendationLocal.campaign_recommendation_id || selectedRecommendationLocal.template_recommendation_id"
              class="black-button"
              style="margin:auto 0;"
              @click="activate"
            >
              {{ loadingCreateCampaignTemplate ? 'Activating...' : 'Activate' }}
            </div>
          </div>
          <UserAuthenticationModal
            v-if="displayUserAuthenticationModal" 
            :name="selectedRecommendationLocal.name"
            :payload-type="selectedRecommendationLocal.type"
            :request-type="'Activate'"
            @login-success="createCampaignTemplate"
            @close-modal="closeOpenModals"
          />
          <CreateTemplateModal
            v-if="displayAddTemplatesModal"
            @template-created="onNewTemplateCreated"
            @close-modal="closeOpenModals"
          />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
// import axios from 'axios';
import TemplateEditor from '@/components/templates/template-editor.vue';
import UserAuthenticationModal from '@/components/modals/campaign-template-authentication-modal.vue';
import CampaignEditForm from '@/components/campaign/form/campaign-edit-form.vue';
import CreateTemplateModal from '@/components/modals/create-template-modal.vue';
import axios from 'axios';
import VirtualScroller from 'primevue/virtualscroller';

export default {
  components: {
    TemplateEditor,
    UserAuthenticationModal,
    CreateTemplateModal,
    CampaignEditForm,
    VirtualScroller
  },
  props: {
    /**
     * The preselected recommendation
     * @type {Object}
     */
    selectedRecommendation: {
      type: Object,
      default() {
        return {}
      }
    },
    /**
     * The available pharmacies
     * @type {Array}
     */
    companyPharmacies: {
      type: Array,
      default() {
        return []
      }
    },
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'closeModal',
    'recommendedCampaignCreated',
    'recommendedTemplateCreated'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
      recommendationIconSrc: require('@/assets/icons/recommendation_black_icon.svg'),
      recommendations: [],
      loadingRecommendedList: false,
      recommendationListCurrentPage: 1,
      recommendationListPageCount: 1,
      selectedRecommendationLocal: {},
      selectedPharmacies: [],
      companyPharmaciesLocal: [],
      selectedTemplates: [],
      templates: [],
      loadingTemplates: false,
      loadingTemplatesError: '',
      lazyLoadingTemplates: false, 
      currentTemplatesPage: 1,
      totalTemplatePages: 1,
      templateListPerPage: 1,
      searchTemplates: '',
      templateDelayTimer: null,
      typeSelection: 'campaign',
      typeOptions: [
        { key: 'Campaign', value: 'campaign' },
        { key: 'Template', value: 'template' }
      ],
      goalOptions:  [
        'revenue',
        'adherence',
        'loyalty',
        'scripts',
      ],
      measurementType: null,
      searchString: '',
      displayAddTemplatesModal: false,
      displayUserAuthenticationModal: false,
      loadingCreateCampaignTemplate: false,
      createCampaignTemplateError: '',
      recommendationStartDate: null,
      recommendationEndDate: null,
      recommendationBody: null,
      currentDate: new Date(),
      delayTimer: null,
    }
  },
  computed: {
    /**
     * Computed property to watch selectedRecommendationLocal start date
     */
    startDate() {
      return this.selectedRecommendationLocal.start_date;
    },
    /**
     * Computed property to watch selectedRecommendationLocal end date
     */
    endDate() {
      return this.selectedRecommendationLocal.end_date;
    },
    /**
     * The current and recommended templates
     * Combines the current and recommended templates, either updating or adding recommended templates to the list
     * @returns {Array}
     */
    currentAndRecommendedTemplates() {
      let templates = [...this.templates];

      // Loop through each of the recommended templates
      this.selectedRecommendationLocal.template_recommendations.forEach(recommendation => {
        // Find the template recommendation in the list of templates
        let matchedTemplate = templates.find(template => template.template_recommendation_id == recommendation.template_id);
        // If the recommended template is in the list of templates, set the template_recommendation_id
        if (matchedTemplate) {
          matchedTemplate.template_recommendation_id = recommendation.template_id;
        }
        // If the recommended template is not in the list of templates, add it to the list of templates
        else {
          templates.unshift({
            template_name: recommendation.template_name,
            template_id: null,
            template_recommendation_id: recommendation.template_id
          });
        }
      })

      return templates;
    },
    /**
     * The payload for the recommendations
     * @returns {Object}
     */
    recommendationsPayLoad() {
      let payload = {
        page: this.recommendationListCurrentPage,
      }
      if (this.searchString !== '') {
        payload.search_term = this.searchString;
      }
      return payload;
    },
    /**
     * Return the Templates placeholder text for dropdown
     * @returns {String}
     */
    templatesPlaceholder() {
      return this.loadingTemplates ? 'Loading Templates' : 'Select Templates';
    },
    /**
     * Templates selected items label text for dropdown
     * @returns {String}
     */
    selectedTemplatesItemsLabel() {
      return this.selectedTemplates && this.selectedTemplates.length ? `${this.selectedTemplates.length} Templates Selected` : 'Select Templates';
    },
    /**
     * Return the max number of selected template labels for dropdown
     * @returns {Number}
     */
    maxSelectedTemplateLabels() {
      if (this.selectedTemplates.length > 0) {
        // If selected templates are not in the list of templates, return 0
        if (this.selectedTemplates.every(selectedTemplate => this.templates.some(template => template.template_id === selectedTemplate.template_id))) {
          return 2;
        }
        else return 0;
      }
      else return 0;
    },
    /**
     * The get templates payload
     * @returns {Object}
     */
    getTemplatesPayload() {
      let payload = {
        page: this.currentTemplatesPage,
        sms_template: true,
        email_template: true
      }
      if (this.searchTemplates !== '') {
        payload.search_term = this.searchTemplates;
      }
      return payload;
    },
    /**
     * The create campaign payload
     * @returns {Object}
     */
    createCampaignPayload() {
      let endDate = this.selectedRecommendationLocal.end_date ? new Date(this.selectedRecommendationLocal.end_date) : null;
      if (endDate) {
        endDate.setHours(23, 59, 59);
        endDate = new Date(endDate.getTime());
      }

      let startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      startDate = new Date(startDate.getTime())
      
      if (this.selectedRecommendationLocal.type === 'campaign') {
        let payload = {
          name: this.selectedRecommendationLocal.name,
          goal_type: this.selectedRecommendationLocal.goal_type,
          measure_by: this.selectedRecommendationLocal.measure_by,
          goal: this.selectedRecommendationLocal.goal,
          locations: this.selectedRecommendationLocal.pharmacies,
          templates: this.selectedRecommendationLocal.templates.map(template => ({
            template_id: null,
            template_recommendation_id: template
          })),
          filters: this.selectedRecommendationLocal.patient_cohort,
          note: this.selectedRecommendationLocal.description,
          start_date: startDate,
          end_date: endDate,
          active: true,
          campaign_recommendation_id: this.selectedRecommendationLocal.campaign_recommendation_id,
        }
        return payload
      }
      else if (this.selectedRecommendationLocal.type === 'template') {
        let payload = {
          name: this.selectedRecommendationLocal.name,
          body: this.recommendationBody,
          subject: this.selectedRecommendationLocal.subject,
          sms_template: this.selectedRecommendationLocal.sms_template,
          email_template: this.selectedRecommendationLocal.email_template,
          active: true,
          template_recommendation_id: this.selectedRecommendationLocal.template_recommendation_id,          
        }
        return payload;
      }
      return {}
    },
    communicationType() {
      if (this.selectedRecommendationLocal.sms_template && this.selectedRecommendationLocal.email_template) {
        return 'both';
      } else if (this.selectedRecommendationLocal.sms_template) {
        return 'sms';
      } else if (this.selectedRecommendationLocal.email_template) {
        return 'email';
      } else {
        return 'both';
      }
    }
  },
  watch: {
    /**
     * Watch for changes in the start date, and set the recommendation start date
     */
    startDate() {
      this.recommendationStartDate = new Date(this.startDate);
    },
    /**
     * Watch for changes in the end date, and set the recommendation end date
     */
    endDate() {
      this.recommendationEndDate = this.endDate ? new Date(this.endDate) : null;
    },
  },
  async mounted() {
    /**
     * Set the local variables to the prop
     */
    this.getTemplates();
    this.setRecommendationLocal(this.selectedRecommendation);
    
    // Set the type selection to the selected recommendation type
    this.typeSelection = this.selectedRecommendationLocal.type ? this.selectedRecommendationLocal.type : 'campaign';
    // Get the recommendations list
    await this.getRecommendationsList();
    
    // If there is no selected recommendation, and there are available recommendations, select the first one
    if (Object.keys(this.selectedRecommendationLocal).length === 0 && this.recommendations.length > 0) {
      this.setRecommendationLocal(this.recommendations[0]);
    }

    // Set the local pharmacies to the company pharmacies
    this.companyPharmaciesLocal = this.companyPharmacies;
  },
  methods: {
    /**
     * Set the selectedRecommendationLocal
     * @param {Object} recommendation
     */
    setRecommendationLocal(recommendation) {
      this.selectedRecommendationLocal = {};
      if (recommendation) {
        Object.assign(this.selectedRecommendationLocal, recommendation);
        if (this.typeSelection === 'campaign' && recommendation.template_recommendations) {
          this.selectedRecommendationLocal.templates = recommendation.template_recommendations.map(template => template.template_id);
        } 
        else if (this.typeSelection === 'template') {
          this.recommendationBody = this.selectedRecommendationLocal.body;
        }
      } else {
        this.recommendationBody = null;
      }
    },
    /**
     * Select the measurement type
     * @param {String} type
     */
    selectMeasurementType(type) {
      this.$store.dispatch('getRefreshToken');
      type = type.toLowerCase();
      this.selectedRecommendationLocal.measure_by = type;
    },
    /**
     * Select the goal type
     * @param {String} type
     */
    selectGoalType(type) {
      this.$store.dispatch('getRefreshToken');
      if ((type === 'adherence' || type === 'loyalty') && this.selectedRecommendationLocal.measure_by === 'value') {
        this.selectedRecommendationLocal.measure_by = '';
      } 
    },
    /**
     * Set the current body
     */
    setContent(content) {
      this.recommendationBody = content;
    },
    /**
     * Search the recommendations
     */
     searchRecommendations(event) {
      this.recommendationListCurrentPage = 1; // reset current page number to 1

      // If the enter key is pressed, then search for campaigns without delay
      if (event.type==='keydown' && event.key === 'Enter') {
        clearTimeout(this.delayTimer); // clear the delay timer when the serach is triggered by enter key
        this.$store.dispatch('getRefreshToken');
        this.getRecommendationsList();
      }
      else {
        // If the input change event is triggered, then search for campaigns with delay
        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(() => {
          this.$store.dispatch('getRefreshToken');
          this.getRecommendationsList();
        }, 400);
      }
    },
    /**
     * Change the type selection for the recommendation list
     */
     changeRecommendationTypeSelection() {
      this.$store.dispatch('getRefreshToken');
      this.recommendationListCurrentPage = 1; // reset current page number to 1
      this.getRecommendationsList();
    },
    /**
     * Get the recommended campaigns
     */
    async getRecommendationsList() {
      this.loadingRecommendedList = true;
      this.$store.dispatch('getRefreshToken');

      // If the current page is 1, clear the recommendations
      if (this.recommendationListCurrentPage === 1) {
        this.recommendations = [];
      }
      
      let apiEndpoint = this.typeSelection == 'campaign' ? '/api/campaign-recommendation/company/list' : '/api/template-recommendation/company/list';

      try {
        const res = await axios.post(apiEndpoint, this.recommendationsPayLoad, this.$store.state.header);
        this.handleGetRecommendationsListSuccess(res);
      } catch (err) {
        this.handleGetRecommendationsListError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success of getting the recommended campaigns
     * @param {Object} res
     */
    handleGetRecommendationsListSuccess(res) {
      console.log('The get recommendations response is: ', res.data);
      this.recommendationListPageCount = res.data.page_count;
      if (this.typeSelection === 'campaign') {
        let campaigns = res.data.campaigns;
        // add the new key to the recommendations
        campaigns.forEach((x) => {
          x.type = "campaign";
          x.id = x.campaign_id;
        });
        // Push campaigns to recommendations
        this.recommendations = [...this.recommendations, ...campaigns];
      } 
      else if (this.typeSelection === 'template') {
        let templates = res.data.templates;
        // add the new key to the recommendations
        templates.forEach((x) => {
          x.type = "template";
          x.id = x.template_recommendation_id;
        });
        // Push templates to recommendations
        this.recommendations = [...this.recommendations, ...templates];
      } 
      if ((this.selectedRecommendationLocal.id === undefined || this.typeSelection !== this.selectedRecommendationLocal.type) && this.recommendations.length > 0) {
        this.changeSelectedRecommendation(this.recommendations[0]);
      } else if (this.recommendations.length === 0) {
        this.setRecommendationLocal(null);
      }
      this.loadingRecommendedList = false;
    },
    /**
     * Handle the error of getting the recommended campaigns
     * @param {Object} err
     */
     handleGetRecommendationsListError(err) {
      console.log('The get recommendations error is: ', err);
      this.loadingRecommendedList = false;
    },
    /**
    * Lazy load the recommendations
    * @param {Object} event - The event object
    */
    async lazyLoadRecommendations(event) {
      // If the last item is reached and there are more pages, get the next page
      if (event.last >= this.recommendations.length && this.recommendationListCurrentPage < this.recommendationListPageCount) {
        this.recommendationListCurrentPage += 1;
        this.getRecommendationsList();
      }
    },
    /**
     * Get the list of templates for the campaign
     */
     async getTemplates() {
      this.loadingTemplates = true;
      this.$store.dispatch('getRefreshToken');
      this.loadingTemplatesError = '';

      if (this.currentTemplatesPage === 1) {
        this.templates = [];
      }
      
      try {
        const res = await axios.post('/api/template/list/enterprise', this.getTemplatesPayload, this.$store.state.header);
        this.handleGetTemplatesSuccess(res);
      } catch (err) {
        this.handleGetTemplatesError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response from the get templates endpoint
     * @param res
     */
    handleGetTemplatesSuccess(res) {
      console.log('The get templates response is: ', res);
      let templates = res.data.templates;
      this.currentTemplatesPage = res.data.current_page;
      this.totalTemplatePages = res.data.page_count;
      this.templateListPerPage = res.data.per_page;

      // Loop through the returned templates and add them to the list of templates
      templates.forEach(template => {
        this.templates.push({
          template_name: template.name,
          template_id: template.template_id,
          template_recommendation_id: template.template_recommendation_id,
        });
      });

      this.loadingTemplates = false;
    },
    /**
     * Handle the error response from the get templates endpoint
     * @param {Object} err
     */
    handleGetTemplatesError(err) {
      console.log('The get templates error is: ', err);
      this.loadingTemplates = false;
      this.loadingTemplatesError = err;
    },
    /**
     * Update the template list with lazy loading
     */
    async updateTemplateList(event) {
      this.lazyLoadingTemplates = true;

      // Call paginated endpoint
      if (event.last >= (this.templates.length - 3) && this.currentTemplatesPage < this.totalTemplatePages) {
        console.log('in pagination');
        this.currentTemplatesPage++;
        await this.getTemplates();
      }

      this.lazyLoadingTemplates = false;
    },
    /**
     * Search the template list
     */
    searchTemplateList(event) {
      !this.lazyLoadingTemplates && (this.lazyLoadingTemplates = true);
      this.searchTemplates = event.value;
      this.currentTemplatesPage = 1;
      this.totalTemplatePages = 1;
      this.templateListPerPage = 1;

      // Debounce the search
      clearTimeout(this.templateDelayTimer);
      this.templateDelayTimer = setTimeout(() => {
        this.getTemplates();
      }, 400);

      this.lazyLoadingTemplates = false;
    },
    /**
     * Display the page
     * @param {Number} page
     */
    displayPage(page) {
      this.recommendationListCurrentPage = page;
      this.getRecommendationsList();
    },
    /**
     * Change the selected recommendation
     * @param {Object} recommendation
     */
    changeSelectedRecommendation(recommendation) {
      this.$store.dispatch('getRefreshToken');
      console.log('Changing selected recommendation to: ', recommendation);
      this.setRecommendationLocal(recommendation);

      // If the selected recommendation is a campaign, clear the selected pharmacies and templates and set the selected templates
      if (this.selectedRecommendationLocal && this.selectedRecommendationLocal.type === 'campaign') {
        this.selectedPharmacies = [];
        this.selectedTemplates = [];
        this.selectedRecommendationLocal.template_recommendations.map(recommendation => {
          // Find the template recommendation in the list of templates
          let matchedTemplate = this.templates.find(template => template.template_recommendation_id === recommendation.template_id);

          // If the recommended template is in the list of templates, add the matched template to selected templates
          if (matchedTemplate) {
            this.selectedTemplates.push({
              template_name: matchedTemplate.template_name,
              template_id: matchedTemplate.template_id,
              template_recommendation_id: recommendation.template_id
            });
          }
          // If the recommended template is not in the list of templates, add it to the selected templates
          else {
            this.selectedTemplates.push({
              template_name: recommendation.template_name,
              template_id: null,
              template_recommendation_id: recommendation.template_id
            });
          }
        });
        // Get selected Templates to the top of templatesList if not there already
        // this.templates = this.templates.filter(template => !this.selectedTemplates.some(selectedTemplate => selectedTemplate.template_id === template.template_id));
      }      
    },
    /**
     * Check if the recommendation is the current recommendation
     * @param {Object} recommendation
     * @returns {Boolean}
     */
    currentRecommendation(recommendation) {
      // If the selected recommendation is a campaign, check the campaign_recommendation_id
      if (this.selectedRecommendationLocal.type === 'campaign') {
        return this.selectedRecommendationLocal.campaign_recommendation_id === recommendation.campaign_recommendation_id;
      }
      // If the selected recommendation is a template, check the template_recommendation_id
      else if (this.selectedRecommendationLocal.type === 'template' && this.selectedRecommendationLocal.template_recommendation_id) {
        return this.selectedRecommendationLocal.template_recommendation_id === recommendation.template_recommendation_id;
      }

      return false;
    },
    /**
     * Add a template
     */
    addTemplate() {
      this.$store.dispatch('getRefreshToken');
      this.displayAddTemplatesModal = true;
    },
    /**
     * When a new template is created
     * @param {Object} template
     */
    onNewTemplateCreated(template) {
      // Push the new template to the templates list
      this.templates.push({
        template_name: template.name,
        template_id: template.template_id,
      });
      // Push the new template to the selected templates list
      this.selectedTemplates.push({
        template_name: template.name,
        template_id: template.template_id,
      });
    },
    /**
     * Emit the publish event
     */
    activate() {
      this.$store.dispatch('getRefreshToken');
      this.createCampaignTemplateError = '';
      // Validation of the campaign or template
      let errors = [];

      if (this.selectedRecommendationLocal.type === 'campaign') {
        if (this.selectedRecommendationLocal.name === '') {
          errors.push('enter a campaign name');
        }
        if (this.selectedRecommendationLocal.goal_type === '') {
          errors.push('select a goal type');
        }
        if (this.selectedRecommendationLocal.measure_by === '') {
          errors.push('select a measurement type');
        }
        if (this.selectedRecommendationLocal.goal === null) {
          errors.push('enter a goal amount');
        }
        if (this.selectedRecommendationLocal.goal <= 0) {
          errors.push('enter a goal amount greater than 0');
        }
        if (!this.selectedRecommendationLocal.pharmacies || this.selectedRecommendationLocal.pharmacies.length === 0) {
          errors.push('select atleast one pharmacy');
        }
        if (!this.selectedRecommendationLocal.templates || this.selectedRecommendationLocal.templates.length === 0) {
          errors.push('select atleast one template');
        }
        if (!this.selectedRecommendationLocal.patient_cohort || Object.keys(this.selectedRecommendationLocal.patient_cohort).length === 0) {
          errors.push('select atleast one patient cohort');
        }
      } 
      else if (this.selectedRecommendationLocal.type === 'template') {
        if (this.selectedRecommendationLocal.name === '') {
          errors.push('enter a template name');
        }
        if (!this.selectedRecommendationLocal.sms_template && !this.selectedRecommendationLocal.email_template) {
          errors.push('select atleast one template type');
        }
        if (this.recommendationBody === '') {
          errors.push('enter a template body');
        }
        if (!this.recommendationBody.includes("{Pharmacy Name}")) {
          errors.push('include {Pharmacy Name} in the template body');
        }
      }

      if (errors.length > 0) {
        if (errors.length > 1) errors[errors.length - 1] = `and ${errors[errors.length - 1]}`;
        this.createCampaignTemplateError = `Please ${errors.join(', ')}`
        return;
      }

      this.loadingCreateCampaignTemplate = true;
      this.displayUserAuthenticationModal = true; 
    },
     /**
     * Save the campaign
     */
    async createCampaignTemplate() {
      this.loadingCreateCampaignTemplate = true;
      this.$store.dispatch('getRefreshToken');
      this.createCampaignTemplateError = '';

      // If creating a campaign
      if (this.selectedRecommendationLocal.type === 'campaign') {
        try {
          const res = await axios.post('/api/campaign/create/enterprise', this.createCampaignPayload, this.$store.state.header);
          this.handleCreateCampaignSuccess(res);
        } catch (err) {
          this.handleCreateCampaignError(this.handleAxiosError(err));
        }
      }
      // If creating a template
      else if (this.selectedRecommendationLocal.type === 'template') {
        try {
          const res = await axios.post('/api/template/create/enterprise', this.createCampaignPayload, this.$store.state.header);
          this.handleCreateTemplateSuccess(res);
        } catch (err) {
          this.handleCreateTemplateError(this.handleAxiosError(err));
        }
      }
      // If invalid recommendation type
      else {
        this.loadingCreateCampaignTemplate = false;
        this.createCampaignTemplateError = 'Invalid recommendation type';
      }
    },
    /**
     * Handle the success of creating the campaign
     * @param {Object} res
     */
    handleCreateCampaignSuccess(res) {
      console.log('The campaign created response is: ', res);
      this.loadingCreateCampaignTemplate = false;
      this.$emit('recommendedCampaignCreated');
    },
    /**
     * Handle the error of creating the campaign
     * @param {Object} err
     */
    handleCreateCampaignError(err) {
      console.log('The campaign or template error response is: ', err);
      this.loadingCreateCampaignTemplate = false;
      this.createCampaignTemplateError = err;
    },
    /**
     * Handle the success of creating the template
     * @param {Object} res
     */
    handleCreateTemplateSuccess(res) {
      console.log('The template created response is: ', res);
      this.loadingCreateCampaignTemplate = false;
      this.$emit('recommendedTemplateCreated');
    },
    /**
     * Handle the error of creating the template
     * @param {Object} err
     */
    handleCreateTemplateError(err) {
      console.log('The create template error response is: ', err);
      this.loadingCreateCampaignTemplate = false;
      this.createCampaignTemplateError = err;
    },
    /**
     * Close the open modals
     */
    closeOpenModals() {
      this.displayAddTemplatesModal = false;
      this.displayUserAuthenticationModal = false;
      this.loadingCreateCampaignTemplate = false;
    },
    /**
     * Emit the close modal event
     */
    closeModal() {
      this.$emit('closeModal');
    },
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: row;
  height: 78vh;
  min-width: 1300px;
}

.sidebar {
  width: 300px;
  background-color: $grey;
  display: flex;
  flex-direction: column;
}

.header-box {
  display: flex;
  flex-direction: column;
  padding: 2em;
  gap: 1em;
}

.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:deep(.p-inputtext) {
  width: 100%;
}

.p-inputtext,
.p-selectbutton,
.p-password-input {
  border-radius: 5px;
  border: 1px solid $deepgrey;
  overflow: hidden;
}

.p-togglebutton {
  border-radius: 5px;
  border: 1px solid $deepgrey;
  background: white;
  color: $deepgrey;
  overflow: hidden;
}

.p-togglebutton :deep(.p-button-icon) {
  padding: 0;
}

.p-togglebutton.p-button.p-highlight {
  background-color: $deepgrey !important;
  color: white !important;
}

:deep(.p-togglebutton.p-focus) {
  box-shadow: none;
}

:deep(.p-selectbutton) .p-highlight {
  background-color: $deepgrey !important;
  border: 1px solid $deepgrey !important;
}

:deep(.p-calendar-w-btn .p-datepicker-trigger) {
  background: $strongblue;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

.p-selectbutton {
  display: flex;
}

.p-selectbutton :deep(div) {
  flex: 1;
}

:deep(.p-button:focus) {
  box-shadow: none;
}

.recommendations-list {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  border-top: 1px solid $deepgrey;
}

.recommendation-item {
  height: 4em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $deepgrey;
  padding: 0 1em 0 2em;
  cursor: pointer;
  background-color: $grey;
}

.recommendation-item:first {
  border-top: 1px solid $deepgrey;
}

.recommendation-item:hover {
  background-color: $babyblue;
}

.recommendation-item h4 {
  font-weight: 700;
  font-size: 11pt;
  color: $deepgrey;
}

.selected-recommendation {
  background-color: white;
}

.flex-row {
  display: flex;
}

.recommendation-content {
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 1;
  text-align: left;
  overflow-y: auto;
}

.recommendation-content h4 {
  margin: 0;
}

.b-bottom {
  padding-bottom: 1em;
  border-bottom: 1px solid $bordergrey;
}

.radio-group {
  gap: 0.5em;
}

.radio-item {
  font-size: 11pt;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.form-container {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  gap: 20px;
  align-items: stretch;
  padding: 30px 30px 10px 30px;
}

.form-container h3 {
  margin: 0;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.flex-item {
  flex: 1;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.grey-input {
  border: 1px solid #ced4da !important;
  border-radius: 3px;
}

.pharmacies-container {
  display: flex;
  border-radius: 5px;
  background-color: $grey;
  overflow-y: auto;
  flex: 1 1 150px;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 10px 0 10px;
}

.pharmacy-bubble {
  display: flex;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  background-color: $bluegreen;
  color: white;
  font-weight: 500;
  font-size: 11pt;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

.error-text {
  color: $error;
  margin: 0 auto 0 0;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
  text-align: left;
}

.red-error-icon {
  min-width: 20px;
  background-color: $error;
  border-color: $error;
  color: white;
  border-radius: 50% !important;
  height: 20px;
  width: 20px;
  margin: auto;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.error-text-span {
  margin: auto;
  padding-left: 10px;
}

.tag-success {
  background-color: $complete;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 600;
}
</style>
