<template>
  <div
    class="meters-group"
    :style="`height: ${height.value}${height.unit}; border-radius: ${height.value / 2}${height.unit};`"
  >
    <span
      v-for="(val, index) in meterPercentages"
      :key="index"
      class="meter-item"
      :style="`background-color: ${val.color}; width: ${val.percentage}%;`"
    >
    </span>
  </div>
</template>

<script>
export default {
  props: {
    height: {
      type: Object,
      required: false,
      default: () => ({
        value: 1.25,
        unit: 'rem'
      })
    },
    meterValues: {
      type: Array,
      required: true,
    }
  },
  computed: {
    validValues() {
      return this.meterValues.filter((val) => val.percentage > 0);
    },
    meterPercentages() {
      return this.validValues.map((val) => {
        return {
          ...val,
          percentage: val.percentage
        }
      });
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";
.meters-group {
  width: 100%;
  height: 0.5rem;
  display: flex;
  background-color: #D8DCDC;
  overflow: hidden;
}

.meter-item {
  background-color: #3498DB;
}
</style>