<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Edit Pharmacy
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <div class="flex-input">
                <label for="pharmacy_name">Pharmacy Name</label>
                <InputText
                  id="pharmacy_name"
                  v-model="editPharmacyForm.name"
                  placeholder="Enter a Pharmacy Name"
                  class="grey-input"
                />
              </div>
              
              <div class="form-row">
                <div class="flex-input flex-item">
                  <label for="phone_number">Phone Number</label>
                  <InputText
                    id="phone_number"
                    v-model="editPharmacyForm.phone_number"
                    placeholder="Enter a Phone Number"
                    class="grey-input flex-item"
                  />
                </div>
                <div class="flex-input flex-item">
                  <label for="street_address">Street Address</label>
                  <vue-google-autocomplete
                    v-if="$store.getters.getMapsApiLoaded == true"
                    id="map"
                    ref="address"
                    classname="p-inputtext p-component grey-input flex-expand"
                    placeholder="Enter a Street Address"
                    :country="countryCode.toLowerCase()"
                    :enable-geolocation="true"
                    @placechanged="getAddressData"
                  />
                  <InputText
                    v-else
                    id="street_address"
                    v-model="editPharmacyForm.street_address"
                    placeholder="Enter a Street Address"
                    class="grey-input"
                  />
                </div>
              </div>
              
              <div class="form-row">
                <div class="flex-input flex-item">
                  <label for="town">{{ townLabel }}</label>
                  <InputText
                    v-model="editPharmacyForm.town"
                    :placeholder="townPlaceholder"
                    class="grey-input flex-item"
                  />
                </div>
                <div class="flex-input flex-item">
                  <label for="postcode">Postcode</label>
                  <InputText
                    id="postcode"
                    v-model="editPharmacyForm.postcode"
                    placeholder="Enter a Postcode"
                    class="grey-input flex-item"
                    :type="countryCode == 'AU' ? 'number' : 'text'"
                  />
                </div>
              </div>
              <div class="form-row">
                <div 
                  v-if="countryCode=='AU'"  
                  class="flex-input flex-item"
                >
                  <label for="region">State</label>
                  <Dropdown
                    id="region"
                    v-model="editPharmacyForm.region"
                    placeholder="Select a State"
                    :options="states"
                    class="flex-item"
                  />
                </div>
                <div class="flex-input flex-item">
                  <label for="country">Country</label>
                  <InputText
                    id="country"
                    v-model="editPharmacyForm.country_name"
                    placeholder="Enter a Country"
                    class="grey-input"
                  />
                </div>
              </div>
              <div class="flex-input">
                <label for="data_source">Data Source</label>
                <Dropdown
                  id="data_source"
                  v-model="editPharmacyForm.data_source"
                  :options="dispensingSources"
                  option-label="name"
                  option-value="key"
                  placeholder="Select a Data Source"
                  class="grey-input"
                />
              </div>
              <div class="flex-input">
                <label for="modules">Modules</label>
                <MultiSelect
                  id="modules"
                  v-model="selectedModules"
                  :options="pharmacyModules"
                  option-label="display_name"
                  placeholder="Select Modules"
                  class="grey-input"
                />
              </div>
              <div class="flex-input">
                <label for="pharmaprograms_id">PharmaPrograms ID</label>
                <InputText
                  v-if="pharmaProgramsSelectedModule"
                  id="pharmaprograms_id"
                  v-model="pharmaProgramsSelectedModule.external_id"
                  placeholder="PharmaPrograms ID"
                  class="grey-input"
                />
              </div>
              <div class="flex-input">
                <label for="features">Features</label>
                <MultiSelect
                  id="features"
                  v-model="selectedFeatures"
                  :options="featuresLocal"
                  placeholder="Select Features"
                  class="grey-input"
                />
              </div>
              <!-- 
                The no_guild_consent value is a boolean
                The checkbox is checked if the value is true
               -->
              <div class="flex-input flex-item">
                <label for="no_guild_consent">No Guild Consent</label>
                <Checkbox
                  v-model="editPharmacyForm.no_guild_consent"
                  input-id="no_guild_consent"
                  :binary="true"
                />
              </div>
            </div>
            <p
              v-if="editPharmacyError"
              class="error-message"
            >
              {{ editPharmacyError }}
            </p>
            <p
              v-if="editPharmacySuccess"
              class="success-message"
            >
              {{ editPharmacySuccess }}
            </p>
          </slot>
        </div>
  
        <!-- FOOTER -->
        <div class="modal-footer">
          <slot name="footer">
            <!-- 
                The updateFilters method is called when the confirm button is clicked
                The method emits an event to the parent component
               -->
            <div
              class="black-button"
              @click="editPharmacy"
            >
              Confirm
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  props: {
    /**
     * The selected pharmacy
     * @type {Object}
     */
    pharmacy: {
      type: Object,
      default() {
        return {
        }
      }
    },
    /**
     * The modules to be displayed in the modules dropdown
     * @type {Array}
     */
    modules: {
      type: Array,
      default() {
        return []
      }
    }
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'closeModal',
    'updatePharmacy'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
      loading: false,
      editPharmacyError: '',
      editPharmacySuccess: '',
      editPharmacyForm: {
        name: this.pharmacy.name,
        phone_number: this.pharmacy.phone_number,
        street_address: this.pharmacy.street_address,
        region: this.countryCode === 'AU' ? this.pharmacy.region : null,
        postcode: this.pharmacy.postcode,
        country_name: this.pharmacy.country_name,
        town: this.pharmacy.town,
        no_guild_consent: this.pharmacy.no_guild_consent,
        data_source: this.pharmacy.data_source,
      },
      selectedModules: [],
      modulesLocal: [],
      featuresLocal: [
        'beta',
      ],
      selectedFeatures: [],
    }
  },
  computed: {
    /**
     * The edit pharmacy payload
     * @returns {Object}
     */
    editPharmacyPayload() {
      return {
        ...this.editPharmacyForm,
        features: this.selectedFeatures,
        module_ids: this.selectedModules.map((module) => ({
          module_id: module.uuid,
          external_id: module.external_id ? module.external_id : null
        }))
      }
    },
    /**
     * The town placeholder
     * town for GB and suburb for AU
     * @returns {String}
     */
    townPlaceholder() {
      if (this.countryCode === 'GB') {
        return 'Enter a Town';
      }
      else {
        return 'Enter a Suburb';
      }
    },
    /**
     * The town label
     * town for GB and suburb for AU
     * @returns {String}
     */
    townLabel() {
      if (this.countryCode === 'GB') {
        return 'Town';
      }
      else {
        return 'Suburb';
      }
    },
    /**
     * The modules which are for pharmacy
     * @return {Array}
     */
    pharmacyModules() {
      return this.modulesLocal.filter(module => module.location_type === 'pharmacy');
    },
    pharmaProgramsSelectedModule() {
      return this.selectedModules.find(module => module.name == 'pharmaprograms');
    },
    /**
     * Retrieve the externalId of the PharmaPrograms module
     */
    pharmaProgramsModuleExternalId() {
      return this.pharmaProgramsSelectedModule ? this.pharmaProgramsSelectedModule.external_id : null;
    },
  },
  watch: {
    /**
     * Watch for changes to the pharmaProgramsModuleExternalId
     */
    pharmaProgramsModuleExternalId() {
      // When the value changes, update the respective module external_id
      this.pharmacyModules.find(module => module.name === this.pharmaProgramsSelectedModule.name).external_id = this.pharmaProgramsModuleExternalId;
    }
  },
  mounted() {
    // Set the address in autocomplete field
    if (this.$store.getters.getMapsApiLoaded == true) {
      this.$refs.address.update(this.pharmacy.street_address);
    }
    // Set the local modules
    this.modulesLocal = this.modules.map(x => ({
      ...x,
      external_id: null // Set the external_id property to match with selectedModules
    }));
    this.selectedModules = this.pharmacy.modules.map((module) => ({
      uuid: module.uuid,
      name: module.name,
      display_name: module.display_name,
      display_colour: module.display_colour,
      location_type: module.location_type,
      external_id: module.external_id
    }))
    // Set the selected features
    this.selectedFeatures = this.pharmacy.features;
  },
  methods: {
    /**
     * Emit the close modal event
     */
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
     getAddressData(addressData, placeResultData) {
      // Calculate the address from subpremise, street_number, and route
      let street_address = '';
      if (addressData.subpremise) {
        street_address += addressData.subpremise + '/';
      }
      if (addressData.street_number) {
        street_address += addressData.street_number + ' ';
      }
      if (addressData.route) {
        street_address += addressData.route;
      }
      this.editPharmacyForm.street_address = street_address;

      // Postal town as town for GB and locality as town for AU
      if (this.countryCode === 'GB') {
        this.editPharmacyForm.town = placeResultData.address_components.find(x => x.types.includes('postal_town')).long_name;
      }
      else if (this.countryCode === 'AU') {
        this.editPharmacyForm.town = addressData.locality;
        this.editPharmacyForm.region = addressData.administrative_area_level_1;
      }

      this.editPharmacyForm.postcode = addressData.postal_code;
      this.editPharmacyForm.country_name = addressData.country;
      console.log('this is the address now', addressData, placeResultData, this.editPharmacyForm);
    },
    /**
     * Edit a pharmacy
     * @returns {Promise<void>}
     */
    editPharmacy() {
      this.$store.dispatch('getRefreshToken');
      this.editPharmacyError = '';
      this.editPharmacySuccess = '';
      this.loading = true;

      axios.post(`/api/location/${this.pharmacy.uuid}/edit`, this.editPharmacyPayload, this.$store.state.header)
      .then(res => {
        this.handleEditPharmacySuccess(res);
      }).catch(err => {
        this.handleEditPharmacyError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the success response for the pharmacy
     * @param {Object} res 
     */
    handleEditPharmacySuccess(res) {
      console.log('The edit pharmacy response is: ', res);
      this.loading = false;
      this.editPharmacySuccess = 'Pharmacy Edited Successfully.';
      this.updatePharmacy(res.data.data);
    },
    /**
     * Handle the error response for the pharmacy
     * @param {Object} err 
     */
    handleEditPharmacyError(err) {
      console.log('The edit pharmacy error is: ', err);
      this.loading = false;
      this.editPharmacyError = err;
    },
    /**
     * Emit the update pharmacy event to the parent component
     * The event is emitted with the updated pharmacy
     */
    updatePharmacy(pharmacy) {
      this.$emit('updatePharmacy', pharmacy);
    }
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.pharmacies-container {
  display: flex;
  border-radius: 5px;
  background-color: $grey;
  overflow-y: auto;
  flex: 1 1 150px;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 10px 0 10px;
}

.pharmacy-bubble {
  display: flex;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  background-color: $bluegreen;
  color: white;
  font-weight: 500;
  font-size: 11pt;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

</style>
