<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Add User
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <div class="form-row">
                <InputText
                  v-model="addUserPayload.username"
                  placeholder="Enter a Username"
                  class="grey-input flex-item"
                />
                <InputText
                  v-model="addUserPayload.password"
                  placeholder="Enter a Password"
                  class="grey-input flex-item"
                  type="password"
                />
              </div>
              <div class="form-row">
                <InputText
                  v-model="addUserPayload.first_name"
                  placeholder="Enter a First Name"
                  class="grey-input flex-item"
                />
                <InputText
                  v-model="addUserPayload.last_name"
                  placeholder="Select a Last Name"
                  class="grey-input flex-item"
                />
              </div>
              <div class="form-row">
                <InputText
                  v-model="addUserPayload.email"
                  placeholder="Enter an Email"
                  class="grey-input flex-item"
                />
                <Dropdown
                  v-model="selectedCompany"
                  :loading="loadingCompanies"
                  :editable="true"
                  :options="companies"
                  option-label="name"
                  placeholder="Select a Company"
                  class="grey-input flex-item"
                  @keyup="debounceSearchCompanies(selectedCompany)"
                  @change="selectCompany(selectedCompany)"
                />
              </div>
              <div class="form-row">
                <label for="create_enterprise_login">Enterprise Login</label>
                <Checkbox
                  v-model="addUserPayload.enterprise_login"
                  input-id="create_enterprise_login"
                  :binary="true"
                />
              </div>
              <div class="form-row">
                <label for="account_creation_email">Send Account Creation Email</label>
                <Checkbox
                  v-model="addUserPayload.account_creation_email"
                  input-id="account_creation_email"
                  :binary="true"
                />
              </div>
            </div>
            <p
              v-if="createUserError"
              class="error-message"
            >
              {{ createUserError }}
            </p>
            <p
              v-if="createUserSuccess"
              class="success-message"
            >
              {{ createUserSuccess }}
            </p>
          </slot>
        </div>
    
        <!-- FOOTER -->
        <div class="modal-footer">
          <slot name="footer">
            <!-- 
                  The updateFilters method is called when the confirm button is clicked
                  The method emits an event to the parent component
                 -->
            <div
              class="black-button"
              @click="addUser"
            >
              Confirm
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
    
  <script>
  import axios from 'axios';
  
  export default {
    props: {
      companyId: {
        type: String,
        default: ''
      }
    },
    /**
     * Emits events to parent component
     */
    emits: [
      'closeModal'
    ],
    data() {
      /**
       * The local data for the component
       */
      return {
        loading: false,
        createUserError: '',
        createUserSuccess: '',
        selectedCompany: null,
        loadingCompanies: false,
        companies: [],
        addUserPayload: {
          username: '',
          password: '',
          first_name: '',
          last_name: '',
          email: '',
          company_id: '',
          enterprise_login: false,
          account_creation_email: true,
        }
      }
    },
    computed: {
    },
    mounted() {
      /**
       * Set the local variables to the props
       * This is required to ensure that the local variables can be updated prior to emitting changes
       */
      this.addUserPayload.company_id = this.companyId;
    },
    methods: {
      /**
       * Emit the close modal event
       */
      closeModal() {
        this.$emit('closeModal');
      },
      /**
       * Create a user
       * @returns {Promise<void>}
       */
      addUser() {
        this.$store.dispatch('getRefreshToken');
        this.createUserError = '';
        this.createUserSuccess = '';
        this.loading = true;
  
        axios.post('/api/user/create', this.addUserPayload, this.$store.state.header)
        .then(res => {
          this.handleCreateUserSuccess(res);
        }).catch(err => {
          this.handleCreateUserError(this.handleAxiosError(err));
        });
      },
      /**
       * Handle the success response for the user
       * @param {Object} res 
       */
      handleCreateUserSuccess(res) {
        console.log('The create user response is: ', res);
        this.loading = false;
        this.clear();
        this.createUserSuccess = 'User Created Successfully.'
      },
      /**
       * Handle the error response for the user
       * @param {Object} err 
       */
      handleCreateUserError(err) {
        console.log('The create user error is: ', err);
        this.loading = false;
        this.createUserError = err;
      },
      /**
       * Debounce search to retrieve companies based on company name
       * 400 ms delay on search
       * @param {String} companyName 
       */
       debounceSearchCompanies(companyName) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchCompanies(companyName);
        }, 400);
      },
      /**
       * Search for companies based on company name
       * @param {String} companyName 
       */
      searchCompanies(companyName) {
        this.$store.dispatch('getRefreshToken');
        this.loadingCompanies = true;
        axios.post('/api/company/search', { "page": 1, "name": companyName }, this.$store.state.header)
        .then(res => {
          this.handleSearchCompaniesSuccess(res);
        }).catch(err => {
          this.handleSearchCompaniesError(this.handleAxiosError(err));
        });
      },
      /**
       * Handle the success response for the companies
       * @param {Object} res 
       */
      handleSearchCompaniesSuccess(res) {
        console.log('The search companies response is: ', res);
        this.loadingCompanies = false;
        this.companies = res.data.results;
      },
      /**
       * Select a company and set the addUserPayload company_id
       */
      selectCompany(company) {
        if (typeof company === 'object' && company !== null) {
          this.addUserPayload.company_id = company.uuid;
        }
      },
      /**
       * Handle the error response for the companies
       * @param {Object} err 
       */
      handleSearchCompaniesError(err) {
        console.log('The search companies error is: ', err);
        this.loadingCompanies = false;
      },
      clear() {
        this.addUserPayload.username = '',
        this.addUserPayload.password = '',
        this.addUserPayload.first_name = '',
        this.addUserPayload.last_name = '',
        this.addUserPayload.email = '',
        this.addUserPayload.company_id = '',
        this.addUserPayload.enterprise_login = false,
        this.addUserPayload.account_creation_email = true
      }
    }
  }
  </script>
    
  <style lang="scss" scoped>
  @import "../../assets/css/main.scss";
  
  .modal-container {
    display: flex;
    flex-direction: column;
    height: 78vh;
  }
  
  .error-message {
    color: $error;
  }
  
  .success-message {
    color: $correct;
  }
  
  .form-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: stretch;
  
    .span {
      display: flex;
    }
  }
  
  .flex-item {
    flex: 1;
  }
  
  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;
  }
  
  .pharmacies-container {
    display: flex;
    border-radius: 5px;
    background-color: $grey;
    overflow-y: auto;
    flex: 1 1 150px;
    row-gap: 5px;
    column-gap: 10px;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 20px 10px 0 10px;
  }
  
  .pharmacy-bubble {
    display: flex;
    border-radius: 5px;
    padding: 3px 10px 3px 10px;
    background-color: $bluegreen;
    color: white;
    font-weight: 500;
    font-size: 11pt;
    align-items: center;
    justify-content: center;
    height: fit-content;
  }
  
  .close-icon {
    cursor: pointer;
    padding-left: 10px;
    padding-right: 0;
  }
  
  </style>
  