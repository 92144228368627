<template>
  <div class="editor-container">
    <!-- TOOLBAR -->
    <div class="toolbar">
      <SelectButton
        v-model="mode"
        :options="modeOptions"
        option-label="value"
        data-key="value"
        :allow-empty="false"
      >
        <template #option="slotProps">
          <i
            v-if="slotProps.option.value == 'edit'"
            :class="slotProps.option.icon"
          ></i>
          <img
            v-else-if="slotProps.option.value == 'gpt' && mode.value == 'edit'"
            :src="chatGptGreyIconSrc"
            class="extrasmall-icon"
          >
          <img
            v-else-if="slotProps.option.value == 'gpt' && mode.value == 'gpt'"
            :src="chatGptWhiteIconSrc"
            class="extrasmall-icon"
          >
        </template>
      </SelectButton>
    </div>

    <!-- KEEP ALIVE FOR FieldListTextEditor and ChatBox -->
    <KeepAlive>
      <component
        :is="currentComponent"
        v-bind="currentProperties"
        ref="currentComp"
        @edit="edit"
        @content-updated="setContent"
      />
    </KeepAlive>
  </div>
</template>

<script>
import ChatBox from '@/components/templates/chat-box.vue';
import FieldListTextEditor from '@/components/templates/field-list-text-editor.vue';
import { ref } from 'vue';

export default {
  name: 'TemplateEditor',
  components: {
    FieldListTextEditor,
    ChatBox
  },
  props: {
    /**
     * The content of the editor
     * @type {String}
     */
    content: {
      type: String,
      default: ''
    },
    /**
     * The communication type of the selected template
     * @type {String}
     */
    communication: {
      type: String,
      default: ''
    },
    /**
     * The source of the updated content
     * @type {String}
     */
    source: {
      type: String,
      default: 'editor'
    }
  },
  emits: [
    'contentUpdated'
  ],
  data() {
    return {
      chatGptWhiteIconSrc: require('@/assets/icons/chat_gpt_white_icon.svg'),
      chatGptGreyIconSrc: require('@/assets/icons/chat_gpt_grey_icon.svg'),
      curlyBracketIconSrc: require('@/assets/icons/curly_bracket_icon.svg'),
      mode: { icon: 'pi pi-pencil extrasmall-icon', value: 'edit' },
      modeOptions: [
        { icon: 'pi pi-pencil extrasmall-icon', value: 'edit' },
        { icon: 'pi chat-gpt-icon', value: 'gpt' }
      ],
      currentComponent: ref('FieldListTextEditor'),
      currentContent: ''
    }
  },
  computed: {
    // Get the properties for the current component
    currentProperties() {
      if (this.currentComponent === 'FieldListTextEditor') {
        return {
          content: this.currentContent
        }
      } else if (this.currentComponent === 'ChatBox') {
        return {
          communication: this.communicationType
        }
      }
      return null;
    }
  },
  watch: {
    // Watch for mode changes
    mode: {
      handler: function (newVal) {
        if (newVal.value == 'edit') {
          this.loadEdit();
        } else if (newVal.value == 'gpt') {
          this.loadChatGpt();
        }
      },
      deep: true
    },
    // Watch for content changes
    content: {
      handler: function (newVal) {
        this.currentContent = newVal;
        this.setEditorContent(newVal, this.source);
      },
      deep: true
    },
  },
  mounted() {
    this.currentContent = this.content;
    this.communicationType = this.communication;
  },
  methods: {
    /**
     * On message edit, update the TextEditor content, and switch to edit mode
     * @param {Object} value 
     */
    edit(value) {
      this.$store.dispatch('getRefreshToken');
      this.mode = { icon: 'pi pi-pencil extrasmall-icon', value: 'edit' };
      this.loadEdit();
      this.setEditorContent(value.text, 'gpt');
    },
    /**
     * Set the content of the editor current component
     * @param {String} value 
     */
    setEditorContent(value, source='editor') {
      this.$nextTick(() => {
        this.$refs.currentComp.setContent(value, source);
      });
    },
    /**
     * Set the content
     */
    setContent(value) {
      this.currentContent = value;
      this.$emit('contentUpdated', value);
    },
    /**
     * Load the FieldListTextEditor component
     */
    loadEdit() {
      this.$store.dispatch('getRefreshToken');
      this.currentComponent = 'FieldListTextEditor';
    },
    /**
     * Load the ChatBox component
     */
    loadChatGpt() {
      this.$store.dispatch('getRefreshToken');
      this.currentComponent = 'ChatBox';
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.editor-container {
  border: 1px solid $bordergrey;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.toolbar {
  height: 3em;
  background-color: $grey;
  border-bottom: 1px solid $bordergrey;
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 1em;
}

:deep(.p-selectbutton) .p-highlight {
  background-color: $deepgrey !important;
  border: 1px solid $deepgrey !important;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

.p-selectbutton {
  display: flex;
}

.p-selectbutton :deep(div) {
  flex: 1;
}

.p-selectbutton i {
  padding: 0;
}

.flex-item {
  flex: 1;
}
</style>