<template>
  <div
    class="d-flex flex-column"
    style="gap:1rem;background-color:#F3F4F4;padding:1rem;"
  >
    <Card class="card-pa-0 overflow-hidden">
      <template #content>
        <div 
          class="stats-container"
          :loading="selectedPharmacyLocal.pharmacyStatsLoading"
        >
          <section class="stat-item">
            <h5 class="stat-key">
              Roi
            </h5>
            <span class="stat-value text-green">
              {{ roi }}
            </span>
          </section>
          <Divider
            layout="vertical"
            class="divider"
          />
          <section class="stat-item">
            <h5 class="stat-key">
              Revenue
            </h5>
            <span class="stat-value">
              {{ revenue }}
              <span
                v-if="revenue_change"
                class="text_grey"
              >
                ({{ revenue_change }})
              </span>
            </span>
          </section>
        </div>
        <Divider
          layout="horizontal"
          class="divider"
        />
        <div class="stats-container">
          <section class="stat-item stat-item-grey">
            <h5 class="stat-key">
              Cost
            </h5>
            <span class="stat-value">
              {{ cost }}
            </span>
          </section>
          <Divider
            layout="vertical"
            class="divider"
          />
          <section class="stat-item stat-item-grey">
            <h5 class="stat-key">
              Reach
            </h5>
            <span class="stat-value">
              {{ selectedPharmacyLocal.reach || '-' }}
            </span>
          </section>
          <Divider
            layout="vertical"
            class="divider"
          />
          <section class="stat-item stat-item-grey">
            <h5 class="stat-key">
              SMS
            </h5>
            <span class="stat-value">
              {{ selectedPharmacyLocal.sms || '-' }}
            </span>
          </section>
          <Divider
            layout="vertical"
            class="divider"
          />
          <section class="stat-item stat-item-grey">
            <h5 class="stat-key">
              Email
            </h5>
            <span class="stat-value">
              {{ selectedPharmacyLocal.email || '-' }}
            </span>
          </section>
        </div>
      </template>
    </Card>
    <Card class="card-pa-0 overflow-hidden">
      <template #content>
        <div class="stats-container">
          <section class="stat-item stat-item-grey">
            <Tag
              class="campaign-goal-type-tag refer"
              value="Messages"
            />
          </section>
          <Divider
            layout="vertical"
            class="divider"
          />
          <section class="stat-item stat-item-grey">
            <h5 class="stat-key">
              Campaigns
            </h5>
            <span class="stat-value">
              {{ selectedPharmacyLocal.num_campaign_messages !== null ? selectedPharmacyLocal.num_campaign_messages : '-' }}
            </span>
          </section>
          <Divider
            layout="vertical"
            class="divider"
          />
          <section class="stat-item stat-item-grey">
            <h5 class="stat-key">
              Trials
            </h5>
            <span class="stat-value">
              {{ selectedPharmacyLocal.num_trial_messages !== null ? selectedPharmacyLocal.num_trial_messages : '-' }}
            </span>
          </section>
          <Divider
            layout="vertical"
            class="divider"
          />
          <section class="stat-item stat-item-grey">
            <h5 class="stat-key">
              Communications
            </h5>
            <span class="stat-value">
              {{ selectedPharmacyLocal.num_normal_messages !== null ? selectedPharmacyLocal.num_normal_messages : '-' }}
            </span>
          </section>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
export default {
  components: {
  },
  props: {
    pharmacy: {
      type: Object,
      default() {
        return {
        }
      }
    },
  },
  data() {
    return {
      selectedPharmacyLocal: {},
      selectedCampaignLocal: {}
    }
  },
  computed: {
    roi() {
      if (this.selectedPharmacyLocal.roi === null) {
        return '-';
      }

      return (this.selectedPharmacyLocal.roi > 0 ? `+${this.selectedPharmacyLocal.roi}` : this.selectedPharmacyLocal.roi) + '%';
    },
    revenue() {
      if (this.selectedPharmacyLocal.revenue === null) {
        return `-`;
      }

      return (this.selectedPharmacyLocal.revenue > 0 ? `+${this.$n(this.selectedPharmacyLocal.revenue, 'currency')}` : `${this.$n(this.selectedPharmacyLocal.revenue, 'currency')}`);
    },
    revenue_change() {
      if (this.selectedPharmacyLocal.revenue_change == null) {
        return '';
      }

      return this.selectedPharmacyLocal.revenue_change + '%';
    },
    cost() {
      if (this.selectedPharmacyLocal.cost === null) {
        return `-`;
      }

      return this.$n(this.selectedPharmacyLocal.cost, 'currency');
    },
    current_goal_value() {
      if (this.selectedPharmacyLocal.current_goal_value === null) {
        return `-`;
      }

      if (this.selectedCampaignLocal.goal_type === 'revenue') {
        if (this.selectedPharmacyLocal.current_goal_value < 0 && this.selectedCampaignLocal.measure_by === 'target') {
          return `-${this.$n(this.selectedPharmacyLocal.current_goal_value*(-1), 'currency')}`;
        }
        else if (this.selectedPharmacyLocal.current_goal_value >= 0 && this.selectedCampaignLocal.measure_by === 'target') {
          return `${this.$n(this.selectedPharmacyLocal.current_goal_value, 'currency')}`;
        }
        else if (this.selectedCampaignLocal.measure_by === 'value') {
          return `+${this.$n(this.selectedPharmacyLocal.current_goal_value, 'currency')}`;
        }
        else if (this.selectedCampaignLocal.measure_by === 'percentage') {
          return (this.selectedPharmacyLocal.current_goal_value > 0 ? `+${this.selectedPharmacyLocal.current_goal_value}` : this.selectedPharmacyLocal.current_goal_value) + '%';
        }
      }

      if (this.selectedCampaignLocal.goal_type === 'scripts' || this.selectedCampaignLocal.goal_type === 'visits') {
        if (this.selectedCampaignLocal.measure_by === 'percentage') {
            return (this.selectedPharmacyLocal.current_goal_value > 0 ? `+${this.selectedPharmacyLocal.current_goal_value}` : this.selectedPharmacyLocal.current_goal_value) + '%';
          }
        else if (this.selectedCampaignLocal.measure_by === 'value') {
            return (this.selectedPharmacyLocal.current_goal_value > 0 ? `+${this.selectedPharmacyLocal.current_goal_value}` : this.selectedPharmacyLocal.current_goal_value);
          }
        else if (this.selectedCampaignLocal.measure_by === 'target') {
            return (this.selectedPharmacyLocal.current_goal_value > 0 ? `${this.selectedPharmacyLocal.current_goal_value}` : this.selectedPharmacyLocal.current_goal_value);
          }
      }

      if (this.selectedCampaignLocal.goal_type === 'adherence' || this.selectedCampaignLocal.goal_type === 'loyalty') {
        if (this.selectedCampaignLocal.measure_by === 'percentage') {
          return (this.selectedPharmacyLocal.current_goal_value > 0 ? `+${this.selectedPharmacyLocal.current_goal_value}` : this.selectedPharmacyLocal.current_goal_value) + '%';
        }
        else if (this.selectedCampaignLocal.measure_by === 'target') {
          return (this.selectedPharmacyLocal.current_goal_value > 0 ? `${this.selectedPharmacyLocal.current_goal_value}` : this.selectedPharmacyLocal.current_goal_value) + '%';
        }
      }

      return (this.selectedPharmacyLocal.current_goal_value > 0 ? `+${this.selectedPharmacyLocal.current_goal_value}` : this.selectedPharmacyLocal.current_goal_value) + '%';
    },
    goal_value() {
      if (typeof this.selectedCampaignLocal.goal !== 'number' || !this.selectedCampaignLocal.goal) {
        return `-`;
      }

      if (this.selectedCampaignLocal.goal_type === 'revenue') {
        if (this.selectedCampaignLocal.goal < 0 && this.selectedCampaignLocal.measure_by === 'target') {
          return `-${this.$n(this.selectedCampaignLocal.goal*(-1), 'currency')}`;
        }
        else if (this.selectedCampaignLocal.goal >= 0 && this.selectedCampaignLocal.measure_by === 'target') {
          return `${this.$n(this.selectedCampaignLocal.goal, 'currency')}`;
        }
        else if (this.selectedCampaignLocal.measure_by === 'value') {
          return (this.selectedCampaignLocal.goal > 0 ? `+${this.selectedCampaignLocal.goal}` : this.selectedCampaignLocal.goal);
        }
        else if (this.selectedCampaignLocal.measure_by === 'percentage') {
          return (this.selectedCampaignLocal.goal > 0 ? `+${this.selectedCampaignLocal.goal}` : this.selectedCampaignLocal.goal) + '%';
        }
      }

      if (this.selectedCampaignLocal.goal_type === 'scripts' || this.selectedCampaignLocal.goal_type === 'visits') {
        if (this.selectedCampaignLocal.measure_by === 'percentage') {
          return (this.selectedCampaignLocal.goal > 0 ? `+${this.selectedCampaignLocal.goal}` : this.selectedCampaignLocal.goal) + '%';
        }
        else if (this.selectedCampaignLocal.measure_by === 'value') {
          return (this.selectedCampaignLocal.goal > 0 ? `+${this.selectedCampaignLocal.goal}` : this.selectedCampaignLocal.goal);
        }
        else if (this.selectedCampaignLocal.measure_by === 'target') {
          return (this.selectedCampaignLocal.goal > 0 ? `${this.selectedCampaignLocal.goal}` : this.selectedCampaignLocal.goal);
        }
      }

      if (this.selectedCampaignLocal.goal_type === 'adherence' || this.selectedCampaignLocal.goal_type === 'loyalty') {
        if (this.selectedCampaignLocal.measure_by === 'percentage') {
          return (this.selectedCampaignLocal.goal > 0 ? `+${this.selectedCampaignLocal.goal}` : this.selectedCampaignLocal.goal) + '%';
        }
        else if (this.selectedCampaignLocal.measure_by === 'target') {
          return (this.selectedCampaignLocal.goal > 0 ? `${this.selectedCampaignLocal.goal}` : this.selectedCampaignLocal.goal) + '%';
        }
      }

      return (this.selectedCampaignLocal.goal > 0 ? `+${this.selectedCampaignLocal.goal}` : this.selectedCampaignLocal.goal) + '%';
    },
  },
  mounted() {
    this.$store.dispatch('getRefreshToken');
    this.selectedPharmacyLocal = this.pharmacy;
    this.selectedCampaignLocal = this.campaign;
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.stats-container {
  display: flex;
}

.divider {
  margin: 0 !important;
}

.stat-item {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.25rem;

  &.no-padding {
    padding: 0;
  }

  &.stat-item-grey {
    background: #F3F4F4;
  }

  h5.stat-key {
    width: fit-content;
    margin: 0;
    font-size: 0.875rem;
    text-transform: uppercase;
  }

  span.stat-value {
    width: fit-content;
    font-size: 1.25rem;
    font-weight: 600;
    color: $black;
  }

  span.text-green {
    color: #00AD50;
  }

  .text_grey {
    color: #7B7E7E;
  }
}

.p-card {
  border: solid 1px #BDC4C4;
  border-radius: 10px;
  box-shadow: none;
  color: #7B7E7E;
}

:deep(.p-card-content) {
  padding: 0 !important;
}

.card-pa-0 :deep(.p-card-body) {
  padding: 0;
}

.border-right {
  border-right: solid 1px #BDC4C4;
}
</style>