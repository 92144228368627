<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Edit Template
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <InputText
                v-model="templateForm.name"
                placeholder="Template Name"
                class="grey-input"
              />
              <div class="form-row flex-end">
                <!-- SUBJECT LINE -->
                <InputText
                  v-if="templateForm.email_template"
                  v-model="templateForm.subject"
                  placeholder="Subject Line"
                  class="grey-input flex-item"
                />
                <ToggleButton
                  v-model="templateForm.email_template"
                  on-label="Email"
                  off-label="Email"
                  on-icon="pi pi-check"
                  off-icon="pi pi-times"
                />
                <ToggleButton
                  v-model="templateForm.sms_template"
                  on-label="SMS"
                  off-label="SMS"
                  on-icon="pi pi-check"
                  off-icon="pi pi-times"
                />
              </div>
              <TemplateEditor
                v-if="templateMounted"
                class="flex-item"
                :content="templateForm.body"
                :communication="communicationType"
                @content-updated="setContent"
              />
            </div>
          </slot>
        </div>

        <!-- FOOTER -->
        <div
          class="modal-footer"
          style="padding-left: 0;"
        >
          <div
            v-if="templateEditError && templateEditError !==''"
            class="error-text"
          >
            <span
              class="red-error-icon"
            >!</span>
            <span class="error-text-span">{{ templateEditError }}</span>
          </div>
          <div
            class="black-button"
            style="margin:auto 0;"
            @click="save"
          >
            {{ loadingTemplateEdit ? 'Saving...' : 'Save' }}
          </div>
        </div>
        <UserAuthenticationModel
          v-if="displayUserAuthenticationModal"
          :name="templateForm.name"
          :payload-type="'template'"
          :request-type="'Save'"
          @login-success="editTemplate"
          @save-as-new="setSaveAsNew"
          @close-modal="closeOpenModals" 
        />
      </div>
    </div>
  </div>
</template>

<script>
import TemplateEditor from '@/components/templates/template-editor.vue';
import UserAuthenticationModel from '@/components/modals/campaign-template-authentication-modal.vue';
import axios from 'axios';

export default {
  name: 'EditTemplateModal',
  components: {
    TemplateEditor,
    UserAuthenticationModel
  },
  props: {
    /**
     * The selected template
     * @type {Object}
     */
    template: {
      type: Object,
      default() {
        return {
        }
      }
    },
  },
  emits: [
    'closeModal',
    'templateUpdated',
    'templateCreated'
  ],
  data() {
    return {
      displayUserAuthenticationModal: false,
      saveAsNew: false,
      templateMounted: false,
      loadingTemplateEdit: false,
      templateEditError: '',
      templateForm: {
        name: this.template.name,
        body: this.template.body,
        subject: this.template.subject,
        sms_template: this.template.sms_template,
        email_template: this.template.email_template,
        active: this.template.active,
      }
    }
  },
  computed: {
    /**
     * Generate payload to edit the template
     * @returns {Object}
     */
    editTemplatePayload() {
      let payload = {
        name: this.templateForm.name,
        body: this.templateForm.body,
        subject: this.templateForm.subject,
        sms_template: this.templateForm.sms_template,
        email_template: this.templateForm.email_template,
        active: this.templateForm.active,
      }
      if (!this.saveAsNew) {
        payload.template_id = this.template.template_id;
      }
      return payload;
    },
    communicationType() {
      if (this.templateForm.sms_template && this.templateForm.email_template) {
        return 'both';
      } else if (this.templateForm.sms_template) {
        return 'sms';
      } else if (this.templateForm.email_template) {
        return 'email';
      } else {
        return 'both';
      }
    }
  },
  mounted() {
    this.templateMounted = true;
  },
  methods: {
    /**
     * Set the current content
     */
    setContent(content) {
      this.templateForm.body = content;
    },
    /**
     * Save the template
     */
    save() {
      this.$store.dispatch('getRefreshToken');

      let errors = [];
      
      if (this.templateForm.name === '') {
        errors.push('enter a template name');
      }
      if (!this.templateForm.email_template && !this.templateForm.sms_template) {
        errors.push('select at least one template type');
      }
      if (this.templateForm.body === '') {
        errors.push('enter a message body');
      }
      if (!this.templateForm.body.includes("{Pharmacy Name}")) {
        errors.push('include {Pharmacy Name} in the message body');
      }

      if (errors.length > 0) {
        if (errors.length > 1) {
          errors[errors.length - 1] = 'and ' + errors[errors.length - 1];
        }
        this.templateEditError = `Please ${errors.join(', ')}.`
        return;
      }

      this.loadingTemplateEdit = true;
      this.displayUserAuthenticationModal = true;
    },
    /**
     * Edit a template
     */
    async editTemplate() {
      this.$store.dispatch('getRefreshToken');
      console.log('Edit template');
      this.loadingTemplateEdit = true;
      let apiEndpoint = this.saveAsNew ? '/api/template/create/enterprise' : `/api/template/${this.template.template_id}/edit/enterprise`;

      await axios.post(apiEndpoint, this.editTemplatePayload,  this.$store.state.header)
        .then((response) => {
          this.handleEditTemplateSuccess(response)
        })
        .catch((error) => {
          this.handleEditTemplateError(error);
        });
    },
    /**
     * Handle the successful edit case
     */
    handleEditTemplateSuccess(res) {
      console.log('The edit template response is', res.data);
      if (!this.saveAsNew) {
        this.templateUpdated();
      }
      else {
        this.templateCreated(res.data.id);
      }
    },
    /**
     * Handle the failed edit case
     * @param {Object} err
     */
    handleEditTemplateError(err) {
      console.log('The edit template error is', err);
      this.loadingTemplateEdit = false;
      this.templateEditError = err;
    },
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * Emit the template updated event to the parent component
     */
    templateUpdated() {
        this.templateForm.template_id = this.template.template_id;
        this.templateForm.template_recommendation_id = this.template.template_recommendation_id;
        this.templateForm.campaigns = this.template.campaigns;
        this.$emit('templateUpdated', this.templateForm);
        this.$emit('closeModal');
    },
    templateCreated(id) {
      this.templateForm.template_id = id;
      this.$emit('templateCreated', this.templateForm);
      this.$emit('closeModal');
    },
    /**
     * Set the save as new flag
     * @param {Boolean} value
     * @returns {Boolean}
     */
     setSaveAsNew(value) {
      this.$store.dispatch('getRefreshToken');
      if (value === false) {
        this.saveAsNew = false;
      }
      else {
        this.saveAsNew = true;
        this.templateForm.name = value;
      }
    },
    /**
     * Close Open Modals
     */
    closeOpenModals() {
      this.displayUserAuthenticationModal = false;
      this.loadingTemplateEdit = false;
    }      
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  height: 78vh;
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-end {
  justify-content: flex-end;
}

.flex-item {
  flex: 1;
}

.radio-group {
  gap: 0.5em;
}

.modal-body {
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 2.5em;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.modal-header {
  padding: 40px 40px 0 40px !important;
}

.form-header {
  color: black;
}

.p-togglebutton {
  border-radius: 5px;
  border: 1px solid $deepgrey;
  background: white;
  color: $deepgrey;
  overflow: hidden;
}

.p-togglebutton :deep(.p-button-icon) {
  padding: 0;
}

.p-togglebutton.p-button.p-highlight {
  background-color: $deepgrey !important;
  color: white !important;
}

:deep(.p-togglebutton.p-focus) {
  box-shadow: none;
}

:deep(.p-button) .p-button-icon {
  padding: 0;
}

.error-text {
  color: $error;
  margin: 0 auto 0 0;
  padding-left: 40px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
  text-align: left;
}

.red-error-icon {
  min-width: 20px;
  background-color: $error;
  border-color: $error;
  color: white;
  border-radius: 50% !important;
  height: 20px;
  width: 20px;
  margin: auto;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.error-text-span {
  margin: auto;
  padding-left: 10px;
}

</style>