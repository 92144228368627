<template>
  <div>
    <div :class="['message', { 'dark': isDark }]">
      {{ text }}
    </div>
    <div
      v-if="editable"
      class="edit"
      @click="edit"
    >
      <p>Edit this message</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageItem',
  props: {
    text: {
      type: String,
      required: true
    },
    isDark: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  emits: [
    'edit'
  ],
  methods: {
    edit() {
      this.$emit('edit', {
        text: this.text
      });
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.message {
  background: $grey;
  border-radius: 10px;
  padding: 1em;
  width: fit-content;
}

.message.dark {
  background: $strongblue;
  color: white;
}

.edit {
  cursor: pointer;
  text-align: center;
}

.edit > p {
  text-decoration: underline;
}
</style>