<template>
  <div class="tag-container">
    <div
      v-if="params === 'never'"
      v-tooltip.top="'The product has never been counted'"
      class="tag never rounded-5"
    >
      Never
    </div>
    <div
      v-if="params === 'overdue'"
      v-tooltip.top="'The product is overdue for a count'"
      class="tag overdue rounded-5"
    >
      Overdue
    </div>
    <div
      v-if="params === 'counted'"
      v-tooltip.top="'The product has recently been counted'"
      class="tag counted rounded-5"
    >
      Counted
    </div>
  </div>
</template>

<script>
export default {
  name: "StatusTag",
  props: {
    params: {
      type: String,
      default: ''
    },
  }
}
</script>

<style lang="scss">
@import "../../assets/css/main.scss";

.tag-container {
  display: flex;
  align-items: center;
  height: 100%;
}

.tag {
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  font-weight: 600;
}

.never {
  background: $incorrectborder;
  color: white;
}

.overdue {
  background: $peach;
  color: $incorrectborder;
}

.counted {
  background: $darkgrey;
  color: white;
}

</style>