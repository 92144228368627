<template>
  <editor-content
    :editor="editor"
    class="editor"
  />
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Highlight from '@tiptap/extension-highlight'
import { markPasteRule } from '@tiptap/core'
import HardBreak from '@tiptap/extension-hard-break'

export default {
  name: 'TextEditor',
  components: {
    EditorContent
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    editable: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'update:modelValue'
  ],
  data() {
    return {
      editor: null,
      // Regex to apply on highlighting
      highlightRegex: /{First Name}|{Last Name}|{Pharmacy Name}|{Pharmacy Address}|{Pharmacy Number}/g
    }
  },
  computed: {
    inputModel() {
      return this.modelValue.replace(/\n/g, '<br>');
    }
  },
  watch: {
    // Watch the modelValue and update the editor content if it changes
    modelValue(value) {
      if (this.editor === null) {
        return;
      }

      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)
      // Replace new line with break tag
      value = value.replace(/\n/g, '<br>');

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false)
    },
  },
  mounted() {
    // Extend the highlight module to add custom input and paste rules
    const CustomHighlight = Highlight.extend({
      addPasteRules() {
        return [
          markPasteRule({
            find: /{First Name}|{Last Name}|{Pharmacy Name}|{Pharmacy Address}|{Pharmacy Number}/g,
            type: this.type,
          }),
        ]
      }
      // addInputRules() {
      //   return [
      //     markInputRule({
      //       find: /(?:\{(?:[^{}]+)\})$/,
      //       type: this.type,
      //     }),
      //     this.closedMarkInputRule({
      //       find: /(?:\{(?:[^{}]+)\})$/,
      //       type: this.type,
      //       getAttrs: true
      //     })
      //   ]
      // }
    });
    // Import the tiptap editor with extensions
    this.editor = new Editor({
      content: this.inputModel,
      extensions: [
        Document,
        Paragraph,
        Text,
        HardBreak,
        CustomHighlight.configure({
          inclusive: false,
          exitable: true
        })
      ],
      editable: this.editable,
      onUpdate: () => {
        this.$emit('update:modelValue', this.editor.getHTML());
      }
    })
  },
  beforeUnmount() {
    this.editor.destroy();
  },
  methods: {
    // Insert text into the editor
    insertText(text) {
      this.editor.commands.focus();
      this.editor.chain()
        .setMark("highlight")
        .insertContent(text.name)
        .unsetMark("highlight")
        .run();
    },
    // closedMarkInputRule(regexp, markType, getAttrs) {
    //   return new InputRule(regexp, (state, match, start, end) => {
    //     let attrs = getAttrs instanceof Function ? getAttrs(match) : getAttrs
    //     let tr = state.tr
    //     if (match[1]) {
    //       let textStart = start + match[0].indexOf(match[1])
    //       let textEnd = textStart + match[1].length
    //       if (textEnd < end) tr.delete(textEnd, end)
    //       if (textStart > start) tr.delete(start, textStart)
    //       end = start + match[1].length
    //     }
    //     tr.addMark(start, end, markType.create(attrs))
    //     tr.removeStoredMark(markType) // Do not continue with mark.
    //     return tr
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.editor {
  margin: 1em;
}

:deep(.tiptap) {
  height: 100%;
}

:deep(.ProseMirror:focus) {
  outline: none;
}

:deep(.tiptap) p {
  margin: 0;
  text-align: left;
  word-break: break-word;
}

</style>