<template>
  <div class="view">
    <div
      class="panel flex-expand flex-column"
    >
      <div class="page-header">
        <h1 class="flex-expand">
          60 Day Dispensing Impact
        </h1>
        <NavFunctions class="nav-functions" />
      </div>
      <!-- PAGE FILTERS -->
      <div class="filters-row">
        <!-- SELECTED UPTAKE PERCENTAGE -->
        <InputNumber
          v-model="selectedUptakePercentage"
          class="flex-item flex-expand"
          input-id="percent"
          suffix="%"
          :min="0"
          :max="100"
          placeholder="Enter Uptake Percentage"
        />
        <!-- CATEGORY SELECTION -->
        <Dropdown
          v-model="selectedCategory"
          class="flex-item flex-expand"
          :options="categories"
          option-label="value"
          placeholder="Select a Category"
          @change="getSixtyddCategories"
        />
        <!-- STATISTIC DROPDOWN -->
        <Dropdown
          v-model="selectedStatistic"
          class="flex-item flex-expand"
          :options="statistics"
          option-label="value"
          placeholder="Select a Statistic"
        />
        <div
          class="data-box"
          :class="selectedTranche ? `selected-box` : `unselected-box`"
        >
          <p>
            <strong>{{ selectedTranche ? selectedTranche.value : 'No Tranche Selected' }}</strong>
          </p>
        </div>
        <Button
          icon="pi pi-refresh"
          aria-label="Refresh"
          class="p-button-raised p-button-rounded"
          @click="reloadStatsAndCategories"
        />
        <!-- 
          Open the settings modal on click
        -->
        <i
          class="button pi-sliders-h pi"
          @click="openSettingsModal"
        ></i>
      </div>
      <!-- STATS SUMMARY -->
      <div
        class="overview-stats flex-expand"
        :style="loadingStatsData ? `gap: 10px;` : ``"
      >
        <!-- TOTAL PHARMACIES -->
        <div
          v-if="!loadingStatsData"
          class="overview_card"
        >
          <div class="stats-top-row">
            <p class="stats-heading">
              Number of Pharmacies
            </p>
          </div>
          <h3
            v-if="sixtyddStatsData"
            class="stats-value"
          >
            {{ Intl.NumberFormat().format(sixtyddStatsData.pharmacies_count) }}
          </h3>
        </div>
        <Skeleton
          v-else
          class="stats-skeleton"
        />
        <!-- BOTTOM LINE LIST -->
        <div
          v-if="!loadingStatsData"
          class="overview_card"
        >
          <div class="stats-top-row">
            <p class="stats-heading">
              Bottom Line Lost
            </p>
          </div>
          <h3
            v-if="sixtyddStatsData"
            class="stats-value"
          >
            {{ Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(sixtyddStatsData.revenue_lost) }}
          </h3>
        </div>
        <Skeleton
          v-else
          class="stats-skeleton"
        />
        <!-- SCRIPT MED SHORTAGES -->
        <div
          v-if="!loadingStatsData"
          class="overview_card"
        >
          <div class="stats-top-row">
            <p class="stats-heading">
              Script Med Shortages Affected
            </p>
          </div>
          <h3
            v-if="sixtyddStatsData"
            class="stats-value"
          >
            {{ Intl.NumberFormat().format(sixtyddStatsData.affected_shortage_scripts) }}
          </h3>
        </div>
        <Skeleton
          v-else
          class="stats-skeleton"
        />
        <!-- AFFECTED SCRIPTS -->
        <div
          v-if="!loadingStatsData"
          class="overview_card"
        >
          <div class="stats-top-row">
            <p class="stats-heading">
              Scripts Affected
            </p>
          </div>
          <h3
            v-if="sixtyddStatsData"
            class="stats-value"
          >
            {{ Intl.NumberFormat().format(sixtyddStatsData.affected_scripts) }}
          </h3>
        </div>
        <Skeleton
          v-else
          class="stats-skeleton"
        />
        <!-- SCRIPTS LOST -->
        <div
          v-if="!loadingStatsData"
          class="overview_card"
        >
          <div class="stats-top-row">
            <p class="stats-heading">
              Scripts Lost
            </p>
          </div>
          <h3
            v-if="sixtyddStatsData"
            class="stats-value"
          >
            {{ sixtyddStatsData.total_affected_scripts_lost_percent }}%
          </h3>
        </div>
        <Skeleton
          v-else
          class="stats-skeleton"
        />
        <!-- Scripts Affected -->
        <div
          v-if="!loadingStatsData"
          class="overview_card"
        >
          <div class="stats-top-row">
            <p class="stats-heading">
              Scripts Affected
            </p>
          </div>
          <h3
            v-if="sixtyddStatsData"
            class="stats-value"
          >
            {{ sixtyddStatsData.affected_scripts_percent }}%
          </h3>
        </div>
        <Skeleton
          v-else
          class="stats-skeleton"
        />
        <!-- Patients Affected -->
        <div
          v-if="!loadingStatsData"
          class="overview_card"
        >
          <div class="stats-top-row">
            <p class="stats-heading">
              Patients Affected
            </p>
          </div>
          <h3
            v-if="sixtyddStatsData"
            class="stats-value"
          >
            {{ sixtyddStatsData.affected_patients_percent }}%
          </h3>
        </div>
        <Skeleton
          v-else
          class="stats-skeleton"
        />
        <!-- Visits Lost -->
        <div
          v-if="!loadingStatsData"
          class="overview_card"
        >
          <div class="stats-top-row">
            <p class="stats-heading">
              Visits Lost
            </p>
          </div>
          <h3
            v-if="sixtyddStatsData"
            class="stats-value"
          >
            {{ sixtyddStatsData.visits_lost_percent }}%
          </h3>
        </div>
      </div>
      <!-- ADHERENCE TABLE -->
      <!-- TODO: Update Table to use v-for on returned data -->
      <!-- EXPORT ROW -->
      <div class="export">
        <span
          v-if="selectedCategory && selectedStatistic"
          class="key-container"
        >
          <span class="key-heading">{{ selectedCategory.value }} - {{ selectedStatistic.value }}</span>
        </span>
        <img
          v-if="!exportingCSV"
          :src="exportIconSrc"
          class="small-icon button flex-right"
          alt="Export Button"
          @click="exportCSV()"
        >
        <ProgressSpinner
          v-else
          class="small-icon flex-right"
          stroke-width="8"
          animation-duration=".75"
          fill="var(--surface-ground)"
          aria-label="Loading"
        />
      </div>
      <DataTable
        ref="dt"
        :value="sixtyddCategoriesData.data"
        table-class="revenue-table"
        striped-rows
        scrollable
        scroll-direction="both"
        :loading="loadingCategoriesData"
        lazy
        paginator
        sort-field="name"
        :sort-order="1"
        :total-records="sixtyddCategoriesData.total"
        :rows="sixtyddCategoriesData.per_page"
        @page="onPage($event)"
        @sort="onSort($event)"
      >
        <template #empty>
          No records found
        </template>
        <template #loading>
          Loading records, please wait...
        </template>
        <Column
          header="Pharmacy"
          field="name"
          style="width: 200px !important"
          :body-style="{height: '3.5rem !important'}"
          :header-style="{height:'4rem'}"
          exportable
          sortable
        />
        <Column
          header="State"
          field="state"
          :style="{width:'150px'}"
          :body-style="{height: '3.5rem !important'}"
          :header-style="{height:'4rem'}"
          exportable
          sortable
        />
        <Column
          header="Postcode"
          field="postcode"
          :style="{width:'150px'}"
          :body-style="{height: '3.5rem !important'}"
          :header-style="{height:'4rem'}"
          exportable
          sortable
        />
        <Column
          v-for="(col, index) in sixtyddCategoriesColumns"
          :key="index"
          :header="col"
          :style="{width:'200px'}"
          :body-style="{height: '3.5rem !important'}"
          :header-style="{height:'4rem'}"
          exportable
          sortable
          :sort-field="col"
        >
          <template #body="slotProps">
            {{ 
              slotProps.data && slotProps.data.categories && slotProps.data.categories[index] && selectedStatistic
                ? selectedStatistic.prefix + slotProps.data.categories[index][selectedStatistic.key] + selectedStatistic.suffix
                : ''
            }}
          </template>
        </Column>
      </DataTable>
    </div>
    <!-- <div class="right-col">
          <DataTable
            :value="categoryListData.best_categories.categories"
            :loading="!categoryListLoaded"
            class="no-borders"
          >
            <template #empty>
              No records found
            </template>
            <template #loading>
              Loading records, please wait...
            </template>
            <Column
              header="Subcategory"
              field="category"
            />
            <Column header="Adherence from SR AVG">
              <template #body="slotProps">
                <span
                  class="adherence-span"
                >
                  <img
                    :src="getRateIcon(slotProps.data.adherent_script_percent)"
                    class="extrasmall-icon"
                    alt="Rate Icon"
                  >
                  {{ unboundPercent(slotProps.data.adherent_script_percent) }}
                </span>
              </template>
            </Column>
            <Column header="Revenue">
              <template #body="slotProps">
                <span>
                  ${{ Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(slotProps.data.revenue_value) }}
                </span>
              </template>
            </Column>
            <ColumnGroup type="footer">
              <Row>
                <Column
                  footer="Total"
                  :colspan="2"
                />
                <Column>
                  <template #footer>
                    <span>
                      ${{ categoryListData.best_categories.total ? Intl.NumberFormat("en", { maximumFractionDigits: 2 }).format(categoryListData.best_categories.total) : 0 }}
                    </span>
                  </template>
                </Column>
              </Row>
            </ColumnGroup>
          </DataTable>
          <GoogleMapLoader
            v-if="$store.getters.getMapsApiLoaded == true"
            :map-config="mapConfig"
            style="height: 400px;"
          />
        </div> -->
    <!-- </div> -->
    <!-- </div> -->
  </div>
  <SixtyDaySettingsModal
    v-if="displaySettingsModal"
    :selected-uptake-percentage="selectedUptakePercentage"
    :statistics="statistics"
    :selected-statistic="selectedStatistic"
    :tranches="tranches"
    :selected-tranche="selectedTranche"
    :categories="categories"
    :selected-category="selectedCategory"
    :states="states"
    :selected-states="selectedStates"
    :locations="locations"
    :selected-locations="selectedLocations"
    :selected-pharmacies="selectedPharmacies"
    @close-modal="closeModal"
    @update-filters="updateFilters"
  />
  <EulaModal
    v-if="displayEulaModal"
    @close-eula-modal="closeEulaModal"
  />
</template>

<script>
import NavFunctions from '@/components/navigation/nav-menu-dropdown.vue'
// import GoogleMapLoader from '@/components/maps/google-map-loader.vue'
import SixtyDaySettingsModal from '@/components/modals/sixty-day-settings-modal.vue';
import EulaModal from '@/components/modals/eula-modal.vue'
import axios from 'axios';

export default {
  components: {
    NavFunctions,
    // GoogleMapLoader,
    SixtyDaySettingsModal,
    EulaModal
  },
  data() {
    return {
        // Configuration for the Google Map
        mapConfig: {
          center: {lat: -25.274, lng: 133.775},
          zoom: 4,
          mapId: '6b605c7f8200889e'
        },
        // SVG Image Imports
        exportIconSrc: require('@/assets/icons/export_icon.svg'),
        // Filters
        selectedUptakePercentage: 63,
        statistics: [
          {
            "key": "affected_patients",
            "value": "Affected Patients",
            "prefix": "",
            "suffix": ""
          },
          {
            "key": "affected_patients_percent",
            "value": "Affected Patients (% of Total Affected Patients)",
            "prefix": "",
            "suffix": "%"
          },
          {
            "key": "male_patients_percent",
            "value": "Male",
            "prefix": "",
            "suffix": "%"
          },
          {
            "key": "female_patients_percent",
            "value": "Female",
            "prefix": "",
            "suffix": "%"
          },
          {
            "key": "affected_scripts",
            "value": "Affected Scripts",
            "prefix": "",
            "suffix": ""
          },
          {
            "key": "affected_scripts_percent",
            "value": "Affected Scripts (% of Total Affected Scripts)",
            "prefix": "",
            "suffix": "%"
          },
          {
            "key": "affected_revenue",
            "value": "Bottom Line Affected",
            "prefix": "$",
            "suffix": ""
          },
          {
            "key": "affected_visits",
            "value": "Visits Affected",
            "prefix": "",
            "suffix": ""
          },
          {
            "key": "scripts_lost",
            "value": "Scripts Lost",
            "prefix": "",
            "suffix": ""
          },
          {
            "key": "lost_affected_scripts_percent",
            "value": "Scripts Lost (% of Total Affected Scripts)",
            "prefix": "",
            "suffix": "%"
          },
          {
            "key": "revenue_lost",
            "value": "Bottom Line Lost",
            "prefix": "$",
            "suffix": ""
          },
          {
            "key": "visits_lost",
            "value": "Visits Lost",
            "prefix": "",
            "suffix": ""
          },
          {
            "key": "shortage_patients",
            "value": "Medication Shortages (patients)",
            "prefix": "",
            "suffix": ""
          },
          {
            "key": "shortage_scripts",
            "value": "Medication Shortages (scripts)",
            "prefix": "",
            "suffix": ""
          },
        ],
        selectedStatistic: {
          "key": "revenue_lost",
          "value": "Bottom Line Lost",
          "prefix": "$",
          "suffix": ""
        },
        tranches: [
          {
            "key": "all",
            "value": "All Molecules"
          },
          {
            "key": "1",
            "value": "Tranche 1"
          }
        ],
        selectedTranche: {
          "key": "all",
          "value": "All Molecules"
        },
        categories: [
          {
            "key": "atc_lvl2",
            "value": "Condition"
          },
          {
            "key": "age",
            "value": "Age Group"
          },
          // {
          //   "key": "sex",
          //   "value": "Sex"
          // },
          {
            "key": "mp_pt",
            "value": "Molecule"
          }
        ],
        selectedCategory: {
          "key": "atc_lvl2",
          "value": "Condition"
        },
        selectedCol: 'name',
        page: 0,
        sortDirection: 1,
        pharmacyInfoError: '',
        pharmacyInfoLoaded: false,
        locations: [],
        selectedLocations: [],
        selectedPharmacies: [],
        sixtyddInfoError: '',
        loadingSixtyddInfo: false,
        selectedStates: [
          'NSW',
          'QLD',
          'VIC',
          'SA',
          'WA',
          'TAS',
          'NT',
          'ACT'
        ],
        sixtyddStatsData: {},
        sixtyddStatsError: '',
        loadingStatsData: false,
        sixtyddCategoriesData: {
          sort_direction: 1,
          col: 'name',
          current_page: 1,
          page_count: 1,
          data: []
        },
        sixtyddCategoriesColumns: [],
        sixtyddCategoriesError: '',
        loadingCategoriesData: false,
        exportingCSV: false,
        exportCSVError: '',
        opportunityData: {},
        columnData: [],
        categoryListError: '',
        categoryListLoaded: false,
        adherenceAdjustment: false,
        pricePerScriptAdjustment: false,
        displaySettingsModal: false,
        displayEulaModal: false,
    }
  },
  computed: {
    /**
     * Returns the payload for the sixtydd info request
     * @returns {Object} sixtyddInfoPayload
     */
    sixtyddInfoPayload() {
      return {
        "tranche": this.selectedTranche.key,
      }
    },
    /**
     * Returns the payload for the sixtydd stats request
     * @returns {Object} sixtyddStatsPayload
     */
    sixtyddStatsPayload() {
      return {
        "uptake_percentage": this.selectedUptakePercentage,
        "pharmacies": this.selectedPharmacies.map(pharmacy => pharmacy.uuid),
        "tranche": this.selectedTranche.key,
      }
    },
    /**
     * Returns the payload for the sixtydd categories request
     * @returns {Object} sixtyddCategoriesPayload
     */
     sixtyddCategoriesPayload() {
      return {
        "sort_direction": this.sortDirection == -1 ? 'desc' : 'asc',
        "col": this.selectedCol,
        "page": this.page,
        "uptake_percentage": this.selectedUptakePercentage,
        "pharmacies": this.selectedPharmacies.map(pharmacy => pharmacy.uuid),
        "category": this.selectedCategory.key,
        "selected_statistic": this.selectedStatistic.key,
        "tranche": this.selectedTranche.key,
      }
    }
  },
  /**
   * On mount, get the pharmacy info and the pharmacy stats
   */
  async mounted() {
    // this.initMap();
    let eula = localStorage.getItem('portal_eula');
    if (!eula) {
      this.displayEulaModal = true;
    } else {
      await this.initSixtyddInfo();
    }
  },
  methods: {
    initMap() {
      this.map = new this.google.maps.Map(document.getElementById('map'), {
        center: {lat: -25.719, lng: 134.516},
        zoom: 1,
        mapId: '6b605c7f8200889e' // A map ID using a style with one or more feature layers enabled.
      });
    },
    /**
     * Reload the stats and categories
     */
     async reloadStatsAndCategories() {
      await this.getSixtyddStats();
      await this.getSixtyddCategories();
    },
    /**
     * Initialize the sixty dd info for the company
     * @returns {Promise} - The promise for the company/sixtydd/info request
     */
    async initSixtyddInfo() {
      this.$store.dispatch('getRefreshToken');
      this.sixtyddInfoError = '';
      this.loadingSixtyddInfo = true;
      this.loadingStatsData = true;
      this.loadingCategoriesData = true;

      axios.post('/api/company/sixtyday/info', this.sixtyddInfoPayload, this.$store.state.header)
      .then(res => {
        this.handleSixtyddInfoSuccess(res);
        this.getSixtyddStats();
        this.getSixtyddCategories();
      }).catch(err => {
        this.handleSixtyddInfoError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the sixtydd info success case
     * @param {Object} res
     */
    handleSixtyddInfoSuccess(res) {
      console.log('The sixtydd info response is', res.data);
      this.loadingSixtyddInfo = false;
      this.locations = res.data;
      this.selectedPharmacies = [];
      // Push each of the locations into selected locations
      this.locations.forEach(location => {
        this.selectedLocations.push(location);
        this.selectedPharmacies.push(...location.locations);
      });
    },
    /**
     * Handle the sixtydd info error case
     * @param {Object} err
     */
    handleSixtyddInfoError(err) {
      console.log('The sixtydd info error is', err);
      this.loadingSixtyddInfo = false;
      this.sixtyddInfoError = err;
    },
    /**
     * Get the Sixty dd stats for the company
     * @returns {Promise} - The promise for the company/sixtydd/stats request
     */
    async getSixtyddStats() {
      this.$store.dispatch('getRefreshToken');
      this.sixtyddStatsData = {};
      this.sixtyddStatsError = '';
      this.loadingStatsData = true;

      axios.post('/api/company/sixtyday/stats', this.sixtyddStatsPayload, this.$store.state.header)
      .then(res => {
        this.handleSixtyddStatsSuccess(res);
      }).catch(err => {
        this.handleSixtyddStatsError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the sixtydd stats success case
     * @param {Object} res
     */
    handleSixtyddStatsSuccess(res) {
      console.log('The sixtydd stats response is', res.data);
      this.loadingStatsData = false;
      this.sixtyddStatsData = res.data;
    },
    /**
     * Handle the sixtydd stats error case
     * @param {Object} err
     */
    handleSixtyddStatsError(err) {
      console.log('The sixtydd stats error is', err);
      this.loadingStatsData = false;
      this.sixtyddStatsError = err;
    },
    /**
     * Get the Sixty dd categories for the company
     * @returns {Promise} - The promise for the company/sixtydd/categories request
     */
     async getSixtyddCategories() {
      this.$store.dispatch('getRefreshToken');
      this.sixtyddCategoriesData.data = [];
      this.sixtyddCategoriesColumns = [];
      this.sixtyddCategoriesError = '';
      this.loadingCategoriesData = true;

      axios.post('/api/company/sixtyday/categories', this.sixtyddCategoriesPayload, this.$store.state.header)
      .then(res => {
        this.handleSixtyddCategoriesSuccess(res);
      }).catch(err => {
        this.handleSixtyddCategoriesError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the sixtydd categories success case
     * @param {Object} res
     */
    handleSixtyddCategoriesSuccess(res) {
      console.log('The sixtydd categories response is', res.data);
      this.loadingCategoriesData = false;
      this.sixtyddCategoriesData = res.data;
      this.sixtyddCategoriesColumns = res.data.columns;
    },
    /**
     * Handle the sixtydd categories error case
     * @param {Object} err
     */
    handleSixtyddCategoriesError(err) {
      console.log('The sixtydd categories error is', err);
      this.loadingCategoriesData = false;
      this.sixtyddCategoriesError = err;
    },
    /**
     * Open the settings modal
     */
    openSettingsModal() {
      this.$store.dispatch('getRefreshToken');
      this.displaySettingsModal = true;
    },
    /**
     * Export the category list date to a CSV for the company
     * @returns {Promise} - The promise for the enterprise-pharmacy-table-csv request
     */
    async exportCSV() {
      this.exportingCSV = true;
      this.$store.dispatch('getRefreshToken');
      console.log('Exporting Data');
      try {
        const res = await axios.post('/api/company/sixtyday/categories-export', this.sixtyddCategoriesPayload, this.$store.state.header);
        this.handleExportSuccess(res);
      } catch (err) {
        this.handleExportError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the export CSV success case
     * @param {Object} res
     */
     handleExportSuccess(res) {
      console.log('The export csv response is', res.data);
      // Get the CSV data and download it
      let csv = res.data;
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = `${new Date().getFullYear()}_${this.selectedCategory.value}.csv`;
      anchor.click();
      this.exportingCSV = false;
    },
    /**
     * Handle the export CSV error case
     * @param {Object} err
     */
     handleExportError(err) {
      console.log('The export csv response is', err);
      this.exportingCSV = false;
      this.exportCSVError = err;
    },
    /**
     * Calculate a percentage from a decimal, ensuring that the decimal is between 0 and 1
     * @param {Number} decimal 
     */
    boundPercent(decimal) {
      if (!decimal || Number.isNaN(decimal)) {
        return '';
      }
      if (decimal <= 1 && decimal >= 0) {
        return (decimal * 100).toFixed(2) + "%"
      }
      return 'Invalid decimal';
    },
    /**
     * Calculate a percentage from a decimal
     * @param {Number} decimal 
     */
    unboundPercent(decimal) {
      if (!decimal || Number.isNaN(decimal)) {
        return '0%';
      }
      return (decimal * 100).toFixed(2) + "%";
    },
    /**
     * Update the filters when the user confirms them in the settings modal
     * @param {Object} value 
     */
    updateFilters(value) {
      this.selectedUptakePercentage = value.selectedUptakePercentage;
      this.selectedStatistic = value.selectedStatistic;
      this.selectedCategory = value.selectedCategory;
      this.selectedStates = value.selectedStates;
      this.selectedLocations = value.selectedLocations;
      this.selectedPharmacies = value.selectedPharmacies;
      // If the tranche has updated then init info again
      if (value.selectedTranche !== this.selectedTranche) {
        this.selectedTranche = value.selectedTranche;
        this.initSixtyddInfo();
      }
      // If the tranche hasnt updated then just reload stats and categories
      else {
        this.reloadStatsAndCategories();
      }
    },
    /**
     * Close the settings modal
     */
    closeModal() {
      this.displaySettingsModal = false;
    },
    /**
     * Close the EULA Modal
     */
    closeEulaModal() {
      this.displayEulaModal = false;
      this.initSixtyddInfo();
    },
    // Event to handle sort event
    onSort(event) {
      this.selectedCol = event.sortField;
      this.sortDirection = event.sortOrder;
      this.getSixtyddCategories();
    },
    // Event to handle table page change
    onPage(event) {
      this.page = event.page;
      this.getSixtyddCategories();
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/main.scss";

.full-container {
  display: grid;
  column-gap: 40px;
  height: 100%;
}

.grid-container {
  display: grid;
  column-gap: 40px;
  height: 100%;
}

.left-col {
  grid-column-start: 1;
  grid-column-end: 2;
}

.right-col {
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filters-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  margin-bottom: 1em;
  gap: 20px;
}

.category-combobox {
  flex: 1 1 300px;
}

.overview-stats {
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  border-radius: 12px;
  height: 70px;
  margin-bottom: 1em;
}

.overview_card {
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  justify-content: center;
  height: 70px;
}

.stats-value {
  margin: 5px;
}

.stats-top-row {
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
  padding: 0 5px;
  flex: 1;
}

.stats-heading {
  font-size: 12px;
  font-weight: 600;
  opacity: 0.7;
  margin: 0;
  flex: 1;
}

.stats-percentage {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.overview_card + .overview_card {
  border-left: solid 2px $bordergrey;
}

.p-datatable :deep(.p-column-title) {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.p-datatable-wrapper) {
  border: 1px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border: 1px solid $bordergrey;
}

:deep(.p-datatable-table) {
  table-layout: fixed;
}

.export {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  gap: 25px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: $grey;
  border: 1px solid $bordergrey;
  border-bottom: none;
}

.positive-rate {
  color: green;
}

.negative-rate {
  color: red;
}

.neutral-rate {
  color: grey;
}

.key-container {
  display: flex;
  gap: 1.5em;
}

.key-icon {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: inline-block;
}

.key-heading {
  font-size: 11pt;
  font-weight: 600;
  color: $deepgrey;
}

.key-text {
  font-size: 11pt;
  font-weight: 500;
  color: $deepgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.p-progress-spinner {
  margin: 0 0 0 auto;
}

th {
  height: 4rem !important;
}

.table-main-header {
  font-size: 13pt;
  font-weight: 600 !important;
  color: black !important;
}

.table-sub-header {
  font-size: 12pt;
  font-weight: 500 !important;
  color: $deepgrey !important;
  height: 4rem;
}

.totals-row {
  background-color: $phoneorders !important;
  color: white !important;
  font-size: 13pt;
}

.average-row {
  background-color: $babyblue !important;
  color: $phoneorders !important;
  font-size: 13pt;
}

.adherence-span {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 60px;
  height: 60px;
  margin: 10px 0 10px 0;

  h1 {
    margin: 0;
  }
}

.nav-functions {
  align-self: flex-start;
  z-index: 3;
}

.table-footer {
  display: flex;
  align-items: center;
}

.flex-right {
  margin-left: auto;
}

.p-datatable th[class*="align-"] .p-column-header-content {
  display: inline-flex ;
}

.no-borders * {
  border : 0px !important;
}

.align-center {
  text-align: center !important;
}

.p-tabview {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
}

.p-tabview-panels {
  padding: 0 !important;
}

.p-tabview-nav-link {
  justify-content: center;
  background: $babyblue !important;
  height: 60px;
}

.p-tabview-header {
  flex: 1;
  height: 58px;
}

.opp-calc {
  background-color: $babyblue;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 1em;
  gap: 8px;
}

.calc-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.opp-calc h3 {
  font-size: 14pt;
  color: $phoneorders;
}

.opp-calc h4 {
  font-size: 12pt;
  color: $phoneorders;
  margin: 0.5em 0 0.67em 0;
}

.adjustment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.adjustment .p-togglebutton {
  flex: 1 1 100px;
}

.input-span {
  flex: 3 1 100px;
}

.input-span .p-inputtext {
  width: 100%;
}

.p-inputtext,
.p-selectbutton,
.p-password-input,
.p-togglebutton {
  border-radius: 5px;
  border: 1px solid $phoneorders;
  overflow: hidden;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

.flex-expand {
  flex: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.output-calc {
  background-color: white;
  border-radius: 6px;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.5em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2em;
}

.opportunity-left-col {
  grid-column: 1 / 2;
}

.opportunity-right-col {
  grid-column: 2 / 3;
}

.opportunity-heading {
  padding: 0.5em 1em 0 0.5em;;
}

.opportunity-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 3.1875rem;
}

.data-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.arrow {
  justify-content: flex-start;
}

.potential-container {
  background-color: $phoneorders;
  border-radius: 6px;
  padding: 0.5em 1em 1em 0.5em;
}

.current-container {
  padding: 0.5em 1em 1em 0.5em;
}

.output-current-data {
  font-size: 20pt;
  color: $phoneorders;
  margin: 0;
}

.output-current-label {
  font-size: 11pt;
  color: $phoneorders;
  opacity: 0.75;
  margin: 0;
}

.output-potential-data {
  font-size: 20pt;
  color: white;
  margin: 0;
}

.output-potential-label {
  font-size: 11pt;
  color: white;
  opacity: 0.75;
  margin: 0;
}

.output-skeleton {
  height: 50px !important;
}

.stats-skeleton {
  height: 70px !important;
}
</style>
