import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/views/login-page.vue';
import Financials from '@/views/financials-page.vue'
import CampaignsAndMarketing from '@/views/campaigns-marketing-page.vue'
import GuildSixtyDayDispensing from '@/views/guild-sixty-day-page.vue'
import SixtyDayDispensing from '@/views/sixty-day-page.vue'
import Settings from '@/views/settings-page.vue'
import Calculator from '@/views/pharmacy-guild-signup-page.vue'
import ClinicalTrialConsentForm from '@/views/clinical-trial-consent-form-page.vue'
import clinicalTrialReferralDashboard from '@/views/clinical-trial-referral-dashboard.vue';
import UnsubscribeEmailPage from '@/views/unsubscribe-email-page.vue';
import S8Management from '@/views/s8-management-page.vue'
import SupplierAdDashboard from '@/views/supplier-ad-dashboard.vue'
// import PharmaPrograms from '@/views/pharmaprograms-signup-page.vue'
import PasswordReset from '@/views/password-reset-page.vue'
import PathNotFound from '@/views/404-page.vue'
import DownloadFilePage from '@/views/download-file-page.vue';

// Define routes
const routes = [
  {
    path: '/financials',
    name: 'Financials',
    alias: '/',
    component: Financials,
    meta: {
        guest: false,
        requiresAuth: true,
        title: 'Dashboard - StrongRoom AI'
    },
    beforeEnter: [dataAnalytics]
  },
  {
    path: '/campaigns',
    name: 'Campaigns and Marketing',
    alias: '/',
    component: CampaignsAndMarketing,
    meta: {
        guest: false,
        requiresAuth: true,
        title: 'Campaigns and Marketing - StrongRoom AI'
    },
    beforeEnter: [dataAnalytics]
  },
  {
    path: '/guildsixtydd',
    name: 'Guild Sixty Day Dispensing',
    alias: '/',
    component: GuildSixtyDayDispensing,
    meta: {
        guest: false,
        requiresAuth: true,
        title: 'Guild Sixty Day Dispensing - StrongRoom AI'
    },
    beforeEnter: [guildLogin]
  },
  {
    path: '/referrals',
    name: 'Referrals Dashboard',
    alias: '/',
    component: clinicalTrialReferralDashboard,
    meta: {
        guest: false,
        requiresAuth: true,
        title: 'Clinical Trials Referrals Dashboard - StrongRoom AI'
    },
    beforeEnter: [clinicalTrialsLogin]
  },
  {
    path: '/sixtydd',
    name: 'Sixty Day Dispensing',
    alias: '/',
    component: SixtyDayDispensing,
    meta: {
        guest: false,
        requiresAuth: true,
        title: 'Sixty Day Dispensing - StrongRoom AI'
    },
    beforeEnter: [sixtyDaySignup]
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
        guest: false,
        requiresAuth: true,
        title: 'Settings - StrongRoom AI'
    },
    beforeEnter: [srLogin]
  },
  {
    path: '/calculator',
    name: 'Calculator',
    component: Calculator,
    meta: {
        guest: true,
        title: 'Pharmacy Guild Signup - StrongRoom AI'
    }
  },
  {
    path: '/clinicaltrial/:id',
    name: 'Clinical Trials Consent Form',
    component: ClinicalTrialConsentForm,
    meta: {
        guest: true,
        title: 'Clinical Trials Consent Form - StrongRoom AI'
    }
  },
  {
    path: '/unsubscribe/:id',
    name: 'Unsubscribe',
    component: UnsubscribeEmailPage,
    meta: {
        guest: true,
        title: 'Unsubscribe - StrongRoom AI'
    }
  },
  {
    path: '/download-file/:id',
    name: 'Download File',
    component: DownloadFilePage,
    meta: {
        guest: true,
        title: 'Download - StrongRoom AI'
    }
  },
  {
    path: '/s8management',
    name: 'S8 Management',
    component: S8Management,
    meta: {
      guest: false,
      requiresAuth: true,
      title: 'S8 Management - StrongRoom AI'
    },
    beforeEnter: [ecdr]
  },
  {
    path: '/analytics',
    name: 'Analytics Dashboard',
    component: SupplierAdDashboard,
    meta: {
      guest: false,
      requiresAuth: true,
      title: 'Analytics Dashboard - StrongRoom AI'
    },
    beforeEnter: [analyticsLogin]
  },
  // {
  //   path: '/pharmaprograms',
  //   name: 'PharmaPrograms',
  //   component: PharmaPrograms,
  //   meta: {
  //     guest: true,
  //     title: 'PharmaPrograms Signup - StrongRoom AI'
  //   }
  // },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      guest: true,
      title: 'Login - StrongRoom AI'
    }
  },
  {
    path: '/reset-password/:id',
    name: 'Password Reset',
    component: PasswordReset,
    meta: {
      guest: true,
      requiresAuth: false,
      title: 'Password Reset - StrongRoom AI'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: PathNotFound,
    meta: {
      title: 'Page Not Found - StrongRoom AI'
    }
  },
];

function srLogin() {
  let sr_login = JSON.parse(localStorage.getItem('sr_login') ?? false);
  if (!sr_login) {
    console.log('Invalid Permissions');
    return false;
  }
}

function guildLogin() {
  let guild_login = JSON.parse(localStorage.getItem('guild_login') ?? false);
  if (!guild_login) {
    console.log('Invalid Permissions');
    return false;
  }
}

function clinicalTrialsLogin() {
  let clinical_trials_login = JSON.parse(localStorage.getItem('clinical_trials_login') ?? false);
  if (!clinical_trials_login) {
    console.log('Invalid Permissions');
    return false;
  }
}

function sixtyDaySignup() {
  let sixty_day_signup = JSON.parse(localStorage.getItem('sixty_day_signup') ?? false);
  if (!sixty_day_signup) {
    console.log('Invalid Permissions');
    return false;
  }
}

function dataAnalytics() {
  let data_analytics = JSON.parse(localStorage.getItem('data_analytics') ?? false);
  if (!data_analytics) {
    console.log('Invalid Permissions');
    return false;
  }
}

function ecdr() {
  let ecdr = JSON.parse(localStorage.getItem('ecdr') ?? false);
  if (!ecdr) {
    console.log('Invalid Permissions');
    return false;
  }
}

function analyticsLogin() {
  let supplier_login = JSON.parse(localStorage.getItem('supplier_analytics') ?? false);
  let ad_login = JSON.parse(localStorage.getItem('ad_analytics') ?? false);
  if (!supplier_login && !ad_login) {
    console.log('Invalid Permissions');
    return false;
  }
}

// Get country code from environment variables and set base URL
let countryCode = process.env.VUE_APP_COUNTRY_CODE ? process.env.VUE_APP_COUNTRY_CODE : 'AU';
let baseURL = '/';
if (countryCode !== 'AU') {
  baseURL = '/en_' + countryCode;
}

// Create router instance
const router = createRouter({
  history: createWebHistory(baseURL),
  routes,
});

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    localStorage.setItem('prev', JSON.stringify(from.path));
    console.log('this is the before each route being used', !('; '+document.cookie).split(`; auth=`).pop().split(';')[0]);
    // Check if the route requires auth - if not present redirect to login
    if (to.name != 'login' && to.meta.requiresAuth && !('; '+document.cookie).split(`; auth=`).pop().split(';')[0]) {
        next({ path: '/login' })
    }
    // Continue to route if auth cookie is present
    else {
      next()
    }
});

export default router;
