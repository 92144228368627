<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        :class="requestTypeLocal==='Save' || (templatesToBeUdpated.length > 2 || campaignsToBeUdpated.length > 2) ? 'large-modal-container' : (templatesToBeUdpated.length > 0 || campaignsToBeUdpated.length > 0) ? 'medium-modal-container' : ''"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              {{ requestTypeLocal }} {{ payloadTypeLocal==='campaign' ? 'Campaign' : 'Template' }}
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <!-- INFORMATION -->
            <div class="authentication-info">
              <p v-if="requestTypeLocal==='Activate' || requestTypeLocal==='Deactivate'">
                Are you sure you want to <span class="authentication-highlight"> {{ requestTypeLocal }} {{ campaignOrTemplateName }} </span> to selected pharmacies?
              </p>
              <p v-else-if="requestTypeLocal==='Delete'">
                Are you sure you want to <span class="authentication-highlight"> {{ requestTypeLocal }} {{ campaignOrTemplateName }} </span>?
              </p>
              <p v-else>
                <span class="authentication-highlight"> Save Changes</span> to <span class="authentication-highlight">{{ campaignOrTemplateName }}</span> OR <span :class="'authentication-highlight'">Create New {{ payloadTypeLocal==='campaign' ? 'Campaign' : 'Template' }}</span>?
              </p>
              <p v-if="templatesToBeUdpated.length > 0 || campaignsToBeUdpated.length > 0">
                <span class="authentication-highlight">Note:</span> This will {{ requestTypeLocal==='Activate' ? 'activate' : 'deactivate' }} the following {{ payloadTypeLocal==='campaign' ? 'Templates' : 'Campaigns' }}:
                <ul>
                  <li
                    v-for="(update, index) in payloadTypeLocal==='campaign' ? templatesToBeUdpated : campaignsToBeUdpated"
                    :key="index"
                  >
                    {{ update }}
                  </li>
                </ul>
              </p>
              <p v-if="requestTypeLocal==='Delete'">
                This will permanently remove this campaign from any attached Pharmacies and the Head Office dashboard.
              </p>
              <p class="authentication-highlight">
                You cannot undo this action.
              </p>
              <p>Closing this module will not complete any actions.</p>
            </div>
            <!-- SAVE OPTIONS -->
            <div
              v-if="requestTypeLocal==='Save'"
              class="form-row"
            >
              <div class="flex-input">
                <!-- SAVE / CREATE AS NEW CAMPAIGN/TEMPLATE OPTION-->
                <SelectButton
                  v-model="saveTypeSelection"
                  :options="saveTypeOptions"
                  option-label="key"
                  option-value="value"
                  :allow-empty="false"
                /> 
              </div>
            </div>
            <div
              v-if="saveTypeSelection==='new'"
              class="form-row"
            >
              <!-- SAVE / CREATE AS NEW CAMPAIGN OPTION-->
              <div 
                v-if="payloadTypeLocal==='campaign'"
                class="flex-input flex-item"
              >
                <label
                  for="authentication-campaign-name"
                >Campaign Name</label>
                <InputText
                  id="authentication-campaign-name"
                  v-model="newCampaignName"
                  placeholder="Campaign Name"
                  class="grey-input"
                /> 
              </div>
              <!-- SAVE / CREATE AS NEW TEMPLATE OPTION-->
              <div 
                v-else
                class="flex-input flex-item"
              >
                <label
                  for="authentication-template-name"
                >Template Name</label>
                <InputText
                  id="authentication-template-name"
                  v-model="newTemplateName"
                  placeholder="Template Name"
                  class="grey-input"
                /> 
              </div>
            </div>
            <div class="form-row">
              <!-- USERNAME -->
              <div class="flex-input flex-item">
                <InputText
                  id="authentication-username"
                  ref="username"
                  v-model="loginForm.name"
                  placeholder="Username"
                  class="grey-input"
                />
              </div>
              <!-- PASSWORD -->
              <div class="flex-input flex-item">
                <InputText
                  id="authentication-password"
                  v-model="loginForm.password"
                  type="password"
                  placeholder="Password"
                  class="grey-input"
                  @keyup.enter="submitLogin"
                />
              </div>
            </div>
            <!-- BUTTONS -->
            <div class="form-row">
              <div class="flex-input flex-item">
                <div
                  class="grey-button cancel-button"
                  @click="closeModal"
                >
                  Cancel
                </div>
              </div>
              <div class="flex-input flex-item">
                <div
                  class="black-button"
                  style="text-align: center;"
                  @click="submitLogin"
                >
                  {{ !loadingLogin ? requestTypeLocal : 'Logging in...' }}
                </div>
              </div>
            </div>
            <!-- NOTE -->
            <div
              v-if="(loginError && loginError !=='') || (userError && userError !=='')"
              class="error-text"
            >
              <span
                class="red-error-icon"
              >!</span>
              <span class="error-text-span">{{ loginError }}</span>
              <span class="error-text-span">{{ userError }}</span>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import axios from 'axios';

export default {
  name: 'CampaignTemplatesUserAuthentication',
  components: {
    InputText
  },
  props: {
    /**
     * The payload attached to the campaign
     * @type {Array}
     */
    name: {
      type: String,
      default() {
        return ''
      },
    },
    /**
     * The payloadType attached to the campaign
     * @type {String}
     */
    payloadType: {
      type: String,
      default() {
        return ''
      },
    },
    /**
     * The request type to activate/deactivate/save/delete the campaign
     * @type {String}
     */
    requestType: {
      type: String,
      default() {
        return ''
      },
    },
    /**
     * The campaign/template updates
     * @type {Array}
     */
    updates: {
      type: Array,
      default() {
        return []
      }
    }
  },
  emits: [
    'closeModal',
    'loginSuccess',
    'saveAsNew',
  ],
  data() {
    return {
      campaignOrTemplateName: '',
      loginForm: {
        name: '',
        password: ''
      },
      loginError: '',
      loadingLogin: false,
      userError: '',
      payloadTypeLocal: '',
      requestTypeLocal : '',
      saveTypeSelection: 'save',
      saveTypeOptions: [
        { key: 'Save Changes', value: 'save' },
        { key: 'New', value: 'new' }
      ],
      newCampaignName: '',
      newTemplateName: '',
      templatesToBeUdpated: [],
      campaignsToBeUdpated: [],
    }
  },
  mounted() {
    this.campaignOrTemplateName = this.name;
    this.requestTypeLocal = this.requestType;
    this.payloadTypeLocal = this.payloadType;
    if (this.updates.length > 0 && this.payloadTypeLocal === 'campaign') {
      this.templatesToBeUdpated = this.updates;
    }
    else if (this.updates.length > 0 && this.payloadTypeLocal === 'template') {
      this.campaignsToBeUdpated = this.updates;
    }
    // Autofocus username
    this.$refs.username.$el.focus();
  },
  methods: {
    /**
     * Submit the user login credentials to the application
     */
    async submitLogin() {
      this.loginError = '';
      this.loadingLogin = true;
      if (!this.loginForm.name.trim() || !this.loginForm.password.trim()) {
        this.userError = 'Username and password are required.';
        this.loadingLogin = false;
        return;
      }
      // Validation for new campaign/template name
      if (this.requestTypeLocal === 'Save' && this.saveTypeSelection === 'new') {
        if (this.payloadTypeLocal === 'campaign' && !this.newCampaignName.trim()) {
          this.userError = 'Campaign name is required.';
          this.loadingLogin = false;
          return;
        }
        if (this.payloadTypeLocal === 'template' && !this.newTemplateName.trim()) {
          this.userError = 'Template name is required.';
          this.loadingLogin = false;
          return;
        }
      }

      this.userError = '';

      try {
        // Call the Enterprise Login endpoint
        const res = await axios.post('/api/confirm-enterprise-login', this.loginForm, this.$store.state.header);
        this.handleLoginSuccess(res);
      } catch (err) {
        this.handleLoginError(err);
      }
    },
    /**
     * Handle the login success
     * @param {Object} data
     */
    handleLoginSuccess(res) {
      // Reset the login count and set access_token
      this.loadingLogin = false;
      console.log(res);

      // If the request type is save, emit the saveAsNew event
      if (this.requestTypeLocal === 'Save' && this.saveTypeSelection === 'save') {
        this.$emit('saveAsNew', false);
      }
      else if (this.requestTypeLocal === 'Save' && this.saveTypeSelection === 'new') {
        if (this.payloadTypeLocal === 'campaign') {
          this.$emit('saveAsNew', this.newCampaignName);
        }
        else {
          this.$emit('saveAsNew', this.newTemplateName);
        }
      }
      this.$emit('loginSuccess');
      this.$emit('closeModal');
    },
    /**
     * Handle the login errors
     * @param {Object} err
     */
    handleLoginError(err) {
      // Handle the login errors
      console.log('enterprise login err is', err);
      this.loadingLogin = false;
      if (err.message.split(" ").includes('403')) {
        this.loginError = err.response && err.response.data ? err.response.data : "Incorrect username or password.";
        this.loginCount = localStorage.getItem('loginCount') ? Number(JSON.parse(localStorage.getItem('loginCount'))) + 1 : 1;
        localStorage.setItem('loginCount', this.loginCount);
      } else {
        if (err.response && err.response.data) {
          this.loginError = err.response.data;
        } else {
          this.loginError = err.response;
        }
      }
    },
    /**
     * Closes the modal
     */
    closeModal() {
      this.$emit('closeModal');
    }
  }
}
</script>
    
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  height: 40vh;
  min-width: 700px;
  display: flex;
  flex-direction: column;
}

.medium-modal-container {
  height: 44vh;
  min-width: 700px;
  display: flex;
  flex-direction: column;
}

.large-modal-container {
  height: 52vh;
  min-width: 700px;
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.flex-item {
    flex: 1;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-top: 1em;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.modal-body {
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.modal-header {
  padding: 40px 40px 0 40px !important;
}

.form-header {
  color: black;
  text-align: left;
}

:deep(.p-accordion-header-link) {
  height: 4em;
  background: white;
  border: none;
  border-top: 1px solid $bordergrey;
}

:deep(.p-accordion-content) {
  border: none;
}

:deep(.p-accordion-content) p {
  margin: 0;
  font-size: 11pt;
  font-weight: 500;
  color: $deepgrey;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.authentication-info {
  font-size: 0.8rem;
  text-align: left;
}

.cancel-button {
    text-align: center;
    color: $deepgrey;
}

.authentication-highlight {
  font-weight: 600;
}

.p-inputtext,
.p-selectbutton,
.p-password-input {
  border-radius: 5px;
  border: 1px solid $deepgrey;
  overflow: hidden;
}

:deep(.p-selectbutton) .p-highlight {
background-color: $deepgrey !important;
border: 1px solid $deepgrey !important;
}

.error-text {
  color: $error;
  margin: auto auto auto 0;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
}

.red-error-icon {
  background-color: $error;
  border-color: $error;
  color: white;
  border-radius: 50% !important;
  height: 20px;
  width: 20px;
  margin: auto;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.error-text-span {
  margin: auto;
  padding-left: 5px;
}

</style>