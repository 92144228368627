<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Generate Message History Report
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <!-- FROM TIME -->
              <div class="flex-input">
                <label for="from_time">From Time</label>
                <Calendar
                  id="from_time"
                  v-model="from"
                  show-button-bar
                  date-format="yy-mm-dd"
                  placeholder="Select From Date Time"
                  class="grey-input"
                />
              </div>
              <!-- TO TIME -->
              <div class="flex-input">
                <label for="to_time">To Time</label>
                <Calendar
                  id="to_time"
                  v-model="to"
                  show-button-bar
                  date-format="yy-mm-dd"
                  placeholder="Select To Date Time"
                  class="grey-input"
                />
              </div>
              <!-- EMAIL ADDRESS -->
              <div class="flex-input">
                <label for="email_address">Email Address</label>
                <InputText
                  id="email_address"
                  v-model="email"
                  placeholder="Enter an Email Address"
                  class="grey-input"
                />
              </div>
              <Button
                label="Generate Report"
                :loading="loading"
                @click="generateReport"
              />
              <p
                v-if="generateReportError"
                class="error-message"
              >
                {{ generateReportError }}
              </p>
              <p
                v-if="generateReportSuccess"
                class="success-message"
              >
                {{ generateReportSuccess }}
              </p>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  props: {
    pharmacy: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'closeModal'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
      loading: false,
      generateReportError: '',
      generateReportSuccess: '',
      selectedPharmacies: [],
      from: null,
      to: null,
      email: null
    }
  },
  computed: {
    generateReportPayload() {
      return {
        from: new Date(this.from).toISOString(),
        to: new Date(this.to).toISOString(),
        id: this.pharmacy.uuid,
        email: this.email
      }
    }
  },
  mounted() {
    var currentDate = new Date();
    this.to = new Date();
    this.from = currentDate;
    this.from.setMonth(currentDate.getMonth() - 1);
  },
  methods: {
    /**
     * Emit the close modal event
     */
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * Edit a company
     * @returns {Promise<void>}
     */
    async generateReport() {
      this.$store.dispatch('getRefreshToken');
      this.generateReportError = '';
      this.generateReportSuccess = '';
      this.loading = true;

      try {
        const res = await axios.post('/api/admin/message-history-report', this.generateReportPayload, this.$store.state.header);
        this.handleGenerateReportSuccess(res);
      } catch (err) {
        this.handleGenerateReportError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for generating the report
     * @param {Object} res 
     */
    handleGenerateReportSuccess(res) {
      console.log('The generate report response is: ', res);
      this.loading = false;
      this.generateReportSuccess = 'Report Successfully Generated.'
    },
    /**
     * Handle the error response for generating the report
     * @param {Object} err 
     */
    handleGenerateReportError(err) {
      console.log('The generate report error is: ', err);
      this.loading = false;
      this.generateReportError = err;
    }
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: column;
  // height: 78vh;
  min-width: 400px;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.pharmacies-container {
  display: flex;
  border-radius: 5px;
  background-color: $grey;
  overflow-y: auto;
  flex: 1 1 150px;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 10px 0 10px;
}

.pharmacy-bubble {
  display: flex;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  background-color: $bluegreen;
  color: white;
  font-weight: 500;
  font-size: 11pt;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

</style>
