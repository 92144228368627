<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Link Location
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <Dropdown
                v-model="selectedCompany"
                :loading="loadingCompanies"
                :editable="true"
                :options="companies"
                option-label="name"
                placeholder="Select a Company"
                class="grey-input"
                @keyup="debounceSearchCompanies(selectedCompany)"
                @change="getLocations(selectedCompany)"
              />
              <div
                v-for="location in locations" 
                :key="location.uuid" 
              >
                <Checkbox
                  v-model="selectedLocations"
                  :input-id="location.uuid"
                  :value="location.uuid"
                  name="location"
                />
                <label
                  :for="location.uuid"
                  style="font-size: 9pt;"
                >
                  {{ location.name }}
                </label>
              </div>
            </div>
            <p
              v-if="linkLocationError"
              class="error-message"
            >
              {{ linkLocationError }}
            </p>
            <p
              v-if="linkLocationSuccess"
              class="success-message"
            >
              {{ linkLocationSuccess }}
            </p>
          </slot>
        </div>

        <!-- FOOTER -->
        <div class="modal-footer">
          <slot name="footer">
            <!-- 
            The updateFilters method is called when the confirm button is clicked
            The method emits an event to the parent component
          -->
            <div
              class="black-button"
              @click="linkLocation"
            >
              Confirm
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
  import axios from 'axios';
  
  export default {
    props: {
      /**
       * The Selected User
       * @type {Object}
       */
      user: {
        type: Object,
        default() {
          return {
          }
        }
      }
    },
    /**
     * Emits events to parent component
     */
    emits: [
      'closeModal'
    ],
    data() {
       
      /**
       * The local data for the component
       */
      return {
        loading: false,
        selectedCompany: null,
        selectedLocations: [],
        locations: [],
        companies: [],
        timeout: null,
        loadingCompanies: false,
        linkLocationSuccess: '',
        linkLocationError: '',
        linkLocationPayload: {
          login_id: this.user ? this.user.uuid : '',
          location_id: ''
        }
      }
    },
    mounted() {
    },
    methods: {
      /**
       * Emit the close modal event
       */
      closeModal() {
        this.$emit('closeModal');
      },
      /**
       * Debounce search to retrieve companies based on company name
       * 400 ms delay on search
       * @param {String} companyName 
       */
      debounceSearchCompanies(companyName) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchCompanies(companyName);
        }, 400);
      },
      /**
       * Search for companies based on company name
       * @param {String} companyName 
       */
      searchCompanies(companyName) {
        this.$store.dispatch('getRefreshToken');
        this.loadingCompanies = true;
        axios.post('/api/company/search', { "page": 1, "name": companyName }, this.$store.state.header)
        .then(res => {
          this.handleSearchCompaniesSuccess(res);
        }).catch(err => {
          this.handleSearchCompaniesError(this.handleAxiosError(err));
        });
      },
      /**
       * Handle the success response for the companies
       * @param {Object} res 
       */
      handleSearchCompaniesSuccess(res) {
        console.log('The search companies response is: ', res);
        this.loadingCompanies = false;
        this.companies = res.data.results;
      },
      /**
       * Handle the error response for the companies
       * @param {Object} err 
       */
      handleSearchCompaniesError(err) {
        console.log('The search companies error is: ', err);
        this.loadingCompanies = false;
      },
      /**
       * Retrieve the locations for a company
       * @param {Object} company 
       */
      getLocations(company) {
        if (typeof company === 'object' && company !== null) {
          this.$store.dispatch('getRefreshToken');
          this.loadingLocations = true;
          this.selectedCompany = company.name;
          this.locations = [];
          this.selectedLocations = [];
          axios.post(`/api/company/${company.uuid}/locations`, null, this.$store.state.header)
          .then(res => {
            this.handleSearchLocationsSuccess(res);
          }).catch(err => {
            this.handleSearchLocationsError(this.handleAxiosError(err));
          });
        }
      },
      /**
       * Handle the success response for the locations
       * @param {Object} res 
       */
      handleSearchLocationsSuccess(res) {
        console.log('The search locations response is: ', res);
        this.loadingLocations = false;
        this.locations = res.data.data;
      },
      /**
       * Handle the error response for the locations
       * @param {Object} err 
       */
      handleSearchLocationsError(err) {
        console.log('The search locations error is: ', err);
        this.loadingLocations = false;
      },
      /**
       * Link a location
       * @returns {Promise<void>}
       */
      linkLocation() {
        this.$store.dispatch('getRefreshToken');
        this.linkLocationError = '';
        this.linkLocationSuccess = '';
        this.loading = true;

        // For each of the selected locations, call the link location request
        this.selectedLocations.forEach(location => {
          this.linkLocationPayload.location_id = location;
          this.linkLocationRequest();
        });

        this.loading = false;
        this.clear();
        this.linkLocationSuccess = 'Location(s) successfully linked.'
      },
      async linkLocationRequest() {
        this.$store.dispatch('getRefreshToken');
        await axios.post('/api/location-link-login', this.linkLocationPayload, this.$store.state.header)
        .then(res => {
          this.handleLinkLocationSuccess(res);
        }).catch(err => {
          this.handlLinkLocationError(this.handleAxiosError(err));
        });
      },
      /**
       * Handle the success response for the location
       * @param {Object} res 
       */
      handleLinkLocationSuccess(res) {
        console.log('The link location response is: ', res);
      },
      /**
       * Handle the error response for the location
       * @param {Object} err 
       */
      handleLinkLocationError(err) {
        console.log('The link location error is: ', err);
        this.loading = false;
        this.linkLocationError = err;
      },
      clear() {
        this.selectedLocation = [];
      }
    }
  }
  </script>
    
  <style lang="scss" scoped>
  @import "../../assets/css/main.scss";
  
  .modal-container {
    display: flex;
    flex-direction: column;
    height: 78vh;
  }
  
  .error-message {
    color: $error;
  }
  
  .success-message {
    color: $correct;
  }
  
  .form-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: stretch;
  
    .span {
      display: flex;
    }
  }
  
  .flex-item {
    flex: 1;
  }
  
  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;
  }

  .checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  
  .pharmacies-container {
    display: flex;
    border-radius: 5px;
    background-color: $grey;
    overflow-y: auto;
    flex: 1 1 150px;
    row-gap: 5px;
    column-gap: 10px;
    flex-wrap: wrap;
    align-content: flex-start;
    padding: 20px 10px 0 10px;
  }
  
  .pharmacy-bubble {
    display: flex;
    border-radius: 5px;
    padding: 3px 10px 3px 10px;
    background-color: $bluegreen;
    color: white;
    font-weight: 500;
    font-size: 11pt;
    align-items: center;
    justify-content: center;
    height: fit-content;
  }
  
  .close-icon {
    cursor: pointer;
    padding-left: 10px;
    padding-right: 0;
  }
  
  </style>
  