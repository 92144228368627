<template>
  <div class="tag-row">
    <div
      v-if="params.otp"
      v-tooltip.top="'Opioid Treatment Program Medication'"
      class="tag pink-bg rounded-5"
    >
      OTP
    </div>
    <div
      v-if="params.cannabis"
      v-tooltip.top="'Cannabis Medication'"
      class="tag green-bg rounded-5"
    >
      <img
        :src="cannabisIcon"
        class="small-icon"
        alt="Cannabis"
      >
    </div>
    <img
      v-if="params.custom"
      v-tooltip.top="'Custom Medication'"
      :src="customProductIcon"
      class="medium-icon"
      alt="Custom Product"
    >
    <div
      v-if="params.inactive"
      v-tooltip.top="'Inactive Product'"
      class="tag grey-background rounded-5"
    >
      Inactive
    </div>
  </div>
</template>

<script>
export default {
  name: "DrugTypeTags",
  props: {
    params: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  data() {
    return {
      customProductIcon: require('@/assets/icons/custom_product_icon.svg'),
      cannabisIcon: require('@/assets/icons/cannabis_icon.svg'),
    }
  }
}
</script>

<style lang="scss">
.tag-row {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
}

.tag {
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.75rem;
  color: white;
  font-weight: 600;
}

</style>
