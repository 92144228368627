<template>
  <div class="stats-group">
    <div class="stats-group-left">
      <div
        v-for="(stat, index) in meterValues"
        :key="index"
        class="stat-item"
      >
        <div
          v-if="index !== 0"
          class="stat-divider-container"
        >
          <Divider
            layout="vertical"
            class="stat-divider"
          />
        </div>
        <slot
          v-if="icons[stat.icon]"
          :name="stat.icon"
        >
        </slot>
        <span style="font-size: 14px;"><b>{{ stat.value }}</b> {{ stat.label }}</span>
      </div>
    </div>
    <div class="stats-group-right">
      <div class="stat-item">
        <slot
          v-if="icons[totalValues.icon]"
          :name="totalValues.icon"
        >
        </slot>
        <span style="font-size: 14px;"><b>{{ totalValues.value }}</b> {{ totalValues.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    meterValues: {
      type: Array,
      required: true,
    },
    totalValues: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      icons: {
        check: true,
        profile: true
      }
    };
  },
}
</script>

<style lang="scss" scoped>
.stats-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .stats-group-left, .stats-group-right {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .stat-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .stat-divider-container {
      height: 1rem;
      display: flex;
      position: relative;
      margin: 0.25rem;

      .stat-divider {
        margin: 0;
        padding: 0;
      }
    }
  }
}
</style>