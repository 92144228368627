<template>
  <div class="view">
    <div
      class="panel full-height flex-column"
    >
      <div class="page-header">
        <Menubar
          v-if="dashboardsData.length > 0"
          :model="dashboardsData"
          class="analytics-menu"
        >
          <template #item="{ item }">
            <div style="display: flex; gap: 10px; align-items: center; justify-content: center;">
              <span 
                class="ml-2"
                style="font-size: 1em;"
                :style="current_dashboard.id === item.id ? {borderBottom: '4px solid #0870DC'}:{}"
                @click="activateMenuItem(item.id)" 
              >
                {{ item.dashboard_type }}
              </span>
            </div>
          </template>
        </Menubar>
        <div v-else></div>
        <NavFunctions class="nav-functions" />
      </div>
      <!-- STATS SUMMARY -->
      <div
        style="display: flex; flex: 1; align-items: center; justify-content: center;"
        class="analytics-container"
      >
        <div
          v-if="loadingDashboardsData"
          style="display: flex; align-items: center; gap: 1rem;"
        >
          <ProgressSpinner
            class="medium-icon"
            stroke-width="8"
            animation-duration=".75"
            fill="var(--surface-ground)"
            aria-label="Loading"
          />
          Loading Analytics...
        </div>
        <div
          id="superset-dashboard"
          :style="loadingDashboardsData ? {display: 'none'} : {display: 'block'}"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { embedDashboard } from "@superset-ui/embedded-sdk";
import NavFunctions from '@/components/navigation/nav-menu-dropdown.vue';
import axios from 'axios';

export default {
  components: {
    NavFunctions
  },
  data() {
    return {
      dashboardsData: [],
      dashboardsDataError: '',
      loadingDashboardsData: false,
      expandedDashboardsRows: [],
      company_search: '',
      current_dashboard: null,
      getDashboardsPayload: {
        active: true
      }
    }
  },
  async mounted() {
    await this.getDashboards();
  },
  methods: {
    /**
     * Sets the current dashboard to active and embeds superset
     * @param {string} id - the id of the dashboard being set to active
     */
    async activateMenuItem(id) {
      console.log('The dashboard id is: ', id);
      this.loadingDashboardsData = true;

      // Find the dashboard from its id
      const dashboard = this.dashboardsData.find(dashboard => dashboard.id === id);

      if (dashboard) {
        // Embed Superset for the found dashboard
        await this.embedSuperset(dashboard.dashboard_id, dashboard.domain_url, dashboard.id);
        dashboard.display = true;
        this.current_dashboard = dashboard;

        // Update display status for other dashboards
        this.dashboardsData.forEach(d => {
          if (d.id !== id) {
            d.display = false;
          }
        });
      } else {
        console.error(`Dashboard with id ${id} not found.`);
      }

      this.loadingDashboardsData = false;
    },
    /**
     * Get the dashboards from the backend
     */
    async getDashboards() {
      this.dashboardsData = [];
      this.dashboardsDataError = '';
      this.loadingDashboardsData = true;

      try {
        const res = await axios.post('/api/get-dashboards', this.getDashboardsPayload, this.$store.state.header);
        await this.handleDashboardsSuccess(res);
      } catch (err) {
        this.handleDashboardsError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the error response for the dashboards
     * @param {Object} err - The error object
     */
     handleDashboardsError(err) {
      console.log('The dashboards error is: ', err);
      this.loadingDashboardsData = false;
      this.dashboardsDataError = err;
    },
    /**
     * Handle the success response for the dashboards
     * @param {Object} res - The response object
     */
    async handleDashboardsSuccess(res) {
      console.log('The successful get dashboards response is: ', res.data);
      this.dashboardsData = res.data;
      if (this.dashboardsData.length > 0) {
        this.current_dashboard = this.dashboardsData[0];
        this.current_dashboard.display = true;
        await this.embedSuperset(this.current_dashboard.dashboard_id, this.current_dashboard.domain_url, this.current_dashboard.id);
      }
      this.loadingDashboardsData = false;
    },
    /**
     * get the guest token for the superset dashboard
     * @param {string} uuid - The dashboard uuid
     */
    async getGuestToken(uuid) {
      try {
        const res = await axios.post('/api/superset-token', { "dashboard_id": uuid }, this.$store.state.header);
        return this.handleGetGuestTokenSuccess(res);
      } catch (err) {
        this.handleGetGuestTokenError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the error response for the guest token
     * @param {Object} err - The error object
     */
    handleGetGuestTokenSuccess(res) {
      console.log('The successful get guest token response is: ', res.data);
      return res.data.token;
    },
    /**
     * Handle the error response for the guest token
     * @param {Object} err - The error object
     */
    handleGetGuestTokenError(err) {
      console.log('The get guest token error is: ', err);
    },
    /**
     * Handle the process for mounting and embedding superset
     * 
     * @param {string} dashboard_id 
     * @param {string} url 
     * @param {string} uuid 
     */
    async embedSuperset(dashboard_id, url, uuid) {
      console.log("The superset dashboard id is: ", dashboard_id);
      const guestToken = await this.getGuestToken(uuid);
      
      if (guestToken) {
        embedDashboard({
          id: dashboard_id,
          supersetDomain: url,
          mountPoint: document.getElementById('superset-dashboard'),
          fetchGuestToken: () => guestToken,
          dashboardUiConfig: {
            hideTitle: true,
            filters: {
              expanded: true,
            },
            urlParams: {}
          },
          iframeSandboxExtras: ['allow-scripts', 'allow-same-origin'] // Adjust as needed for security
        });
      } else {
        console.error('No guest token found for embedding superset.');
      }
    }
  }
}
</script>


<style lang="scss">
@import "../assets/css/main.scss";
iframe {
  height: 87vh;
  width: 89vw;
  border: none !important; 
}

.full-height {
  height: 100%;
}

.header-hover {
  cursor: pointer;
  border-radius: 5px;
}

.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  max-height: 60px;
  height: 60px;
  margin: 10px 0 10px 0;

  h1 {
    margin: 0;
    padding: 10px;
    border: 1px solid transparent;
  }
}

.nav-functions {
  align-self: flex-start;
  z-index: 3;
}

.analytics-menu .p-menuitem {
  font-weight: bold;
  font-size: 1.7em;
  cursor: pointer;
}

.analytics-menu .p-menuitem.p-focus div {
  background-color: #f8f9fa !important;
}

.analytics-menu .p-menubar-root-list {
  display: flex;
  gap: 10px;
}

:deep(.p-inputtext) {
  width: 100%;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

.flex-item {
  flex: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  gap: 1em;
}

.full-container {
  display: grid;
  column-gap: 40px;
  height: 100%;
}

.grid-container {
  display: grid;
  column-gap: 40px;
  height: 100%;
}

.left-col {
  grid-column-start: 1;
  grid-column-end: 2;
}

.right-col {
  grid-column-start: 2;
  grid-column-end: 3;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filters-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  margin-bottom: 1em;
  gap: 20px;
}

.category-combobox {
  flex: 1 1 300px;
}

.analytics-container .overview-stats {
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  border-radius: 12px;
  height: 70px;
  margin-bottom: 1em;
}

.overview_card {
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;
  justify-content: center;
  height: 70px;
}

.header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  padding: 0.5em 0 0.5em 0;
}

.stats-value {
  margin: 5px;
}

.stats-top-row {
  display: flex;
  width: 100%;
  gap: 5px;
  align-items: center;
  padding: 0 5px;
  flex: 1;
}

.stats-heading {
  font-size: 12px;
  font-weight: 600;
  opacity: 0.7;
  margin: 0;
  flex: 1;
}

.stats-percentage {
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.p-datatable :deep(.p-column-title) {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.p-datatable-table) {
  table-layout: fixed;
}

.positive-rate {
  color: green;
}

.negative-rate {
  color: red;
}

.neutral-rate {
  color: grey;
}

.key-container {
  display: flex;
  gap: 1.5em;
}

.key-icon {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: inline-block;
}

.p-progress-spinner {
  margin: 0 0 0 0 !important;
}

th {
  height: 4rem !important;
}

.table-main-header {
  font-size: 13pt;
  font-weight: 600 !important;
  color: black !important;
}

.table-footer {
  display: flex;
  align-items: center;
}

.flex-right {
  margin-left: auto;
}

.p-datatable th[class*="align-"] .p-column-header-content {
  display: inline-flex ;
}

// .no-borders * {
//   border : 0px !important;
// }

.align-center {
  text-align: center !important;
}

.p-tabview {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  overflow: hidden;
}

.p-tabview-panels {
  padding: 0 !important;
}

.analytics-container .p-tabview-header {
  flex: 1;
  height: 58px;
}

.calc-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.adjustment {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.adjustment .p-togglebutton {
  flex: 1 1 100px;
}

.input-span {
  flex: 3 1 100px;
}

.input-span .p-inputtext {
  width: 100%;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

.flex-expand {
  flex: 1;
}

.analytics-container .output-calc {
  background-color: white;
  border-radius: 6px;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.5em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2em;
}

.opportunity-left-col {
  grid-column: 1 / 2;
}

.opportunity-right-col {
  grid-column: 2 / 3;
}

.opportunity-heading {
  padding: 0.5em 1em 0 0.5em;;
}

.opportunity-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 3.1875rem;
}

.data-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.arrow {
  justify-content: flex-start;
}

.current-container {
  padding: 0.5em 1em 1em 0.5em;
}

.output-potential-data {
  font-size: 20pt;
  color: white;
  margin: 0;
}

.output-potential-label {
  font-size: 11pt;
  color: white;
  opacity: 0.75;
  margin: 0;
}

.output-skeleton {
  height: 50px !important;
}

.stats-skeleton {
  height: 70px !important;
}
</style>