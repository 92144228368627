<template>
  <div class="content-section">
    <FieldList @field-selected="fieldSelected" />
    <TextEditor
      ref="textEditor"
      v-model="currentContent"
      class="flex-item"
    />
  </div>
</template>

<script>
import FieldList from '@/components/templates/field-list.vue';
import TextEditor from '@/components/templates/text-editor.vue';

export default {
  name: 'FieldListTextEditor',
  components: {
    FieldList,
    TextEditor
  },
  props: {
    /**
     * The content of the editor
     * @type {String}
     */
    content: {
      type: String,
      default: ''
    }
  },
  emits: [
    'contentUpdated'
  ],
  data() {
    return {
      currentContent: '', // The current content of the editor
    }
  },
  computed: {
    // Remove HTML tags from content
    cleanContent() {
      let content = this.currentContent;
      // Replace HTML <br> tag with new line
      content = content.replace(/(?:<\/p><p>|<br>|<br \\>)/g, '\n');
      // Remove all other HTML tags
      return content.replace(/<\/?[^>]+(>|$)/g, "");
    }
  },
  watch: {
    // Watch for clean content changes and emit contentUpdated
    cleanContent: {
      handler: function (newVal) {
        this.$emit('contentUpdated', newVal);
      }
    }
  },
  mounted() {
    // Set the currentContent to the content prop
    // nextTick required to ensure currentContent is available
    this.$nextTick(() => {
      this.currentContent = this.content;
    })
  },
  methods: {
    // On selection of a field, insert text into the editor
    fieldSelected(value) {
      this.$store.dispatch('getRefreshToken');
      this.$refs.textEditor.insertText(value);
    },
    // Set the content of the editor
    setContent(value, source = 'editor') {
      if (source === 'gpt' || source === 'recommended') {
        this.currentContent = value;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.flex-item {
  flex: 1;
}

.content-section {
  display: flex;
  flex: 1;
}
</style>