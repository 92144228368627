<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Create Company
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <InputText
                v-model="addCompanyPayload.company_name"
                placeholder="Enter a Company Name"
                class="grey-input"
              />
              <div class="form-row">
                <InputText
                  v-model="addCompanyPayload.username"
                  placeholder="Enter a Username"
                  class="grey-input flex-item"
                />
                <InputText
                  v-model="addCompanyPayload.email"
                  placeholder="Enter a Email"
                  class="grey-input flex-item"
                />
              </div>
              <div class="form-row">
                <InputText
                  v-model="addCompanyPayload.first_name"
                  placeholder="Enter a First Name"
                  class="grey-input flex-item"
                />
                <InputText
                  v-model="addCompanyPayload.last_name"
                  placeholder="Select a Last Name"
                  class="grey-input flex-item"
                />
              </div>
              <InputText
                v-model="addCompanyPayload.password"
                placeholder="Enter a Password"
                class="grey-input"
                type="password"
              />
              <div class="flex-input">
                <label for="sixty_day_signup">60 Day Signup</label>
                <Checkbox
                  v-model="addCompanyPayload.sixty_day_signup"
                  input-id="sixty_day_signup" 
                  :binary="true"
                />
              </div>
              <div class="flex-input flex-item">
                <label for="cloud_ecdr">Cloud eCDR</label>
                <Checkbox
                  v-model="addCompanyPayload.cloud_ecdr"
                  input-id="cloud_ecdr" 
                  :binary="true"
                />
              </div>
            </div>
            <p
              v-if="createCompanyError"
              class="error-message"
            >
              {{ createCompanyError }}
            </p>
            <p
              v-if="createCompanySuccess"
              class="success-message"
            >
              {{ createCompanySuccess }}
            </p>
          </slot>
        </div>
  
        <!-- FOOTER -->
        <div class="modal-footer">
          <slot name="footer">
            <!-- 
                The updateFilters method is called when the confirm button is clicked
                The method emits an event to the parent component
               -->
            <div
              class="black-button"
              @click="addCompany"
            >
              Confirm
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  props: {
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'closeModal'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
      loading: false,
      createCompanyError: '',
      createCompanySuccess: '',
      addCompanyPayload: {
        company_name: '',
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        password: '',
        sixty_day_signup: false,
        cloud_ecdr: false
      }
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    /**
     * Emit the close modal event
     */
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * Create a company
     * @returns {Promise<void>}
     */
    async addCompany() {
      this.$store.dispatch('getRefreshToken');
      this.createCompanyError = '';
      this.createCompanySuccess = '';
      this.loading = true;

      await axios.post('/api/company/create', this.addCompanyPayload, this.$store.state.header)
      .then(res => {
        this.handleCreateCompanySuccess(res);
      }).catch(err => {
        this.handleCreateCompanyError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the success response for the company
     * @param {Object} res 
     */
    handleCreateCompanySuccess(res) {
      console.log('The create company response is: ', res);
      this.loading = false;
      this.clear();
      this.createCompanySuccess = 'Company Created Successfully.'
    },
    /**
     * Handle the error response for the company
     * @param {Object} err 
     */
    handleCreateCompanyError(err) {
      console.log('The create company error is: ', err);
      this.loading = false;
      this.createCompanyError = err;
    },
    clear() {
      this.addCompanyPayload.company_name = '';
      this.addCompanyPayload.username = '';
      this.addCompanyPayload.email = '';
      this.addCompanyPayload.first_name = '';
      this.addCompanyPayload.last_name = '';
      this.addCompanyPayload.password = '';
      this.addCompanyPayload.sixty_day_signup = false;
      this.addCompanyPayload.cloud_ecdr = false;
    }
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.pharmacies-container {
  display: flex;
  border-radius: 5px;
  background-color: $grey;
  overflow-y: auto;
  flex: 1 1 150px;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 10px 0 10px;
}

.pharmacy-bubble {
  display: flex;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  background-color: $bluegreen;
  color: white;
  font-weight: 500;
  font-size: 11pt;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

</style>
