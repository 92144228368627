<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Fake Patients - {{ pharmacy.name }}
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <Accordion>
                <AccordionTab>
                  <!-- HEADER -->
                  <template #header>
                    <span>New Fake Patient</span>
                  </template>
                  <div class="flex-column">
                    <div class="flex-row">
                      <!-- FIRST NAME -->
                      <div class="flex-input flex-item">
                        <label for="add_first_name">First Name</label>
                        <InputText
                          id="add_first_name"
                          v-model="addFakePatientForm.first_name"
                          placeholder="Enter a First Name"
                          class="grey-input"
                        />
                      </div>
                      <!-- LAST NAME -->
                      <div class="flex-input flex-item">
                        <label for="add_last_name">Last Name</label>
                        <InputText
                          id="add_last_name"
                          v-model="addFakePatientForm.last_name"
                          placeholder="Enter a Last Name"
                          class="grey-input"
                        />
                      </div>
                    </div>
                    <div class="flex-row">
                      <!-- AGE -->
                      <div class="flex-input flex-item">
                        <label for="add_age">Age</label>
                        <InputNumber
                          id="add_age"
                          v-model="addFakePatientForm.age"
                          input-id="integeronly"
                          placeholder="Enter an Age"
                          class="grey-input"
                        />
                      </div>
                      <!-- SEX -->
                      <div class="flex-input flex-item">
                        <label for="add_sex">Sex</label>
                        <Dropdown
                          id="add_sex"
                          v-model="addFakePatientForm.sex"
                          :options="sexOptions"
                          class="grey-input"
                        />
                      </div>
                    </div>
                    <div class="flex-row">
                      <!-- MOBILE -->
                      <div class="flex-input flex-item">
                        <label for="add_mobile">Mobile Number</label>
                        <InputText
                          id="add_mobile"
                          v-model="addFakePatientForm.mobile"
                          placeholder="Enter a Mobile Number"
                          class="grey-input"
                        />
                      </div>
                      <!-- Email -->
                      <div class="flex-input flex-item">
                        <label for="add_email">Email Address</label>
                        <InputText
                          id="add_email"
                          v-model="addFakePatientForm.email"
                          placeholder="Enter an Email Address"
                          class="grey-input"
                        />
                      </div>
                    </div>
                    <div class="flex-row">
                      <!-- ADDRESS -->
                      <div class="flex-input flex-item">
                        <label for="add_address">Address</label>
                        <InputText
                          id="add_address"
                          v-model="addFakePatientForm.address"
                          placeholder="Enter an Address"
                          class="grey-input"
                        />
                      </div>
                      <!-- ID -->
                      <div class="flex-input flex-item">
                        <label for="add_id">Patient ID (-1 to -999999)</label>
                        <div class="flex-row">
                          <InputNumber
                            id="add_id"
                            v-model="addFakePatientForm.id"
                            input-id="minmax"
                            :min="-1"
                            :max="-999999"
                            :disabled="true"
                            placeholder="Enter a Patient ID"
                            class="grey-input flex-item"
                          />
                          <Button
                            icon="pi pi-refresh"
                            severity="info"
                            aria-label="Refresh Patient ID"
                            @click="generatePatientID"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="flex-row">
                      <!-- Medicare -->
                      <div class="flex-input flex-item">
                        <label for="add_medicare">Medicare</label>
                        <InputText
                          id="add_medicare"
                          v-model="addFakePatientForm.medicare"
                          placeholder="Enter a Medicare Number"
                          class="grey-input"
                        />
                      </div>
                      <!-- DVA -->
                      <div class="flex-input flex-item">
                        <label for="add_dva">DVA</label>
                        <InputText
                          id="add_dva"
                          v-model="addFakePatientForm.dva"
                          placeholder="Enter a DVA Number"
                          class="grey-input"
                        />
                      </div>
                    </div>
                    <div class="flex-row">
                      <!-- ADHERENCE -->
                      <div class="flex-input flex-item">
                        <label for="add_adherence">Adherence (1 to 5)</label>
                        <InputNumber
                          id="add_adherence"
                          v-model="addFakePatientForm.adherence_level"
                          input-id="minmax"
                          :min="1"
                          :max="5"
                          placeholder="Enter an Adherence Level"
                          class="grey-input"
                        />
                      </div>
                      <!-- LOYALTY -->
                      <div class="flex-input flex-item">
                        <label for="add_loyalty">Loyalty (1 to 5)</label>
                        <InputNumber
                          id="add_loyalty"
                          v-model="addFakePatientForm.loyalty_level"
                          input-id="minmax"
                          :min="1"
                          :max="5"
                          placeholder="Enter a Loyalty Level"
                          class="grey-input"
                        />
                      </div>
                    </div>
                    <div class="flex-row">
                      <!-- LEAKED SCRIPTS -->
                      <div class="flex-input flex-item">
                        <label for="add_leaked_scripts">Leaked Scripts</label>
                        <InputNumber
                          id="add_leaked_scripts"
                          v-model="addFakePatientForm.leaked_scripts"
                          input-id="integeronly"
                          placeholder="Number of Leaked Scripts"
                          class="grey-input"
                        />
                      </div>
                      <!-- TOTAL SCRIPTS -->
                      <div class="flex-input flex-item">
                        <label for="add_total_scripts">Total Scripts</label>
                        <InputNumber
                          id="add_total_scripts"
                          v-model="addFakePatientForm.total_scripts"
                          input-id="integeronly"
                          placeholder="Number of Total Scripts"
                          class="grey-input"
                        />
                      </div>
                    </div>
                    <div class="flex-row">
                      <!-- SMS NO CONTACT -->
                      <div class="flex-input flex-item">
                        <label for="add_sms_nocontact">SMS No Contact Reason</label>
                        <InputText
                          id="add_sms_nocontact"
                          v-model="addFakePatientForm.sms_nocontact_reason"
                          placeholder="Enter a Reason"
                          class="grey-input"
                        />
                      </div>
                      <!-- EMAIL NO CONTACT -->
                      <div class="flex-input flex-item">
                        <label for="add_email_nocontact">Email No Contact Reason</label>
                        <InputText
                          id="add_email_nocontact"
                          v-model="addFakePatientForm.email_nocontact_reason"
                          placeholder="Enter a Reason"
                          class="grey-input"
                        />
                      </div>
                    </div>
                    <Button
                      label="Submit"
                      :loading="addingFakePatients"
                      @click="addFakePatient"
                    />
                    <p
                      v-if="addFakePatientsError"
                      class="error-message"
                    >
                      {{ addFakePatientsError }}
                    </p>
                    <p
                      v-if="addFakePatientsSuccess"
                      class="success-message"
                    >
                      {{ addFakePatientsSuccess }}
                    </p>
                  </div>
                </AccordionTab>
              </Accordion>
              <!-- TABLE FOR FAKE PATIENTS -->
              <DataTable
                v-model:expandedRows="expandedPatientRows"
                :loading="loadingFakePatients"
                :value="fakePatients"
                data-key="id"
              >
                <template #empty>
                  No records found
                </template>
                <template #loading>
                  Loading records, please wait...
                </template>
                <template #header>
                  <div class="flex-row">
                    <span
                      class="flex-item"
                      style="text-align: left;"
                    >Fake Patients</span>
                    <Button
                      icon="pi pi-refresh"
                      :loading="loadingFakePatients"
                      rounded
                      raised
                      @click="getFakePatients"
                    />
                  </div>
                </template>
                <!-- EXPANDER -->
                <Column
                  expander
                  style="width: 5rem"
                />
                <!-- Name -->
                <Column header="Name">
                  <template #body="slotProps">
                    {{ slotProps.data.first_name }} {{ slotProps.data.last_name }}
                  </template>
                </Column>
                <!-- MOBILE PHONE -->
                <Column
                  field="mobile"
                  header="Mobile"
                />
                <!-- EMAIL ADDRESS -->
                <Column
                  field="email"
                  header="Email"
                />
                <!-- DELETE BUTTON -->
                <!-- DELETES THE FAKE PATIENT -->
                <Column>
                  <template #body="slotProps">
                    <Button
                      icon="pi pi-trash"
                      outlined
                      rounded
                      severity="danger"
                      @click="openConfirmPatientDeletion(slotProps.data)"
                    />
                  </template>
                </Column>
                <!-- EXPANDABLE SECTION -->
                <template #expansion="slotProps">
                  <div>
                    <!-- USER LOCATIONS TABLE -->
                    <div>
                      <div class="form-row">
                        <div class="flex-input flex-item">
                          <label for="age">Age</label>
                          <InputText
                            id="age"
                            v-model="slotProps.data.age"
                            disabled
                            class="grey-input"
                          />
                        </div>
                        <div class="flex-input flex-item">
                          <label for="sex">Sex</label>
                          <InputText
                            id="sex"
                            v-model="slotProps.data.sex"
                            disabled
                            class="grey-input"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="flex-input flex-item">
                          <label for="address">Address</label>
                          <InputText
                            id="address"
                            v-model="slotProps.data.address"
                            disabled
                            class="grey-input"
                          />
                        </div>
                        <div class="flex-input flex-item">
                          <label for="id">Patient ID</label>
                          <InputText
                            id="id"
                            v-model="slotProps.data.id"
                            disabled
                            class="grey-input"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="flex-input flex-item">
                          <label for="medicare">Medicare</label>
                          <InputText
                            id="medicare"
                            v-model="slotProps.data.medicare"
                            disabled
                            class="grey-input"
                          />
                        </div>
                        <div class="flex-input flex-item">
                          <label for="dva">DVA</label>
                          <InputText
                            id="dva"
                            v-model="slotProps.data.dva"
                            disabled
                            class="grey-input"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="flex-input flex-item">
                          <label for="adherence">Adherence Level</label>
                          <InputText
                            id="adherence"
                            v-model="slotProps.data.adherence_level"
                            disabled
                            class="grey-input"
                          />
                        </div>
                        <div class="flex-input flex-item">
                          <label for="loyalty">Loyalty Level</label>
                          <InputText
                            id="loyalty"
                            v-model="slotProps.data.loyalty_level"
                            disabled
                            class="grey-input"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="flex-input flex-item">
                          <label for="leaked_scripts">Leaked Scripts</label>
                          <InputText
                            id="leaked_scripts"
                            v-model="slotProps.data.leaked_scripts"
                            disabled
                            class="grey-input"
                          />
                        </div>
                        <div class="flex-input flex-item">
                          <label for="total_scripts">Total Scripts</label>
                          <InputText
                            id="total_scripts"
                            v-model="slotProps.data.total_scripts"
                            disabled
                            class="grey-input"
                          />
                        </div>
                      </div>
                      <div class="form-row">
                        <div class="flex-input flex-item">
                          <label for="sms_nocontact_reason">SMS No Contact Reason</label>
                          <InputText
                            id="sms_nocontact_reason"
                            v-model="slotProps.data.sms_nocontact_reason"
                            disabled
                            class="grey-input"
                          />
                        </div>
                        <div class="flex-input flex-item">
                          <label for="email_nocontact_reason">Email No Contact Reason</label>
                          <InputText
                            id="email_nocontact_reason"
                            v-model="slotProps.data.email_nocontact_reason"
                            disabled
                            class="grey-input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </DataTable>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
  <ConfirmDialog group="fake_patients_confirmation" />
</template>
  
<script>
import axios from 'axios';
import { useConfirm } from 'primevue/useconfirm';

export default {
    props: {
        /**
         * The selected pharmacy
         * @type {Object}
         */
        pharmacy: {
            type: Object,
            default() {
                return {};
            }
        },
    },
    /**
     * Emits events to parent component
     */
    emits: [
        'updateQueue',
        'closeModal'
    ],
    data() {
        /**
         * The local data for the component
         */
        return {
            expandedPatientRows: [],
            loadingFakePatients: false,
            addingFakePatients: false,
            removingFakePatients: false,
            getFakePatientsError: '',
            getFakePatientsSuccess: '',
            addFakePatientsError: '',
            addFakePatientsSuccess: '',
            removeFakePatientError: '',
            removeFakePatientSuccess: '',
            fakePatients: null,
            addFakePatientForm: {
              id: 0,
              first_name: '',
              last_name: '',
              age: 0,
              sex: '',
              mobile: '',
              email: '',
              address: '',
              medicare: '',
              dva: '',
              adherence_level: 5,
              loyalty_level: 5,
              leaked_scripts: 0,
              total_scripts: 0,
              sms_nocontact_reason: '',
              email_nocontact_reason: '',
            },
            sexOptions: [
              "Male",
              "Female"
            ],
            confirm: useConfirm()
        };
    },
    computed: {
      addFakePatientsPayload() {
        return {
          id: this.pharmacy.uuid,
          patient: this.addFakePatientForm
        }
      },
      getFakePatientsPayload() {
        return {
          id: this.pharmacy.uuid,
          patient_ids: []
        }
      }
    },
    mounted() {
        this.generatePatientID();
        this.getFakePatients();
    },
    methods: {
        /**
         * Emit the close modal event
         */
        closeModal() {
            this.$emit('closeModal');
        },
        /**
         * Get the fake patients
         * @return {Promise} - The promise of the support/get-fake-patients request
         */
        async getFakePatients() {
          this.$store.dispatch('getRefreshToken');
          this.getFakePatientsError = '';
          this.getFakePatientsSuccess = '';
          this.loadingFakePatients = true;
          this.fakePatients = null;

          await axios.post('/api/support/get-fake-patients', this.getFakePatientsPayload, this.$store.state.header)
          .then(res => {
            this.handleGetFakePatientsSuccess(res);
          })
          .catch(err => {
            this.handleGetFakePatientsError(this.handleAxiosError(err));
          })
        },
        /**
         * Handle the get fake patients success case
         * @param {Object} res
         */
        handleGetFakePatientsSuccess(res) {
          console.log('The get fake patients response is', res.data);
          this.fakePatients = res.data.fake_patient_list;
          this.loadingFakePatients = false;
          this.getFakePatientsSuccess = 'Fake patients successfully retrieved';
        },
        /**
         * Handle the get fake patients error case
         * @param {Object} err
         */
        handleGetFakePatientsError(err) {
          console.log('The get fake patients error is', err);
          this.loadingFakePatients = false;
          this.getFakePatientsError = err;
        },
        /**
         * Add a fake patient
         * @return {Promise} - The promise of the support/add-fake-patients request
         */
         async addFakePatient() {
          this.$store.dispatch('getRefreshToken');
          this.addFakePatientsError = '';
          this.addFakePatientsSuccess = '';
          this.addingFakePatients = true;

          await axios.post('/api/support/add-fake-patients', this.addFakePatientsPayload, this.$store.state.header)
          .then(res => {
            this.handleAddFakePatientsSuccess(res);
          })
          .catch(err => {
            this.handleAddFakePatientsError(this.handleAxiosError(err));
          })
        },
        /**
         * Handle the add fake patients success case
         * @param {Object} res
         */
        handleAddFakePatientsSuccess(res) {
          console.log('The add fake patients response is', res.data);
          this.getFakePatients();
          this.addingFakePatients = false;
          this.addFakePatientsSuccess = 'Fake patients successfully retrieved';
          this.resetAddFakePatientForm();
        },
        /**
         * Handle the add fake patients error case
         * @param {Object} err
         */
        handleAddFakePatientsError(err) {
          console.log('The add fake patients error is', err);
          this.addingFakePatients = false;
          this.addFakePatientsError = err;
        },
        /**
         * Reset the add fake patient form
         */
        resetAddFakePatientForm() {
          this.addFakePatientForm = {
              id: 0,
              first_name: '',
              last_name: '',
              age: 0,
              sex: '',
              mobile: '',
              email: '',
              address: '',
              medicare: '',
              dva: '',
              adherence_level: 5,
              loyalty_level: 5,
              leaked_scripts: 0,
              total_scripts: 0,
              sms_nocontact_reason: '',
              email_nocontact_reason: '',
            }
            this.generatePatientID();
        },
        /**
         * Open the confirm fake patients deletion dialog
         * @param {Object} patient - The fake patient to remove
         */
        openConfirmPatientDeletion(patient) {
          this.$store.dispatch('getRefreshToken');
          this.confirm.require({
            header: 'Confirm Fake Patient Deletion',
            message: 'Are you sure you want to remove this fake patient?',
            icon: 'pi pi-exclamation-triangle',
            group: 'fake_patients_confirmation',
            /**
             * If the user confirms the deletion
             * Remove thefake patient
             */
            accept: () => {
              this.removeFakePatient(patient);
            },
            reject: () => {
              console.log('The fake patient removal was cancelled');
            }
          })
        },
        /**
         * Remove the fake patient
         * @param {Object} patient - The fake patient to remove
         */
         async removeFakePatient(patient) {
          this.$store.dispatch('getRefreshToken');
          this.removeFakePatientError = '';
          this.removeFakePatientSuccess = '';
          this.removingFakePatients = true;

          await axios.post('/api/support/delete-fake-patients', { id: this.pharmacy.uuid, patient_ids: [patient.id] }, this.$store.state.header)
          .then(res => {
            this.handleRemoveFakePatientSuccess(res);
          })
          .catch(err => {
            this.handleRemoveFakePatientError(this.handleAxiosError(err));
          })
        },
        /**
         * Handle the success response for removing the fake patient
         * @param {Object} res - The response object
         */
        handleRemoveFakePatientSuccess(res) {
          console.log('The remove fake patient response is:', res.data);
          this.removingFakePatients = false;
          this.removeFakePatientSuccess = 'Fake patient successfully removed.';
          this.getFakePatients();
        },
        /**
         * Handle the error response for removing the fake patient
         * @param {Object} err - The error object
         */
        handleRemoveFakePatientError(err) {
          console.log('The remove fake patient error is: ', err);
          this.removingFakePatients = false;
          this.removeFakePatientError = err;
        },
        /**
         * Generate a patient ID
         * Return a random integer between -1 and -999999
         */
        generatePatientID() {
          this.addFakePatientForm.id = Math.floor(Math.random() * 999999) - 1000000;
        },
    }
}
</script>
  
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.advanced-options {
  border: 1px dashed grey;
  padding: 10px;
}

.modal-container {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.p-inputtext,
.p-selectbutton,
.p-password-input,
.p-togglebutton {
  border-radius: 5px;
  border: 1px solid $phoneorders;
  overflow: hidden;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

:deep(.p-selectbutton) {
  display: flex;
}

:deep(.p-selectbutton) div {
  flex: 1;
}

.flex-item {
  flex: 1;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

</style>
