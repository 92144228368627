<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Edit User
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <h4>Editing {{ user.person.personal_information.first_name + ' ' + user.person.personal_information.last_name }}</h4>
              <div class="form-row">
                <div class="flex-input flex-item">
                  <label for="username">Username</label>
                  <InputText
                    id="username"
                    v-model="editUserForm.name"
                    placeholder="Enter a Username"
                    class="grey-input"
                  />
                </div>
                <div class="flex-input flex-item">
                  <label for="email">Email</label>
                  <InputText
                    id="email"
                    v-model="editUserForm.email"
                    placeholder="Enter an Email"
                    class="grey-input flex-item"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="flex-input flex-item">
                  <label for="first_name">First Name</label>
                  <InputText
                    id="first_name"
                    v-model="editUserForm.first_name"
                    placeholder="Enter a First Name"
                    class="grey-input flex-item"
                  />
                </div>
                <div class="flex-input flex-item">
                  <label for="last_name">Last Name</label>
                  <InputText
                    id="last_name"
                    v-model="editUserForm.last_name"
                    placeholder="Select a Last Name"
                    class="grey-input flex-item"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="flex-input flex-item">
                  <label for="password">Reset Password</label>
                  <Password
                    id="password"
                    v-model="resetPassword.password"
                    placeholder="Enter a Password"
                    toggle-mask
                    :feedback="false"
                    :input-style="{'width': '100%'}"
                    class="grey-input flex-item"
                  />
                </div>
              </div>
              <!-- 
                The guild_login is a boolean value
                The checkbox is checked if the value is true
              -->
              <div class="form-row">
                <label for="guild_login">
                  Guild Login
                </label>
                <Checkbox
                  id="guild_login"
                  v-model="editUserForm.guild_login"
                  :binary="true"
                />
              </div>
              <div class="form-row">
                <label for="clinical_trials_login">
                  Clinical Trials Recruiter Login
                </label>
                <Checkbox
                  id="clinical_trials_login"
                  v-model="editUserForm.clinical_trials_login"
                  :binary="true"
                  @click="getRecruiters"
                />
              </div>
              <div
                v-if="editUserForm.clinical_trials_login"
                class="form-row"
              >
                <div class="flex-input flex-item">
                  <label for="recruiter">Recruiter</label>
                  <Dropdown
                    id="recruiter"
                    v-model="selectedRecruiter"
                    :options="recruiterOptions"
                    :loading="loadingRecruiters"
                    option-label="recruiter_provider_name"
                    input-id="recruiter_provider_id"
                    data-key="recruiter_provider_id"
                    placeholder="Recruiter"
                    class="grey-input"
                  />
                </div>
              </div>
            </div>
            <p
              v-if="editUserError"
              class="error-message"
            >
              {{ editUserError }}
            </p>
            <p
              v-if="editUserSuccess"
              class="success-message"
            >
              {{ editUserSuccess }}
            </p>
          </slot>
        </div>
      
        <!-- FOOTER -->
        <div class="modal-footer">
          <slot name="footer">
            <!-- 
              The updateFilters method is called when the confirm button is clicked
              The method emits an event to the parent component
              -->
            <div
              class="black-button"
              :disabled="loading"
              @click="editPassword"
            >
              Reset Password
            </div>
            <div
              class="black-button"
              :disabled="loading"
              @click="editUser"
            >
              Confirm
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
import axios from 'axios';

export default {
  props: {
    /**
     * The user object
     */
    user: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'closeModal'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
      loading: false,
      editUserError: '',
      editUserSuccess: '',
      resetPassword: {
        password: '',
      },
      loadingRecruiters: false,
      recruiterOptions: [],
      selectedRecruiter: null,
      editUserForm: {
        name: this.user.name,
        first_name: this.user.person.personal_information.first_name,
        last_name: this.user.person.personal_information.last_name,
        email: this.user.email,
        guild_login: this.user.guild_login,
        clinical_trials_login: this.user.clinical_trials_login,
      },
    }
  },
  computed: {
    /**
     * Get recruiters payload
     * @returns {Object}
     */
     getRecruitersPayload() {
      return {
        name: '',
        'recruiter_provider': 'recruiter',
        page: 1,
      }
    },
    /**
     * Edit user payload
     * @returns {Object}
     */
    editUserPayload() {
      return {
        login_id: this.user.login_id,
        name: this.editUserForm.name,
        first_name: this.editUserForm.first_name,
        last_name: this.editUserForm.last_name,
        email: this.editUserForm.email,
        guild_login: this.editUserForm.guild_login,
        clinical_trials_login: this.editUserForm.clinical_trials_login,
        recruiter_id: this.selectedRecruiter.recruiter_provider_id,
      }
    },
  },
  async mounted() {
    /**
     * Set the local variables to the props
     * This is required to ensure that the local variables can be updated prior to emitting changes
     */
    console.log('The user is: ', this.user);
    if (this.user.clinical_trials_login) {
      await this.getRecruiters();
    }
    this.selectedRecruiter = {
        recruiter_provider_id: this.user.recruiter.recruiter_provider_id,
        recruiter_provider_name: this.user.recruiter.recruiter_provider_name,
        recruiter_or_provider: this.user.recruiter.recruiter_or_provider
      };
    console.log('The selected recruiter is: ', this.selectedRecruiter);
  },
  methods: {
    /**
     * Emit the close modal event
     */
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * Edit a user
     * @returns {Promise<void>}
     */
    editUser() {
      this.$store.dispatch('getRefreshToken');
      this.editUserError = '';
      this.editUserSuccess = '';
      this.loading = true;

      axios.post(`/api/user/${this.user.uuid}/edit`, this.editUserPayload, this.$store.state.header)
      .then(res => {
        this.handleEditUserSuccess(res);
      }).catch(err => {
        this.handleEditUserError(this.handleAxiosError(err));
      });
    },
    /**
     * Edit the user's password
     * @param string password - The password
     * 
     * @returns {Promise<void>}
     */
    editPassword() {
      this.$store.dispatch('getRefreshToken');
      this.editUserError = '';
      this.editUserSuccess = '';
      this.loading = true;

      axios.post(`/api/user/${this.user.uuid}/reset/password`, this.resetPassword, this.$store.state.header)
      .then(res => {
        this.handleResetPasswordSuccess(res);
      }).catch(err => {
        this.handleEditUserError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the success response for the user
     * @param {Object} res 
     */
    handleEditUserSuccess(res) {
      console.log('The edit user response is: ', res);
      this.loading = false;
      this.editUserSuccess = 'User Edited Successfully.'
    },
    /**
     * Handle the success response for the password
     * @param {Object} res 
     */
      handleResetPasswordSuccess(res) {
      console.log('The reset password response is: ', res);
      this.loading = false;
      this.editUserSuccess = 'Password reset Successfully.'
    },
    /**
     * Handle the error response for the user
     * @param {Object} err 
     */
    handleEditUserError(err) {
      console.log('The edit user error is: ', err);
      this.loading = false;
      this.editUserError = err;
    },
    /**
     * Get the list of recruiters
     * @param {Object} err
     */
    async getRecruiters() {
      this.$store.dispatch('getRefreshToken');
      try {
          this.loadingRecruiters = true;
          this.recruiterOptions = [];
        const res = await axios.post('/api/recruiter-provider/admin/search', this.getRecruitersPayload, this.$store.state.header);
        this.handleRecruitersSuccess(res);
      } catch (err) {
        this.handleRecruitersError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response from the get recruiters/providers endpoint
     * @param {Object} res
     */
    handleRecruitersSuccess(res) {
      console.log('The get recruiters response is: ', res);
      let recruiters = res.data.data;
      this.loadingRecruiters = false;

      recruiters.forEach(recruiter => {
          this.recruiterOptions.push(recruiter);
      });
    },
    /**
     * Handle the error response from the get recruiters/providers endpoint
     * @param {Object} err
     */
    handleRecruitersError(err) {
      console.log('The get recruiters/providers error is: ', err);
      this.loadingRecruiters = false;
      this.loadingProviders = false;
    },
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.pharmacies-container {
  display: flex;
  border-radius: 5px;
  background-color: $grey;
  overflow-y: auto;
  flex: 1 1 150px;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 10px 0 10px;
}

.pharmacy-bubble {
  display: flex;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  background-color: $bluegreen;
  color: white;
  font-weight: 500;
  font-size: 11pt;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

</style>
    