<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              End User License Agreement
            </h3>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <vue-pdf-embed
                source="/Portal_ToS.pdf"
                style="display: inline-block; padding-top: 10px;"
              />
            </div>
          </slot>
        </div>
      
        <!-- FOOTER -->
        <div class="modal-footer">
          <slot name="footer">
            <div
              class="green-button"
              @click="confirm"
            >
              Confirm
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
import axios from 'axios';
import VuePdfEmbed from 'vue-pdf-embed'

export default {
  components: {
    VuePdfEmbed
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'closeEulaModal'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
    }
  },
  methods: {
    /**
     * Confirm the EULA
     */
    confirm() {
      axios.post('/api/user/confirm-portal-eula', {}, this.$store.state.header)
      .then(res => {
        this.handleConfirmEulaSuccess(res);
      }).catch(err => {
        this.handleConfirmEulaError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the eula success case
     * @param {Object} res
     */
     handleConfirmEulaSuccess(res) {
      console.log('The eula response is', res.data);
      localStorage.setItem('portal_eula', res);
      this.closeEulaModal();
    },
    /**
     * Handle the eula error case
     * @param {Object} err
     */
     handleConfirmEulaError(err) {
      console.log('The sixtydd info error is', err);
    },
    /**
     * Emit the close modal event
     */
    closeEulaModal() {
      this.$emit('closeEulaModal');
    },
  }
}
</script>
  
<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.error-message {
  color: $error;
}

.success-message {
  color: $correct;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.modal-body {
  overflow: auto;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.pharmacies-container {
  display: flex;
  border-radius: 5px;
  background-color: $grey;
  overflow-y: auto;
  flex: 1 1 150px;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 10px 0 10px;
}

.pharmacy-bubble {
  display: flex;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  background-color: $bluegreen;
  color: white;
  font-weight: 500;
  font-size: 11pt;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

</style>
    