<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Filter Category
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <div class="form-row">
                <div
                  class="form-row flex-item"
                  display="flex"
                >
                  <!-- UPTAKE PERCENTAGE -->
                  <div class="flex-input flex-item">
                    <label for="uptake">Uptake</label>
                    <InputNumber
                      id="uptake"
                      v-model="selectedUptakePercentageLocal"
                      class="flex-item"
                      input-id="percent"
                      suffix="%"
                      :min="0"
                      :max="100"
                      placeholder="Enter Uptake Percentage"
                    />
                  </div>
                  <!-- CATEGORY SELECTION -->
                  <div class="flex-input flex-item">
                    <label for="category">Category</label>
                    <Dropdown
                      id="category"
                      v-model="selectedCategoryLocal"
                      class="flex-item"
                      :options="categories"
                      option-label="value"
                      placeholder="Select a Category"
                    />
                  </div>
                </div>
                <!-- STATISTIC DROPDOWN -->
                <div class="flex-input flex-item">
                  <label for="statistic">Statistic</label>
                  <Dropdown
                    id="statistic"
                    v-model="selectedStatisticLocal"
                    class="flex-item"
                    :options="statistics"
                    option-label="value"
                    placeholder="Select a Statistic"
                  />
                </div>
              </div>
              <!-- TIME PERIOD DROPDOWN -->
              <div class="form-row">
                <div class="flex-input flex-item">
                  <label for="tranche">Filter By</label>
                  <SelectButton
                    id="tranche"
                    v-model="selectedTrancheLocal"
                    :options="tranches"
                    option-label="value"
                    :allow-empty="false"
                  />
                </div>
                <div class="flex-item"></div>
                <!-- NUMBER OF PHARMACIES -->
                <!-- <div
                  class="data-box flex-item"
                  :class="selectedPharmaciesLocal.length ? `selected-box` : `unselected-box`"
                >
                  <p>
                    <strong>{{ selectedPharmaciesLocal.length ? selectedPharmaciesLocal.length : 0 }} Pharmacies</strong>
                  </p>
                </div> -->
              </div>
              <div class="form-row">
                <!-- STATE DROPDOWN -->
                <div class="flex-input flex-item">
                  <label for="state">States</label>
                  <MultiSelect
                    id="state"
                    v-model="selectedStatesLocal"
                    placeholder="Select States"
                    class="flex-item"
                    type="text"
                    :options="states"
                  />
                </div>
                <!-- POSTCODE MULTISELECT DROPDOWN -->
                <div class="flex-input flex-item">
                  <label for="postcodes">Postcodes</label>
                  <MultiSelect
                    id="postcodes"
                    v-model="selectedPostcodesLocal"
                    class="flex-item"
                    :options="filteredPostcodesLocal"
                    placeholder="Search Postcodes"
                    selected-items-label="Search Postcodes"
                    :filter="true"
                  />
                </div>
              </div>
              <!-- PHARMACY MULTISELECT DROPDOWN -->
              <div class="flex-input">
                <label for="pharmacies">Pharmacies</label>
                <MultiSelect
                  id="pharmacies"
                  v-model="selectedPharmaciesLocal"
                  :options="filteredPharmacies"
                  option-label="name"
                  placeholder="Search Pharmacies"
                  :max-selected-labels="maxSelectedLabels"
                  selected-items-label="Search Pharmacies"
                  :filter="true"
                />
              </div>
              <!-- SELECTED LOCATIONS CONTAINER -->
              <div class="pharmacies-container">
                <!-- 
                  Pharmacy bubbles are displayed here
                  The pharmacy name is displayed in the bubble
                  The close icon removes the pharmacy from the selectedLocationsLocal array
                 -->
                <div
                  v-for="(item, index) in selectedPharmaciesLocal"
                  :key="item.uuid"
                  class="pharmacy-bubble"
                >
                  <div class="pharmacy-name">
                    {{ item.name }}
                  </div>
                  <i
                    class="pi pi-times close-icon"
                    @click="removePharmacy(index)"
                  ></i>
                </div>
              </div>
            </div>
          </slot>
        </div>

        <!-- FOOTER -->
        <div class="modal-footer">
          <slot name="footer">
            <div
              class="grey-button"
              @click="reset"
            >
              Reset
            </div>
            <!-- 
              The updateFilters method is called when the confirm button is clicked
              The method emits an event to the parent component
             -->
            <div
              class="black-button"
              @click="updateFilters"
            >
              Confirm
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        /**
         * The selected uptake percentage of 60DD
         * @type {Number}
         */
        selectedUptakePercentage: {
            type: Number,
            default: 63
        },
        /**
         * The statistics to be displayed in the statistics dropdown
         * @type {Array}
         */
        statistics: {
            type: Array,
            default() {
                return [];
            }
        },
        /**
         * The selected statistic for display in the table
         * @type {Object}
         */
        selectedStatistic: {
            type: Object,
            default() {
                return {};
            }
        },
        /**
          * The tranches to be displayed in the view trnche selection
          */
        tranches: {
            type: Array,
            default() {
                return [];
            }
        },
        /**
         * The selected tranche
         * @type {Object}
         */
        selectedTranche: {
            type: Object,
            default() {
                return {};
            }
        },
        /**
         * The categories to be displayed in the categories dropdown
         * @type {Array}
         */
        categories: {
            type: Array,
            default() {
                return [];
            }
        },
        /**
         * The selected category
         * @type {Object}
         */
        selectedCategory: {
            type: Object,
            default() {
                return {};
            }
        },
        /**
         * The locations to be displayed in the locations dropdown
         * @type {Array}
         */
        locations: {
            type: Array,
            default() {
                return [];
            }
        },
        /**
         * The selected locations
         * @type {Array}
         */
        selectedLocations: {
            type: Array,
            default() {
                return [];
            }
        },
        /**
         * The selected pharmacies
         * @type {Array}
         */
        selectedPharmacies: {
            type: Array,
            default() {
                return [];
            }
        },
        /**
         * The states to be displayed in the states dropdown
         * @type {Array}
         */
        states: {
            type: Array,
            default() {
                return [];
            }
        },
        /**
         * The selected state
         * @type {String}
         */
        selectedStates: {
            type: Array,
            default() {
                return [];
            }
        }
    },
    /**
     * Emits events to parent component
     */
    emits: [
        "updateFilters",
        "closeModal"
    ],
    data() {
        /**
         * The local data for the component
         */
        return {
            selectedUptakePercentageLocal: 63,
            selectedStatisticLocal: "",
            selectedCategoryLocal: "",
            selectedTrancheLocal: "",
            selectedStatesLocal: [],
            selectedPostcodesLocal: [],
            filteredPostcodesLocal: [],
            selectedLocationsLocal: [],
            selectedPharmaciesLocal: []
        };
    },
    computed: {
        /**
         * Filter the pharmacies based on the selected states and postcodes
         * @return {Array} The filtered pharmacies
         */
        filteredPharmacies: {
            get() {
                let filteredPharmacies = [];
                // Loop through each of the states, and get the postcodes for the selected state
                this.locations.forEach(location => {
                    // Check if the selected states includes the current state
                    if (this.selectedStatesLocal.includes(location.name)) {
                        // Get the pharmacies which match the selected postcodes
                        filteredPharmacies.push(...location.locations.filter(pharmacy => {
                            return this.selectedPostcodesLocal.includes(pharmacy.postcode);
                        }));
                    }
                });
                return filteredPharmacies;
            }
        },
        /**
         * Return 0 for max selected labels - required to pass number as prop
         */
        maxSelectedLabels() {
            return 0;
        }
    },
    watch: {
        selectedStatesLocal(states) {
            let filteredPostcodes = [];
            // Loop through each of the states, and get the postcodes for the selected state
            this.locations.forEach(location => {
                if (states.includes(location.name)) {
                    filteredPostcodes.push(...new Set(location.locations.map(item => item.postcode)));
                }
            });
            this.filteredPostcodesLocal = filteredPostcodes;
            this.selectedPostcodesLocal = filteredPostcodes;
        }
    },
    mounted() {
        /**
         * Set the local variables to the props
         * This is required to ensure that the local variables can be updated prior to emitting changes
         */
        this.selectedUptakePercentageLocal = this.selectedUptakePercentage;
        this.selectedStatisticLocal = this.selectedStatistic;
        this.selectedTrancheLocal = this.selectedTranche;
        this.selectedCategoryLocal = this.selectedCategory;
        this.selectedStatesLocal = this.selectedStates;
        // Map required to avoice each location being passed by reference
        this.selectedLocationsLocal = this.selectedLocations;
        this.selectedPharmaciesLocal = this.selectedPharmacies;
    },
    methods: {
        /**
         * Remove a pharmacy from the selected pharmacies array
         * @param {int} index
         */
        removePharmacy(index) {
            if (index > -1) {
                this.selectedPharmaciesLocal.splice(index, 1);
            }
        },
        /**
         * Reset the local variables
         */
        reset() {
            this.selectedUptakePercentageLocal = 63,
            this.selectedStatisticLocal = "",
            this.selectedTrancheLocal = "",
            this.selectedCategoryLocal = "",
            this.selectedStatesLocal = [],
            this.selectedLocationsLocal = [],
            this.selectedPharmaciesLocal = [];
        },
        /**
         * Emit the update filters event to the parent component
         * The event is emitted with the local variables
         */
        updateFilters() {
            this.closeModal();
            this.$emit("updateFilters", {
                selectedUptakePercentage: this.selectedUptakePercentageLocal,
                selectedStatistic: this.selectedStatisticLocal,
                selectedTranche: this.selectedTrancheLocal,
                selectedCategory: this.selectedCategoryLocal,
                selectedStates: this.selectedStatesLocal,
                selectedLocations: this.selectedLocationsLocal,
                selectedPharmacies: this.selectedPharmaciesLocal
            });
        },
        /**
         * Emit the close modal event
         */
        closeModal() {
            this.$emit("closeModal");
        },
    }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: column;
  height: 78vh;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.p-inputtext,
.p-selectbutton,
.p-password-input,
.p-togglebutton {
  border-radius: 5px;
  border: 1px solid $phoneorders;
  overflow: hidden;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

:deep(.p-selectbutton) {
  display: flex;
}

:deep(.p-selectbutton) div {
  flex: 1;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.pharmacies-container {
  display: flex;
  border-radius: 5px;
  background-color: $grey;
  overflow-y: auto;
  flex: 1 1 150px;
  row-gap: 5px;
  column-gap: 10px;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 20px 10px 0 10px;
}

.pharmacy-bubble {
  display: flex;
  border-radius: 5px;
  padding: 3px 10px 3px 10px;
  background-color: $bluegreen;
  color: white;
  font-weight: 500;
  font-size: 11pt;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.close-icon {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 0;
}

</style>
