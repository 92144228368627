<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Campaign Attachments
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <!-- TEMPLATES -->
            <div class="attachment-type-header">
              <h4>Templates</h4>
            </div>
            <div class="form-row">
              <div
                v-for="template in campaignTemplates"
                :key="template.template_id"
              >
                <div class="template-item">
                  {{ template.template_name }}
                </div>
              </div>
            </div>
            <!-- NOTE -->
            <div class="attachment-type-header">
              <h4>Note</h4>
            </div>
            <div class="form-row">              
              <div>
                <p style="text-align: left;">
                  {{ campaignNote }}
                </p>
              </div>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'CampaignAttachments',
  components: {
  },
  props: {
    /**
     * The templates attached to the campaign
     * @type {Array}
     */
      templates: {
      type: Array,
      default() {
        return []
      },
    },
    /**
     * The note attached to the campaign
     * @type {String}
     */
      note: {
        type: String,
        default() {
          return ''
        }
      }
    },
    emits: [
      'closeModal'
    ],
    data() {
      return {
        campaignTemplates: [],
        campaignNote: ''
      }
    },
    mounted() {
      this.campaignTemplates = this.templates;
      this.campaignNote = this.note;
    },
    methods: {
    /**
     * Closes the modal
     */
      closeModal() {
        this.$emit('closeModal');
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  @import "../../assets/css/main.scss";
  
  .modal-container {
    height: 78vh;
    display: flex;
    flex-direction: column;
  }
  
  .form-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 20px;
  }
  
  .flex-input {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: stretch;
    text-align: left;
  }
  
  .flex-input > label {
    font-size: 0.8rem;
  }
  
  .form-row {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: stretch;
  
    .span {
      display: flex;
    }
  }
  
  .template-item {
    flex: 1;
    background-color: $deepgrey;
    border-radius: 5px;
    padding: 10px 10px 10px 10px;
    margin-top: 10px;
    font-size: 0.8rem;
    color: white;
  }
  
  .radio-group {
    gap: 0.5em;
  }
  
  .modal-body {
    padding-left: 40px !important;
    padding-right: 40px !important;
    padding-top: 1em;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .modal-header {
    padding: 40px 40px 0 40px !important;
  }
  
  .form-header {
    color: black;
    text-align: left;
  }
  
  :deep(.p-accordion-header-link) {
    height: 4em;
    background: white;
    border: none;
    border-top: 1px solid $bordergrey;
  }
  
  :deep(.p-accordion-content) {
    border: none;
  }
  
  :deep(.p-accordion-content) p {
    margin: 0;
    font-size: 11pt;
    font-weight: 500;
    color: $deepgrey;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
  }
  .attachment-type-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $bordergrey;
    }
  </style>