<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              New Template
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <InputText
                v-model="createTemplatePayload.name"
                placeholder="Template Name"
                class="grey-input"
              />
              <div class="form-row flex-end">
                <!-- SUBJECT LINE -->
                <InputText
                  v-if="createTemplatePayload.email_template"
                  v-model="createTemplatePayload.subject"
                  placeholder="Subject Line"
                  class="grey-input flex-item"
                />
                <ToggleButton
                  v-model="createTemplatePayload.email_template"
                  on-label="Email"
                  off-label="Email"
                  on-icon="pi pi-check"
                  off-icon="pi pi-times"
                />
                <ToggleButton
                  v-model="createTemplatePayload.sms_template"
                  on-label="SMS"
                  off-label="SMS"
                  on-icon="pi pi-check"
                  off-icon="pi pi-times"
                />
              </div>
              <TemplateEditor
                class="flex-item"
                :content="createTemplatePayload.body"
                :communication="communicationType"
                @content-updated="setContent"
              />
            </div>
          </slot>
        </div>
        <!-- FOOTER -->
        <div
          class="modal-footer"
          style="padding-left: 0;"
        >
          <div
            v-if="templateCreateError && templateCreateError !==''"
            class="error-text"
          >
            <span
              class="red-error-icon"
            >!</span>
            <span class="error-text-span">{{ templateCreateError }}</span>
          </div>
          <div
            class="black-button"
            style="margin:auto 0;"
            @click="createTemplate"
          >
            {{ loadingTemplateCreate ? 'Activating...' : 'Activate' }}
          </div>
        </div>
        <UserAuthenticationModal
          v-if="displayUserAuthenticationModal"
          :name="createTemplatePayload.name"
          :payload-type="'template'"
          :request-type="'Activate'"
          @login-success="createTemplateLoginSuccess"
          @close-modal="closeOpenModals"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TemplateEditor from '@/components/templates/template-editor.vue';
import UserAuthenticationModal from '@/components/modals/campaign-template-authentication-modal.vue';
import axios from 'axios';

export default {
  name: 'CreateTemplateModal',
  components: {
    TemplateEditor,
    UserAuthenticationModal
  },
  props: {
  },
  emits: [
    'closeModal',
    'templateCreated'

  ],
  data() {
    return {
      createTemplatePayload: {
        name: '',
        body: '',
        subject: '',
        sms_template: true,
        email_template: true,
        active: true,
      },
      displayUserAuthenticationModal: false,
      loadingTemplateCreate: false,
      templateCreateError: null,
    }
  },
  computed: {
    communicationType() {
      if (this.createTemplatePayload.sms_template && this.createTemplatePayload.email_template) {
        return 'both';
      } else if (this.createTemplatePayload.sms_template) {
        return 'sms';
      } else if (this.createTemplatePayload.email_template) {
        return 'email';
      } else {
        return 'both';
      }
    }
  },
  mounted() {
  },
  methods: {
    /**
     * Set the current body
     */
    setContent(content) {
      this.createTemplatePayload.body = content;
    },
    /**
     * Add a template
     */
    addTemplate() {
      this.$store.dispatch('getRefreshToken');
      console.log('add template');
    },
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * Create a template using the information in the createTemplatePayload
     * @returns {Promise} - The promise for the template/create/enterprise request
     */
    createTemplate() {
      this.$store.dispatch('getRefreshToken');
      // Validate the template name
      let errors = [];
      
      if (this.createTemplatePayload.name === '') {
        errors.push('enter a template name');
      }
      if (!this.createTemplatePayload.sms_template && !this.createTemplatePayload.email_template) {
        errors.push('select at least one template type');
      }
      if (this.createTemplatePayload.body === '') {
        errors.push('enter a message body');
      }
      if (!this.createTemplatePayload.body.includes("{Pharmacy Name}")) {
        errors.push('include {Pharmacy Name} in the message body');
      }
      if (errors.length > 0) {
        if (errors.length > 1) {
          errors[errors.length - 1] = 'and ' + errors[errors.length - 1];
        }
        this.templateCreateError = `Please ${errors.join(', ')}.`
        return;
      }
      this.loadingTemplateCreate = true;
      this.displayUserAuthenticationModal = true;
    },
    /**
     * Create a template after successful login
     */
    createTemplateLoginSuccess() {
      this.loadingTemplateCreate = true;
      this.displayUserAuthenticationModal = false;
      this.$store.dispatch('getRefreshToken');

      axios.post('/api/template/create/enterprise', this.createTemplatePayload, this.$store.state.header)
      .then(res => {
        this.handleCreateTemplateSuccess(res);
      }).catch(err => {
        this.handleCreateTemplateError(this.handleAxiosError(err));
      });
    },
    /**
     * Handle the get template list success case
     * @param {Object} res
     */
     handleCreateTemplateSuccess(res) {
      console.log('The get template list response is', res.data);
      let emitTemplateData = {
        template_id: res.data.id,
        name: this.createTemplatePayload.name,
      }
      this.loadingTemplateCreate = false;
      this.$emit('templateCreated', emitTemplateData);
      this.closeModal();
    },
    /**
     * Handle the get template list error case
     * @param {Object} err
     */
     handleCreateTemplateError(err) {
      console.log('The get template list error is', err);
      this.loadingTemplateCreate = false;
      this.templateCreateError = err;
    },
    /**
     * Close all open modals
     */
    closeOpenModals() {
      this.displayUserAuthenticationModal = false;
      this.loadingTemplateCreate = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  height: 78vh;
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-end {
  justify-content: flex-end;
}

.flex-item {
  flex: 1;
}

.radio-group {
  gap: 0.5em;
}

.modal-body {
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 2.5em;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.modal-header {
  padding: 40px 40px 0 40px !important;
}

.form-header {
  color: black;
}

.p-togglebutton {
  border-radius: 5px;
  border: 1px solid $deepgrey;
  background: white;
  color: $deepgrey;
  overflow: hidden;
}

.p-togglebutton :deep(.p-button-icon) {
  padding: 0;
}

.p-togglebutton.p-button.p-highlight {
  background-color: $deepgrey !important;
  color: white !important;
}

:deep(.p-togglebutton.p-focus) {
  box-shadow: none;
}

:deep(.p-button) .p-button-icon {
  padding: 0;
}

.error-text {
  color: $error;
  margin: 0 auto 0 0;
  padding-left: 40px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
  text-align: left;
}

.red-error-icon {
  min-width: 20px;
  background-color: $error;
  border-color: $error;
  color: white;
  border-radius: 50% !important;
  height: 20px;
  width: 20px;
  margin: auto;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.error-text-span {
  margin: auto;
  padding-left: 10px;
}

</style>