<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              ChatGPT Help
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <p>Quick answers about ChatGPT. Can't find what you're looking for? <b>Contact Support.</b></p>
              <Accordion :multiple="true">
                <AccordionTab header="What prompt do I use for email?">
                  <p>
                    An example of an email prompt is:<br><br>
                    “Create an email template to send to pharmacy patients aged 20-29 that have low medication adherence levels, with information, insights and suggestions to help them adhere to their medication regimen.”
                  </p>
                  <WarningMessage style="margin-top: 1em;">
                    Please ensure to exlude any identifiable patient information in ChatGPT prompts
                  </WarningMessage>
                </AccordionTab>
                <AccordionTab header="What prompt do I use for SMS?">
                  <p>
                    An example of an SMS prompt is:<br><br>
                    “Create an SMS template to send to pharmacy patients aged 20-29 that have low medication adherence levels, with information, insights and suggestions to help them adhere to their medication regimen.”
                  </p>
                  <WarningMessage style="margin-top: 1em;">
                    Please ensure to exlude any identifiable patient information in ChatGPT prompts
                  </WarningMessage>
                </AccordionTab>
                <AccordionTab header="I want to use a message created by ChatGPT, how do I use it?">
                  <p>
                    If you are happy to use the template that ChatGPT has created, select “Edit this Message” located underneath the response. Then you will be able to edit the template to your liking.
                  </p>
                  <WarningMessage style="margin-top: 1em;">
                    Ensure ChatGPT Template is reviewed before sending
                  </WarningMessage>
                </AccordionTab>
              </Accordion>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WarningMessage from '@/components/messages/warning-message.vue';

export default {
  name: 'ChatGptHelp',
  components: {
    WarningMessage
  },
  emits: [
    'closeModal'
  ],
  data() {
    return {
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  min-width: 750px;
  min-height: min(800px, 80vh);
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.modal-body {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.modal-header {
  padding: 40px 40px 0 40px !important;
}

.form-header {
  color: black;
}

:deep(.p-accordion-header-link) {
  height: 4em;
  background: white;
  border: none;
  border-top: 1px solid $bordergrey;
}

:deep(.p-accordion-content) {
  border: none;
}

:deep(.p-accordion-content) p {
  margin: 0;
  font-size: 11pt;
  font-weight: 500;
  color: $deepgrey;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

</style>