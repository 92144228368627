<template>
  <div class="flex-column flex-item">
    <div
      v-if="messages.length === 0"
      class="center"
    >
      <p>Please ensure to exlude any <br> identifiable patient information in ChatGPT prompts</p>
    </div>
    <div
      v-else
      class="chat-gpt-container"
    >
      <MessageItem
        v-for="(item, index) in messages"
        :key="index"
        :class="[{ 'right': item.isDark }]"
        :text="item.text"
        :is-dark="item.isDark"
        :editable="item.editable"
        @edit="edit"
      />
    </div>
    <div class="prompt">
      <Button
        icon="pi pi-question"
        text
        rounded
        severity="secondary"
        aria-label="Help"
        @click="openChatGptHelpModal"
      />
      <InputText
        ref="inputField"
        v-model="question"
        placeholder="Type Prompt... (Press 'Enter' for a default prompt)."
        class="grey-input"
        @keyup.enter="chatgpt"
      />
    </div>
    <ChatGptHelpModal
      v-if="displayChatGptHelpModal"
      @close-modal="closeModal"
    />
  </div>
</template>

<script>
import axios from 'axios';
import MessageItem from '@/components/templates/message-item.vue';
import ChatGptHelpModal from '@/components/modals/chat-gpt-help-modal.vue';

export default {
  name: 'ChatBox',
  components: {
    MessageItem,
    ChatGptHelpModal
  },
  props: {
    /**
     * The selected template's communication type
     * @type {Object}
     */
    communication: {
      type: String,
      default: ''
    },
  },
  emits: [
    'edit'
  ],
  data() {
    return {
      question: '',
      loading: false,
      messages: [],
      chatGPTError: '',
      displayChatGptHelpModal: false
    }
  },
  computed: {
    chatGPTPayLoad() {
      return {
        "prompt": this.question,
        "category_name": "",
        "subcategory_name": "",
        "communication_type": this.communication,
      };
    },
  },
  mounted() {
    this.$refs.inputField.$el.focus();
  },
  methods: {
    /**
     * Send a request to chatgpt
     */
    async chatgpt() {
      this.loading = true;
      this.$store.dispatch('getRefreshToken');

      try {
        const res = await axios.post('/api/template/chatgpt', this.chatGPTPayLoad, this.$store.state.header);
        this.handleChatGPTSuccess(res);
      } catch (err) {
        this.handleChatGPTError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the get campaign list success case
     * @param {Object} res
     */
     async handleChatGPTSuccess(res) {
      console.log('The chatGPT response is', res.data);

      this.response = res.data.template_text;
      
      this.addMessage(this.question, true, false);
      this.question = '';

      this.addMessage(this.response, false, true);

      this.loading = false;
    },
    /**
     * Handle the get campaign list error case
     * @param {Object} err
     */
    handleChatGPTError(err) {
      console.log('The chatGPT error is', err);
      this.chatGPTError = err;
      this.loading = false;
    },
    /**
     * Add a message to the chat
     * Add to start of array to display in reverse order
     * @param {String} text - The message text
     * @param {Boolean} isDark - Whether the message is from the user
     * @param {Boolean} editable - Whether the message is editable
     */
    addMessage(text, isDark, editable) {
      this.messages.unshift({
        text,
        isDark,
        editable
      });
    },
    /**
     * On edit, emit the message to the parent component
     * @param {Object} value 
     */
    edit(value) {
      this.$emit('edit', {
        text: value.text
      });
    },
    openChatGptHelpModal() {
      this.$store.dispatch('getRefreshToken');
      console.log('clicked');
      this.displayChatGptHelpModal = true;
    },
    closeModal() {
      this.displayChatGptHelpModal = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-item {
  flex: 1;
}

.chat-gpt-container {
  flex: 1 0 0;
  background-color: white;
  display: flex;
  flex-direction: column-reverse;
  gap: 1em;
  flex-grow: 1;
  overflow-y: auto;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
}

.right {
  margin-left: auto;
}

.prompt {
  height: 3em;
  border-top: 1px solid $bordergrey;
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.prompt .p-inputtext {
  border: none;
  box-shadow: none;
}

.center {
  display: flex;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
}

.center > p {
  text-align: center;
  margin: 0;
  font-size: 12pt;
  font-weight: 600;
}

</style>