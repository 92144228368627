<template>
  <!-- PAGE FILTERS -->
  <div class="filters-row">
    <!-- PRODUCT LIST FILTERS -->
    <div
      v-if="selectedTable === 'stock'"
      class="flex-row flex-item flex-wrap"
    >
      <!-- SEARCH BOX -->
      <span
        class="p-input-icon-right"
        style="flex: 0 1 350px; min-width: 200px;"
      >
        <i
          class="pi pi-search button"
          @click="getS8ProductList"
        ></i>
        <InputText
          v-model="searchTerm"
          placeholder="Search"
          class="grey-input"
          @keyup.enter="getS8ProductList"
        />
      </span>
      <!-- TAGS -->
      <MultiSelect
        v-model="selectedTags"
        :options="tags"
        option-label="name"
        option-value="value"
        placeholder="Select Tags"
        class="grey-input"
        style="flex: 0 1 300px; min-width: 200px;"
      />
      <!-- STATUS -->
      <MultiSelect
        v-model="selectedStatus"
        :options="status"
        option-label="name"
        option-value="value"
        placeholder="Select Status"
        class="grey-input"
        style="flex: 0 1 300px; min-width: 200px;"
      />
      <!-- IN STOCK -->
      <ToggleButton
        v-model="inStock"
        on-label="In Stock"
        off-label="In Stock"
        @change="getS8ProductList"
      />
      <!-- ACTIVE -->
      <ToggleButton
        v-model="active"
        on-label="Active"
        off-label="Active"
        @change="getS8ProductList"
      />
      <!-- REFRESH BUTTON -->
      <Button
        icon="pi pi-refresh"
        aria-label="Refresh"
        class="p-button-raised p-button-rounded no-shrink"
        :loading="loadingProductList"
        @click="getS8ProductList"
      />
      <!-- EXPORT BUTTON -->
      <img
        v-if="!exportingProductListCSV"
        :src="exportIconSrc"
        class="medium-icon button"
        alt="Export Icon"
        @click="exportProductListCSV"
      >
      <ProgressSpinner
        v-else
        class="medium-icon"
        stroke-width="8"
        animation-duration=".75"
        fill="var(--surface-ground)"
        aria-label="Loading"
      />
      <!-- CONTACT -->
      <div
        class="data-box selected-box button"
        style="height: 40px;"
        @click="openContactModal"
      >
        <p>
          <strong>Contact</strong>
        </p>
      </div>
    </div>
    <!-- DRUG REGISTER FILTERS -->
    <div
      v-else-if="selectedTable === 'drug'"
      class="flex-row flex-item flex-wrap"
    >
      <!-- SEARCH BOX -->
      <span
        class="p-input-icon-right"
        style="flex: 0 1 350px; min-width: 200px;"
      >
        <i
          class="pi pi-search button"
          @click="getS8DrugRegister"
        ></i>
        <InputText
          v-model="drugRegisterSearchTerm"
          placeholder="Search"
          class="grey-input"
          @keyup.enter="getS8DrugRegister"
        />
      </span>
      <!-- TAGS -->
      <!-- REINTRODUCE WHEN COUNTERSIGNING IMPLEMENTED -->
      <!-- <MultiSelect
        v-model="selectedDrugRegisterTags"
        :options="drugRegisterTags"
        option-label="name"
        option-value="value"
        placeholder="Select Tags"
        class="grey-input"
        style="flex: 0 1 300px; min-width: 200px;"
      /> -->
      <Calendar
        v-model="dateRange"
        selection-mode="range"
        :date-format="$t('dateFormat')"
        :placeholder="$t('dateRangeFormat')"
        class="grey-input"
        style="flex: 0 1 300px; min-width: 200px;"
        show-button-bar
        @date-select="getS8DrugRegister"
      />
      <!-- REFRESH BUTTON -->
      <Button
        icon="pi pi-refresh"
        aria-label="Refresh"
        class="p-button-raised p-button-rounded no-shrink"
        :loading="loadingDrugRegister"
        @click="getS8DrugRegister"
      />
      <!-- EXPORT BUTTON -->
      <img
        v-if="!exportingDrugRegisterCSV"
        :src="exportIconSrc"
        class="medium-icon button"
        alt="Export Icon"
        @click="exportDrugRegisterCSV"
      >
      <ProgressSpinner
        v-else
        class="medium-icon"
        stroke-width="8"
        animation-duration=".75"
        fill="var(--surface-ground)"
        aria-label="Loading"
      />
      <!-- CONTACT -->
      <div
        class="data-box selected-box button"
        style="height: 40px;"
        @click="openContactModal"
      >
        <p>
          <strong>Contact</strong>
        </p>
      </div>
    </div>
    <!-- TABLE SELECTION -->
    <SelectButton 
      v-model="selectedTable"
      :options="tableOptions"
      :allow-empty="false"
      option-label="name"
      option-value="value"
      style="margin-left: auto; flex-grow: 0; flex-shrink: 0;"
    />
  </div>
  <!-- PRODUCT LIST CONTENT -->
  <div
    v-if="selectedTable == 'stock'"
    class="flex-column flex-item"
    style="min-height: 0;"
  >
    <DataTable
      :value="rowData.results"
      :loading="loadingProductList"
      scrollable
      scroll-height="flex"
      style="min-height: 0; font-size: 14px;"
      class="flex-item"
      size="small"
      :sort-field="productSortField"
      :sort-order="productSortOrder"
      @sort="onSort($event)"
    >
      <template #empty>
        No records found
      </template>
      <template #loading>
        Loading records, please wait...
      </template>
      <Column
        field="product_name"
        header="Product"
        :sortable="true"
        style="min-width: 160px"
      />
      <Column
        header="Tags"
        style="min-width: 80px"
      >
        <template #body="slotProps">
          <DrugTypeTags :params="slotProps.data.tags" />
        </template>
      </Column>
      <Column
        field="balance"
        header="Balance"
        :sortable="true"
        style="min-width: 80px"
      />
      <Column
        header="Last Stocktake"
        field="last_stocktake_on"
        :sortable="true"
        style="min-width: 80px"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.last_stocktake_on">
            {{ $d(new Date(slotProps.data.last_stocktake_on), 'long') }}
          </span>
        </template>
      </Column>
      <Column
        field="last_stocktake_taken_by"
        header="Pharmacist"
        :sortable="true"
        style="min-width: 80px"
      />
      <Column
        field="last_stocktake_note"
        header="Note"
        :sortable="true"
        style="min-width: 80px"
      />
      <Column
        header="Status"
        style="min-width: 80px"
      >
        <template #body="slotProps">
          <StatusTag :params="slotProps.data.status" />
        </template>
      </Column>
    </DataTable>
    <!-- PAGINATION LINKS -->
    <div
      class="pagination-links"
      style="align-self: center;"
    >
      <p 
        v-show="rowData.current_page > 1" 
        class="pagination-arrow"
        @click="displayPage( rowData.current_page - 1 )"
      > 
        &larr; 
      </p>
      <p> 
        Page {{ rowData.current_page }} of {{ rowData.last_page }} 
      </p>
      <p 
        v-show="rowData.current_page < rowData.last_page"
        class="pagination-arrow"  
        @click="displayPage( rowData.current_page + 1 )"
      > 
        &rarr; 
      </p>
    </div>
  </div>
  <!-- DRUG REGISTER CONTENT -->
  <div
    v-else-if="selectedTable == 'drug'"
    class="flex-column flex-item"
    style="min-height: 0;"
  >
    <DataTable
      :value="drugRegisterRowData.results"
      :loading="loadingDrugRegister"
      scrollable
      scroll-height="flex"
      style="min-height: 0; font-size: 14px;"
      class="flex-item"
      size="small"
      :sort-field="drugRegisterSortField"
      :sort-order="drugRegisterSortOrder"
      @sort="onDrugRegisterSort($event)"
    >
      <template #empty>
        No records found
      </template>
      <template #loading>
        Loading records, please wait...
      </template>
      <Column
        field="transaction_product_id"
        header="ID"
        :sortable="true"
        :hidden="true"
      />
      <Column
        header="Date"
        field="date"
        :sortable="true"
        style="min-width: 80px"
      >
        <template #body="slotProps">
          <span v-if="slotProps.data.date">
            {{ $d(new Date(slotProps.data.date), 'short') }}
          </span>
        </template>
      </Column>
      <Column
        field="product_name"
        header="Product"
        :sortable="true"
        style="min-width: 160px"
      />
      <Column
        header="Balance Check"
        style="min-width: 80px"
      >
        <template #body="slotProps">
          <DrugRegisterEntryTags :params="slotProps.data.balance_check_status" />
        </template>
      </Column>
      <Column
        field="entry_type"
        header="Action"
        :sortable="true"
        style="min-width: 80px"
      />
      <Column
        field="name"
        header="Name"
        :sortable="true"
        style="min-width: 160px"
      />
      <Column
        field="address"
        header="Address"
        :sortable="true"
        style="min-width: 160px"
      />
      <Column
        field="in"
        header="In"
        :sortable="true"
        style="min-width: 80px"
      />
      <Column
        field="out"
        header="Out"
        :sortable="true"
        style="min-width: 80px"
      />
      <Column
        field="balance"
        header="Balance"
        :sortable="true"
        style="min-width: 80px"
      />
      <Column
        field="reference_number"
        header="Reference #"
        :sortable="true"
        style="min-width: 80px"
      />
      <Column
        field="pharmacist"
        header="Pharmacist"
        :sortable="true"
        style="min-width: 80px"
      />
      <Column
        field="doctor"
        header="Doctor"
        :sortable="true"
        style="min-width: 80px"
      />
      <Column
        field="note"
        header="Note"
        :sortable="true"
        style="min-width: 80px"
      />
    </DataTable>
    <!-- PAGINATION LINKS -->
    <div
      class="pagination-links"
      style="align-self: center;"
    >
      <p 
        v-show="drugRegisterRowData.current_page > 1" 
        class="pagination-arrow"
        @click="displayDrugRegisterPage( drugRegisterRowData.current_page - 1 )"
      > 
        &larr; 
      </p>
      <p> 
        Page {{ drugRegisterRowData.current_page }} of {{ drugRegisterRowData.last_page }} 
      </p>
      <p 
        v-show="drugRegisterRowData.current_page < drugRegisterRowData.last_page"
        class="pagination-arrow"  
        @click="displayDrugRegisterPage( drugRegisterRowData.current_page + 1 )"
      > 
        &rarr; 
      </p>
    </div>
  </div>
  
  <!-- CONTACT PHARMACY MODAL -->
  <ContactPharmacyModal
    v-if="displayContactModal"
    :pharmacy="pharmacy.location"
    :owner="pharmacy.owner"
    @close-modal="closeModal"
  />
</template>

<script>
import axios from 'axios';
import DrugTypeTags from '@/components/s8/drug-type-tags.vue';
import StatusTag from '@/components/s8/status-tag.vue';
import DrugRegisterEntryTags from '@/components/s8/drug-register-entry-tags.vue';
import ContactPharmacyModal from '@/components/modals/contact-pharmacy-modal.vue';

export default {
  name: "S8PharmacyTable",
  components: {
    ContactPharmacyModal,
    DrugTypeTags,
    StatusTag,
    DrugRegisterEntryTags
  },
  props: {
    pharmacy: {
      type: Object,
      required: true,
    },
  },
  emits: [
    'rowClicked'
  ],
  data() {
    return {
      exportIconSrc: require('@/assets/icons/export_icon.svg'),
      exportingProductListCSV: false,
      exportingDrugRegisterCSV: false,
      selectedTable: "stock",
      tableOptions: [
        { name: "Stock Register", value: "stock" },
        { name: "Drug Register", value: "drug" }
      ],
      // Product List Filters
      searchTerm: '',
      tags: [
        { name: "OTP", value: "otp" },
        { name: "Custom", value: "custom" },
        { name: "Cannabis", value: "cannabis" }
      ],
      selectedTags: [],
      status: [
        { name: "Counted", value: "counted" },
        { name: "Overdue", value: "overdue" },
        { name: "Never", value: "never"}
      ],
      selectedStatus: [
        "counted",
        "overdue"
      ],
      inStock: false,
      active: true,
      loadingProductList: false,
      productListError: '',
      // Drug Register Filters
      drugRegisterSearchTerm: '',
      drugRegisterTags: [
        { name: "Balance", value: "balance" },
        { name: "Countersigned", value: "countersigned" }
      ],
      selectedDrugRegisterTags: [],
      dateRange: null,
      loadingDrugRegister: false,
      drugRegisterError: '',
      drugRegisterSortField: 'transaction_product_id',
      drugRegisterSortOrder: -1,
      // Product List Table Properties
      rowData: [],
      productSortField: 'product_name',
      productSortOrder: 1,
      // Drug Register Table Properties
      drugRegisterRowData: [],
      displayContactModal: false,
    };
  },
  computed: {
    productListPayload() {
      return {
        name: this.searchTerm,
        otp: this.selectedTags.includes('otp'),
        cannabis: this.selectedTags.includes('cannabis'),
        custom: this.selectedTags.includes('custom'),
        counted: this.selectedStatus.includes('counted'),
        overdue: this.selectedStatus.includes('overdue'),
        never: this.selectedStatus.includes('never'),
        in_stock: this.inStock,
        active: this.active,
        s8_stocktake_warning_days: this.pharmacy ? this.pharmacy.s8_stocktake_warning_days : 28,
        sort_field: this.productSortField,
        sort_order: this.productSortOrder === 1 ? 'asc' : 'desc',
        page: this.$store.state.s8ProductListPage || 1,
      }
    },
    exportProductListPayload() {
      return {
        name: this.searchTerm,
        otp: this.selectedTags.includes('otp'),
        cannabis: this.selectedTags.includes('cannabis'),
        custom: this.selectedTags.includes('custom'),
        counted: this.selectedStatus.includes('counted'),
        overdue: this.selectedStatus.includes('overdue'),
        never: this.selectedStatus.includes('never'),
        in_stock: this.inStock,
        active: this.active,
        s8_stocktake_warning_days: this.pharmacy ? this.pharmacy.s8_stocktake_warning_days : 28,
        sort_field: this.productSortField,
        sort_order: this.productSortOrder === 1 ? 'asc' : 'desc',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
    },
    drugRegisterPayload() {
      return {
        name: this.drugRegisterSearchTerm,
        start_date: this.dateRange && this.dateRange.length > 0 ? this.dateRange[0].toISOString() : null,
        end_date: this.dateRange && this.dateRange.length > 1 ? this.dateRange[1].toISOString() : null,
        balance: this.selectedDrugRegisterTags.includes('balance'),
        countersigned: this.selectedDrugRegisterTags.includes('countersigned'),
        sort_field: this.drugRegisterSortField,
        sort_order: this.drugRegisterSortOrder === 1 ? 'asc' : 'desc',
        page: this.$store.state.s8DrugRegisterPage || 1,
      }
    },
    exportDrugRegisterPayload() {
      return {
        name: this.drugRegisterSearchTerm,
        start_date: this.dateRange && this.dateRange.length > 0 ? this.dateRange[0].toISOString() : null,
        end_date: this.dateRange && this.dateRange.length > 1 ? this.dateRange[1].toISOString() : null,
        balance: this.selectedDrugRegisterTags.includes('balance'),
        countersigned: this.selectedDrugRegisterTags.includes('countersigned'),
        sort_field: this.drugRegisterSortField,
        sort_order: this.drugRegisterSortOrder === 1 ? 'asc' : 'desc',
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
    }
  },
  async mounted() {
    // Set the date range - 1 month ago till now
    const currentDate = new Date();
    let to = new Date();
    let from = currentDate;
    from.setMonth(currentDate.getMonth() - 1);
    this.dateRange = [from, to];

    await Promise.all([
      this.getS8ProductList(),
      this.getS8DrugRegister(),
    ]);
  },
  methods: {
    /**
     * Get the S8 Product List
     * @returns {Promise} - The promise for the s8 product list
     */
    async getS8ProductList() {
      this.$store.dispatch('getRefreshToken');
      this.rowData = [];
      this.loadingProductList = true;
      this.productListError = '';

      try {
        const res = await axios.post(`/api/s8/${this.pharmacy.location.uuid}/product-list`, this.productListPayload, this.$store.state.header);
        this.handleS8ProductListSuccess(res);
      } catch (err) {
        this.handleS8ProductListError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the s8 product list
     * @param {Object} res - The response object
     */
    handleS8ProductListSuccess(res) {
      console.log('The S8 Product List response is: ', res.data);
      this.rowData = res.data;
      this.loadingProductList = false;
    },
    /**
     * Handle the error response for the s8 product list
     * @param {Object} err - The error object
     */
    handleS8ProductListError(err) {
      console.error('The S8 Product List error is: ', err);
      this.loadingProductList = false;
      this.productListError = err;
    },
    /**
     * Export the S8 Product List
     * @returns {Promise} - The promise for the s8 product list export
     */
    async exportProductListCSV() {
      this.exportingProductListCSV = true;
      this.$store.dispatch('getRefreshToken');
      console.log('Exporting Product List');

      try {
        const res = await axios.post(`/api/s8/${this.pharmacy.location.uuid}/product-list/export`, this.exportProductListPayload, this.$store.state.header);
        this.handleExportProductListCSVSuccess(res);
      } catch (err) {
        this.handleExportProductListCSVError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the s8 product list export
     * @param {Object} res - The response object
     */
    handleExportProductListCSVSuccess(res) {
      console.log('The S8 Product List export response is: ', res.data);
      // Get the CSV data and download it
      let csv = res.data;
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = `${this.pharmacy.location.name}_stock_register.csv`;
      anchor.click();
      this.exportingProductListCSV = false;
    },
    /**
     * Handle the error response for the s8 product list export
     * @param {Object} err - The error object
     */
    handleExportProductListCSVError(err) {
      console.error('The S8 Product List export error is: ', err);
      this.exportingProductListCSV = false;
    },
    /**
     * Get the S8 Drug Register
     * @returns {Promise} - The promise for the s8 drug register
     */
    async getS8DrugRegister() {
      this.$store.dispatch('getRefreshToken');
      this.drugRegisterRowData = [];
      this.loadingDrugRegister = true;
      this.drugRegisterError = '';

      try {
        const res = await axios.post(`/api/s8/${this.pharmacy.location.uuid}/drug-register`, this.drugRegisterPayload, this.$store.state.header);
        this.handleS8DrugRegisterSuccess(res);
      } catch (err) {
        this.handleS8DrugRegisterError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the s8 drug register
     * @param {Object} res - The response object
     */
     handleS8DrugRegisterSuccess(res) {
      console.log('The S8 Drug Register response is: ', res.data);
      this.drugRegisterRowData = res.data;
      this.loadingDrugRegister = false;
    },
    /**
     * Handle the error response for the s8 drug register
     * @param {Object} err - The error object
     */
    handleS8DrugRegisterError(err) {
      console.error('The S8 Drug Register error is: ', err);
      this.loadingDrugRegister = false;
      this.drugRegisterError = err;
    },
    /**
     * Export the S8 Drug Register
     * @returns {Promise} - The promise for the s8 drug register export
     */
     async exportDrugRegisterCSV() {
      this.exportingDrugRegisterCSV = true;
      this.$store.dispatch('getRefreshToken');
      console.log('Exporting Drug Register');

      try {
        const res = await axios.post(`/api/s8/${this.pharmacy.location.uuid}/drug-register/export`, this.exportDrugRegisterPayload, this.$store.state.header);
        this.handleExportDrugRegisterCSVSuccess(res);
      } catch (err) {
        this.handleExportDrugRegisterCSVError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle the success response for the s8 drug register export
     * @param {Object} res - The response object
     */
    handleExportDrugRegisterCSVSuccess(res) {
      console.log('The S8 Drug Register export response is: ', res.data);
      // Get the CSV data and download it
      let csv = res.data;
      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
      anchor.target = '_blank';
      anchor.download = `${this.pharmacy.location.name}_drug_register.csv`;
      anchor.click();
      this.exportingDrugRegisterCSV = false;
    },
    /**
     * Handle the error response for the s8 drug register export
     * @param {Object} err - The error object
     */
    handleExportDrugRegisterCSVError(err) {
      console.error('The S8 Drug Register export error is: ', err);
      this.exportingDrugRegisterCSV = false;
    },
    /**
     * Handle the page refresh for the s8 product list
     * @param integer page - The page number
     */
    displayPage(page) {
      this.$store.state.s8ProductListPage = page;
      this.getS8ProductList();
    },
    /**
     * Handle the page refresh for the s8 drug register
     * @param integer page - The page number
     */
    displayDrugRegisterPage(page) {
      this.$store.state.s8DrugRegisterPage = page;
      this.getS8DrugRegister();
    },
    /**
     * When the sort order/column is changed
     * @param {Object} event
     */
    onSort(event) {
      this.productSortField = event.sortField;
      this.productSortOrder = event.sortOrder;
      this.getS8ProductList();
    },
    /**
     * When the sort/order column for the drug register is changed
     * @param {Object} event
     */
    onDrugRegisterSort(event) {
      this.drugRegisterSortField = event.sortField;
      this.drugRegisterSortOrder = event.sortOrder;
      this.getS8DrugRegister();
    },
    /**
     * Display the contact modal
     */
    openContactModal() {
      this.$store.dispatch('getRefreshToken');
      this.displayContactModal = true;
    },
    /**
     * Close the open modals
     */
    closeModal() {
      this.displayContactModal = false;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 20px;
  row-gap: 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.filters-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
  gap: 20px;
}

.flex-item {
  flex: 1;
}

.p-selectbutton {
  border-radius: 12px;
  border: 1px solid $bordergrey;
  overflow: hidden;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

:deep(.p-selectbutton) {
  display: flex;
}

:deep(.p-selectbutton) .p-button {
  box-shadow: none;
  white-space: nowrap;
  min-width: max-content;
}

:deep(.p-selectbutton) div {
  flex: 1;
  font-weight: 600;
  background: $grey;
  color: $deepgrey;
  border: none;
}

:deep(.p-selectbutton) div:not(:last-child) {
  border-right: 1px solid $bordergrey;
}

:deep(.p-selectbutton) .p-highlight {
  background: white;
  color: black;
  border: none;
}

.p-togglebutton {
  border-radius: 5px;
  border: 1px solid $strongblue;
  background: white;
  color: $strongblue;
  overflow: hidden;
}

.p-togglebutton :deep(.p-button-icon) {
  padding: 0;
}

.p-togglebutton.p-button.p-highlight {
  background-color: $strongblue !important;
  color: white !important;
}

:deep(.p-togglebutton.p-focus) {
  box-shadow: none;
}

.p-progress-spinner {
  margin: 0;
}

.ag-theme-quartz {
  --ag-font-size: 12px;
  --ag-wrapper-border-radius: 5px;
  --ag-header-background-color: white;
  --ag-font-family: Montserrat, Helvetica, Arial, sans-serif;
  --ag-row-border-style: none;
}
</style>