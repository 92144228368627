<template>
  <div
    class="modal-mask"
  >
    <div class="modal-wrapper">
      <div
        class="modal-container"
      >
        <div class="dialog-body">
          <div class="dialog-header">
            <div class="dialog-header-title">
              <header class="dialog-title">
                Refine Cohort
              </header>
            </div>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i class="pi pi-times"></i>
            </button>
          </div>
          <div class="dialog-content">
            <div class="campaign-refine-cohort-dialog">
              <CampaignRefineCohortForm
                :campaign="campaign"
                :prefix-filters="prefixFilters"
                @filters="setFilters"
                @close-modal="closeModal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignRefineCohortForm from '@/components/campaign/form/campaign-refine-cohort-form.vue';

export default {
  components: {
    CampaignRefineCohortForm,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    }
  },
  emits: [
    'closeModal',
    'filters',
  ],
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    /**
     * Sets the filters
     * @param {Object} filters
     */
    setFilters(filters) {
      this.$emit('filters', filters);
      this.$emit('closeModal');
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-container {
  width: 70%;
  max-width: 1048px;
  min-height: 720px;
}
.campaign-refine-cohort-dialog {
  text-align: left;
}
</style>