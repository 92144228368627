<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
      >
        <div class="modal-header">
          <h3 class="form-header">
            Time out warning
          </h3>
        </div>
        <div class="modal-body">
          <slot name="body">
            <form class="action-modal">
              <!-- BASIC INFO -->
              <div class="form-section-container">
                <div>
                  <div class="flex-row">
                    <i
                      class="pi pi-clock"
                      style="font-size: 2em !important"
                    ></i>
                    <p style="font-size: 1em">
                      Your session will expire in
                    </p>
                  </div>
                  <h2>
                    {{ logoutTime }}
                  </h2>
                  <p style="font-size: 1em">
                    Please click "Continue" to keep working, or click "Log Off" to end your session now.
                  </p>
                </div>
              </div>
            </form>
          </slot>
        </div>

        <div
          class="modal-footer"
          style="display: flex; justify-content: center; padding: 5px 20px;"
        >
          <slot
            name="footer"
            style="display: flex;"
          >
            <button
              class="flat-filled-button"
              @click="extendSession()"
            >
              Continue
            </button>
            <button
              class="flat-light-button"
              @click="eraseLoginDetails()"
            >
              Logout
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  components: {
  },
  props: [],
  emits: ['closeModal'],
  data() {
    return {
    }
  },
  computed: {
    // Convert the timeToLogout from seconds to minutes and seconds
    // If the time is less than 60 seconds, just display the seconds
    // Round seconds to the nearest whole number
    logoutTime() {
      let time = this.$store.state.timeToLogOut;
      let minutes = Math.floor(time / 60);
      let seconds = Math.round(time % 60);
      if (minutes > 0) {
        return `${minutes} mins and ${seconds} secs`;
      } else {
        return `${seconds} secs`;
      }
    }
  },
  mounted() {
  },
  methods: {
    extendSession() {
      console.log('hitting this extend session function');
      this.$store.dispatch('getRefreshToken');
      this.closeModal();
    },
    closeModal() {
      this.$emit('closeModal');
    },
    eraseLoginDetails() {
      axios.post('/api/logout', {}, this.$store.state.header)
      .then(res => {
        console.log('this is the logout res', res.data);
      }).catch(err => {
        console.log('error is', err.response.data);
      })

      // Remove the username and company if rememberUsername is not set
      if (!localStorage.getItem('rememberUsername') || localStorage.getItem('rememberUsername') !== 'true') {
        localStorage.removeItem('username');
        localStorage.removeItem('company');
      }

      localStorage.removeItem('currentLocation');
      localStorage.removeItem('user');
      localStorage.removeItem('session_id');
      localStorage.removeItem('lastRefresh');
      localStorage.removeItem('savedform');
      localStorage.removeItem('sr_login');
      localStorage.removeItem('guild_login');
      localStorage.removeItem('clinical_trials_login');
      localStorage.removeItem('recruiter');
      localStorage.removeItem('sixty_day_signup');
      localStorage.removeItem('data_analytics');
      localStorage.removeItem('supplier_analytics');
      localStorage.removeItem('ad_analytics');
      localStorage.removeItem('ecdr');
      localStorage.removeItem('portal_eula');
      document.cookie = "auth=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
      this.$store.commit('setAuthorization');

      // Remove the user in Splunk for RUM
      this.setSplunkUserSession(undefined);

      this.$router.push('/login');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  min-width: 400px;
  width: 400px;
  padding: 10px;
}

.form-section-container {
    display: flex;
    flex-direction: column;
}

.action-modal {
    background-color: white;
    padding: 15px 0;
    border-radius: 4px;
}

.flex-row {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
