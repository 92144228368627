<template>
  <div class="view">
    <div
      class="panel full-height flex-column"
    >
      <div class="page-header">
        <div class="flex-item flex-row">
          <h1
            class="header-hover"
            @click="loadStatsPage"
          >
            S8 Management
          </h1>
          <h1 v-if="currentComponent === 'S8PharmacyTable'">
            >
          </h1>
          <h1 v-if="currentComponent === 'S8PharmacyTable'">
            {{ selectedPharmacy.location.name }}
          </h1>
        </div>
        <NavFunctions class="nav-functions" />
      </div>
      <KeepAlive exclude="S8PharmacyTable">
        <component
          :is="currentComponent"
          v-bind="currentProperties"
          @row-clicked="rowClicked"
        />
      </KeepAlive>
    </div>
  </div>
</template>

<script>
import NavFunctions from '@/components/navigation/nav-menu-dropdown.vue';
import S8StatsTable from '@/components/s8/s8-stats-table.vue';
import S8PharmacyTable from '@/components/s8/s8-pharmacy-table.vue';
import { ref } from 'vue';

export default {
  name: 'S8ManagementPage',
  components: {
    NavFunctions,
    S8StatsTable,
    S8PharmacyTable,
  },
  setup() {
    return {
    }
  },
  data() {
    return {
      currentComponent: ref('S8StatsTable'),
      currentPharmacy: null,
    }
  },
  computed: {
    currentProperties() {
      if (this.currentComponent === 'S8PharmacyTable') {
        return {
          pharmacy: this.selectedPharmacy
        }
      }
      return null;
    },
  },
  async mounted() {
  },
  methods: {
    /**
     * When a row is clicked, open the pharmacy page
     */
    rowClicked(rowData) {
      this.selectedPharmacy = rowData.data;
      this.loadPharmacyPage();
    },
    /**
     * Load the S8PharmacyTable
     */
    loadPharmacyPage() {
      this.currentComponent = ref('S8PharmacyTable');
    },
    /**
     * Load the S8StatsTable
     */
    loadStatsPage() {
      this.currentComponent = ref('S8StatsTable');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/main.scss";

.full-height {
  height: 100%;
}

.header-hover {
  cursor: pointer;
  border-radius: 5px;
}

.header-hover:hover {
  color: $strongblue;
  background-color: $babyblue;
  border: 1px solid $strongblue;
}

.page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 60px;
  height: 60px;
  margin: 10px 0 10px 0;

  h1 {
    margin: 0;
    padding: 10px;
    border: 1px solid transparent;
  }
}

.nav-functions {
  align-self: flex-start;
  z-index: 3;
}

:deep(.p-inputtext) {
  width: 100%;
}

.p-inputtext,
.p-selectbutton,
.p-password-input {
  border-radius: 5px;
  border: 1px solid $deepgrey;
  overflow: hidden;
}

.p-selectbutton .p-button .p-password-input {
  border: none !important;
}

.flex-item {
  flex: 1;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  gap: 1em;
}
</style>