<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              Time Period
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <!-- TIME PERIOD DROPDOWN -->
              <div class="form-row">
                <Dropdown
                  v-model="selectedPeriodTypeLocal"
                  :options="periods"
                  placeholder="Select a Period"
                  class="flex-item"
                />
                <Dropdown
                  v-if="selectedPeriodTypeLocal === 'Month'"
                  v-model="selectedPeriodLocal.month"
                  :options="months"
                  option-label="name"
                  option-value="value"
                  placeholder="Select a Month"
                  class="flex-item"
                />
                <Dropdown
                  v-if="selectedPeriodTypeLocal === 'Quarter'"
                  v-model="selectedPeriodLocal.quarter"
                  :options="quarters"
                  option-label="name"
                  option-value="value"
                  placeholder="Select a Quarter"
                  class="flex-item"
                />
                <Dropdown
                  v-model="selectedPeriodLocal.year"
                  :options="years"
                  option-label="name"
                  option-value="value"
                  placeholder="Select a Year"
                  class="flex-item"
                />
              </div>
            </div>
          </slot>
        </div>
        <!-- FOOTER -->
        <div class="modal-footer">
          <div
            v-if="selectionError && selectionError !==''"
            class="error-text"
          >
            <span
              class="red-error-icon"
            >!</span>
            <span class="error-text-span">{{ selectionError }}</span>
          </div>
          <slot name="footer">
            <div
              class="grey-button"
              @click="reset"
            >
              Reset
            </div>
            <!-- 
              The updateFilters method is called when the confirm button is clicked
              The method emits an event to the parent component
             -->
            <div
              class="black-button"
              @click="updateFilters"
            >
              Confirm
            </div>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    /**
     * The selected period
     * @type {Object}
     */
    selectedPeriod: {
      type: Object,
      default() {
        return {
          year: new Date().getFullYear(),
          month: null,
          quarter: null
        }
      }
    },
    /**
     * The months which can be selected from
     */
    months: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * The quarters which can be selected from
     */
    quarters: {
      type: Array,
      default() {
        return []
      }
    },
    /**
     * The years which can be selected from
     */
    years: {
      type: Array,
      default() {
        return []
      }
    },
    selectedPeriodType: {
      type: String,
      default: 'Calendar Year'
    },
  },
  /**
   * Emits events to parent component
   */
  emits: [
    'updateFilters',
    'closeModal'
  ],
  data() {
    /**
     * The local data for the component
     */
    return {
      selectedPeriodLocal: {},
      periods: [
        'Calendar Year',
        'Quarter',
        'Month'
      ],
      selectedPeriodTypeLocal: '',
      selectionError: ''
    }
  },
  computed: {
  },
  watch: {
    /**
     * Watch for changes to the selectedPeriodTypeLocal
     * If month is selected, set the quarter to null
     * If quarter is selected, set the month to null
     */
     selectedPeriodTypeLocal: {
      handler() {
        if (this.selectedPeriodTypeLocal === 'Month') {
          this.selectedPeriodLocal.quarter = null;
        } else if (this.selectedPeriodTypeLocal === 'Quarter') {
          this.selectedPeriodLocal.month = null;
        }
        else if (this.selectedPeriodTypeLocal === 'Calendar Year') {
          this.selectedPeriodLocal.quarter = null;
          this.selectedPeriodLocal.month = null;
        }
      },
      immediate: true
    }
  },
  mounted() {
    /**
     * Set the local variables to the props
     * This is required to ensure that the local variables can be updated prior to emitting changes
     */
    this.selectedPeriodLocal = this.selectedPeriod;
    this.selectedPeriodTypeLocal = this.selectedPeriodType;
  },
  methods: {
    /**
     * Reset the local variables
     */
    reset() {
      this.selectedPeriodLocal = {year: new Date().getFullYear(), month: null, quarter: null},
      this.selectedPeriodTypeLocal = 'Calendar Year'
    },
    /**
     * Emit the update filters event to the parent component
     * The event is emitted with the local variables
     */
    updateFilters() {      
      if (this.selectedPeriodTypeLocal === 'Month' && !this.selectedPeriodLocal.month) {
        this.selectionError = 'Please select a month';
        return;
      }
      if (this.selectedPeriodTypeLocal === 'Quarter' && !this.selectedPeriodLocal.quarter) {
        this.selectionError = 'Please select a quarter';
        return;
      }

      this.$emit('updateFilters', {
        selectedPeriod: this.selectedPeriodLocal,
        selectedPeriodType: this.selectedPeriodTypeLocal
      });
      this.closeModal();
    },
    /**
     * Emit the close modal event
     */
    closeModal() {
      this.$emit('closeModal');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  display: flex;
  flex-direction: column;
  height: 20vh;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;
}

.flex-item {
  flex: 1;
}

.modal-body {
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
}

.error-text {
  color: $error;
  margin: 0 auto 0 0;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
}

.red-error-icon {
  background-color: $error;
  border-color: $error;
  color: white;
  border-radius: 50% !important;
  height: 20px;
  width: 20px;
  margin: auto;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.error-text-span {
  margin: auto;
  padding-left: 10px;
}


</style>
