<template>
  <div class="section">
    <span class="p-input-icon-right">
      <i class="pi pi-search"></i>
      <InputText
        v-model="searchTerm"
        placeholder="Fields"
        class="grey-input"
      />
    </span>
    <div class="field-list">
      <div
        v-for="(item, index) in fields"
        :key="index"
        class="field-name"
        :style="computedColor(item)"
        @click="$emit('fieldSelected', item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FieldList',
  emits: [
    'fieldSelected'
  ],
  data() {
    return {
      searchTerm: '',
      fields: [
        {
          name: '{First Name}',
          rectColor: '#E5F1FE',
          fontColor: '#0870DC',
          serverValue: '{first_name}'
        },
        {
          name: '{Last Name}',
          rectColor: '#E5F1FE',
          fontColor: '#0870DC',
          serverValue: '{last_name}'
        },
        {
          name: '{Pharmacy Name}',
          rectColor: '#E0FFEE',
          fontColor: '#00AD50',
          serverValue: '{pharmacy_name}'
        },
        {
          name: '{Pharmacy Address}',
          rectColor: '#E0FFEE',
          fontColor: '#00AD50',
          serverValue: '{pharmacy_address}'
        },
        {
          name: '{Pharmacy Number}',
          rectColor: '#E0FFEE',
          fontColor: '#00AD50',
          serverValue: '{pharmacy_number}'
        }
      ],
      computedColor(field) {
        return {
          background: field.rectColor,
          color: field.fontColor
        }
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-right: 1px solid $deepgrey;
}

.field-list {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: stretch;
  text-align: center;
}

.field-name {
  font-weight: 600;
  border-radius: 5px;
  padding: 0.3em 0em 0.3em 0em;
  margin: 0em 10px 0em 10px;
  cursor: pointer;
}

:deep(.p-inputtext:focus) {
  box-shadow: none;
}
</style>