<template>
  <div class="modal-mask">
    <div class="modal-wrapper">
      <div
        class="modal-container"
        style="width: 30%;"
      >
        <!-- HEADER -->
        <div class="modal-header">
          <slot name="header">
            <h3 class="form-header">
              {{ editMode ? "Edit": "Create" }} Recruiter/Provider
            </h3>
            <button
              class="red-close-button"
              @click="closeModal"
            >
              <i
                class="pi pi-times"
              ></i>
            </button>
          </slot>
        </div>
        <!-- BODY -->
        <div class="modal-body">
          <slot name="body">
            <div class="form-container">
              <div class="flex-input">
                <label for="recruiter_name">Recruiter/Provider Name</label>
                <InputText
                  id="trial_name"
                  v-model="createEditRecruiterProviderForm.name"
                  placeholder="Name"
                  class="grey-input"
                />
              </div>
              <div class="flex-input">
                <label for="recruiter_description">Description</label>
                <InputText
                  id="description"
                  v-model="createEditRecruiterProviderForm.description"
                  placeholder="Description"
                  class="grey-input"
                />
              </div>
              <div class="form-row">
                <div class="flex-input flex-item">
                  <label for="recruiter_provider">Recruiter/Provider</label>
                  <Dropdown
                    id="recruiter_provider"
                    v-model="createEditRecruiterProviderForm.recruiter_provider"
                    :options="['recruiter', 'provider']"
                    placeholder="Select Recruiter/Provider"
                    class="grey-input"
                  />
                </div>
                <div class="flex-input flex-item">
                  <label for="logo_url">Logo URL</label>
                  <InputText
                    id="logo_url"
                    v-model="createEditRecruiterProviderForm.logo_url"
                    placeholder="Logo URL"
                    class="grey-input"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="flex-input flex-item">
                  <label for="phone_number">Phone Number</label>
                  <InputText
                    id="phone_number"
                    v-model="createEditRecruiterProviderForm.phone"
                    placeholder="Phone Number"
                    class="grey-input"
                  />
                </div>
                <div class="flex-input flex-item">
                  <label for="email">Email</label>
                  <InputText
                    id="email"
                    v-model="createEditRecruiterProviderForm.email"
                    placeholder="Email"
                    class="grey-input"
                  />
                </div>
              </div>
              <div class="form-row">
                <div class="flex-input flex-item">
                  <label for="website">Website</label>
                  <InputText
                    id="website"
                    v-model="createEditRecruiterProviderForm.website"
                    placeholder="Website URL"
                    class="grey-input"
                  />
                </div>
                <div class="flex-input flex-item">
                  <label for="privacy policy">Privacy Policy</label>
                  <InputText
                    id="privacy_policy_url"
                    v-model="createEditRecruiterProviderForm.privacy_policy_url"
                    placeholder="Privacy Policy URL"
                    class="grey-input"
                  />
                </div>
              </div>
            </div>
          </slot>
        </div>
        <!-- FOOTER -->
        <div
          class="modal-footer"
          style="padding-left: 0;"
        >
          <div
            v-if="createEditRecruiterProviderError && createEditRecruiterProviderError !==''"
            class="error-text"
          >
            <span
              class="red-error-icon"
            >!</span>
            <span class="error-text-span">{{ createEditRecruiterProviderError }}</span>
          </div>
          <div
            v-else-if="createEditRecruiterProviderSuccess && createEditRecruiterProviderSuccess !==''"
            class="success-message" 
          >
            <span class="error-text-span">{{ createEditRecruiterProviderSuccess }}</span>
          </div>
          <div
            class="black-button"
            style="margin:auto 0;"
            @click="activate"
          >
            {{ actionButtonText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'AdminCreateEditRecruiterProviderModal',
  components: {
  },
  props: {
    /**
     * The selected trials
     * @type {Object}
     */
    recruiterProvider: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  emits: [
    'closeModal',
    'recruiterProviderCreated',
    'recruiterProviderEdited'
  ],
  data() {
    return {
      selectedRecruiterProvider: [],
      createEditRecruiterProviderForm: {
        name: '',
        description: '',
        recruiter_provider: '',
        logo_url: '',
        phone: '',
        email: '',
        website: '',
        privacy_policy_url: '',
      },
      loadingCreateRecruiterProvider: false,
      createEditRecruiterProviderError: '',
      createEditRecruiterProviderSuccess: '',      
      editMode: false,
      actionButtonText: 'Create',
    }
  },
  computed: {
    /**
     * Create Trial Payload
     */
    createEditRecruiterProviderPayload() {
      let payload = {
        name: this.createEditRecruiterProviderForm.name,
        description: this.createEditRecruiterProviderForm.description,
        recruiter_or_provider: this.createEditRecruiterProviderForm.recruiter_provider,
        logo_url: this.createEditRecruiterProviderForm.logo_url,
        phone: this.createEditRecruiterProviderForm.phone,
        email: this.createEditRecruiterProviderForm.email,
        website: this.createEditRecruiterProviderForm.website,
        privacy_policy_url: this.createEditRecruiterProviderForm.privacy_policy_url,
      };

      return payload;
    },
  },
  async mounted() {
    this.selectedRecruiterProvider = this.recruiterProvider;

    if (!this.selectedRecruiterProvider || !this.selectedRecruiterProvider.recruiter_provider_id) {
      this.editMode = false;
      this.actionButtonText = 'Create';
    }
    else {
      this.editMode = true;
      this.actionButtonText = 'Update';

      this.createEditRecruiterProviderForm.name = this.recruiterProvider.name;
      this.createEditRecruiterProviderForm.description = this.recruiterProvider.description;
      this.createEditRecruiterProviderForm.recruiter_provider = this.recruiterProvider.recruiter_or_provider;
      this.createEditRecruiterProviderForm.logo_url = this.recruiterProvider.logo_url;
      this.createEditRecruiterProviderForm.phone = this.recruiterProvider.phone;
      this.createEditRecruiterProviderForm.email = this.recruiterProvider.email;
      this.createEditRecruiterProviderForm.website = this.recruiterProvider.website;
      this.createEditRecruiterProviderForm.privacy_policy_url = this.recruiterProvider.privacy_policy_url;
    }
  },
  methods: {
    /**
     * Create or Update the Recruiter/Provider
    */
    activate() {
      this.$store.dispatch('getRefreshToken');
      this.createEditRecruiterProviderError = '';
      let errors = [];
      if (this.createEditRecruiterProviderForm.name === '') {
        errors.push('enter a recruiter/provider name');
      }
      if (this.createEditRecruiterProviderForm.description === '') {
        errors.push('enter eligibility criteria');
      }
      if (this.createEditRecruiterProviderForm.recruiter_provider === '') {
        errors.push('select a recruiter/provider');
      }
      if (this.createEditRecruiterProviderForm.logo_url === '') {
        errors.push('enter a logo url');
      }
      if (this.createEditRecruiterProviderForm.phone === '') {
        errors.push('enter a phone number');
      }
      if (this.createEditRecruiterProviderForm.email === '') {
        errors.push('enter an email');
      }
      if (this.createEditRecruiterProviderForm.website === '') {
        errors.push('enter a website');
      }
      if (this.createEditRecruiterProviderForm.privacy_policy_url === '') {
        errors.push('enter a privacy policy');
      }
      if (errors.length > 0) {
        // If there are multiple errors, add 'and' before the last error
        if (errors.length > 1) errors[errors.length - 1] = `and ${errors[errors.length - 1]}`;
        // Join the errors till the second last with a comma and the last with an 'and'
        this.createEditRecruiterProviderError = `Please ${errors.join(', ')}.`;
        return;
      }      
      this.loadingCreateRecruiterProvider = true;
      console.log('The recruiter/provider form is: ', this.createEditRecruiterProviderForm);
      this.editMode ? this.updateRecruiterProvider() : this.createRecruiterProvider();
    },
    /**
     * Create the Recruiter/Provider
     */
    async createRecruiterProvider() {
      this.$store.dispatch('getRefreshToken');
      this.loadingCreateRecruiterProvider = true;
      this.actionButtonText = 'Creating...';

      // Call the Create Trial Endpoint

      try {
        const res = await axios.post('/api/recruiter-provider/admin/create', this.createEditRecruiterProviderPayload, this.$store.state.header);
        this.handleRecruiterProviderCreateSuccess(res);
      } catch (err) {
        this.handleRecruiterProviderCreateError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle Recruiter/Provider Creation Success
     * @param {Object} res
     */
    handleRecruiterProvideCreateSuccess(res) {
      console.log('The recruiter/provider creation response is: ', res);
      this.loadingCreateRecruiterProvider = false;
      this.actionButtonText = 'Create';
      this.createEditRecruiterProviderSuccess = 'Recruiter/Provider Created Successfully';
      this.clear();

      this.$emit('recruiterProviderCreated', res); // Emit the created Trial when successful
      // this.$emit('closeModal');
    },
    /**
     * Handle Recruiter/Provider Creation Error
     * @param {Object} err
     */
    handleRecruiterProviderCreateError(err) {
      console.log('The Trial creation error is: ', err);
      this.loadingCreateRecruiterProvider = false;
      this.actionButtonText = 'Create';
      this.createEditRecruiterProviderError  = err;
    },
    /**
     * Update the Recruiter/Provider Info
     */
    async updateRecruiterProvider() {
      this.$store.dispatch('getRefreshToken');
      this.loadingCreateRecruiterProvider = true;
      this.actionButtonText = 'Updating...';

      // Call the Update recruiter/provider Endpoint

      try {
        const res = await axios.post(`/api/recruiter-provider/admin/${this.selectedRecruiterProvider.recruiter_provider_id}/edit`, this.createEditRecruiterProviderPayload, this.$store.state.header);
        this.handleRecruiterProviderUpdateSuccess(res);
      } catch (err) {
        this.handleRecruiterProviderUpdateError(this.handleAxiosError(err));
      }
    },
    /**
     * Handle Recruiter/Provider Update Success
     * @param {Object} res
     */
     handleRecruiterProviderUpdateSuccess(res) {
      console.log('The recruiter/provider update response is: ', res);
      this.loadingCreateRecruiterProvider = false;
      this.createEditRecruiterProviderSuccess = 'Recruiter/Provider Info Updated Successfully';
      this.actionButtonText = 'Update';
      this.$emit('recruiterProviderEdited', res); // Emit the updated trial when successful
      // this.$emit('closeModal');
    },
    /**
     * Handle Trial Update Error
     * @param {Object} err
     */
     handleRecruiterProviderUpdateError(err) {
      console.log('The recruiter/provider update error is: ', err);
      this.loadingCreateRecruiterProvider = false;
      this.createEditRecruiterProviderError  = err;
    },
    /**
     * Clear the form
     */
    clear() {
      this.createEditRecruiterProviderForm = {
          name: '',
          description: '',
          recruiter_provider: '',
          logo_url: '',
          phone: '',
          email: '',
          website: '',
          privacy_policy_url: '',
        };
    },
    /**
     * Close the modal
     */
    closeModal() {
      this.$emit('closeModal');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/main.scss";

.modal-container {
  height: 78vh;
  display: flex;
  flex-direction: column;
}

.form-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 20px;
}

.flex-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: stretch;
  text-align: left;
}

.flex-input > label {
  font-size: 0.8rem;
}

.form-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: stretch;

  .span {
    display: flex;
  }
}

.flex-item {
  flex: 1;
}

.radio-group {
  gap: 0.5em;
}

.modal-body {
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 2.5em;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.modal-header {
  padding: 40px 40px 0 40px !important;
}

.form-header {
  color: black;
}

:deep(.p-accordion-header-link) {
  height: 4em;
  background: white;
  border: none;
  border-top: 1px solid $bordergrey;
}

:deep(.p-accordion-content) {
  border: none;
}

:deep(.p-accordion-content) p {
  margin: 0;
  font-size: 11pt;
  font-weight: 500;
  color: $deepgrey;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

:deep(.p-calendar-w-btn .p-datepicker-trigger) {
  background: $strongblue;
}

.error-text {
  color: $error;
  margin: 0 auto 0 0;
  padding-left: 40px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
  text-align: left;
}

.red-error-icon {
  min-width: 20px;
  background-color: $error;
  border-color: $error;
  color: white;
  border-radius: 50% !important;
  height: 20px;
  width: 20px;
  margin: auto;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.error-text-span {
  margin: auto;
  padding-left: 10px;
}

.success-message {
  color: $complete;
  margin: 0 auto 0 0;
  padding-left: 40px;
  font-weight: 600;
  font-size: 0.9rem;
  display: flex;
  font-weight: 600;
  text-align: left;
}

</style>  