<template>
  <Card
    class="card-pa-0 mb-4 overflow-hidden"
    style="height: 600px;"
  >
    <template #content>
      <div class="d-flex h-100">
        <div
          style="min-width: 300px; width: 300px;"
          class="d-flex flex-col"
        >
          <div>
            <div
              class="p-input-icon-left flex-item left-margin"
              style="width: 100%;"
            >
              <i class="pi pi-search px-1"></i>
              <InputText
                v-model="search"
                placeholder="Molecule"
                class="grey-input border-none"
                style="width: 100%; padding: 10px 32px;"
                :disabled="disabled"
                @input="searchMolecules"
              />
            </div>
          </div>
          <Divider class="my-0 mb-2" />
          <template v-if="loading">
            <div class="d-flex align-center">
              <ProgressSpinner />
            </div>
          </template>
          <div
            v-else
            class="d-flex flex-col"
            style="margin-top: 1px; flex-grow: 1;"
          >
            <div class="d-flex flex-row ga-2 mb-2 px-2">
              <Checkbox
                v-model="selectAll"
                :binary="true"
                :disabled="disabled"
                @change="onSelectAllMolecules"
              />
              <span>Select All</span>
            </div>
            <VirtualScroller
              v-if="!loading"
              :items="filtered_molecule_items"
              :item-size="30"
              :num-tolerated-items="30"
              style="flex-grow: 1; height: 100%;"
            >
              <template #item="{item}">
                <div class="d-flex flex-row ga-2 mb-2 px-2">
                  <Checkbox 
                    v-model="molecules" 
                    :input-id="item.molecule" 
                    :name="item.molecule"
                    :value="item"
                    :disabled="disabled"
                    @change="onSelectMolecule(item)"
                  />
                  <label 
                    :for="item.molecule"
                  >
                    {{ item.molecule }}
                  </label>
                </div>
              </template>
            </VirtualScroller>
          </div>
        </div>
        <Divider
          layout="vertical"
          class="mx-0"
        />
        <div
          class="d-flex flex-col"
          style="flex-grow: 1; min-width: 0;"
        >
          <div class="fs-14 font-weight-500 px-2 py-3">
            {{ molecules.length }} Selected
          </div>
          <VirtualScroller
            :items="molecules"
            :item-size="16"
            :num-tolerated-items="10"
            class="molecules-selected"
          >
            <template #item="{item}">
              <Chip
                :label="item.molecule"
                :removable="!disabled"
                @remove="onRemoveMolecule(item)"
              />
            </template>
          </VirtualScroller>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import axios from 'axios';
import VirtualScroller from 'primevue/virtualscroller';

export default {
  components: {
    VirtualScroller,
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  emits: [
    'update:modelValue',
    'fullMoleculeList'
  ],
  data() {
    return {
      molecule_items: [],
      filtered_molecule_items: [],
      molecules: [],
      loading: false,
      search: '',
      selectAll: false,
    }
  },
  watch: {
    modelValue(value) {
      this.molecules = value
    },
    molecules(value) {
      if (value.length < this.filtered_molecule_items.length) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
    },
  },
  async mounted() {
    await this.getMolecules()
    this.molecules = this.modelValue
  },
  methods: {
    async getMolecules() {
      this.$store.dispatch('getRefreshToken');
      this.loading = true

      await axios.post(`/api/molecule-list`, {} , this.$store.state.header)
        .then(res => {
          this.molecule_items = res.data.molecule_atc_codes
          this.molecule_items.sort((a, b) => {
            return a.molecule.localeCompare(b.molecule)
          })
          if (this.molecule_items.length > 0) {
            this.filtered_molecule_items = this.molecule_items
          }
          this.selectAll = true;
          this.onSelectAllMolecules();
          this.$emit('fullMoleculeList', this.molecule_items)
        }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
    },
    onSelectAllMolecules() {
      if (this.selectAll)
        this.molecules = this.filtered_molecule_items
      else
        this.molecules = []
      this.onUpdateMolecules()
    },
    onSelectMolecule() {
      this.sortMolecules()
      this.onUpdateMolecules()
    },
    sortMolecules() {
      this.molecules.sort((a, b) => {
        return a.molecule.localeCompare(b.molecule)
      })
    },
    searchMolecules() {
      this.filtered_molecule_items = this.molecule_items.filter(molecule => {
        return molecule.molecule.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    onRemoveMolecule(molecule) {
      this.molecules = this.molecules.filter(mol => mol.molecule != molecule.molecule)
      this.sortMolecules()
      this.onUpdateMolecules()
    },
    onUpdateMolecules() {
      this.$emit('update:modelValue', this.molecules)
    },
  },
}
</script>

<style lang="scss" scoped>
.p-card {
  border: solid 1px #BDC4C4;
  border-radius: 10px;
  box-shadow: none;
  color: #7B7E7E;
}

:deep(.p-card-content) {
  padding: 0 !important;
  height: 100%;
}

.card-pa-0 :deep(.p-card-body) {
  padding: 0;
  height: 100%;
}

:deep(.p-datatable .p-datatable-thead) {
  background-color: #FFFFFF !important;
}

:deep(.p-datatable .p-datatable-thead > tr > th) {
  font-size: 14px;
  font-weight: 500;
  padding: 5px 10px;
  border: none;
  background: #FFFFFF;
}

:deep(.p-datatable .p-column-header-content) {
  color: #7B7E7E;
}

:deep(.p-datatable .p-datatable-tbody > tr > td) {
  color: #7B7E7E;
  font-size: 14px;
  font-weight: 500;
  padding: 2px 10px;
  border: none;
}

.molecules-selected :deep(.p-virtualscroller-content) {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: auto !important;
  max-height: 100%;
  gap: 4px;
  padding: 0 8px 8px 8px;
}

.p-chip {
  flex: 0 0 50%;
  max-width: calc(50% - 4px);
  flex-basis: calc(50% - 4px);
  flex-grow: 1;
  background: $strongblue;
  color: #FFFFFF;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
}

:deep(.p-chip-text) {
  flex-grow: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.p-chip :deep(.p-chip-remove-icon) {
  min-width: 16px;
}
</style>